import React from 'react';
import { FormGroup, Label, Input, Form, Button, FormFeedback } from 'reactstrap';
import { NativeSelect, TextField, InputLabel } from '@material-ui/core';
import PropTypes from 'prop-types';
import SelectorSimple from '../Comunes/SelectorSimple';
import Alert from '../../shared/components/alert/Alert';
import {
  obtenerTurnos,
  obtenerOperaciones,
  obtenerProcesosEspeciales,
  obtenerProgramas,
  obtenerSubsecciones,
  obtenerUbicacionesPorSubseccion,
  obtenerTallasPorGrupo,
  obtenerGruposTallas,
} from '../Comunes/datosComunes';
import SelectorCascada from '../Comunes/SelectorCascada';
import { registrarCorreccionComoNuevoEvento, obtenerInfoLoteEficiencia } from './datosCorreccion';
import { CryUsername } from '../../appConfig.json';

class CreacionEvento extends React.Component {
  static mostrarValidacion(errores) {
    let mensaje = '';
    if (errores) {
      for (let i = 0; i < errores.length; i += 1) {
        mensaje += errores[i];
      }
    }
    return mensaje;
  }
  static iniciarEstadoErrores() {
    return {
      cedulaOperario: [],
      codigoLote: [],
      operacionId: [],
      codigoMaterial: [],
      codigoCategoria: [],
      turno: [],
      tallaId: [],
      pares: [],
      cantidadLote: [],
      unidadMedida: [],
      ubicacionId: [],
      fechaInicio: [],
      fechaFin: [],
      eventoMecatronicaId: [],
      notificadoTejido: [],
    };
  }
  constructor(props) {
    super(props);
    this.handleChange = this.handleChange.bind(this);
    this.guardarEvento = this.guardarEvento.bind(this);
    this.cerrarFormulario = this.cerrarFormulario.bind(this);
    this.actualizarEstado = this.actualizarEstado.bind(this);
    this.validarFormulario = this.validarFormulario.bind(this);
    this.resetearEstadoErrores = this.resetearEstadoErrores.bind(this);
    this.cambioLote = this.cambioLote.bind(this);
    this.state = {
      cedulaOperario: '',
      codigoLote: '',
      codigoMaterial: '',
      operacionId: '',
      turno: '',
      grupoTallaId: '',
      tallaId: '',
      descripcionTalla: '',
      descripcionGrupoTalla: '',
      procesoEspecial: '',
      codigoCategoria: '',
      fechaInicio: '',
      fechaFin: '',
      programaId: '',
      pares: 0,
      cantidadLote: 0,
      unidadMedida: '',
      eventoMecatronicaId: '0',
      subseccionId: '',
      ubicacionId: '',
      cantidadEsperada: 0,
      cantidadProducida: 0,
      minutosEsprerados: 0,
      minutosProducidos: 0,
      notificadoTejido: '',
      estandar: 0,
      loading: false,
      saving: false,
      eventoModificado: false,
      errores: CreacionEvento.iniciarEstadoErrores(),
      errorForm: false,
      mensajeErrorGeneral: null,
      cargandoInfoLote: false,
      editarInfoLote: true,
    };
  }
  componentWillUnmount() {
    this.abortController.abort();
  }
  abortController = new AbortController();
  handleChange(e) {
    this.setState({ [e.target.name]: e.target.value });
  }
  async actualizarEstado(evento, eventoModificado) {
    this.setState({
      cedulaOperario: evento.cedulaOperario,
      codigoLote: evento.codigoLote,
      codigoMaterial: evento.codigoMaterial,
      operacionId: evento.operacionId,
      turno: evento.turno,
      descripcionGrupoTalla: evento.descripcionGrupoTalla,
      descripcionTalla: evento.descripcionTalla,
      procesoEspecial: evento.procesoEspecial,
      codigoCategoria: evento.codigoCategoria,
      fechaInicio: evento.fechaInicio,
      fechaFin: evento.fechaFin,
      programaId: evento.programaId,
      pares: evento.pares,
      cantidadLote: evento.cantidadLote,
      unidadMedida: evento.unidadMedida,
      eventoMecatronicaId: evento.eventoMecatronicaId,
      subseccionId: evento.subseccionId,
      ubicacionId: evento.ubicacionId,
      cantidadEsperada: evento.cantidadEsperada,
      cantidadProducida: evento.cantidadProducida,
      minutosEsprerados: evento.minutosEsprerados,
      minutosProducidos: evento.minutosProducidos,
      notificadoTejido: evento.notificadoTejido,
      estandar: evento.estandar,
      loading: false,
      saving: false,
      eventoModificado,
      errores: CreacionEvento.iniciarEstadoErrores(),
    });
  }
  async guardarEvento() {
    if (await this.validarFormulario()) {
      const evento = {
        cedulaOperario: this.state.cedulaOperario,
        codigoLote: this.state.codigoLote,
        codigoMaterial: this.state.codigoMaterial,
        codigoCategoria: this.state.codigoCategoria,
        operacionId: this.state.operacionId,
        turno: this.state.turno,
        grupoTallaId: this.state.grupoTallaId,
        tallaId: this.state.tallaId,
        procesoEspecial: this.state.procesoEspecial,
        fechaInicio: this.state.fechaInicio,
        fechaFin: this.state.fechaFin,
        programaId: this.state.programaId,
        pares: this.state.pares,
        cantidadLote: this.state.cantidadLote,
        unidadMedida: this.state.unidadMedida,
        eventoMecatronicaId: this.state.eventoMecatronicaId,
        subseccionId: this.state.subseccionId,
        ubicacionId: this.state.ubicacionId,
        notificadoTejido: this.state.notificadoTejido,
        cantidadEsperada: this.state.cantidadEsperada,
        cantidadProducida: this.state.cantidadProducida,
        minutosEsprerados: this.state.minutosEsprerados,
        minutosProducidos: this.state.minutosProducidos,
        usuario: localStorage.getItem(CryUsername),
      };
      this.setState({ saving: true });
      const resultado = await registrarCorreccionComoNuevoEvento(evento);
      if (!resultado.error) {
        if (this.props.onSave) {
          this.props.onSave(resultado.datos);
        }
        await this.actualizarEstado(resultado.datos, true);
      } else {
        const xerrores = CreacionEvento.iniciarEstadoErrores();
        const errores = { ...xerrores, ...resultado.datos };
        this.setState({ saving: false, errorForm: true, errores });
      }
    }
  }
  async cambioLote(e) {
    const lote = e.target.value;
    if (lote != null) {
      const numeroLote = parseInt(lote, 10);
      if (!isNaN(numeroLote) && numeroLote > 0) {
        this.setState({
          codigoMaterial: '',
          codigoCategoria: '',
          descripcionTalla: '',
          descripcionGrupoTalla: '',
          grupoTallaId: '',
          tallaId: '',
          cargandoInfoLote: true,
        });
        this.obtenerInfoLote(lote);
      }
    }
  }
  async obtenerInfoLote(lote) {
    const result = await obtenerInfoLoteEficiencia(lote, this.abortController.signal);
    this.setState({
      codigoMaterial: result.datos.codigoMaterial,
      codigoCategoria: result.datos.codigoCategoria,
      tallaId: result.datos.tallaId,
      descripcionTalla: result.datos.descripcionTalla,
      grupoTallaId: result.datos.grupoTallaId,
      descripcionGrupoTalla: result.datos.descripcionGrupoTalla,
      cargandoInfoLote: false,
      editarInfoLote: result.datos.codigoLote == null,
    });
  }
  async validarFormulario() {
    const errores = CreacionEvento.iniciarEstadoErrores();
    let hayError = false;
    if (this.state.cedulaOperario == null || this.state.cedulaOperario === '') {
      errores.cedulaOperario.push('Debe especificar el empleado');
      hayError = true;
    }
    if (this.state.codigoLote == null || this.state.codigoLote === '') {
      errores.codigoLote.push('Debe especificar el lote');
      hayError = true;
    }
    if (this.state.operacionId == null || this.state.operacionId === '') {
      errores.operacionId.push('Debe especificar la operación');
      hayError = true;
    }
    if (this.state.turno == null || this.state.turno === '') {
      errores.turno.push('Debe especificar el turno');
      hayError = true;
    }
    if ((this.state.codigoMaterial == null || this.state.codigoMaterial === '')) {
      errores.codigoMaterial.push('Debe especificar el código del material');
      hayError = true;
    }
    if ((this.state.codigoCategoria == null || this.state.codigoCategoria === '')) {
      errores.codigoCategoria.push('Debe specificar el código de la categoría');
      hayError = true;
    }
    if (this.state.editarInfoLote && (this.state.tallaId == null || this.state.tallaId === '')) {
      errores.tallaId.push('Debe especificar una talla');
      hayError = true;
    }
    if (this.state.cantidadLote == null || this.state.cantidadLote === '') {
      errores.cantidadLote.push('Debe especificar una cantidad');
      hayError = true;
    }
    const fechaInicio = Date.parse(this.state.fechaInicio);
    if (!fechaInicio) {
      errores.fechaInicio.push('Debe specificar una fecha de inicio');
      hayError = true;
    }
    const fechaFin = Date.parse(this.state.fechaFin);
    if (!fechaFin) {
      errores.fechaFin.push('Debe specificar una fecha de finalización');
      hayError = true;
    }
    if (errores.fechaInicio.length === 0 && errores.fechaFin.length === 0) {
      if (fechaInicio > fechaFin) {
        errores.fechaInicio.push('La fecha de inicio debe ser anterior a la fecha de finalización');
        errores.fechaFin.push('La fecha de finalización debe ser posterior a la fecha de inicio');
        hayError = true;
      }
    }
    if (this.state.pares == null || this.state.pares === '') {
      errores.pares.push('Debe especificicar la cantidad de pares');
      hayError = true;
    }
    if (this.state.unidadMedida == null || this.state.unidadMedida === '') {
      errores.unidadMedida.push('Debe especificar la unidad de medida');
      hayError = true;
    }
    if (this.state.eventoMecatronicaId == null || this.state.eventoMecatronicaId === '') {
      errores.eventoMecatronicaId.push('Debe especificar el evento mecatrónica');
      hayError = true;
    } else {
      const rg = new RegExp('^[0-9]+$');
      if (!rg.test(this.state.eventoMecatronicaId)) {
        errores.eventoMecatronicaId.push('El formato no es válido. Debe ser un número entero');
        hayError = true;
      }
    }
    if (this.state.ubicacionId == null || this.state.ubicacionId === '') {
      errores.ubicacionId.push('Debe especificar una subsección y a continuación una ubicación');
      hayError = true;
    }
    if (this.state.notificadoTejido == null || this.state.notificadoTejido === '') {
      errores.notificadoTejido.push('Debe especificar el notificado tejido');
      hayError = true;
    }
    this.setState({ errores, errorForm: hayError });
    return !hayError;
  }
  async resetearEstadoErrores() {
    const errores = CreacionEvento.iniciarEstadoErrores();
    this.setState({ errores, errorForm: false, mensajeErrorGeneral: null });
  }
  cerrarFormulario() {
    if (this.props.onClose) {
      this.props.onClose();
    }
  }
  render() {
    if (this.state.loading) {
      return <div>Cargando...</div>;
    }
    return (
      <Form>
        {
          this.state.errorForm &&
          <Alert color="danger" className="alert--bordered" icon toggle={this.resetearEstadoErrores}>
            Hay errores en el formulario. Por favor verifique y corríjalos.&nbsp;
            {this.state.mensajeErrorGeneral}
          </Alert>
        }
        <div className="row">
          <FormGroup className="col-3">
            <InputLabel>Empleado</InputLabel>
            <TextField
              type="text"
              name="cedulaOperario"
              value={this.state.cedulaOperario}
              onChange={this.handleChange}
              disabled={this.state.saving}
              invalid={this.state.errores.cedulaOperario.length > 0}
            />
            <FormFeedback>{CreacionEvento.mostrarValidacion(this.state.errores.cedulaOperario)}</FormFeedback>
          </FormGroup>
          <FormGroup className="col-3">
            <InputLabel>Lote</InputLabel>
            <TextField
              type="text"
              name="codigoLote"
              value={this.state.codigoLote}
              onChange={this.handleChange}
              onBlur={this.cambioLote}
              disabled={this.state.saving}
              invalid={this.state.errores.codigoLote.length > 0}
            />
            <FormFeedback>{CreacionEvento.mostrarValidacion(this.state.errores.codigoLote)}</FormFeedback>
          </FormGroup>
          <FormGroup className="col-3">
            <InputLabel>Material</InputLabel>
            <TextField
              type="text"
              name="codigoMaterial"
              value={this.state.codigoMaterial}
              onChange={this.handleChange}
              readOnly={!this.state.editarInfoLote}
              placeholder={this.state.cargandoInfoLote ? 'Espere...' : ''}
              invalid={
                this.state.editarInfoLote &&
                this.state.errores.codigoMaterial.length > 0
              }
            />
            <FormFeedback>{CreacionEvento.mostrarValidacion(this.state.errores.codigoMaterial)}</FormFeedback>
          </FormGroup>
          <FormGroup className="col-3">
            <InputLabel>Categoria</InputLabel>
            <TextField
              type="text"
              name="codigoCategoria"
              value={this.state.codigoCategoria}
              onChange={this.handleChange}
              readOnly={!this.state.editarInfoLote}
              placeholder={this.state.cargandoInfoLote ? 'Espere...' : ''}
              invalid={
                this.state.editarInfoLote &&
                this.state.errores.codigoCategoria.length > 0
              }
            />
            <FormFeedback>{CreacionEvento.mostrarValidacion(this.state.errores.codigoCategoria)}</FormFeedback>
          </FormGroup>
        </div>
        <div className="row">
          {!this.state.editarInfoLote || this.state.cargandoInfoLote ?
            <React.Fragment>
              <FormGroup className="col-3">
                <InputLabel>Grupo talla</InputLabel>
                <TextField
                  type="text"
                  name="descripcionGrupoTalla"
                  value={this.state.descripcionGrupoTalla}
                  readOnly
                  placeholder={this.state.cargandoInfoLote ? 'Espere...' : ''}
                />
              </FormGroup>
              <FormGroup className="col-3">
                <Label>Talla</Label>
                <Input
                  type="text"
                  name="descripcionTalla"
                  value={this.state.descripcionTalla}
                  readOnly
                  placeholder={this.state.cargandoInfoLote ? 'Espere...' : ''}
                />
              </FormGroup>
            </React.Fragment> :
            <SelectorCascada
              primaryLabel="Grupo talla"
              filteredLabel="Talla"
              primaryInputName="grupoTallaId"
              filteredInputName="tallaId"
              primaryValue={this.state.grupoTallaId}
              filteredValue={this.state.tallaId}
              onChange={this.handleChange}
              onFilteredChange={this.handleChange}
              getParents={obtenerGruposTallas}
              getChilds={obtenerTallasPorGrupo}
              primaryCol={3}
              filteredCol={3}
              disabled={this.state.saving}
              filteredInvalid={
                this.state.editarInfoLote &&
                this.state.errores.tallaId.length > 0
              }
              filteredInvalidMessage={CreacionEvento.mostrarValidacion(this.state.errores.tallaId)}
            />
          }
          <div className="col-3">
            <SelectorSimple
              inputName="operacionId"
              className="selectOperacionGEvento"
              value={this.state.operacionId}
              primaryLabel="Operación General"
              onChange={this.handleChange}
              obtenerDatos={obtenerOperaciones}
              mostrarEtiqueta
              campoId="id"
              campoDesc="descripcion"
              disabled={this.state.saving}
              invalid={this.state.errores.operacionId.length > 0}
              invalidMessage={CreacionEvento.mostrarValidacion(this.state.errores.operacionId)}
            />
            <SelectorSimple
              inputName="programaId"
              value={this.state.programaId}
              primaryLabel="Programa"
              onChange={this.handleChange}
              obtenerDatos={obtenerProgramas}
              mostrarEtiqueta
              className="selectProgramaEvent"
              campoId="id"
              campoDesc="descripcion"
              disabled={this.state.saving}
            />
          </div>
        </div>
        <div className="row">
          <SelectorSimple
            inputName="turno"
            value={this.state.turno}
            primaryLabel="Turno"
            onChange={this.handleChange}
            obtenerDatos={obtenerTurnos}
            mostrarEtiqueta
            className="col-3"
            campoId="id"
            campoDesc="descripcionTurno"
            disabled={this.state.saving}
            invalid={this.state.errores.turno.length > 0}
            invalidMessage={CreacionEvento.mostrarValidacion(this.state.errores.turno)}
          />
          <FormGroup className="col-3">
            <InputLabel>Inicio</InputLabel>
            <TextField
              name="fechaInicio"
              type="datetime-local"
              value={this.state.fechaInicio}
              disabled={this.state.saving}
              onChange={this.handleChange}
              invalid={this.state.errores.fechaInicio.length > 0}
            />
            <FormFeedback>{CreacionEvento.mostrarValidacion(this.state.errores.fechaInicio)}</FormFeedback>
          </FormGroup>
          <FormGroup className="col-3">
            <InputLabel>Fin</InputLabel>
            <TextField
              name="fechaFin"
              type="datetime-local"
              value={this.state.fechaFin}
              disabled={this.state.saving}
              onChange={this.handleChange}
              invalid={this.state.errores.fechaFin.length > 0}
            />
            <FormFeedback>{CreacionEvento.mostrarValidacion(this.state.errores.fechaFin)}</FormFeedback>
          </FormGroup>
          <FormGroup className="col-3">
            <InputLabel>Unidad medida</InputLabel>
            <TextField
              type="text"
              name="unidadMedida"
              value={this.state.unidadMedida}
              onChange={this.handleChange}
              disabled={this.state.saving}
              invalid={this.state.errores.unidadMedida.length > 0}
            />
            <FormFeedback>{CreacionEvento.mostrarValidacion(this.state.errores.unidadMedida)}</FormFeedback>
          </FormGroup>
        </div>
        <div className="row">
          <FormGroup className="col-3">
            <InputLabel>Pares</InputLabel>
            <TextField
              type="number"
              name="pares"
              value={this.state.pares}
              onChange={this.handleChange}
              disabled={this.state.saving}
              invalid={this.state.errores.pares.length > 0}
            />
            <FormFeedback>{CreacionEvento.mostrarValidacion(this.state.errores.pares)}</FormFeedback>
          </FormGroup>
          <SelectorSimple
            inputName="procesoEspecial"
            value={this.state.procesoEspecial}
            primaryLabel="Paro"
            onChange={this.handleChange}
            obtenerDatos={obtenerProcesosEspeciales}
            mostrarEtiqueta
            className="col-3"
            campoId="id"
            campoDesc="descripcion"
            disabled={this.state.saving}
          />
          <FormGroup className="col-3">
            <InputLabel>Cantidad</InputLabel>
            <TextField
              type="number"
              name="cantidadLote"
              value={this.state.cantidadLote}
              onChange={this.handleChange}
              disabled={this.state.saving}
              invalid={
                this.state.errores &&
                this.state.errores.cantidadLote &&
                this.state.errores.cantidadLote.length > 0
              }
            />
            <FormFeedback>{CreacionEvento.mostrarValidacion(this.state.errores.cantidadLote)}</FormFeedback>
          </FormGroup>
          <FormGroup className="col-3">
            <InputLabel>Evento mecatrónica</InputLabel>
            <TextField
              type="text"
              name="eventoMecatronicaId"
              value={this.state.eventoMecatronicaId}
              onChange={this.handleChange}
              disabled={this.state.saving}
              invalid={this.state.errores.eventoMecatronicaId.length > 0}
            />
            <FormFeedback>{CreacionEvento.mostrarValidacion(this.state.errores.eventoMecatronicaId)}</FormFeedback>
          </FormGroup>
        </div>
        <div className="row">
          <div className="selectEventSeccion">
            <SelectorCascada
              className="selectEventSeccion"
              primaryLabel="Subsección"
              filteredLabel="Ubicación"
              primaryInputName="subseccionId"
              filteredInputName="ubicacionId"
              primaryValue={this.state.subseccionId}
              filteredValue={this.state.ubicacionId}
              onChange={this.handleChange}
              onFilteredChange={this.handleChange}
              getParents={obtenerSubsecciones}
              getChilds={obtenerUbicacionesPorSubseccion}
              primaryCol={3}
              filteredCol={3}
              disabled={this.state.saving}
              filteredInvalid={
                this.state.errores &&
                this.state.errores.ubicacionId &&
                this.state.errores.ubicacionId.length > 0
              }
              filteredInvalidMessage={CreacionEvento.mostrarValidacion(this.state.errores.ubicacionId)}
            />
          </div>
          <FormGroup className="col-6">
            <NativeSelect
              name="notificadoTejido"
              className="selectNotiTejido"
              value={this.state.notificadoTejido}
              onChange={this.handleChange}
              disabled={this.state.saving}
              invalid={this.state.errores.notificadoTejido.length > 0}
            >
              <option key="x" value="" defaultValue="">Notificado tejido</option>
              <option key="true" value="true">Sí</option>
              <option key="false" value="false">No</option>
            </NativeSelect>
            <FormFeedback>{CreacionEvento.mostrarValidacion(this.state.errores.notificadoTejido)}</FormFeedback>
          </FormGroup>
          <div className="col-3">&nbsp;</div>
        </div>
        <div className="row mt-3 pt-3 border-top">
          <span className="col" />
          <FormGroup className="col-2">
            <InputLabel>Estandar</InputLabel>
            <TextField
              disabled
              type="text"
              name="estandar"
              value={this.state.estandar}
              onChange={this.handleChange}
              readOnly
            />
          </FormGroup>
          <FormGroup className="col-2">
            <InputLabel>Cantidad esperada</InputLabel>
            <TextField
              disabled
              type="text"
              name="cantidadEsperada"
              value={this.state.cantidadEsperada}
              onChange={this.handleChange}
              readOnly
            />
          </FormGroup>
          <FormGroup className="col-2">
            <InputLabel>Cantidad producida</InputLabel>
            <TextField
              disabled
              type="text"
              name="cantidadProducida"
              value={this.state.cantidadProducida}
              onChange={this.handleChange}
              readOnly
            />
          </FormGroup>
          <FormGroup className="col-2">
            <InputLabel>Minutos esperados</InputLabel>
            <TextField
              disabled
              type="text"
              name="minutosEsprerados"
              value={this.state.minutosEsprerados}
              onChange={this.handleChange}
              readOnly
            />
          </FormGroup>
          <FormGroup className="col-2">
            <InputLabel>Minutos producidos</InputLabel>
            <TextField
              disabled
              type="text"
              name="minutosProducidos"
              value={this.state.minutosProducidos}
              onChange={this.handleChange}
              readOnly
            />
          </FormGroup>
          <span className="col" />
        </div>
        <div className="row">
          <div className="col text-center">
            <Button
              className="btn btn-primary"
              type="button"
              name="guardar"
              onClick={this.guardarEvento}
              disabled={this.state.saving || this.state.eventoModificado}
            >
              {this.state.saving ? 'Guardando' : 'Guardar'}
            </Button>
            <Button type="button" name="cerrar" onClick={this.cerrarFormulario}>Cerrar</Button>
          </div>
        </div>
      </Form>
    );
  }
}
CreacionEvento.propTypes = {
  onSave: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default CreacionEvento;
