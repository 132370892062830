/* eslint-disable import/prefer-default-export */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { TableRow, TableCell } from '@material-ui/core';
import { InformationIcon } from 'mdi-react';
import { Chip } from './../styles';

import { OperacionDialog } from './OperacionDialog';

export class FilteredProcess extends Component {
  constructor(props) {
    super(props);
    this.state = {
      operaciones: [{}],
      open: false,
      operacion: {},
    };
  }

  componentDidMount = () => {
    this.setState({
      operaciones: this.props.operaciones,
    });
  }

  componentWillReceiveProps = (nextProps) => {
    this.setState({
      operaciones: nextProps.operaciones,
    });
  }

  checkUndefinedEstandar = grupoTalla => (grupoTalla !== undefined ? grupoTalla.estandar : 0);

  handleClickOpen = (operacion) => {
    this.toggleModal();
    this.setState({
      operacion,
    });
  };

  handleSelect = (ope) => {
    this.props.handleProcessSelect(ope);
    this.setState({
      open: false,
    });
  }

  toggleModal = () => {
    this.setState(prevState => ({
      open: !prevState.open,
    }));
  };

  render() {
    const { operaciones, open, operacion } = this.state;
    return (
      <>
        {
          operaciones.map(ope => (
            <TableRow key={ope.id}>
              <TableCell>
                <Chip
                  label={ope.nombre}
                  variant="outlined"
                  color="primary"
                  deleteIcon={<InformationIcon />}
                  onDelete={() => this.handleClickOpen(ope)}
                  onClick={() => this.handleSelect(ope)}
                />
              </TableCell>
            </TableRow>))
        }
        <OperacionDialog
          toggleModal={this.toggleModal}
          open={open}
          operacion={operacion}
          handleProcessSelect={() => this.handleSelect(operacion)}
        />
      </>
    );
  }
}

FilteredProcess.propTypes = {
  operaciones: PropTypes.arrayOf(PropTypes.element).isRequired,
  handleProcessSelect: PropTypes.func.isRequired,
};
