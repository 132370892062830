import React, { useState } from 'react';
import {
  Grid,
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Paper,
  TablePagination,
  Input,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import 'react-table/react-table.css';
import format from 'date-fns/format';

const Reporte = (props) => {
  const { ordenes } = props;
  const formatoFecha = new Intl.DateTimeFormat('es-CO');
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [filters, setFilters] = useState({});

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleFilterChange = (columnId, value) => {
    setFilters(prevFilters => ({
      ...prevFilters,
      [columnId]: value,
    }));
  };

  const formato = (d) => {
    if (d) {
      const x = new Date(Date.parse(d));
      return format(x, 'dd/MM/yyyy HH:mm');
    }
    return null;
  };

  const filteredData = ordenes.filter(row =>
    Object.keys(filters).every((columnId) => {
      const cellValue = String(row[columnId]).toLowerCase();
      const filterValue = filters[columnId].toLowerCase();
      return cellValue.includes(filterValue);
    }));
  const filtroFecha = (f, r) => {
    const x = formato(r[f.id]);
    return x && x.toLowerCase().indexOf(f.value.toLowerCase()) >= 0;
  };
  const filtroNumero = (f, r) => {
    const x = r[f.id];
    if (x != null && !isNaN(x)) {
      return x.toString(10).indexOf(f.value) >= 0;
    }
    return false;
  };
  return (
    <TableContainer component={Paper}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell align="left">Centro
              <Input
                placeholder="Filtrar"
                onChange={e => handleFilterChange('codigoCentro', e.target.value)}
              />
            </TableCell>
            <TableCell align="right">Tipo Material
              <Input
                placeholder="Filtrar"
                onChange={e => handleFilterChange('tipoMaterial', e.target.value)}
              />
            </TableCell>
            <TableCell align="left">Orden Prev.
              <Input
                placeholder="Filtrar"
                onChange={e => handleFilterChange('codigoOrdenPrevisional', e.target.value)}
              />
            </TableCell>
            <TableCell align="left">Orden Fab.
              <Input
                placeholder="Filtrar"
                onChange={e => handleFilterChange('codigoOrdenFabricacion', e.target.value)}
              />
            </TableCell>
            <TableCell align="left">Sol. pedido
              <Input
                placeholder="Filtrar"
                onChange={e => handleFilterChange('codigoOrdenFabricacion', e.target.value)}
              />
            </TableCell>
            <TableCell align="right">Material FERT
              <Input
                placeholder="Filtrar"
                onChange={e => handleFilterChange('ordenProduccion', e.target.value)}
              />
            </TableCell>
            <TableCell align="right">Matriz FERT
              <Input
                placeholder="Filtrar"
                onChange={e => handleFilterChange('materialFert', e.target.value)}
              />
            </TableCell>
            <TableCell align="right">Categoría
              <Input
                placeholder="Filtrar"
                onChange={e => handleFilterChange('codigoMatrizFert', e.target.value)}
              />
            </TableCell>
            <TableCell align="right">Orden TP
              <Input
                placeholder="Filtrar"
                onChange={e => handleFilterChange('codigoOrdenTP', e.target.value)}
              />
            </TableCell>
            <TableCell align="right">Tipo cambio
              <Input
                placeholder="Filtrar"
                onChange={e => handleFilterChange('observacionTipoOrden', e.target.value)}
              />
            </TableCell>
            <TableCell align="right">Material TP
              <Input
                placeholder="Filtrar"
                onChange={e => handleFilterChange('observacionTipoOrden', e.target.value)}
              />
            </TableCell>
            <TableCell align="right">Matriz TP
              <Input
                placeholder="Filtrar"
                onChange={e => handleFilterChange('observacionTipoOrden', e.target.value)}
              />
            </TableCell>
            <TableCell align="right">Fecha Ini. tejido
              <Input
                placeholder="Filtrar"
                onChange={e => filtroFecha('fechaInicioTejido', e.target.value)}
              />
            </TableCell>
            <TableCell align="right">Máquina
              <Input
                placeholder="Filtrar"
                onChange={e => handleFilterChange('codigoMaquina', e.target.value)}
              />
            </TableCell>
            <TableCell align="right">cantidadProgramada
              <Input
                placeholder="Filtrar"
                onChange={e => handleFilterChange('cantidadProgramada', e.target.value)}
              />
            </TableCell>
            <TableCell align="right">Cant. Tej.
              <Input
                placeholder="Filtrar"
                onChange={e => handleFilterChange('cantidadTejida', e.target.value)}
              />
            </TableCell>
            <TableCell align="right">Cant. Pend. x tejer
              <Input
                placeholder="Filtrar"
                onChange={e => handleFilterChange('cantidadPendienteTejer', e.target.value)}
              />
            </TableCell>
            <TableCell align="right">Reserva
              <Input
                placeholder="Filtrar"
                onChange={e => handleFilterChange('codigoReserva', e.target.value)}
              />
            </TableCell>
            <TableCell align="right">F. creación reserva
              <Input
                placeholder="Filtrar"
                onChange={e => filtroFecha('fechaCreacionReserva', e.target.value)}
              />
            </TableCell>
            <TableCell align="right">Tipo SMP
              <Input
                placeholder="Filtrar"
                onChange={e => handleFilterChange('tipoOrdenParcialReserva', e.target.value)}
              />
            </TableCell>
            <TableCell align="right">Estado parcial
              <Input
                placeholder="Filtrar"
                onChange={e => handleFilterChange('textoEstadoParcialReserva', e.target.value)}
              />
            </TableCell>
            <TableCell align="right">Número NT
              <Input
                placeholder="Filtrar"
                onChange={e => filtroNumero('numeroTransporte', e.target.value)}
              />
            </TableCell>
            <TableCell align="right">F. real solicitado
              <Input
                placeholder="Filtrar"
                onChange={e => filtroFecha('fechaCreacionDetalleParcialReserva', e.target.value)}
              />
            </TableCell>
            <TableCell align="right">Número OT
              <Input
                placeholder="Filtrar"
                onChange={e => filtroNumero('codigoOrdenTransporteParcialReserva', e.target.value)}
              />
            </TableCell>
            <TableCell align="right">F. real despachado
              <Input
                placeholder="Filtrar"
                onChange={e => filtroFecha('fechaCreacionOrdenTransporteParcialReserva', e.target.value)}
              />
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {(rowsPerPage > 0
            ? ordenes.slice(
              page * rowsPerPage,
              page * rowsPerPage + rowsPerPage,
            )
            : ordenes
          ).map(row => (
            <TableRow key={row.id}>
              <TableCell align="right">{row.codigoCentro}</TableCell>
              <TableCell align="left">{row.tipoMaterial}</TableCell>
              <TableCell align="left">{row.codigoOrdenPrevisional}</TableCell>
              <TableCell align="left">{row.codigoOrdenFabricacion}</TableCell>
              <TableCell align="right">{row.ordenProduccion}</TableCell>
              <TableCell align="right">{row.materialFert}</TableCell>
              <TableCell align="right">{row.codigoMatrizFert}</TableCell>
              <TableCell align="right">{row.codigoCategoria}</TableCell>
              <TableCell align="right">{row.codigoOrdenTP}</TableCell>
              <TableCell align="right">{row.observacionTipoOrden}</TableCell>
              <TableCell align="right">{row.codigoMaterialTP}</TableCell>
              <TableCell align="right">{row.codigoMatrizTP}</TableCell>
              <TableCell align="right">{row.fechaInicioTejido}</TableCell>
              <TableCell align="right">{row.codigoMaquina}</TableCell>
              <TableCell align="right">{row.cantidadProgramada}</TableCell>
              <TableCell align="right">{row.cantidadTejida}</TableCell>
              <TableCell align="right">{row.cantidadPendienteTejer}</TableCell>
              <TableCell align="right">{row.codigoReserva}</TableCell>
              <TableCell align="right">{row.fechaCreacionReserva}</TableCell>
              <TableCell align="right">{row.tipoOrdenParcialReserva}</TableCell>
              <TableCell align="right">{row.textoEstadoParcialReserva}</TableCell>
              <TableCell align="right">{row.numeroTransporte}</TableCell>
              <TableCell align="right">{row.fechaCreacionDetalleParcialReserva}</TableCell>
              <TableCell align="right">{row.codigoOrdenTransporteParcialReserva}</TableCell>
              <TableCell align="right">{row.fechaCreacionOrdenTransporteParcialReserva}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      {ordenes.length > 0 && (
        <Grid item xs={12}>
          <TablePagination
            rowsPerPageOptions={[5, 10, 15, 20, 30, 50, 100]}
            component="div"
            count={ordenes.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
            labelRowsPerPage="Registros por página:"
            labelDisplayedRows={({ from, to, count }) => `${from}-${to} de ${count}`}
          />
        </Grid>
      )}
    </TableContainer>
  );
};

Reporte.propTypes = {
  ordenes: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default Reporte;
