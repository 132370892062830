import React, { Component } from 'react';
import PropTypes from 'prop-types';
import SidebarLink from './SidebarLink';
import SidebarCategory from './SidebarCategory';
import { perteneceRolIngenieria } from '../../Comunes/datosComunes';

class MenuCalcetines extends Component {
  static propTypes = {
    onClick: PropTypes.func.isRequired,
  };

  hideSidebar = () => {
    this.props.onClick();
  };

  render() {
    return (
      <div className="sidebar__content">
        <ul className="sidebar__block">
          {/* <SidebarCategory title="Monitor Eficiencias" icon="chart-bars">
            <SidebarLink title="Unidades de Negocio" route="/unidades" onClick={this.hideSidebar} /> */}
          {/* <SidebarLink title="Plantas" route="/plantas" onClick={this.hideSidebar} /> */}
          {/* <SidebarLink title="Secciones" route="/secciones" onClick={this.hideSidebar} /> */}
          {/* </SidebarCategory> */}
        </ul>
        <ul className="sidebar__block">
          <SidebarCategory title="Correcciones" icon="magic-wand">
            <SidebarLink title="Correccion Eventos" route="/eventos/correccion" onClick={this.hideSidebar} />
            <SidebarLink title="Correccion Estandares" route="/estandares" onClick={this.hideSidebar} />
            {
              perteneceRolIngenieria() &&
              <SidebarLink
                title="Carga Estandares"
                route="/cargaestandares"
                onClick={this.hideSidebar}
              />
            }
          </SidebarCategory>
        </ul>
        <ul className="sidebar__block">
          <SidebarCategory title="Maestros" icon="hand">
            <SidebarLink title="Pasillos turnos" route="/maestro/pasillos-turnos" onClick={this.hideSidebar} />
            <SidebarLink title="Maestro Paros" route="/maestro/procesosEspeciales" onClick={this.hideSidebar} />
            <SidebarLink title="Maestro Tallas" route="/maestro/tallas" onClick={this.hideSidebar} />
            <SidebarLink title="Maestro Turnos" route="/maestro/turnos" onClick={this.hideSidebar} />
            <SidebarLink title="Maestro Maquinas" route="/maestro/maquinas" onClick={this.hideSidebar} />
            <SidebarLink title="Maestro Secciones" route="/maestro/secciones" onClick={this.hideSidebar} />
            <SidebarLink title="Maestro Operaciones" route="/maestro/operaciones" onClick={this.hideSidebar} />
          </SidebarCategory>
        </ul>
        <ul className="sidebar__block">
          <SidebarCategory title="Informes" icon="sort-amount-asc">
            <SidebarLink title="Trazabilidad lote" route="/informes/lote" onClick={this.hideSidebar} />
            <SidebarLink title="Básico de segundas" route="/informes/basico-segundas" onClick={this.hideSidebar} />
            <SidebarLink title="Básico de tejido" route="/informes/basico-tejido" onClick={this.hideSidebar} />
            <SidebarCategory title="Legados">
              <SidebarLink
                title="Gantt reservas"
                route="/informes/calceteria/ordenes-programadas-reservas"
                onClick={this.hideSidebar}
              />
            </SidebarCategory>
          </SidebarCategory>
        </ul>
        <ul className="sidebar__block">
          <SidebarCategory title="Incentivos" icon="gift">
            <SidebarLink title="Liquidación y generación" route="/incentivos" onClick={this.hideSidebar} />
          </SidebarCategory>
        </ul>
        {/* <ul className="sidebar__block">
        <SidebarCategory title="Temas" icon="layers">
          <button className="sidebar__link" onClick={this.props.changeToLight}>
          <p className="sidebar__link-title">Tema Claro</p>
          </button>
          <button className="sidebar__link" onClick={this.props.changeToDark}>
          <p className="sidebar__link-title">Tema Oscuro</p>
          </button>
        </SidebarCategory>
        </ul> */}
        {/* <SidebarLink title="Simulador" icon="rocket" route="/simulador" onClick={this.hideSidebar} /> */}
      </div>
    );
  }
}
export default MenuCalcetines;
