import React from 'react';
import { Container, AppBar } from '@material-ui/core';
import { createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import TabPanel from '@material-ui/lab/TabPanel';
import TabContext from '@material-ui/lab/TabContext';
import PropTypes from 'prop-types';
import CargaReferenciaCategoria from './CargaReferenciaCategoria';
import { perteneceRolIngenieria } from '../Comunes/datosComunes';

const theme = createMuiTheme({
  palette: {
    primary: { main: '#263238' },
    secondary: { main: '#1a237e' },
  },
});

class CargaEstandares extends React.Component {
  constructor(props) {
    super(props);
    this.state = { activeTab: 0 };
    this.validarRol();
  }

  validarRol() {
    if (perteneceRolIngenieria()) {
      return;
    }
    this.props.history.push('/');
  }

  render() {
    return (
      <MuiThemeProvider theme={theme}>
        <Container style={{ marginTop: '16px', maxWidth: 'max-content' }}>
          <TabContext value={this.state.activeTab}>
            <AppBar position="static" color="default">
              <Tabs
                centered
                value={this.state.activeTab}
                onChange={async (e, x) => this.setState({ activeTab: x })}
                indicatorColor="primary"
                textColor="primary"
              >
                <Tab label="Referencia Categoria" value={0} />
              </Tabs>
            </AppBar>
            <TabPanel value={0}>
              <CargaReferenciaCategoria />
            </TabPanel>
          </TabContext>
        </Container>
      </MuiThemeProvider>
    );
  }
}

CargaEstandares.propTypes = {
  history: PropTypes.objectOf().isRequired,
};

export default CargaEstandares;
