/* eslint-disable react/prop-types */
import React from 'react';

import { Dialog, DialogContent,
  DialogContentText, DialogActions, Button, useTheme, useMediaQuery, DialogTitle } from '@material-ui/core';
import { ProcesosSeleccionados } from './ProcesosSeleccionados';
import { TiempoProcesos } from './TiempoProcesos';
import SubseccionesCheck from './SubseccionesCheck';
import Alert from '../../../shared/components/alert/Alert';
import Context from '../Context';


const EstandaresDialog = ({
  open, toggleModal, handleAcceptClose, accion, referencia, categoria, loading,
}) => {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Context.Consumer>
      {
        ({ error }) => (
          <Dialog open={open} onClose={toggleModal} fullScreen={fullScreen} maxWidth="md">
            <DialogTitle id="simple-dialog-title">Confirmación ({accion} estándar)</DialogTitle>
            <DialogContent>
              {
                !error.status &&
                <Alert color="danger" className="alert--bordered" icon>
                  { error.mensaje }
                </Alert>
              }
              <DialogContentText>
                <b>Referencia:</b> {referencia}
                <br />
                <b>Categoria:</b> {categoria}
                {
                  accion !== 'eliminar' &&
                  <>
                    <ProcesosSeleccionados />
                    <TiempoProcesos />
                    <SubseccionesCheck />
                  </>
                }
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={toggleModal} color="primary">
                Cancelar
              </Button>
              <Button onClick={handleAcceptClose} color="primary" disabled={loading}>
                { loading ? 'Cargando...' : 'Aceptar' }
              </Button>
            </DialogActions>
          </Dialog>
        )
      }
    </Context.Consumer>
  );
};

export default EstandaresDialog;
