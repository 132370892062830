import React from 'react';
import {
  Button,
  Dialog,
  DialogTitle,
  IconButton,
  Tooltip,
  DialogActions,
  DialogContent,
} from '@material-ui/core';
import { DeleteIcon } from 'mdi-react';

import Context from '../../../Operaciones/store';
import config from '../../../../appConfig.json';
import Alert from '../../../../shared/components/alert/Alert';


function EliminarSubseccion(props) {
  const [open, setOpen] = React.useState(false);
  const [alert, setAlert] = React.useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setAlert(false);
  };
  const handleDelete = async () => {
    const request = await fetch(`${config.ApiUrl}/secciones/eliminar/subseccion/${props.subseccion.id}`, {
      mode: 'cors',
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
        Authorization: localStorage.getItem(config.AuthTokenKey),
        Accept: 'application/json',
      },
    });
    if (request.status === 400) {
      setAlert(true);
    } else {
      props.fetch();
      handleClose();
    }
  };
  return (
    <>
      <Tooltip title="Elimina una subsección" >
        <IconButton size="small" onClick={handleOpen} >
          <DeleteIcon fontSize="small" />
        </IconButton>
      </Tooltip>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle align="center" id="alert-dialog-title">
          ¿Está seguro de eliminar esta subsección?
        </DialogTitle>
        <DialogContent>
          <Alert
            color="danger"
            className="alert--bordered"
            icon
            visible={alert}
          >
            ESTA SUBSECCIÓN TIENE DETALLES ASOCIADOS
          </Alert>
        </DialogContent>
        <DialogActions align="center" >
          <Button
            onClick={handleDelete}
            color="primary"
          >
              SI
          </Button>
          <Button onClick={handleClose} color="primary">
              NO
          </Button>
        </DialogActions>
      </Dialog>
  </>
  );
}

export default EliminarSubseccion;
