import React from 'react';
import PropTypes from 'prop-types';
import { Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Paper,
  CircularProgress,
  Grid } from '@material-ui/core';
import { separarGruposMiles } from '../Comunes/datosComunes';

class TablaMetas extends React.Component {
  static transformar(datos) {
    const valores = {};
    for (let i = 0; i < datos.length; i += 1) {
      const x = datos[i];
      valores[x.codigoIndicador] = x;
    }
    return [
      {
        variable: 'Diaria',
        kilos: valores.KG.valorDiario,
        unidades: valores.UND.valorDiario,
      },
      {
        variable: 'Semanal',
        kilos: valores.KG.valorSemanal,
        unidades: valores.UND.valorSemanal,
      },
    ];
  }
  constructor(props) {
    super(props);
  }
  render() {
    if (this.props.cargando) {
      return (
        <Grid container justify="center"><CircularProgress color="primary" /></Grid>);
    } else if (this.props.error) {
      return <span>Ocurrió un error. Por favor intente nuevamente</span>;
    }
    return (
      <Paper elevation={3}>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell align="left">Meta</TableCell>
              <TableCell align="right">Kilos</TableCell>
              <TableCell align="right">Unidades</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {this.props.metas.map(m => (
              <TableRow>
                <TableCell align="left">{m.variable}</TableCell>
                <TableCell align="right">{separarGruposMiles(Math.round(m.kilos))}</TableCell>
                <TableCell align="right">{separarGruposMiles(Math.round(m.unidades))}</TableCell>
              </TableRow>))
            }
          </TableBody>
        </Table>
      </Paper>
    );
  }
}

TablaMetas.propTypes = {
  cargando: PropTypes.bool.isRequired,
  error: PropTypes.bool,
  metas: PropTypes.arrayOf(PropTypes.object).isRequired,
};

TablaMetas.defaultProps = {
  error: false,
};

export default TablaMetas;
