import React from 'react';
import {
  Button,
  Checkbox,
  Typography,
  Grid,
  Fab,
  Badge,
  IconButton,
  Table,
  TableCell,
  TableContainer,
  TableBody,
  TableHead,
  TableRow,
  Paper,
} from '@material-ui/core';
import { Link, Prompt } from 'react-router-dom';
import ReactTable from 'react-table';
import PropTypes from 'prop-types';
import 'react-table/react-table.css';
import { EditIcon, FileExcelIcon } from 'mdi-react';
import CapturaDiasTurnos from './CapturaDiasTurnos';
import Replicador from './Replicador';
import {
  diasSemana,
  obtenerProgramacionTejidoPasillos,
  obtenerProgramacionTejidoPasillo,
  actualizarProgramacionPasillos,
  actualizarProgramacionPasillo,
  descargarExcelPasillosTurnos,
  descargarExcelPasilloTurnos,
  replicar,
  marcadores,
  obtenerProgramacionTejidoSecciones,
  descargarExcelSeccionesTurnos,
  actualizarProgramacionSecciones,
} from './datos';
import { perteneceRolIngenieria } from '../Comunes/datosComunes';

class Programacion extends React.Component {
  static titulo(tipo) {
    if (tipo.toUpperCase() === 'P') {
      return 'Pasillo';
    } else if (tipo.toUpperCase() === 'M') {
      return 'Máquina';
    } else if (tipo.toUpperCase() === 'S') {
      return 'Sección';
    }
    return '';
  }
  constructor(props) {
    super(props);
    this.iniciarReplicacion = this.iniciarReplicacion.bind(this);
    this.construirColumnas = this.construirColumnas.bind(this);
    this.replicacionConfirmada = this.replicacionConfirmada.bind(this);
    this.replicacionCancelada = this.replicacionCancelada.bind(this);
    this.actualizarProgramacion = this.actualizarProgramacion.bind(this);
    this.iniciarCaptura = this.iniciarCaptura.bind(this);
    this.capturaCancelada = this.capturaCancelada.bind(this);
    this.capturaConfirmada = this.capturaConfirmada.bind(this);
    this.exportarExcel = this.exportarExcel.bind(this);
    this.programacionPasillo = this.programacionPasillo.bind(this);
    this.cancelarEdicionPasillo = this.cancelarEdicionPasillo.bind(this);
    this.tituloPagina = this.tituloPagina.bind(this);
    this.state = {
      pasilloSeleccionado: -1,
      codigoPasilloSeleccionado: null,
      cambiosPendientes: false,
      fechaBase: null,
      consultando: false,
      actualizando: false,
      replicando: false,
      capturando: false,
      registroActual: null,
      indiceCaptura: 0,
      datos: null,
      programacion: [],
    };
  }
  async componentDidMount() {
    if (this.props.cargado) {
      this.props.cargado(this.tituloPagina());
    }
    await this.consultarDatos();
  }
  async componentDidUpdate(prev) {
    if (this.props.ano !== prev.ano || this.props.semana !== prev.semana) {
      await this.consultarDatos();
    }
  }
  abortController = new AbortController();
  async consultarDatos() {
    await this.setState({ consultando: true });
    let respuesta = null;
    if (this.props.tipo === 'P') {
      respuesta = await obtenerProgramacionTejidoPasillos(
        this.props.ano,
        this.props.semana,
        this.abortController.signal,
      );
    } else if (this.props.tipo === 'M') {
      respuesta = await obtenerProgramacionTejidoPasillo(
        this.props.pasillo,
        this.props.ano,
        this.props.semana,
        this.abortController.signal,
      );
    } else if (this.props.tipo === 'S') {
      respuesta = await obtenerProgramacionTejidoSecciones(
        1170,
        this.props.ano,
        this.props.semana,
        this.abortController.signal,
      );
    }
    if (!respuesta.error) {
      if (this.props.infoActualizada) {
        this.props.infoActualizada(respuesta.datos);
      }
      const p = respuesta.datos.programacion;
      for (let i = 0; i < p.length; i += 1) {
        const x = p[i];
        x.seleccionado = false;
      }
      const fechaBase = new Date(Date.parse(respuesta.datos.fechaBase));
      await this.setState({
        datos: respuesta.datos,
        programacion: p,
        consultando: false,
        fechaBase,
        cambiosPendientes: false,
      });
    } else if (this.props.ocurrioError) {
      await this.setState({ consultando: false, cambiosPendientes: false });
      this.props.ocurrioError();
    }
  }
  async iniciarCaptura(c) {
    await this.setState({ registroActual: c.original, capturando: true, indiceCaptura: c.index });
  }
  async registroSeleccionado(c, e) {
    const p = this.state.programacion;
    const x = p[c.index];
    x[c.column.id] = e.target.checked;
    await this.setState({ programacion: p });
  }
  seleccionados() {
    return (
      this.state.programacion.reduce((t, x) => {
        if (x.seleccionado) {
          return t + 1;
        }
        return t;
      }, 0));
  }
  async programacionPasillo(e, c) {
    await this.setState({ pasilloSeleccionado: c.original.elementoId, codigoPasilloSeleccionado: c.original.codigo });
  }
  async cancelarEdicionPasillo(e) {
    if (this.props.cargado) {
      this.props.cargado(this.tituloPagina());
    }
    await this.setState({ pasilloSeleccionado: -1, codigoPasilloSeleccionado: null });
  }
  construirColumnas() {
    const pm = this.state.datos;
    if (!pm || !pm.fechaBase) {
      return [];
    }
    const semana = [0, 1, 2, 3, 4, 5, 6];
    const grupos = semana.map((d) => {
      const columnasInternas = [];
      for (let i = 0; i < pm.turnos.length; i += 1) {
        const t = pm.turnos[i];
        columnasInternas.push({
          Header: c => (
            <TableHead>{`T${t.id}`}</TableHead>
          ),
          filterable: false,
          accessor: `horasD${d}T${t.id}`,
          width: 35,
          Cell: c => (
            <Table align="center">
              {c.original[`horasD${d}T${t.id}`]}{marcadores[c.original[`limiteD${d}T${t.id}`]]}
            </Table>
          ),
        });
      }
      return {
        Header: c => (
          <TableCell>{diasSemana[d]}</TableCell>
        ),
        columns: columnasInternas,
      };
    });
    if (perteneceRolIngenieria()) {
      grupos.unshift({
        Header: '',
        filterable: false,
        accessor: 'seleccionado',
        maxWidth: 60,
        Cell: c => (
          <IconButton size="small" disabled={!perteneceRolIngenieria()}>
            <EditIcon
              color="primary"
              onClick={async e => this.iniciarCaptura(c)}
            />
          </IconButton>
        ),
      });
    }
    if (this.props.tipo === 'M') {
      grupos.unshift({
        Header: <th className="table-header">Parqueadero</th>,
        accessor: 'parqueadero',
        filterMethod: (f, r) => (
          r[f.id].toUpperCase().indexOf(f.value.toUpperCase()) !== -1
        ),
        Cell: c => (
          <Table>
            <Typography>{c.original.parqueadero}</Typography>
          </Table>
        ),
      });
    }
    grupos.unshift({
      Header: c => (
        <Table>
          <Typography>{Programacion.titulo(this.props.tipo)}</Typography>
        </Table>
      ),
      accessor: 'nombre',
      filterMethod: (f, r) => (
        r[f.id].toUpperCase().indexOf(f.value.toUpperCase()) !== -1
      ),
      Cell: (c) => {
        if (this.props.tipo === 'P') {
          return (
            <Button
              variant="text"
              // to={`/maestro/pasillo-turnos/${c.original.elementoId}/${this.props.ano}/${this.props.semana}`}
              onClick={async e => this.programacionPasillo(e, c)}
              disabled={this.state.cambiosPendientes}
            >
              <TableCell align="left">
                {c.original.nombre}
              </TableCell>
            </Button>
          );
        }
        return (
          <TableCell align="left">
            {c.original.nombre}
          </TableCell>
        );
      },
    });
    if (perteneceRolIngenieria()) {
      grupos.unshift({
        Header: (c) => {
          const ch = this.state.programacion &&
            this.state.programacion.length &&
            this.state.programacion.every(x => x.seleccionado);
          return (
            <Table>
              <Checkbox
                onChange={async () => {
                  const sel = this.state.programacion.every(x => x.seleccionado);
                  const p = this.state.programacion;
                  for (let i = 0; i < p.length; i += 1) {
                    const x = p[i];
                    x.seleccionado = !sel;
                  }
                  await this.setState({ programacion: p });
                }}
                checked={ch}
                indeterminate={
                  !this.state.programacion.every(x => x.seleccionado) &&
                  this.state.programacion.some(x => x.seleccionado)
                }
                color="primary"
                size="small"
              />
            </Table>
          );
        },
        filterable: false,
        accessor: 'seleccionado',
        maxWidth: 60,
        Cell: (c) => {
          const ch = this.state.programacion[c.index][c.column.id];
          return (
            <Table>
              <Checkbox
                onChange={async e => this.registroSeleccionado(c, e)}
                disabled={this.state.diaOrigen === 0}
                checked={ch}
                color="primary"
                size="small"
              />
            </Table>
          );
        },
      });
    }
    return grupos;
  }
  async iniciarReplicacion() {
    await this.setState({ replicando: true });
  }
  async actualizarProgramacion() {
    const datos = {
      fechaBase: this.state.datos.fechaBase,
      dias: this.state.datos.dias,
      turnos: this.state.datos.turnos,
      programacion: this.state.programacion,
    };
    await this.setState({ actualizando: true });
    let respuesta;
    if (this.props.tipo === 'P') {
      respuesta = await actualizarProgramacionPasillos(datos, this.abortController.signal);
    } else if (this.props.tipo === 'M') {
      respuesta = await actualizarProgramacionPasillo(this.props.pasillo, datos, this.abortController.signal);
    } else if (this.props.tipo === 'S') {
      respuesta = await actualizarProgramacionSecciones(1170, datos, this.abortController.signal);
    }
    if (!respuesta.error) {
      await this.setState({ actualizando: false, cambiosPendientes: false });
    } else {
      await this.setState({ actualizando: false });
      if (this.props.ocurrioError) {
        this.props.ocurrioError();
      }
    }
  }
  async exportarExcel() {
    await this.setState({ consultando: true });
    try {
      if (this.props.tipo === 'P') {
        await descargarExcelPasillosTurnos(this.props.ano, this.props.semana, this.abortController.signal);
      } else if (this.props.tipo === 'M') {
        await descargarExcelPasilloTurnos(
          this.props.pasillo,
          this.props.ano,
          this.props.semana,
          this.abortController.signal,
        );
      } else if (this.props.tipo === 'S') {
        await descargarExcelSeccionesTurnos(
          1170,
          this.props.ano,
          this.props.semana,
          this.abortController.signal,
        );
      }
    } catch (e) {
      if (this.props.ocurrioError) {
        this.props.ocurrioError();
      }
    } finally {
      await this.setState({ consultando: false });
    }
  }
  async replicacionConfirmada(v) {
    const p = replicar(
      this.state.programacion,
      this.state.datos.turnos,
      this.state.fechaBase,
      v.origen,
      v.destinos,
    );
    for (let i = 0; i < p.length; i += 1) {
      p[i].seleccionado = false;
    }
    await this.setState({ programacion: p, replicando: false, cambiosPendientes: true });
  }
  async replicacionCancelada() {
    await this.setState({ replicando: false });
  }
  async capturaConfirmada(registro, indice) {
    if (registro != null) {
      const p = this.state.programacion;
      const x = p[indice];
      for (const [k, v] of Object.entries(registro)) {
        x[k] = v;
      }
      await this.setState({ programacion: p, capturando: false, cambiosPendientes: true });
    }
  }
  async capturaCancelada() {
    await this.setState({ capturando: false });
  }
  tituloPagina() {
    if (this.props.tipo === 'P') {
      return 'Pasillos turno';
    } else if (this.props.tipo === 'M') {
      return `Máquina turno - Pasillo ${this.props.codigoPasillo}`;
    } else if (this.props.tipo === 'S') {
      return 'Secciones turno';
    }
    return '';
  }
  render() {
    const columnas = this.construirColumnas(this.state.datos, this.state.consultando);
    return (
      <React.Fragment>
        <Prompt
          when={this.state.cambiosPendientes}
          message={'Tiene cambios pendientes para actualizar y si continúa los perderá. ' +
            '¿Está seguro de que desea continuar?'}
        />
        {
          this.state.pasilloSeleccionado === -1 &&
          <Grid container md={12} spacing={1} direction="column">
            <Grid container item xs={12} spacing={1} direction="row" justify="center">
              <Grid item>
                <Badge
                  color="primary"
                  badgeContent={this.seleccionados()}
                  max={99}
                  anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                  }}
                >
                  <Button
                    variant="outlined"
                    // className="btn btn-primary"
                    onClick={this.iniciarReplicacion}
                    disabled={
                      !perteneceRolIngenieria() ||
                      !this.state.programacion.some(x => x.seleccionado) ||
                      this.state.consultando ||
                      this.state.actualizando
                    }
                  >
                    Replicar
                  </Button>
                </Badge>
              </Grid>
              <Grid item>
                <Button
                  variant="outlined"
                  // className="btn btn-primary"
                  onClick={this.actualizarProgramacion}
                  disabled={
                    !perteneceRolIngenieria() ||
                    !this.state.cambiosPendientes ||
                    this.state.consultando ||
                    this.state.actualizando}
                >
                  Actualizar
                </Button>
              </Grid>
              <Grid item>
                <Button
                  className="btn btn-secondary"
                  variant="outlined"
                  onClick={this.exportarExcel}
                  disabled={
                    this.state.consultando ||
                    this.state.actualizando
                  }
                >
                  <FileExcelIcon />&nbsp;
                  Exportar
                </Button>
              </Grid>
              {
                this.props.tipo === 'M' &&
                <Grid item>
                  <Button
                    variant="outlined"
                    onClick={() => {
                      if (this.props.cancelar) {
                        this.props.cancelar();
                      }
                    }}
                    disabled={
                      this.state.consultando ||
                      this.state.actualizando
                    }
                  >
                    Pasillos
                  </Button>
                </Grid>
              }
            </Grid>
            <Grid item>
              <Replicador
                activo={this.state.replicando}
                diaOrigenPredeterminado={0}
                fechaBase={this.state.fechaBase}
                confirmado={this.replicacionConfirmada}
                cancelado={this.replicacionCancelada}
              />
              <CapturaDiasTurnos
                activo={this.state.capturando}
                registro={this.state.registroActual}
                indice={this.state.indiceCaptura}
                fechaBase={this.state.fechaBase}
                turnos={this.state.datos != null ? this.state.datos.turnos : []}
                confirmado={this.capturaConfirmada}
                cancelado={this.capturaCancelada}
              />
            </Grid>
            <Grid item md={12}>
              <ReactTable
                columns={this.construirColumnas()}
                data={this.state.programacion}
                // filterable
                showPagination={false}
                PaginationComponent={false}
                showPageSizeOptions
                previousText="Anterior"
                defaultPageSize={10}
                getTdProps={() => ({
                  style: {
                    verticalAlign: 'none',
                    border: 'none',
                  },
                })}
                getTheadThProps={() => ({
                  style: {
                    verticalAlign: 'none',
                    border: 'none',
                  },
                })}
              />
            </Grid>
          </Grid>
        }
        {
          this.state.pasilloSeleccionado > 0 &&
          <Grid>
            <Programacion
              ano={this.props.ano}
              semana={this.props.semana}
              tipo="M"
              pasillo={this.state.pasilloSeleccionado}
              codigoPasillo={this.state.codigoPasilloSeleccionado}
              infoActualizada={this.props.infoActualizada}
              ocurrioError={this.props.ocurrioError}
              cancelar={this.cancelarEdicionPasillo}
              cargado={this.props.cargado}
            />
          </Grid>
        }
      </React.Fragment>
    );
  }
}

Programacion.propTypes = {
  ano: PropTypes.number.isRequired,
  semana: PropTypes.number.isRequired,
  tipo: PropTypes.string,
  pasillo: PropTypes.number,
  codigoPasillo: PropTypes.string,
  infoActualizada: PropTypes.func,
  ocurrioError: PropTypes.func,
  cancelar: PropTypes.func,
  cargado: PropTypes.func,
};

Programacion.defaultProps = {
  pasillo: -1,
  codigoPasillo: null,
  infoActualizada: null,
  ocurrioError: null,
  tipo: 'P',
  cancelar: null,
  cargado: null,
};

export default Programacion;
