import React from 'react';
import { Grid, Container, AppBar } from '@material-ui/core';
import { createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import TabPanel from '@material-ui/lab/TabPanel';
import TabContext from '@material-ui/lab/TabContext';
import EstandaresOperacionTalla from './EstandaresOperacionTalla';
import EstandaresOperacion from './EstandaresOperacion';
import EstandaresUbicacion from './EstandaresUbicacion';

const theme = createMuiTheme({
  palette: {
    primary: { main: '#263238' },
    secondary: { main: '#1a237e' },
  },
});

class Estandares extends React.Component {
  constructor(props) {
    super(props);
    this.state = { activeTab: 0 };
  }
  render() {
    return (
      <MuiThemeProvider theme={theme}>
        <Container style={{ marginTop: '16px' }}>
          <TabContext value={this.state.activeTab}>
            <AppBar position="static" color="default">
              <Tabs
                centered
                value={this.state.activeTab}
                onChange={async (e, x) => this.setState({ activeTab: x })}
                indicatorColor="primary"
                textColor="primary"
              >
                <Tab label="Estandar operación talla" value={0} />
                <Tab label="Estandar operación" value={1} />
                <Tab label="Estandar ubicación" value={2} />
              </Tabs>
            </AppBar>
            <TabPanel value={0}>
              <EstandaresOperacionTalla />
            </TabPanel>
            <TabPanel value={1}>
              <EstandaresOperacion />
            </TabPanel>
            <TabPanel value={2}>
              <EstandaresUbicacion />
            </TabPanel>
          </TabContext>
        </Container>
      </MuiThemeProvider>);
  }
}

export default Estandares;
