/* eslint-disable import/prefer-default-export */
import React, { useState } from 'react';
import { Card, CardActionArea, CardMedia, Dialog,
  DialogContent, DialogContentText,
  TextField, DialogActions, Button, useMediaQuery, useTheme, makeStyles, Grid } from '@material-ui/core';
import Context from './../Context';

export const NormaEmpaque = () => {
  const [open, setOpen] = useState(false);
  const [norma, setNorma] = useState('');

  const useStyles = makeStyles(theme => ({
    root: {
      marginTop: theme.spacing(3),
    },
  }));
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const classes = useStyles();

  const handleClickOpen = (normaEmpaque) => {
    toggleModal();
    setNorma(normaEmpaque);
  };

  const toggleModal = () => {
    setOpen(!open);
  };

  return (
    <Context.Consumer>
      {
        ({ normaEmpaque, activateNormaEmpaque }) => {
          const habldleAcceptClose = () => {
            toggleModal();
            activateNormaEmpaque(norma);
          };

          return (
            <>
              <Card className={classes.root}>
                <CardActionArea>
                  <CardMedia
                    style={{ height: '225px', backgroundSize: 'contain' }}
                    image={normaEmpaque}
                    title="Norma de empaque referencia"
                    onClick={() => handleClickOpen(normaEmpaque)}
                  />
                </CardActionArea>
              </Card>
              <Grid item xs={12}>
                <Card className={classes.root} style={{ fontSize: 'medium', wordBreak: 'break-all', padding: '13px' }}>
                  {
                    normaEmpaque
                      .replace('http://crymarfsapp01/Doctos_norma_empaque/', '')
                      .replace('.bmp', '')
                  }
                </Card>
              </Grid>
              <Dialog open={open} onClose={toggleModal} fullScreen={fullScreen} maxWidth="md">
                <DialogContent>
                  <DialogContentText>
                    <img src={normaEmpaque} alt={normaEmpaque} />
                  </DialogContentText>
                  <TextField
                    margin="normal"
                    value={norma}
                    id="norma"
                    label="Ruta de imagen"
                    onChange={e => setNorma(e.target.value)}
                    helperText="Ruta de la imagen de la norma de empaque"
                    type="text"
                    fullWidth
                  />
                </DialogContent>
                <DialogActions>
                  <Button onClick={toggleModal} color="primary">
                  Cancelar
                  </Button>
                  <Button onClick={habldleAcceptClose} color="primary">
                  Aceptar
                  </Button>
                </DialogActions>
              </Dialog>
            </>
          );
        }
      }
    </Context.Consumer>
  );
};
