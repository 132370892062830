import React from 'react';

import { TextField, Grid, Fab, Tooltip } from '@material-ui/core';
import { AddIcon } from 'mdi-react';

import AdicionarDetallada from './AdicionarDetallada';
import Context from '../../store';
import { perteneceRolIngenieria } from '../../../Comunes/datosComunes';

export default function BarraDeBusqueda() {
  return (
    <Context.Consumer>
      {
        (value) => {
          const handleChangeInput = (e) => {
          const result = value.detalladas
          .filter(op => (
            op.descripcionOperacionDetallada !== null && op))
          .filter(op => (
            op.descripcionOperacionDetallada.toLowerCase().indexOf(e.target.value.toLowerCase()) !== -1 && op
          ));
          if (result.length !== 0 && e.target.value !== '') { value.activateDet(result); }
          if (result.length === 0 && e.target.value === '') { value.activateDet(value.detalladasOrigin); }
          if (result.length !== 0 && e.target.value === '') { value.activateDet(value.detalladasOrigin); }
          if (result.length === 0 && e.target.value !== '') { value.activateDet(value.detalladasOrigin); }
         };
          if (value.detalladas.length === 0) {
            return (
              <Grid container style={{ marginBlockEnd: '3%' }} alignItems="flex-end">
                <Grid item xs={11}>
                  <TextField
                    id="standard-basic"
                    label="Operación Detallada"
                    type="text"
                    style={{ width: '95%' }}
                  />
                </Grid>
                <Grid item xs={1}>
                  <Tooltip title="Adiciona una operación detallada" aria-label="add">
                    <Fab aria-label="add" size="small" color="action" disabled>
                      <AddIcon />
                    </Fab>
                  </Tooltip>
                </Grid>
              </Grid>);
              }
          return (
            <Grid container style={{ marginBlockEnd: '3%' }} alignItems="flex-end">
              <Grid item xs={11}>
                <TextField
                  id="standard-basic"
                  label="Operación Detallada"
                  type="text"
                  onChange={handleChangeInput}
                  style={{ width: '95%' }}
                />
              </Grid>
              <Grid item xs={1}>
                <AdicionarDetallada />
              </Grid>
            </Grid>
            );
      }
    }
    </Context.Consumer>
  );
}
