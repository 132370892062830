import React from 'react';
import PropTypes from 'prop-types';
import IndicadoresNumericosEstandar from '../../../shared/components/indicadoresNumericos/IndicadoresNumericosEstandar';

const IndicadoresNumericosUbicacion = ({ cantidadReal, tiempoParo }) =>
  (
    <IndicadoresNumericosEstandar tiempoParo={tiempoParo} real={cantidadReal} />
  );
IndicadoresNumericosUbicacion.propTypes = {
  cantidadReal: PropTypes.number.isRequired,
  tiempoParo: PropTypes.number.isRequired,
};

export default IndicadoresNumericosUbicacion;
