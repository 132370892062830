import React from 'react';
import PropTypes from 'prop-types';
import { Card, CardBody, Col } from 'reactstrap';
import { Link } from 'react-router-dom';
import Eficiencia from '../../../shared/components/eficiencia/Eficiencia';
import IndicadoresNumericosTejido from '../../../shared/components/indicadoresNumericos/IndicadoresNumericosTejido';

function IndicadorEficieciaPasillo(props) {
  const link = `tejido/pasillo/${props.pasillo.pasilloId}`;
  return (
    <Col md={6} lg={3} className="card-no-padding">
      <Link to={link}>
        <Card>
          <CardBody
            className="d-flex flex-column justify-content-between"
            style={!props.pasillo.tieneEficiencia ? {
              boxShadow: 'none', opacity: '.5', backgroundColor: '#d9d9d9',
            } : {}}
          >
            <div className="card__title">
              <h5 className="bold-text text-center">{`Pasillo ${props.pasillo.codigoPasillo}`}</h5>
            </div>
            <Eficiencia
              modo="medium"
              porcentaje={props.pasillo.eficiencia.porcentajeEficienciaLote * 100}
              cx={85}
              cy={85}
              innerRadius={85}
              outerRadius={90}
              round={1}
            />
            {
              <IndicadoresNumericosTejido
                porcentajeEficienciaOperativa={(props.pasillo.eficiencia.porcentajeEficienciaOperativa * 100)}
                proyeccion={props.pasillo.proyeccion}
                docenasPLC={props.pasillo.eficiencia.docenasPLC}
              />
            }
          </CardBody>
        </Card>
      </Link>
    </Col>
  );
}

IndicadorEficieciaPasillo.propTypes = {
  pasillo: PropTypes.objectOf(PropTypes.string).isRequired,
};
export default IndicadorEficieciaPasillo;
