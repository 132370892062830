import React from 'react';
import PropTypes from 'prop-types';
import {
  Row,
  Col,
} from 'reactstrap';
import IndicadorEficienciaUbicacion from './IndicadorEficienciaUbicacion';
import IndicadorSubseccion from './IndicadorSubseccion';
import Alert from '../../../shared/components/alert/Alert';
import { GridRow, GridItemBig, GridItem } from '../../../shared/components/gridRow';

const Ubicaciones = ({ subseccion }) => (
  <GridRow>
    <>
      {
        (!subseccion || subseccion.ubicaciones.length <= 0) &&
          <GridItemBig>
            <Row className="justify-content-center">
              <Col sm={8}>
                <Alert color="info" className="alert--bordered" icon>
                  No hay eventos aún!
                </Alert>
              </Col>
            </Row>
          </GridItemBig>
      }
      {
        subseccion &&
        <GridItemBig className="card-no-padding">
          { subseccion.ubicaciones.length > 0 &&
            <IndicadorSubseccion subseccion={subseccion} />
          }
        </GridItemBig>
      }
      {
        subseccion && subseccion.ubicaciones.length > 0 &&
          subseccion.ubicaciones.map(ubicacion => (
            <GridItem level={ubicacion.empleados.length > 1 ? '2' : '1'}>
              <IndicadorEficienciaUbicacion
                key={ubicacion.elementoId}
                codigo={ubicacion.elementoId}
                base="/detalles/ubicacion"
                empleados={ubicacion.empleados}
                ubicacion={ubicacion}
              />
            </GridItem>
          ))
      }
    </>
  </GridRow>
);

Ubicaciones.propTypes = {
  subseccion: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default Ubicaciones;
