import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Row, Col, Container } from 'reactstrap';

import Alert from '../alert/Alert';

class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = {
      hasError: false,
      error: '',
      errorInfo: '',
    };
  }

  componentDidCatch(error, info) {
    this.setState({
      hasError: true,
      error: error.message,
      errorInfo: info.componentStack,
    });
  }

  render() {
    const { hasError, error, errorInfo } = this.state;
    if (hasError) {
      return (
        <Container className="dashboard">
          <Row className="justify-content-center">
            <Col sm={8}>
              <h1 className="text-center">Algo salió mal</h1>
              <Alert color="danger" className="alert--bordered" icon>
                <b>{error}</b>
                <code>
                  {errorInfo}
                </code>
              </Alert>
            </Col>
          </Row>
        </Container>
      );
    }

    return this.props.children;
  }
}

ErrorBoundary.propTypes = {
  children: PropTypes.element.isRequired,
};

export default ErrorBoundary;
