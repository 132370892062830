import React, { PureComponent, Fragment } from 'react';
import { connect } from 'react-redux';
import { Table, Badge, Col } from 'reactstrap';
import PropTypes from 'prop-types';
import format from 'date-fns/getWeek';
import $ from 'jquery';
import 'datatables.net-buttons-dt';
// eslint-disable-next-line import/no-extraneous-dependencies
import 'datatables.net-buttons/js/buttons.html5';
// eslint-disable-next-line import/no-extraneous-dependencies
import 'datatables.net-buttons/js/buttons.flash';
import 'datatables.net-select-dt';

import { getColorEficienciaBadge } from '../../../shared/funtions/ColoresEficiencias';
import { NotificationProps } from '../../../shared/prop-types/ReducerProps';

$.DataTable = require('datatables.net');
window.JSZip = require('jszip');

class EficienciaEventoTable extends PureComponent {
  static propTypes = {
    eventos: PropTypes.arrayOf(PropTypes.shape()).isRequired,
    connection: NotificationProps.isRequired,
    ubicacion: PropTypes.number.isRequired,
    restricted: PropTypes.bool.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      eventos: [],
      restriction: props.restricted,
    };
  }

  componentWillMount = () => {
    this.mapEventosTable(this.props.eventos);
  }

  componentDidMount = () => {
    const { connection } = this.props;

    this.createDataTable($('#table'));
    this.listenEstandarProvisional(connection.hubConnection);
  }

  listenEstandarProvisional = (hubConnection) => {
    hubConnection.on('PendientePorEstandar', (receivedEvent) => {
      if (receivedEvent.swEstandarProvisional) {
        window.location.reload();
        // this.mustAddRow(receivedEvent);
      }
    });
  }

  mustAddRow = (receivedEvent) => {
    if (Number(this.props.ubicacion) === Number(receivedEvent.mesa)) {
      $('#table').DataTable().row.add([
        receivedEvent.mesa,
        receivedEvent.cedulaEmpleado,
        receivedEvent.nombreEmpleado,
        receivedEvent.operacion,
        receivedEvent.codigoLote,
        receivedEvent.tiempo,
        receivedEvent.fechaEfectiva,
        receivedEvent.estandar,
        receivedEvent.cantidadReal,
        receivedEvent.cantidadEsperada,
        ` <h4>
          <span class="badge badge-${getColorEficienciaBadge(receivedEvent.eficiencia)} badge-pill">
            ${receivedEvent.eficiencia} %
          </span>
        </h4>`,
        receivedEvent.swEstandarProvisional ? 'P' : null,
      ]).draw(false);
    }
  }

  mapEventosTable = async (eventos) => {
    const eventosRow = [];

    eventos.map((evento, index) => {
      this.setState({
        cantidadReal: this.state.cantidadReal + evento.cantidadReal,
        cantidadEsperada: this.state.cantidadEsperada + evento.cantidadEsperada,
      });
      // eslint-disable-next-line function-paren-newline
      return eventosRow.push(
      // eslint-disable-next-line react/no-array-index-key
        <tr key={index}>
          <td>{evento.mesa}</td>
          <td>{evento.cedulaEmpleado}</td>
          <td>{evento.nombreEmpleado}</td>
          <td>{evento.operacion}</td>
          <td>{evento.codigoLote}</td>
          <td>{evento.tiempo}</td>
          <td>{format(Date.parse(evento.fechaInicioEvento), 'dd/MM/yyyy')}</td>
          <td>{evento.estandar}</td>
          <td>{evento.cantidadReal}</td>
          <td>{evento.cantidadEsperada}</td>
          <td>
            <h4>
              <Badge
                color={getColorEficienciaBadge(evento.eficiencia)}
                pill
              >
                {evento.eficiencia} %
              </Badge>
            </h4>
          </td>
          <td>{evento.swEstandarProvisional && 'P'}</td>
        </tr>);
    });

    this.setState({
      eventos: eventosRow,
    });
  }

  createDataTable = (element) => {
    const table = element.DataTable({
      buttons: [
        {
          extend: 'excel',
          className: 'btn btn-rounded btn-outline-secondary',
          autoFilter: true,
          text: '<span class="lnr lnr-download" aria-hidden="true"></span> Excel',
          title: null,
        },
        {
          extend: 'copy',
          className: 'btn btn-rounded btn-outline-secondary',
          text: '<span class="lnr lnr-select" aria-hidden="true"></span> Copiar',
          title: null,
        },
      ],
      orderMulti: true,
      select: true,
      lengthMenu: [
        [-1, 10, 25, 50, 100],
        ['*', '10', '25', '50', '100'],
      ],
      language: {
        emptyTable: 'Aún no hay eventos disponibles',
        lengthMenu: 'Mostrar _MENU_ registros',
        info: 'Mostrando _START_ a _END_ de _TOTAL_ registros',
        infoEmpty: 'Mostrando 0 a 0 de 0 registros',
        search: 'Buscar: ',
        paginate: {
          first: 'Primera',
          last: 'Ultima',
          next: 'Siguiente',
          previous: 'Anterior',
        },
        zeroRecords: 'No se encontraron registros que coincidan',
        infoFiltered: '(Filtrados de _MAX_ registros totales)',
      },
      // eslint-disable-next-line object-shorthand
      initComplete: function () {
        this.api().columns([0, 1, 2, 4, 5, 6, 11]).every(function () {
          const column = this;
          const select = $('<select class="form-control form-control-sm"><option value="">*</option></select>')
            .appendTo($(column.footer()).empty())
            // eslint-disable-next-line space-before-function-paren
            .on('change', function() {
              const val = $.fn.dataTable.util.escapeRegex($(this).val());
              column.search(val ? `^${val}$` : '', true, false).draw();
            });
          // eslint-disable-next-line prefer-arrow-callback
          return column.data().unique().sort().each(function (d) {
            select.append(`<option value="${d}">${d}</option>`);
          });
        });
      },
    });

    table.buttons().container().appendTo($('#downloadButtons'));
  }

  render() {
    const { eventos, restriction } = this.state;
    return (
      !restriction &&
      <Fragment>
        <Table responsive borderless striped id="table">
          <thead>
            <tr>
              <th>Mesa</th>
              <th>C.C</th>
              <th>Nombre</th>
              <th>Proceso</th>
              <th>Lote / Paro</th>
              <th>Tiempo</th>
              <th>Fecha</th>
              <th>Estándar</th>
              <th>Cant. Real</th>
              <th>Cant. Esperada</th>
              <th>Eficiencia</th>
              <th>Tipo Estandar</th>
            </tr>
          </thead>
          <tbody>
            {
              eventos
            }
          </tbody>
          <tfoot>
            <tr>
              <th />
              <th />
              <th />
              <th />
              <th />
              <th />
              <th />
              <th />
              <th />
              <th />
              <th />
              <th />
            </tr>
          </tfoot>
        </Table>
        <Col className="text-center" id="downloadButtons" />
      </Fragment>
    );
  }
}

export default connect(state => ({
  connection: state.notification,
}))(EficienciaEventoTable);
