import React from 'react';
import { Tooltip,
  Button,
  Dialog,
  DialogContent,
  DialogActions,
  DialogTitle,
  FormControlLabel,
  Checkbox,
  Grid,
  Typography,
  TextField,
  FormControl,
  InputAdornment,
  InputLabel } from '@material-ui/core';
import { DoneIcon } from 'mdi-react';
import config from '../../../../appConfig.json';
import Alert from '../../../../shared/components/alert/Alert';

export default function CrearTurno(props) {
  const [open, setOpen] = React.useState(false);
  const [switchDefecto, setSwitchDefecto] = React.useState(false);
  const [esOrdinario, setEsOrdinario] = React.useState(false);
  const [esNocturno, setEsNocturno] = React.useState(false);
  const [alert, setAlert] = React.useState(false);
  const [proceso, setProceso] = React.useState({});

  const handleSave = async () => {
    const array = {
      ...proceso,
      switchDefecto,
      esOrdinario,
      esNocturno,
      fechaCreacion: new Date(),
      fechaModificacion: new Date(),
      usuario: localStorage.getItem(config.CryUsername),
    };
    const request = await fetch(`${config.ApiUrl}/turnos/nuevo`, {
      mode: 'cors',
      method: 'PUT',
      body: JSON.stringify(array),
      headers: {
        'Content-Type': 'application/json',
        Authorization: localStorage.getItem(config.AuthTokenKey),
        Accept: 'application/json',
      },
    });
    const response = await request.json();
    if (response.sw === 1) {
      setAlert(true);
    } else {
      props.fetch();
      handleClose();
    }
  };

  const handleChange = (e) => {
    const array = { ...proceso };
    array[e.target.id] = e.target.value;
    setProceso(array);
  };

  const handleChecked = (e) => {
    const array = { ...proceso };
    array[e.target.name] = e.target.checked;
    setProceso(array);
    if (e.target.name === 'switchDefecto') setSwitchDefecto(e.target.checked);
    else if (e.target.name === 'esOrdinario') setEsOrdinario(e.target.checked);
    else setEsNocturno(e.target.checked);
  };

  const handleChangeHora = (e) => {
    const array = { ...proceso };
    array[e.target.id] = e.target.value;
    if (array.horaFinal !== undefined && array.horaInicial !== undefined) {
      const horaI = e.target.id === 'horaInicial' ? (e.target.value).split(':') : array.horaInicial.split(':');
      const horaF = e.target.id === 'horaFinal' ? (e.target.value).split(':') : array.horaFinal.split(':');
      const t1 = new Date();
      const t2 = new Date();
      t1.setHours(horaF[0], horaF[1]);
      t2.setHours(horaI[0], horaI[1]);
      t1.setHours(t1.getHours() - t2.getHours(), t1.getMinutes() - t2.getMinutes());
      array.cantidadMinutos = (t1.getHours() * 60) + t1.getMinutes();
    }
    setProceso(array);
  };

  const handleClose = () => {
    setAlert(false);
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  return (
    <>
      <Tooltip title="Crea un turno" >
        <Button
          style={{ backgroundColor: '#263238', width: '50%' }}
          onClick={handleOpen}
        >
          <DoneIcon style={{ color: 'white' }} />
          <h5 style={{ color: 'white' }}><strong>CREAR</strong></h5>
        </Button>
      </Tooltip>
      <Dialog
        open={open}
        onClose={handleClose}
        scroll="paper"
        aria-labelledby="form-dialog-title"
        maxWidth="sm"
      >
        <form onSubmit={(e) => {
          e.preventDefault();
          handleSave();
          }}
        >
          <DialogTitle>
            <Typography gutterBottom variant="h6" align="center">
              ADICIONAR TURNO
            </Typography>
          </DialogTitle>

          <DialogContent dividers>
            <Alert
              color="danger"
              className="alert--bordered"
              icon
              visible={alert}
            >
              ESTE TURNO ESPECIAL YA ESTA REGISTRADO.
            </Alert>
            <Grid container>
              <Grid item xs={6}>
                <TextField
                  required
                  type="number"
                  label="Código"
                  id="id"
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={6} style={{ marginTop: '3%' }}>
                <TextField
                  readOnly
                  id="cantidadMinutos"
                  value={proceso !== null ? proceso.cantidadMinutos : 0}
                  InputProps={{
                    startAdornment: <InputAdornment position="start">Minutos</InputAdornment>,
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  required
                  fullWidth
                  label="Descripción"
                  style={{ marginBlockEnd: '2%' }}
                  id="descripcionTurno"
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  required
                  type="number"
                  label="Version"
                  id="version"
                  onChange={handleChange}
                  style={{ marginBlockEnd: '2%' }}
                />
              </Grid>
              <Grid item xs={4}>
                <FormControlLabel
                  style={{ marginTop: '2%' }}
                  control={<Checkbox
                    color="primary"
                    checked={switchDefecto}
                    name="switchDefecto"
                    onChange={handleChecked}
                  />}
                  label="Defecto"
                />
              </Grid>
              <Grid item xs={4}>
                <FormControlLabel
                  style={{ marginTop: '2%' }}
                  control={<Checkbox
                    color="primary"
                    checked={esOrdinario}
                    name="esOrdinario"
                    onChange={handleChecked}
                  />}
                  label="Ordinario"
                />
              </Grid>
              <Grid item xs={4}>
                <FormControlLabel
                  style={{ marginTop: '2%' }}
                  control={<Checkbox
                    color="primary"
                    checked={esNocturno}
                    name="esNocturno"
                    onChange={handleChecked}
                  />}
                  label="Nocturno"
                />
              </Grid>
              <Grid item xs={2} style={{ marginTop: '2%' }}>
                <InputLabel>Hora inicial</InputLabel>
              </Grid>
              <Grid item xs={4}>
                <FormControl required>
                  <TextField
                    required
                    type="time"
                    id="horaInicial"
                    onChange={handleChangeHora}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={2} style={{ marginTop: '2%' }}>
                <InputLabel>Hora final</InputLabel>
              </Grid>
              <Grid item xs={4}>
                <FormControl required>
                  <TextField
                    required
                    type="time"
                    id="horaFinal"
                    onChange={handleChangeHora}
                  />
                </FormControl>
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="default">
              Cerrar
            </Button>
            <Button color="primary" type="submit" >
              Añadir
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </>
  );
}
