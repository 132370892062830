import config from '../../../appConfig.json';
import
{
  ejecutarConsulta,
  descargarArchivo,
  ejecutarActualizacion,
  obtenerInfoOperario,
} from '../../Comunes/datosComunes';

async function consultarTejidoPaginado(filtro, signal) {
  return ejecutarConsulta(
    `${config.ApiUrl}/tejido/informe-basico`,
    'POST',
    filtro,
    signal,
  );
}
async function exportarInformeBasicoTejidoExcel(filtro, signal) {
  return descargarArchivo(
    `${config.ApiUrl}/tejido/informe-basico-excel`,
    'POST',
    filtro,
    'Tejido.xlsx',
    signal,
  );
}

export {
  consultarTejidoPaginado,
  exportarInformeBasicoTejidoExcel,
};

