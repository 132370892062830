/* eslint-disable import/prefer-default-export */
import config from './../../../appConfig.json';
import { ListenEventosIngenieria, RemoveEventosIngenieria } from './EventListening';

/**
 * Establece los eventos que se va a activar en la sesión dependiendo
 * del rol del usuario activo.
 * @param {string} rol Rol que desencadena los listener de eventos
 * @param {hubConnection} hubConnection conexión a hub de eventos
 */
export const SetRoleEventListeners = (rol, hubConnection) => {
  switch (rol) {
    case config.RolIngenieria:
      ListenEventosIngenieria(hubConnection);
      break;
    default:
      break;
  }
};

/**
 * Cancela los eventos que se activan en la sesión dependiendo
 * del rol del usuario activo.
 * @param {string} rol Rol que desencadena los listener de eventos
 * @param {hubConnection} hubConnection conexión a hub de eventos
 */
export const RemoveRoleEventListeners = (rol, hubConnection) => {
  switch (rol) {
    case config.RolIngenieria:
      RemoveEventosIngenieria(hubConnection);
      break;
    default:
      break;
  }
};
