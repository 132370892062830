/* eslint-disable import/prefer-default-export */
import styled from 'styled-components';

export const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, ${props => props.colSize});
  grid-template-rows: repeat(auto-fill, ${props => props.rowSize});
  min-height: calc(100vh - 130px);
  grid-gap: ${props => props.gap};

  & .list-group-item 
  {
    padding: 7px 0px;

    & b {
      font-size: 1.2em;
    }
  }

  & .card__title {
    margin: 0px;
  }

  @media screen and (max-width: 768px) {
    grid-template-columns: repeat(auto-fill, 33%);
  }

  @media screen and (max-width: 425px) {
    grid-template-columns: 100%;
  }
`;

export const ItemSizable = styled.div`
  grid-row: ${props => props.rowStart} / span ${props => props.rowEnd};
  grid-column: ${props => props.colStart} / span ${props => props.colEnd};
`;

export const Item = styled.div`
  grid-row: auto / span ${props => (props.level)};

  & > div {
    height: 100%;
    justify-content: space-between;
  }

  & .card-body {
    padding: 5px 12px;
  }

  & .card__title
  {
    margin: 0px;
  }

  & h5 {
    font-size: 1.1em !important;
  }

  & h3 .badge small {
    font-size: 12px;
  }

  & .col {
    margin: 0px;
    padding: 0px;
    & small b {
      font-size: 1.4em;
    }
  }

  & .mobile-app-widget__title {
    margin: 0px;
    
    & h5 {
      font-weight: 600;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      width: 177px;
    }
  }
`;

export const ItemBig = styled.div`
  grid-column: 1 / span 2;
  grid-row-end: span 2;

  @media screen and (max-width: 768px) {
    grid-column: 1 / span 1;
    grid-row-end: span 4;
  }
`;
