import React from 'react';
import Context from '../../../Operaciones/store';
import TablaEventos from './TablaEventos';

function Eventos() {
  return (
    <>
      <Context.Consumer>
        {
          (value) => {
            if (value.lote === null) {
              return (
                <TablaEventos eventos={[]} />
              );
            }
            return (
              <TablaEventos eventos={value.lote.eventos} />
            );
          }
        }
      </Context.Consumer>
    </>
  );
}
export default Eventos;
