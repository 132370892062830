import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import TableCell from '@material-ui/core/TableCell';

const styles = theme => ({
  head: {
    backgroundColor: '#F5F5F5',
    color: '#263238',
  },
  body: {
    fontSize: 14,
  },
});

function StyledTableCell(props) {
  const { classes, ...other } = props;
  return <TableCell {...other} />;
}

export default withStyles(styles)(StyledTableCell);
