import React from 'react';

import BarraDeBusqueda from './Components/BarraDeBusqueda';
import ListaDetalladas from './Components/ListaDetalladas';

function Detalladas() {
  return (
    <>
      <BarraDeBusqueda />
      <ListaDetalladas />
    </>
  );
}

export default Detalladas;
