/* eslint-disable import/prefer-default-export */
import React from 'react';
import { Card, Grid, CardContent, Typography, makeStyles } from '@material-ui/core';
import Context from '../Context';

const useStyles = makeStyles(theme => ({
  root: {
    marginTop: theme.spacing(1),
  },
  unchecked: {
    display: 'none',
  },
}));

// eslint-disable-next-line react/prop-types
const EstandarGrupoTalla = ({ grupoTalla, estandar, check }) => (
  <Grid item xs={4} className={!check && useStyles().unchecked}>
    <Card>
      <CardContent>
        <Typography color="textSecondary">
          { grupoTalla }
        </Typography>
        <Typography variant="h5" component="h2">
          { estandar.toFixed(2) }
        </Typography>
      </CardContent>
    </Card>
  </Grid>
);

export const TiempoProcesos = () => {
  const classes = useStyles();
  return (
    <Context.Consumer>
      {
        ({
          estandarBebes, estandarNinios, estandarAdultos, bebesCheck, niniosCheck, adultosCheck,
        }) => (
          <Grid
            container
            justify="center"
            alignItems="center"
            spacing={1}
            className={classes.root}
          >
            <EstandarGrupoTalla grupoTalla="BEBÉS" estandar={estandarBebes} check={bebesCheck} />
            <EstandarGrupoTalla grupoTalla="NIÑOS" estandar={estandarNinios} check={niniosCheck} />
            <EstandarGrupoTalla grupoTalla="ADULTOS" estandar={estandarAdultos} check={adultosCheck} />
          </Grid>
        )
      }
    </Context.Consumer>
  );
};

