import React from 'react';
import { IconButton,
  makeStyles,
  Button,
  Dialog,
  DialogContent,
  DialogActions } from '@material-ui/core';
import { InfoOutlineIcon } from 'mdi-react';
import Context from '../../../Operaciones/store';
import Segundas from './Segundas';

const useStyles = makeStyles(() => ({
  tableSize: {
    '& .MuiDialog-paperWidthSm': {
      maxWidth: '100%',
    },
  },
}));

function DialogSegundas() {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const handleClose = () => {
    setOpen(false);
  };
  const handleOpen = () => {
    setOpen(true);
  };
  return (
    <>
      <Context.Consumer>
        {
          (value) => {
            if (value.lote === null) {
              return (
                <IconButton
                  style={{ width: '36%', marginLeft: '28%', marginTop: '-13%' }}
                  size="small"
                >
                  <InfoOutlineIcon />
                </IconButton>
              );
            }
            return (
              <>
                <IconButton
                  style={{ width: '36%', marginLeft: '28%', marginTop: '-13%' }}
                  size="small"
                  onClick={handleOpen}
                >
                  <InfoOutlineIcon />
                </IconButton>
                <Dialog
                  open={open}
                  onClose={handleClose}
                  aria-labelledby="form-dialog-title"
                  className={classes.tableSize}
                >
                  <DialogContent style={{ paddingTop: '0%' }}>
                    <Segundas lote={value.lote.codigoLote} />
                  </DialogContent>
                  <DialogActions>
                    <Button onClick={handleClose} color="primary">
                        Cerrar
                    </Button>
                  </DialogActions>
                </Dialog>
              </>
            );
          }
        }
      </Context.Consumer>
    </>
  );
}
export default DialogSegundas;
