import {
  Button,
  CircularProgress,
  Collapse,
  Container,
  createMuiTheme,
  InputLabel,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Toolbar,
} from '@material-ui/core';
import {
  AlphaXIcon,
  CheckboxBlankOutlineIcon,
  CheckboxIntermediateIcon,
  ChevronDownIcon,
  ChevronRightIcon,
  CloseIcon,
  ContentSaveAllIcon,
  FileExcelIcon,
  FilesIcon,
  SendIcon,
  WarningIcon,
} from 'mdi-react';
import React from 'react';
import ModalForm from '../Comunes/ModalForm';
import StyledTableCell from '../Comunes/StyledTableCell';
import config from '../../appConfig.json';
import FiltroCargaReferenciaCategoria from './FiltroCargaReferenciaCategoria';
import Alerta from '../Comunes/Alerta';

const theme = createMuiTheme({
  palette: {
    primary: { main: '#263238' },
    secondary: { main: '#1a237e' },
  },
});

class CargaReferenciaCategoria extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      estandares: [],
      estandaresFiltrados: [],
      modalExcel: true,
      modalConfirmarGuardar: false,
      validandoCategorias: false,
      guardandoEstandares: false,
      file: null,
      expandirErrorItems: false,
      todosSeleccionados: false,
      itemsPorPagina: 10,
      pagina: 0,
      error: {
        visible: false,
        mensaje: '',
      },
      filtro: {
        conError: null,
        listaSubsecciones: [],
        listaCategorias: [],
        listaGrupoTallas: [],
        listaOperaciones: [],
        listaMateriales: [],
      },
      filtrando: false,
    };
    this.seleccionarArchivo = this.seleccionarArchivo.bind(this);
    this.validarEstandares = this.validarEstandares.bind(this);
    this.guardarEstandares = this.guardarEstandares.bind(this);
    this.cerrarModalExcel = this.cerrarModalExcel.bind(this);
    this.seleccionarTodos = this.seleccionarTodos.bind(this);
    this.expandirErrores = this.expandirErrores.bind(this);
    this.seleccionarEstandar = this.seleccionarEstandar.bind(this);
    this.expandirErroresEstandar = this.expandirErroresEstandar.bind(this);
    this.obtenerValoresFiltro = this.obtenerValoresFiltro.bind(this);
    this.filtrar = this.filtrar.bind(this);
  }

  seleccionarArchivo(archivo) {
    this.setState({ file: archivo.target.files[0] });
  }

  cerrarModalExcel() {
    this.setState({ modalExcel: false });
  }

  async seleccionarTodos() {
    const seleccionados = this.state.estandaresFiltrados.map(estandar => (
      estandar.mensaje.length === 0
        ? { ...estandar, seleccionado: !this.state.todosSeleccionados }
        : estandar
    ));
    const { estandares } = this.state;
    for (let index = 0; index < seleccionados.length; index += 1) {
      const element = seleccionados[index];
      estandares[element.id] = element;
    }
    await this.setState({
      todosSeleccionados: !this.state.todosSeleccionados,
      estandaresFiltrados: this.state.estandaresFiltrados.map(estandar => (
        estandar.mensaje.length === 0
          ? { ...estandar, seleccionado: !this.state.todosSeleccionados }
          : estandar
      )),
      estandares: [...estandares],
      filtrando: false,
    });
  }

  async filtrar(filtro) {
    await this.setState({ filtrando: true });
    const resultado = await this.state.estandares.filter((estandar) => {
      if (filtro.conError === 'false' && (estandar.mensaje.length > 0 || estandar.actualizado === false)) {
        return false;
      }
      if (filtro.conError === 'true' && (estandar.mensaje.length === 0 || estandar.actualizado === true)) {
        return false;
      }
      if (filtro.subsecciones.length > 0 &&
        !filtro.subsecciones.some(subseccion =>
          subseccion.id === estandar.idSubseccion)) {
        return false;
      }
      if (filtro.categorias.length > 0 &&
        !filtro.categorias.some(categoria =>
          categoria.id === estandar.idCategoria)) {
        return false;
      }
      if (filtro.grupoTallas.length > 0 &&
        !filtro.grupoTallas.some(grupoTalla =>
          grupoTalla.id === estandar.idGrupoTalla)) {
        return false;
      }
      if (filtro.operaciones.length > 0 &&
        !filtro.operaciones.some(operacion =>
          operacion.id === estandar.idOperacionGeneral)) {
        return false;
      }
      if (filtro.materiales.length > 0 &&
        !filtro.materiales.some(material =>
          material.id === estandar.idMaterial)) {
        return false;
      }
      return true;
    });
    await this.setState({
      estandaresFiltrados: resultado,
      pagina: 0,
      itemsPorPagina: 10,
      expandirErrorItems: false,
      filtrando: false,
    });
  }

  expandirErrores() {
    this.setState({
      expandirErrorItems: !this.state.expandirErrorItems,
      estandaresFiltrados: this.state.estandaresFiltrados.map(estandar => ({
        ...estandar, mostrarErrores: !this.state.expandirErrorItems,
      })),
    });
  }

  paginar = (event, newPage) => {
    this.setState({ pagina: newPage });
  };

  cambiarCantidadItemsPagina = (event) => {
    this.setState({
      itemsPorPagina: parseInt(event.target.value, 10),
      pagina: 0,
    });
  };

  obtenerLista(idKey, codigoKey, descripcionKey) {
    const { estandares } = this.state;
    const lista = [];
    estandares.forEach((estandar) => {
      if (!lista.some(item => item.id === estandar[idKey])) {
        lista.push({
          id: estandar[idKey],
          codigo: estandar[codigoKey],
          descripcion: estandar[descripcionKey],
        });
      }
    });
    return lista;
  }

  async obtenerValoresFiltro() {
    await this.setState({
      filtro: {
        listaSubsecciones: this.obtenerLista(
          'idSubseccion',
          'codigoSubseccion',
          'descripcionSubseccion',
        ),
        listaCategorias: this.obtenerLista(
          'idCategoria',
          'codigoCategoria',
          'descripcionCategoria',
        ),
        listaGrupoTallas: this.obtenerLista(
          'idGrupoTalla',
          'codigoGrupoTalla',
          'descripcionGrupoTalla',
        ),
        listaOperaciones: this.obtenerLista(
          'idOperacionGeneral',
          'codigoOperacionGeneral',
          'descripcionOperacionGeneral',
        ),
        listaMateriales: this.obtenerLista(
          'idMaterial',
          'codigoMaterial',
          'descripcionMaterial',
        ),
      },
    });
  }

  async validarEstandares() {
    await this.setState({
      estandares: [],
      estandaresFiltrados: [],
      validandoCategorias: true,
    });
    const formData = new FormData();
    formData.append('file', this.state.file);
    try {
      const response = await fetch(
        `${config.ApiUrl}/Estandares/referencia-categoria-excel`,
        {
          method: 'POST',
          body: formData,
          headers: {
            Authorization: localStorage.getItem(config.AuthTokenKey),
          },
        },
      );
      if (response.ok) {
        const data = await response.json();
        const estandaresValidados = data.map((estandar, index) => ({
          ...estandar,
          seleccionado: estandar.mensaje.length === 0,
          mostrarErrores: false,
          id: index,
        }));
        this.setState({
          estandaresFiltrados: estandaresValidados,
          modalExcel: false,
          validandoCategorias: false,
          todosSeleccionados: true,
        });
        this.setState({
          estandares: [...this.state.estandaresFiltrados],
        });
        await this.obtenerValoresFiltro();
      } else {
        this.setState({
          error: { visible: true, mensaje: 'Hubo un error al validar los estandares' },
          validandoCategorias: false,
        });
      }
    } catch (error) {
      this.setState({
        error: { visible: true, mensaje: 'Hubo un error al validar los estandares' },
        validandoCategorias: false,
      });
    }
  }

  async guardarEstandares() {
    const estandares = this.state.estandares.filter(estandar => estandar.seleccionado)
      .map((estandar) => {
        const {
          seleccionado,
          mostrarErrores,
          id,
          ...resto
        } = estandar;
        return resto;
      });
    if (!this.state.modalConfirmarGuardar &&
      estandares.length > this.state.estandaresFiltrados.filter(estandar => estandar.seleccionado).length) {
      await this.setState({ modalConfirmarGuardar: true });
      return;
    }
    if (estandares.length === 0) {
      await this.setState({ error: { visible: true, mensaje: 'No seleccionaste algún estandar' } });
      return;
    }
    await this.setState({ guardandoEstandares: true });
    try {
      const response = await fetch(
        `${config.ApiUrl}/Estandares/referencia-categoria-procesar-data`,
        {
          method: 'POST',
          headers: {
            Authorization: localStorage.getItem(config.AuthTokenKey),
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(estandares),
        },
      );
      await this.setState({
        modalConfirmarGuardar: false,
      });
      if (response.ok) {
        const data = await response.json();
        await this.setState({
          estandares: data,
          estandaresFiltrados: data,
        });
        await this.obtenerValoresFiltro();
      } else {
        await this.setState({ error: { visible: true, mensaje: 'Hubo un error al guardar los estandares' } });
      }
    } catch (error) {
      await this.setState({ error: { visible: true, mensaje: 'Hubo un error al guardar los estandares' } });
    }
    await this.setState({ guardandoEstandares: false });
  }

  seleccionarEstandar(estandarSeleccionado) {
    if (estandarSeleccionado.mensaje.length === 0) {
      this.setState({
        estandaresFiltrados: this.state.estandaresFiltrados.map((estandarFiltrado) => {
          if (estandarFiltrado.id === estandarSeleccionado.id) {
            return { ...estandarSeleccionado, seleccionado: !estandarFiltrado.seleccionado };
          }
          return estandarFiltrado;
        }),
        // estandares: [...this.state.estandares],
        estandares: this.state.estandares.map((estandar) => {
          if (estandar.id === estandarSeleccionado.id) {
            return { ...estandarSeleccionado, seleccionado: !estandar.seleccionado };
          }
          return estandar;
        }),
      });
    }
  }

  expandirErroresEstandar(index) {
    this.setState({
      estandaresFiltrados: this.state.estandaresFiltrados.map((item, i) => {
        if (i === index) {
          return { ...item, mostrarErrores: !item.mostrarErrores };
        }
        return item;
      }),
    });
  }

  renderCargaExcel() {
    if (this.state.modalExcel) {
      return (
        <React.Fragment>
          <Container>
            <InputLabel style={{ marginBottom: '20px' }}>
              Cargar el Excel con los Estandares de Referencia Categoria, y dale guardar para verificar los datos
            </InputLabel>
            <InputLabel
              disabled={this.state.validandoCategorias}
              htmlFor="file"
              style={{
                cursor: 'pointer',
                border: '1px solid #263238',
                padding: '10px',
                borderRadius: '5px',
                width: 'fit-content',
              }}
            >
              <FilesIcon />
              {this.state.file ? this.state.file.name : 'No has seleccionado un archivo'}
            </InputLabel>
            <TextField
              id="file"
              type="file"
              onChange={this.seleccionarArchivo}
              disabled={this.state.validandoCategorias}
              style={{ display: 'none' }}
            />
            <Button
              disabled={!this.state.file || this.state.validandoCategorias}
              onClick={this.validarEstandares}
              endIcon={<SendIcon />}
            >
              {
                this.state.validandoCategorias ?
                  <CircularProgress color="#4ce1b6" /> :
                  'Validar'
              }
            </Button>
          </Container>
        </React.Fragment>
      );
    }
    return null;
  }

  renderModalGuardar() {
    return (
      <React.Fragment>
        <Container>
          <InputLabel style={{ marginBottom: '20px' }}>
            Hay estandares seleccionados aparte de los del filtro, ¿deseas guardarlos?
          </InputLabel>
          <Button
            onClick={this.guardarEstandares}
            disabled={this.state.guardandoEstandares}
            endIcon={
              this.state.guardandoEstandares ?
                <CircularProgress color="#4ce1b6" /> :
                <SendIcon />
            }
          >
            Guardar
          </Button>
          <Button
            onClick={() => this.setState({ guardandoEstandares: false })}
            disabled={this.state.guardandoEstandares}
          >
            Cancelar
          </Button>
        </Container>
      </React.Fragment>
    );
  }

  render() {
    const nombreColumnas = [
      <Button
        onClick={this.expandirErrores}
      >
        {
          this.state.expandirErrorItems ?
            <ChevronDownIcon /> :
            <ChevronRightIcon />
        }
      </Button>,
      <Button
        onClick={this.seleccionarTodos}
      >
        {
          this.state.todosSeleccionados ?
            <CheckboxIntermediateIcon /> :
            <CheckboxBlankOutlineIcon />
        }
      </Button>,
      'Subs.',
      'Desc. Subs',
      'Material',
      'Desc. Material',
      'Categoria',
      'Desc. Categoria',
      'Grupo Talla',
      'Desc. Grupo Talla',
      'Operación',
      'Desc. Operación',
      'Estandar',
      this.state.estandares &&
      this.state.estandares.length > 0 &&
      this.state.estandares[0].actualizado !== null ?
        <WarningIcon /> :
        <CloseIcon color="red" />,
    ];
    return (
      <React.Fragment>
        <Toolbar
          style={{
            justifyContent: 'space-around',
          }}
        >
          <Button
            style={{ color: 'white', backgroundColor: theme.palette.primary.main, textTransform: 'none' }}
            startIcon={
              <FileExcelIcon />
            }
            onClick={() => this.setState({ modalExcel: true })}
            disabled={this.state.guardandoEstandares}
          >
            Cargar Excel
          </Button>
          <Button
            style={{ color: 'white', backgroundColor: theme.palette.primary.main, textTransform: 'none' }}
            startIcon={<ContentSaveAllIcon />}
            onClick={this.guardarEstandares}
            disabled={this.state.guardandoEstandares}
          >
            Guardar
          </Button>
        </Toolbar>
        <Paper>
          <Toolbar>
            <FiltroCargaReferenciaCategoria
              filtro={this.state.filtro}
              filtrar={this.filtrar}
            />
          </Toolbar>
          <TableContainer>
            {
              this.state.filtrando ?
                <CircularProgress /> :
                <Table>
                  <TableHead>
                    <TableRow>
                      {
                        nombreColumnas.map(columna => (
                          <StyledTableCell>
                            {columna}
                          </StyledTableCell>
                        ))
                      }
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {
                      this.state.estandaresFiltrados.length > 0 ?
                        (this.state.itemsPorPagina > 0
                          ? this.state.estandaresFiltrados.slice(
                            this.state.pagina * this.state.itemsPorPagina,
                            this.state.pagina * this.state.itemsPorPagina + this.state.itemsPorPagina,
                          )
                          : this.state.estandaresFiltrados
                        ).map((estandar, index) => (
                          <React.Fragment>
                            <TableRow
                              style={
                                estandar.actualizado === false ?
                                  { background: 'rgb(182, 182, 182)' } :
                                  null
                              }
                            >
                              <TableCell>
                                <Button
                                  onClick={() =>
                                    this.expandirErroresEstandar(this.state.pagina * this.state.itemsPorPagina + index)}
                                >
                                  {
                                    estandar.mostrarErrores ?
                                      <ChevronDownIcon /> :
                                      <ChevronRightIcon />
                                  }
                                </Button>
                              </TableCell>
                              <TableCell>
                                <Button
                                  onClick={() => this.seleccionarEstandar(
                                    estandar,
                                    this.state.pagina * this.state.itemsPorPagina + index,
                                  )}
                                  disabled={
                                    estandar.mensaje.length > 0 ||
                                    estandar.actualizado !== null
                                  }
                                >
                                  {
                                    estandar.seleccionado && estandar.mensaje.length === 0 ?
                                      <CheckboxIntermediateIcon /> :
                                      <CheckboxBlankOutlineIcon />
                                  }
                                </Button>
                              </TableCell>
                              <TableCell>{estandar.codigoSubseccion}</TableCell>
                              <TableCell>{estandar.descripcionSubseccion}</TableCell>
                              <TableCell>{estandar.codigoMaterial}</TableCell>
                              <TableCell>{estandar.descripcionMaterial}</TableCell>
                              <TableCell>{estandar.codigoCategoria}</TableCell>
                              <TableCell>{estandar.descripcionCategoria}</TableCell>
                              <TableCell>{estandar.codigoGrupoTalla}</TableCell>
                              <TableCell>{estandar.descripcionGrupoTalla}</TableCell>
                              <TableCell>{estandar.codigoOperacionGeneral}</TableCell>
                              <TableCell>{estandar.descripcionOperacionGeneral}</TableCell>
                              <TableCell>{estandar.estandar}</TableCell>
                              <TableCell>{estandar.mensaje.length}</TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={12}>
                                {
                                  estandar.mensaje.length > 0 ?
                                    <Collapse
                                      style={{ padding: 20 }}
                                      in={estandar.mostrarErrores}
                                      timeout="auto"
                                      unmountOnExit
                                    >
                                      {
                                        estandar.mensaje.map(error => (
                                          <p>
                                            <AlphaXIcon color="red" />{error}.
                                          </p>
                                        ))
                                      }
                                    </Collapse> :
                                    null
                                }
                              </TableCell>
                            </TableRow>
                          </React.Fragment>
                        )) : null}
                  </TableBody>
                </Table>
            }
            <TablePagination
              rowsPerPageOptions={[10, 20, 30, 40, 50]}
              component="div"
              count={this.state.estandaresFiltrados.length}
              rowsPerPage={this.state.itemsPorPagina}
              page={this.state.pagina}
              onChangePage={this.paginar}
              onChangeRowsPerPage={this.cambiarCantidadItemsPagina}
              labelRowsPerPage=""
            />
          </TableContainer>
        </Paper>
        <ModalForm
          open={this.state.modalExcel}
          boton={
            <Button
              disabled={this.state.validandoCategorias}
              onClick={this.cerrarModalExcel}
            >
              <CloseIcon />
            </Button>
          }
          title={
            <h4 className="text-center font-weight-bold custom-title">
              Validar estandares
            </h4>
          }
          content={this.renderCargaExcel()}
        />
        <ModalForm
          title="Guardar Estandares"
          content={this.renderModalGuardar()}
          open={this.state.modalConfirmarGuardar}
        />
        <Alerta
          isVisible={this.state.error.visible}
          toggle={() => this.setState({ error: { visible: false } })}
          texto={this.state.error.mensaje}
        />
      </React.Fragment>
    );
  }
}

export default CargaReferenciaCategoria;
