import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { InformationIcon } from 'mdi-react';
import TextField from '@material-ui/core/TextField';

import {
  Table,
} from 'reactstrap';

function table(props) {
  const {
    data, urlBase, handleChangeFilterParqueadero, handleChangeFilterMaquina,
  } = props;

  const indicador = (porcentaje) => {
    if (porcentaje < 0.90) {
      return 'Máquina lenta';
    }
    if (porcentaje > 1.10) {
      return 'Máquina rapida';
    }
    return '';
  };

  return (
    <>
      <hr />
      <div>
        <TextField
          onChange={handleChangeFilterMaquina}
          label="Máquina"
          id="standard-size-normal"
          style={{ marginLeft: '1%', marginRight: '2%' }}
        />
        <TextField
          onChange={handleChangeFilterParqueadero}
          label="Parqueadero"
          id="standard-size-normal"
          style={{ marginLeft: '1%', marginRight: '2%' }}
        />
      </div>
      <div>
        <Table striped>
          <thead>
            <tr>
              <th scope="row">Máquina</th>
              <th scope="row">Parqueadero</th>
              <th scope="row">Uds primera</th>
              <th scope="row">Uds segunda</th>
              <th scope="row">%sdas reportadas</th>
              <th scope="row">Ef. Máquina</th>
              <th scope="row">Ef. Operativa</th>
              <th scope="row">Rápidez máq</th>
              <th scope="row">Indicador</th>
              <th scope="row">PO</th>
              <th scope="row">DM</th>
              <th scope="row">CR</th>
              <th scope="row">CCR</th>
              <th scope="row">MPB</th>
              <th scope="row">MPT</th>
              <th scope="row">OTROS</th>
              <th />
            </tr>
          </thead>
          <tbody>
            {data.map(maquina => (
              <tr>
                <Link to={`${urlBase}/maquina/${maquina.maquinaId}`}>
                  <td>{maquina.codigoMaquina}</td>
                </Link>
                <td>{maquina.parqueadero}</td>
                <td>{maquina.eficiencia.primeras}</td>
                <td>{maquina.eficiencia.segundas}</td>
                <td>{Math.floor((maquina.eficiencia.porcentajeSegundasReportadas * 100))}%</td>
                <td>{Math.floor(maquina.eficiencia.porcentajeEficienciaLote * 100)}%</td>
                <td>{Math.floor(maquina.eficiencia.porcentajeEficienciaOperativa * 100)}%</td>
                <td>{Math.floor(maquina.eficiencia.porcentajeRapidezMaquina * 100)}%</td>
                <td>{indicador(maquina.eficiencia.porcentajeRapidezMaquina)}</td>
                <td>{Math.floor((maquina.eficiencia.porcentajeParoOperativo) * 100)}%</td>
                <td>{Math.floor((maquina.eficiencia.porcentajeParoMecanico) * 100)}%</td>
                <td>{Math.floor((maquina.eficiencia.porcentajeParoCambioReferencia) * 100)}%</td>
                <td>{Math.floor((maquina.eficiencia.porcentajeParoColaCambioReferencia) * 100)}%</td>
                <td>{Math.floor((maquina.eficiencia.porcentajeParoMateriaPrimaBodega) * 100)}%</td>
                <td>{Math.floor((maquina.eficiencia.porcentajeParoMateriaPrimaTej) * 100)}%</td>
                <td>{Math.floor((maquina.eficiencia.porcentajeParoElectronico +
                  maquina.eficiencia.porcentajeParoFaltaRepuesto +
                  maquina.eficiencia.porcentajeParoServicios +
                  maquina.eficiencia.porcentajeParoCambioPinta +
                  maquina.eficiencia.porcentajeParoLimpieza +
                  maquina.eficiencia.porcentajeParoOff
                  ) * 100)}%
                </td>
                <Link to={`${urlBase}/maquina/${maquina.maquinaId}`}>
                  <td><InformationIcon /></td>
                </Link>
              </tr>
              ))}
          </tbody>
        </Table>
      </div>
    </>
  );
}

table.propTypes = {
  data: PropTypes.objectOf(PropTypes.object).isRequired,
  base: PropTypes.string.isRequired,
  handleChangeFilterParqueadero: PropTypes.isRequired,
  handleChangeFilterMaquina: PropTypes.isRequired,
};
export default table;
