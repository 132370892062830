/* eslint-disable import/prefer-default-export */
import React, { useState } from 'react';
import { CheckIcon, LoadingIcon } from 'mdi-react';
import { TextField, Grid, Fab, MenuItem } from '@material-ui/core';


import Context from '../Context';

import { ApiUrl, AuthTokenKey } from './../../../appConfig.json';

export const ReferenciaCategoria = () => {
  const [referenceError, setReferenceError] = useState(false);
  const [categoryError, setCategoryError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [categories, setCategories] = useState([]);
  const [isCancel, setIsCancel] = useState(false);

  const searchReferenciaCategoria = async (referencia, categoria) => {
    const request = await fetch(`${ApiUrl}/Operaciones/ReferenciaCategoria`, {
      mode: 'cors',
      method: 'POST',
      body: JSON.stringify({
        ReferenciaId: referencia,
        CategoriaId: categoria,
      }),
      headers: {
        'Content-Type': 'application/json;charset=utf-8',
        Authorization: localStorage.getItem(AuthTokenKey),
      },
    });

    const response = await request.json();

    return response;
  };

  return (
    <Context.Consumer>
      {
        ({
          referencia, activateReferencia, categoria, activateCategoria, procesos, activateProcesos,
          activateEstBebes, activateEstNinios, activateEstAdultos, activateNormaEmpaque,
          activateBebes, activateNinios, activateAdultos, activateEmpaqueManual, activateEmpaqueAutotex,
          activateProcesosEliminados, activateProcesosAgregados,
        }) => {
          const handleSearch = async (ct) => {
            if (loading) return;

            setLoading(true);

            if (!validateEmpties()) {
              return;
            }

            const response = await searchReferenciaCategoria(referencia, !ct ? categoria : ct);

            if (!validateResponse(response)) {
              return;
            }

            // Reiniciar los procesos transitorios no guardados
            activateProcesosEliminados([]);
            activateProcesosAgregados([]);

            activateReferencia(response.codigoMaterial);
            activateCategoria(response.codigoCategoria);
            activateNormaEmpaque(response.normaEmpaque);

            response.codigosCategorias.length >= 1 && response.codigosCategorias.push('OTRO');
            setCategories(response.codigosCategorias);

            const ops = response.operaciones;
            activateProcesos(ops.length > 0 ? ops : procesos);

            // Activar Check de Tallas
            activateBebes(response.bebesCheck);
            activateNinios(response.niniosCheck);
            activateAdultos(response.adultosCheck);

            // Activar Check de Subsecciones
            activateEmpaqueManual(response.empaqueManualCheck);
            activateEmpaqueAutotex(response.empaqueAutotexCheck);

            sumarEstandaresEncontrados(response);
            setLoading(false);

            if (response.codigosCategorias.length > 0) {
              setIsCancel(!isCancel);
            }
          };

          const validateEmpties = () => {
            if (referencia.trim() === '' || referencia === undefined) {
              setReferenceError(true);
              setLoading(false);
              return false;
            }

            // if (categoria.trim() === '' || categoria === undefined) {
            //   setCategoryError(true);
            //   setLoading(false);
            //   return false;
            // }

            return true;
          };

          const validateResponse = (response) => {
            if (response === undefined) {
              // unactivateFields();
              setLoading(false);
              return false;
            }

            // if (!response.codigoCategoria || !response.codigoMaterial || !response.normaEmpaque) {
            //   activateReferencia(referencia);
            //   activateCategoria(categoria);
            //   setLoading(false);
            //   return false;
            // }

            if (!response.codigosCategorias || !response.codigoMaterial || !response.normaEmpaque) {
              activateReferencia(referencia);
              activateCategoria(categoria);
              setLoading(false);
              return false;
            }

            return true;
          };

          const sumarEstandaresEncontrados = (response) => {
            let bebes = 0;
            let ninios = 0;
            let adultos = 0;

            const operaciones = response.operaciones.length > 0 ? response.operaciones : procesos;

            operaciones.forEach((operacion) => {
              bebes += operacion.estandares[0].estandar;
              ninios += operacion.estandares[1].estandar;
              adultos += operacion.estandares[2].estandar;
            });

            activateEstBebes(bebes);
            activateEstNinios(ninios);
            activateEstAdultos(adultos);
          };

          const handleReferenceChange = (e) => {
            activateReferencia(e.target.value);
            referenceError && setReferenceError(!referenceError);
          };

          const handleCategoryChange = (e) => {
            activateCategoria(e.target.value.toUpperCase());
            categoryError && setCategoryError(!categoryError);
          };

          const handleChange = async (event) => {
            // setCategory(event.target.value);
            if (event.target.value === 'OTRO') {
              // handleSearch();
              if (isCancel) {
                setCategories([]);
                activateCategoria('');
                setIsCancel(!isCancel);
                return;
              }
            }

            activateCategoria(event.target.value);
            setIsCancel(!isCancel);

            await handleSearch(event.target.value);
          };

          return (
            <Grid container alignItems="center" justify="center">
              <Grid container item sm={10} spacing={2}>
                <Grid item xs={5}>
                  <TextField
                    label="Referencia"
                    value={referencia}
                    onChange={handleReferenceChange}
                    error={referenceError}
                    helperText={referenceError ? 'Se debe ingresar una referencia' : null}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={5}>
                  {
                    categories.length <= 1 ?
                      <TextField
                        label="Categoría"
                        value={categoria}
                        onChange={handleCategoryChange}
                        error={categoryError}
                        helperText={categoryError ? 'Se debe ingresar una categoría' : null}
                        fullWidth
                      /> :
                      <TextField
                        id="standard-select-category"
                        select
                        label="Categoría"
                        value={categoria}
                        onChange={handleChange}
                        helperText="Seleccione una categoría"
                      >
                        {categories.map(option => (
                          <MenuItem key={option} value={option}>
                            {option}
                          </MenuItem>
                        ))}
                      </TextField>
                  }
                </Grid>
                <Grid item xs={2}>
                  {
                    !isCancel &&
                      <Fab color="primary" size="medium" onClick={() => handleSearch(null)}>
                        {
                          !loading ?
                            <CheckIcon />
                          :
                            <LoadingIcon className="load__icon-small" />
                        }
                      </Fab>
                  }
                </Grid>
              </Grid>
            </Grid>
            );
        }
      }
    </Context.Consumer>
  );
};
