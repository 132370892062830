import React from 'react';
import { IconButton,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Divider,
  Paper } from '@material-ui/core';
import { DeleteIcon, AddCircleIcon, EditIcon } from 'mdi-react';
import { perteneceRolIngenieria } from '../../../Comunes/datosComunes';

export default function TablaGrupos(props) {
  return (
    <>
      <Paper>
        <Divider />
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell>Codigo</TableCell>
              <TableCell>Descripción</TableCell>
              <TableCell>Unidad de negocio</TableCell>
              <TableCell>Fecha de creación</TableCell>
              <TableCell>Fecha de modificación</TableCell>
              {
                perteneceRolIngenieria() &&
                <TableCell>
                  Nuevo
                  <IconButton size="small" onClick={() => props.clean()}>
                    <AddCircleIcon color="primary" />
                  </IconButton>
                </TableCell>
              }
            </TableRow>
          </TableHead>
          <TableBody>
            {props.grupos.map(g => (
              <TableRow key={g.id}>
                <TableCell>
                  {g.codigo}
                </TableCell>
                <TableCell>
                  {g.descripcion}
                </TableCell>
                <TableCell>
                  {g.unidadNegocio.descripcion}
                </TableCell>
                <TableCell>
                  {g.fechaCreacion.slice(0, 10)}
                </TableCell>
                <TableCell>
                  {g.fechaModificacion.slice(0, 10)}
                </TableCell>
                {
                  perteneceRolIngenieria() &&
                  <TableCell>
                    <IconButton size="small" onClick={() => { props.activate(g); }} >
                      <EditIcon fontSize="small" color="primary" />
                    </IconButton>
                    <IconButton size="small" onClick={() => { props.delete(g); }} >
                      <DeleteIcon fontSize="small" style={{ color: '#ED3647' }} />
                    </IconButton>
                  </TableCell>
                }
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Paper>
    </>
  );
}
