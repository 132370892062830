import React from 'react';
import { Label, FormGroup, Button } from 'reactstrap';
import { FileExcelIcon, SearchIcon } from 'mdi-react';
import PropTypes from 'prop-types';
import Picky from 'react-picky';
import 'react-picky/dist/picky.css';
import MaterialUISelectorMultiple from '../Comunes/MateriaUISelectorMultiple';
import {
  obtenerSecciones,
  obtenerSubseccionesPorSecciones,
  obtenerOperaciones,
} from '../Comunes/datosComunes';

class FiltroEstandaresOperacion extends React.Component {
  constructor(props) {
    super(props);
    this.seccionSeleccionada = this.seccionSeleccionada.bind(this);
    this.subseccionSeleccionada = this.subseccionSeleccionada.bind(this);
    this.operacionSeleccionada = this.operacionSeleccionada.bind(this);
    this.ejecutarConsulta = this.ejecutarConsulta.bind(this);
    this.exportar = this.exportar.bind(this);
    this.state = {
      listaSecciones: [],
      secciones: [],
      listaSubsecciones: [],
      subsecciones: [],
      listaOperaciones: [],
      operaciones: [],
      tallas: [],
      cargando: {
        secciones: false,
        subsecciones: false,
        operaciones: false,
        tallas: false,
      },
    };
  }
  componentDidMount() {
    this.cargarDatosIniciales().then();
  }
  abortController = new AbortController();
  async cargarDatosIniciales() {
    this.cargarSecciones();
    await this.cargarOperaciones();
  }
  async cargarSecciones() {
    await this.setState({ cargando: { secciones: true } });
    const secciones = await obtenerSecciones(this.abortController.signal);
    await this.setState({ listaSecciones: secciones, secciones: [], cargando: { secciones: false } });
  }
  async cargarSubsecciones() {
    await this.setState({ cargando: { subsecciones: true } });
    const subsecciones =
      await obtenerSubseccionesPorSecciones(this.state.secciones.map(x => x.id), this.abortController.signal);
    await this.setState({
      listaSubsecciones: subsecciones,
      subsecciones: [],
      cargando: { subsecciones: false },
    });
  }
  async cargarOperaciones() {
    await this.setState({ cargando: { operaciones: true } });
    const operaciones = await obtenerOperaciones(this.abortController.signal);
    await this.setState({
      listaOperaciones: operaciones || [],
      operaciones: [],
      cargando: { operaciones: false },
    });
  }
  async seccionSeleccionada(valores) {
    await this.setState({ secciones: valores });
    await this.cargarSubsecciones();
  }
  async subseccionSeleccionada(valores) {
    await this.setState({ subsecciones: valores });
  }
  async operacionSeleccionada(valores) {
    await this.setState({ operaciones: valores });
  }
  ejecutarConsulta() {
    const valores = this.obtenerParametros();
    this.props.ejecucionConsulta(valores);
  }
  exportar() {
    const valores = this.obtenerParametros();
    if (this.props.ejecucionExportacion) {
      this.props.ejecucionExportacion(valores);
    }
  }
  obtenerParametros() {
    const mapToIds = array => (array && Array.isArray(array) ? array.map(x => x.id) : []);
    return {
      secciones: mapToIds(this.state.secciones),
      subsecciones: mapToIds(this.state.subsecciones),
      operaciones: mapToIds(this.state.operaciones),
      tallas: mapToIds(this.state.tallas),
    };
  }
  render() {
    return (
      <React.Fragment>
        <div className="row mx-auto mt-1 pt-1 justify-content-center">
          <FormGroup className="col-2 mx-1 px-1">
            <MaterialUISelectorMultiple
              variant="outlined"
              cargando={this.state.cargando.subsecciones}
              lista={this.state.listaSecciones}
              label="Sección"
              placeholder="Secciones"
              value={this.state.secciones ? this.state.secciones : ''}
              onChange={this.seccionSeleccionada}
            />
          </FormGroup>
          <FormGroup className="col-2 mx-1 px-1">
            <MaterialUISelectorMultiple
              variant="outlined"
              cargando={this.state.cargando.subsecciones}
              lista={this.state.listaSubsecciones}
              label="Subsecciones"
              placeholder="Subsecciones"
              open={false}
              value={this.state.subseccion}
              disabled={this.props.disabled || !this.state.secciones || !this.state.secciones.length}
              onChange={this.subseccionSeleccionada}
            />
          </FormGroup>
          <FormGroup className="col-2 mx-1 px-1">
            <MaterialUISelectorMultiple
              variant="outlined"
              cargando={this.state.cargando.operaciones}
              lista={this.state.listaOperaciones}
              label="Operacion"
              placeholder="Operacion"
              open={false}
              onChange={this.operacionSeleccionada}
              value={this.state.operaciones ? this.state.operaciones : ''}
            />
          </FormGroup>
        </div>
        <div className="row mx-1 px-1">
          <div className="col-12 mx-1 px-1 text-center">
            <Button
              className="btn btn-primary"
              onClick={this.ejecutarConsulta}
              disabled={this.props.disabled}
            >
              <SearchIcon
                className="icons"
              />
              {this.props.etiquetaConsultar}
            </Button>
            <Button
              className="btn btn-secondary"
              onClick={this.exportar}
              disabled={this.props.disabled}
            >
              <FileExcelIcon />
              {this.props.etiquetaExportar}
            </Button>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

FiltroEstandaresOperacion.propTypes = {
  ejecucionConsulta: PropTypes.func.isRequired,
  ejecucionExportacion: PropTypes.func,
  etiquetaConsultar: PropTypes.string.isRequired,
  etiquetaExportar: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
};

FiltroEstandaresOperacion.defaultProps = {
  disabled: false,
  ejecucionExportacion: null,
};

export default FiltroEstandaresOperacion;
