import React from 'react';
import PropTypes from 'prop-types';
import { Badge, Row, Col } from 'reactstrap';

function IndicadoresNumericosTejido(props) {
  return (
    <Row>
      {/* <Col xs={4} sm={4} md={4} className="d-flex flex-column justify-content-center">
        <h3 className="text-center" ><Badge color="">{Math.round(props.proyeccion)}</Badge></h3>
        <p className="text-center"><b>PRYCN</b></p>
      </Col> */}
      <Col xs={6} sm={6} md={6} className="d-flex flex-column justify-content-center">
        <h3 className="text-center" ><Badge color="">{Math.round(props.docenasPLC)}<small>(DZ)</small></Badge></h3>
        <p className="text-center"><b>PLC</b></p>
      </Col>
      <Col xs={6} sm={6} md={6} outline>
        <h3 className="text-center" >
          <Badge color="">
            {Math.round(props.porcentajeEficienciaOperativa)} %
          </Badge>
        </h3>
        <p className="text-center"><b>EF. Ope</b></p>
      </Col>
    </Row>
  );
}

IndicadoresNumericosTejido.propTypes = {
  // proyeccion: PropTypes.number.isRequired,
  docenasPLC: PropTypes.number.isRequired,
  porcentajeEficienciaOperativa: PropTypes.number.isRequired,
};

export default IndicadoresNumericosTejido;
