import React, { Component, Fragment } from 'react';
// eslint-disable-next-line import/no-extraneous-dependencies
import { hot } from 'react-hot-loader';
import { Provider } from 'react-redux';
import { HashRouter } from 'react-router-dom';

import 'bootstrap/dist/css/bootstrap.css';
import '../../scss/app.scss';
import Router from './Router';
import store from './store';
import ScrollToTop from './ScrollToTop';
import Loader from '../../shared/components/loader/Loader';

class App extends Component {
  constructor() {
    super();
    this.state = {
      loading: true,
      loaded: false,
    };
  }

  componentDidMount() {
    window.addEventListener('load', () => {
      this.setState({ loading: false });
      setTimeout(() => {
        this.setState({ loaded: true });
      }, 500);
    });
  }

  render() {
    const { loaded, loading } = this.state;
    return (
      <Provider store={store}>
        <HashRouter>
          <ScrollToTop>
            <Fragment>
              {!loaded && <Loader loading={loading} />}
              <div>
                <Router />
              </div>
            </Fragment>
          </ScrollToTop>
        </HashRouter>
      </Provider>
    );
  }
}

export default hot(module)(App);
