import React from 'react';
import { Grid, Paper, TextField, Checkbox, FormControlLabel, ButtonGroup } from '@material-ui/core';

import Context from '../../store';
import CrearTurno from './CrearTurno';
import ModificarTurno from './ModificarTurno';
import { perteneceRolIngenieria } from '../../../Comunes/datosComunes';

export default function Form(props) {
  return (
    <>
      <Context.Consumer>
        {
          value => (
            <Paper style={{ margin: '2%', padding: '3%' }}>
              <Grid container>
                <Grid item xs={12} style={{ marginBlockEnd: '2%' }}>
                  <h3>Información turno </h3>
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    label="Código"
                    value={value.proceso === null ? '' : value.proceso.id}
                    style={{ marginBlockEnd: '2%' }}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    label="Cantidad minutos"
                    value={value.proceso === null ? '' : value.proceso.cantidadMinutos}
                    style={{ marginBlockEnd: '2%' }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    label="Descripción"
                    value={value.proceso === null ? '' : value.proceso.descripcionTurno}
                    style={{ marginBlockEnd: '2%' }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    label="Version"
                    value={value.proceso === null ? '' : value.proceso.version}
                    style={{ marginBlockEnd: '2%' }}
                  />
                </Grid>
                <Grid item xs={4}>
                  <FormControlLabel
                    style={{ marginTop: '2%' }}
                    control={<Checkbox
                      color="primary"
                      checked={value.proceso === null ? false : value.proceso.switchDefecto}
                      name="checkedA"
                    />}
                    label="Defecto"
                  />
                </Grid>
                <Grid item xs={4}>
                  <FormControlLabel
                    style={{ marginTop: '2%' }}
                    control={<Checkbox
                      color="primary"
                      checked={value.proceso === null ? false : value.proceso.esOrdinario}
                      name="checkedA"
                    />}
                    label="Ordinario"
                  />
                </Grid>
                <Grid item xs={4}>
                  <FormControlLabel
                    style={{ marginTop: '2%' }}
                    control={<Checkbox
                      color="primary"
                      checked={value.proceso === null ? false : value.proceso.esNocturno}
                      name="checkedA"
                    />}
                    label="Nocturno"
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    label="Hora inicial"
                    value={value.proceso === null ? '' : value.proceso.horaInicial}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    label="Hora final"
                    value={value.proceso === null ? '' : value.proceso.horaFinal}
                  />
                </Grid>
                <Grid item xs={12}>
                  {
                    perteneceRolIngenieria() &&
                    <ButtonGroup
                      style={{ display: 'flex', alignContent: 'center', marginTop: '5%' }}
                      variant="contained"
                    >
                      <CrearTurno fetch={props.fetch} />
                      <ModificarTurno fetch={props.fetch} />
                    </ButtonGroup>
                  }
                </Grid>
              </Grid>
            </Paper>
          )
        }
      </Context.Consumer>
    </>
  );
}
