import React from 'react';
import {
  Grid,
  Button,
  TextField,
  Typography,
  Select,
  FormControl,
  MenuItem,
} from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import PropTypes from 'prop-types';
import { diasSemana, turnoBloqueado } from './datos';

const hoy = new Date();
const patronHoras = new RegExp(/^\d+$/);
const patronTurnoId = new RegExp(/^[A-Za-z]+D(\d)+T(\d)+$/);
const obtenerDiasSemana = () => {
  let i = -1;
  return (
    diasSemana.map((x) => {
      i += 1;
      return {
        id: i,
        descripcion: x,
      };
    })
  );
};

class CapturaDiasTurnos extends React.Component {
  constructor(props) {
    super(props);
    this.confirmar = this.confirmar.bind(this);
    this.cancelar = this.cancelar.bind(this);
    this.handleChangeHora = this.handleChangeHora.bind(this);
    this.valorLimitePorHoras = this.valorLimitePorHoras.bind(this);
    this.handleChangeLimite = this.handleChangeLimite.bind(this);
    this.crearFormulario = this.crearFormulario.bind(this);
    this.inicializarEstado = this.inicializarEstado.bind(this);
    this.actualizar = this.actualizar.bind(this);
    this.habilitarLimite = this.habilitarLimite.bind(this);
    this.hayErrorEnLimite = this.hayErrorEnLimite.bind(this);
    this.obtenerTurno = this.obtenerTurno.bind(this);
    this.hayErrorHoras = this.hayErrorHoras.bind(this);
    this.validar = this.validar.bind(this);
    hoy.setHours(0, 0, 0, 0);
    this.state = {
      registro: this.inicializarEstado(),
    };
  }
  async componentDidUpdate(prev) {
    if (this.props.registro !== prev.registro || this.props.activo !== prev.activo) {
      const x = this.inicializarEstado();
      await this.actualizar(x);
    }
  }
  async actualizar(registro) {
    await this.setState({ registro });
  }
  inicializarEstado() {
    if (this.props.registro) {
      const x = {};
      for (const [k, v] of Object.entries(this.props.registro)) {
        x[k] = v;
      }
      return x;
    }
    return [];
  }
  async obtenerHorasTurno(id) {
    const t = this.props.turnos.find(x => x.id === id);
    if (t) {
      return t.horas;
    }
    return 0;
  }
  async handleChangeHora(e, d, t) {
    const x = this.state.registro;
    let v = null;
    let vl = 0;
    if (e.target.value != null && e.target.value.trim() !== '') {
      const m = patronHoras.exec(e.target.value);
      if (m !== null) {
        v = parseInt(e.target.value, 10);
        vl = this.valorLimitePorHoras(v, d, t);
      }
    }
    x[e.target.name] = v;
    x[`limiteD${d}T${t.id}`] = vl;
    await this.setState({ registro: x });
  }
  valorLimitePorHoras(vh, d, t) {
    const kl = `horasD${d}T${t.id}`;
    let vl = this.state.registro[kl];
    if (vh <= 0 || vh >= t.horas) {
      vl = 0;
    }
    return vl;
  }
  hayErrorHoras(d, t) {
    const nombre = `horasD${d}T${t}`;
    const v = this.state.registro[nombre];
    if (v != null) {
      const m = patronTurnoId.exec(nombre);
      const turno = this.obtenerTurno(parseInt(m[2], 10));
      return turno == null || v < 0 || v > turno.horas;
    }
    return true;
  }
  obtenerTurno(id) {
    return this.props.turnos.find(x => x.id === id);
  }
  async handleChangeLimite(e) {
    const x = this.state.registro;
    x[e.target.name] = e.target.value;
    await this.setState({ registro: x });
  }
  async confirmar() {
    if (this.props.confirmado && this.validar()) {
      this.props.confirmado(this.state.registro, this.props.indice);
    }
  }
  async cancelar() {
    if (this.props.cancelado) {
      this.props.cancelado();
    }
  }
  validar() {
    let hayError = false;
    for (let i = 0; i < diasSemana.length && !hayError; i += 1) {
      for (let j = 0; j < this.props.turnos.length && !hayError; j += 1) {
        const t = this.props.turnos[j];
        hayError = !turnoBloqueado(this.props.fechaBase, i, t) &&
          (this.hayErrorHoras(i, t.id) || this.hayErrorEnLimite(i, t.id));
      }
    }
    return !hayError;
  }
  crearFormulario() {
    const filaTurnos = this.props.turnos.map(t => (
      <Grid container item xs={3} justify="center"><Grid item><Typography>{t.alias}</Typography></Grid></Grid>
    ));
    filaTurnos.unshift(<Grid item xs={2} />);
    const renderTurnos = <Grid container tiem direction="row" spacing={1}>{filaTurnos}</Grid>;
    const renderSemana = (
      obtenerDiasSemana().map((d) => {
        const dias = this.props.turnos.map(t =>
          (
            <Grid container item xs={3} direction="row" alignItems="center">
              <Grid item xs={6}>
                <TextField
                  variant="outlined"
                  size="small"
                  name={`horasD${d.id}T${t.id}`}
                  value={this.state.registro[`horasD${d.id}T${t.id}`]}
                  onChange={async e => this.handleChangeHora(e, d.id, t)}
                  disabled={turnoBloqueado(this.props.fechaBase, d.id, t)}
                  error={this.hayErrorHoras(d.id, t.id)}
                />
              </Grid>
              <Grid item xs={6}>
                <FormControl variant="outlined" style={{ minWidth: '100%', maxWidth: '100%' }} size="small">
                  <Select
                    name={`limiteD${d.id}T${t.id}`}
                    value={this.state.registro[`limiteD${d.id}T${t.id}`]}
                    onChange={this.handleChangeLimite}
                    disabled={
                      turnoBloqueado(this.props.fechaBase, d.id, t) ||
                      !this.habilitarLimite(`horasD${d.id}T${t.id}`)
                    }
                    error={this.hayErrorEnLimite(d.id, t.id)}
                  >
                    <MenuItem key={1} value={1}>A</MenuItem>
                    <MenuItem key={2} value={2}>B</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
          ));
        dias.unshift(<Grid item xs={2} alignItems="center"><Typography>{d.descripcion}</Typography></Grid>);
        return <Grid container items direction="row" spacing={1}>{dias}</Grid>;
      })
    );
    return [renderTurnos, renderSemana];
  }
  habilitarLimite(k) {
    const v = this.state.registro[k];
    const xv = v;
    return !isNaN(xv) && xv > 0 && xv < 8;
  }
  hayErrorEnLimite(d, t) {
    const v = this.state.registro[`horasD${d}T${t}`];
    const l = this.state.registro[`limiteD${d}T${t}`];
    return v > 0 && v < 8 && l !== 1 && l !== 2;
  }
  render() {
    return (
      <Grid xs={3}>
        <Dialog
          open={this.props.activo}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          maxWidth="md"
        >
          <DialogTitle id="alert-dialog-title">
            {this.props.registro && this.props.registro.nombre}
          </DialogTitle>
          <DialogContent>
            <Grid container item xs={12} direction="column">
              {this.crearFormulario()}
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.confirmar} disabled={!this.validar()} color="primary">
              Aceptar
            </Button>
            <Button onClick={this.cancelar} color="primary">
              Cancelar
            </Button>
          </DialogActions>
        </Dialog>
      </Grid>
    );
  }
}

CapturaDiasTurnos.propTypes = {
  activo: PropTypes.bool.isRequired,
  registro: PropTypes.shape.isRequired,
  fechaBase: PropTypes.shape().isRequired,
  turnos: PropTypes.arrayOf(PropTypes.shape).isRequired,
  indice: PropTypes.number.isRequired,
  confirmado: PropTypes.func.isRequired,
  cancelado: PropTypes.func.isRequired,
};

CapturaDiasTurnos.defaultProps = {
};

export default CapturaDiasTurnos;
