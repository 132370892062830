import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  IconButton,
  Switch,
  TablePagination,
  withStyles,
} from '@material-ui/core';
import { DeleteIcon, EditIcon, AddCircleIcon } from 'mdi-react';

import MaestroPasillos from '../../Pasillos';
import Alerta from '../../../Comunes/Alerta';
import { perteneceRolIngenieria } from '../../../Comunes/datosComunes';

const StyledTableCell = withStyles(theme => ({
  head: {
    backgroundColor: '#F5F5F5',
    color: '#263238',
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

export default function TablaMaquinas(props) {
  const { maquinas } = props;
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [error] = useState(false);

  const resetearError = async () => this.setState({ error: false });
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(10);
  };

  const emptyRows =
    rowsPerPage - Math.min(rowsPerPage, maquinas.length - page * rowsPerPage);

  return (
    <>
      <Alerta
        texto="Ocurrió un error de ejecución. Intente nuevamente."
        toggle={resetearError}
        isVisible={error}
      />
      <Table size="small">
        <TableHead>
          <TableRow>
            <StyledTableCell align="center">Código maquina</StyledTableCell>
            <StyledTableCell align="center">Código parqueadero</StyledTableCell>
            <StyledTableCell>Estado</StyledTableCell>
            <StyledTableCell align="center">
              Pasillo
              <MaestroPasillos fetch={props.fetch} />
            </StyledTableCell>
            <StyledTableCell align="center">Fecha de creación</StyledTableCell>
            <StyledTableCell align="center">Fecha de modificación</StyledTableCell>
            {perteneceRolIngenieria() && (
              <StyledTableCell>
                Nueva
                <IconButton
                  size="small"
                  onClick={() => {
                    props.onDialogInsert();
                  }}
                >
                  <AddCircleIcon color="primary" />
                </IconButton>
              </StyledTableCell>
            )}
          </TableRow>
        </TableHead>
        <TableBody>
          {(rowsPerPage > 0
            ? maquinas.slice(
              page * rowsPerPage,
              page * rowsPerPage + rowsPerPage,
            )
            : maquinas
          ).map(m => (
            <TableRow key={m.id}>
              <TableCell align="center">{m.codigo}</TableCell>
              <TableCell align="center">{m.parqueadero}</TableCell>
              <TableCell>
                <Switch size="small" checked={m.estado} color="primary" />
              </TableCell>
              <TableCell align="center">{m.pasillo}</TableCell>
              <TableCell align="center">
                {m.fechaCreacion.slice(0, 10)}
              </TableCell>
              <TableCell align="center">
                {m.fechaModificacion.slice(0, 10)}
              </TableCell>
              {perteneceRolIngenieria() && (
                <TableCell>
                  <IconButton size="small">
                    <EditIcon
                      fontSize="small"
                      color="primary"
                      onClick={() => {
                        props.onDialogUpdate(m);
                      }}
                    />
                  </IconButton>
                  <IconButton
                    size="small"
                    onClick={() => {
                      props.onDialogDelete(m);
                    }}
                  >
                    <DeleteIcon
                      fontSize="small"
                      style={{ color: 'red' }}
                    />
                  </IconButton>
                </TableCell>
              )}
            </TableRow>
          ))}
          {emptyRows > 0 && (
            <TableRow style={{ height: 33 * emptyRows }}>
              <TableCell colSpan={6} />
            </TableRow>
          )}
        </TableBody>
      </Table>
      <TablePagination
        rowsPerPageOptions={[10, 20, 50]}
        component="div"
        count={maquinas.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
        labelRowsPerPage=""
      />
    </>
  );
}

TablaMaquinas.propTypes = {
  maquinas: PropTypes.arrayOf(PropTypes.object).isRequired,
  onDialogInsert: PropTypes.func.isRequired,
  onDialogUpdate: PropTypes.func.isRequired,
  onDialogDelete: PropTypes.func.isRequired,
  fetch: PropTypes.func.isRequired,
};
