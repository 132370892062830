import React from 'react';
import PropTypes from 'prop-types';
import ReactToPrint from 'react-to-print';
import { PrinterIcon } from 'mdi-react';
import config from '../../../../appConfig.json';
import TablaSegundas from './TablaSegundas';
import Reactdataexport from './reactdataexport';
import Alert from '../../../../shared/components/alert/Alert';
import './styles/style.css';
// import Loader from '../../../../shared/components/loader/Loader';

class Segundas extends React.Component {
  constructor(props) {
    super(props);
    this.state =
    {
      data: [],
      loading: true,
      error: false,
      mensaje: '',
    };
  }
  componentDidMount() {
    this.fetchData();
  }
  fetchData = async () => {
    const { lote } = this.props;
    this.setState({ loading: true, error: false });
    try {
      const request = await fetch(
        `${config.ApiUrl}/segundas/porlote/${lote}`,
        {
          headers: {
            'Content-Type': 'application/json;charset=utf-8',
            Authorization: localStorage.getItem(config.AuthTokenKey),
          },
        },
      );
      if (request.status === 200) {
        const segundas = await request.json();
        this.setState({
          loading: false,
          data: segundas,
          error: false,
          mensaje: '',
        });
      } else {
        const mensaje = await request.text();
        this.setState({
          loading: false,
          error: true,
          data: [],
          mensaje,
        });
      }
    } catch (err) {
      this.setState({ loading: false, error: true, mensaje: 'Error inesperado' });
    }
  }

  render() {
    const { loading, data, error } = this.state;
    const { lote } = this.props;
    if (loading) {
      return 'Cargando...';
    }
    if (error) {
      return this.state.mensaje;
    }
    if (data.length === 0 && loading === false) {
      return (
        <Alert
          color="info"
          className="alert--bordered"
          icon
          visible={alert}
        >
        EL LOTE: {lote} NO TIENE SEGUNDAS
        </Alert>
      );
    }
    return (
      <div className="container">
        <div className="row">
          <div className="col-lg-12" style={{ textAlign: 'right', marginBlockEnd: '1%' }}>
            <Reactdataexport sw={1} data={data} />
            <ReactToPrint
              trigger={() => <button className="size"><PrinterIcon /></button>}
              content={() => this.componentRef}
            />
          </div>
          <div className="col-lg-12">
            <TablaSegundas ref={(el) => { this.componentRef = el; return this.componentRef; }} data={data} />
          </div>
        </div>
      </div>
    );
  }
}
export default Segundas;
Segundas.propTypes = {
  lote: PropTypes.string.isRequired,
};

