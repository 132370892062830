import React from 'react';
import { FormGroup, Label, Input, Form, Button, FormFeedback } from 'reactstrap';
import { InputLabel, TextField, Grid } from '@material-ui/core';
import PropTypes from 'prop-types';
import SelectorSimple from '../Comunes/SelectorSimple';
import SelectorCascada from '../Comunes/SelectorCascada';
import { crearEstandarOperacionTalla } from './datosEstandares';
import {
  obtenerOperaciones,
  obtenerSecciones,
  obtenerSubseccionesPorSeccion,
  obtenerGruposTallas,
  obtenerTallasPorGrupo,
  mostrarValidacion,
  esDecimalValido,
  convertirDecimal,
} from '../Comunes/datosComunes';
import { CryUsername } from '../../appConfig.json';
import Alerta from '../Comunes/Alerta';

class EstandaresOperacionTallaForm extends React.Component {
  static estadoErroresInicial() {
    return {
      hayErrores: false,
      subseccionId: [],
      operacionGeneralId: [],
      tallaId: [],
      estandar: [],
    };
  }
  constructor(props) {
    super(props);
    this.state = {
      estandar: 0,
      operacionGeneralId: '',
      seccion: '',
      subseccionId: '',
      grupoTalla: '',
      tallaId: '',
      guardando: false,
      errores: EstandaresOperacionTallaForm.estadoErroresInicial(),
    };
    this.handleChange = this.handleChange.bind(this);
    this.guardarEstandar = this.guardarEstandar.bind(this);
    this.validarFormulario = this.validarFormulario.bind(this);
    this.cancelar = this.cancelar.bind(this);
    this.mensajeError = this.mensajeError.bind(this);
    this.resetearEstadoErrores = this.resetearEstadoErrores.bind(this);
  }
  componentWillUnmount() {
    this.abortController.abort();
  }
  abortController = new AbortController();
  handleChange = async (e) => {
    await this.setState({ [e.target.name]: e.target.value });
  }
  async handleChangeSubseccion(e) {
    await this.setState({ subseccionId: e.target.value });
  }
  async validarFormulario() {
    const errores = EstandaresOperacionTallaForm.estadoErroresInicial();
    if (!this.state.subseccionId) {
      errores.hayErrores = true;
      errores.subseccionId = ['Debe especificar la subsección'];
    }
    if (!this.state.operacionGeneralId) {
      errores.hayErrores = true;
      errores.operacionGeneralId = ['Debe especificar la operación'];
    }
    if (!this.state.tallaId) {
      errores.hayErrores = true;
      errores.tallaId = ['Debe especificar la talla'];
    }
    const valorEstandar = convertirDecimal(this.state.estandar);
    if (this.state.estandar == null || this.state.estandar === '') {
      errores.hayErrores = true;
      errores.estandar = ['Debe especificar el valor del estandar'];
    } else if (isNaN(valorEstandar) || !esDecimalValido(this.state.estandar)) {
      errores.hayErrores = true;
      errores.estandar.push('El formato del estandar especificado no es válido');
    } else if (valorEstandar <= 0) {
      errores.hayErrores = true;
      errores.estandar = ['El valor del estandar especificado no es válido. Debe ser mayor que 0'];
    }
    await this.setState({ errores });
    return !errores.hayErrores;
  }
  async guardarEstandar() {
    if (await this.validarFormulario()) {
      await this.setState({ guardando: true });
      const datos = {
        operacionGeneralId: this.state.operacionGeneralId,
        estandar: convertirDecimal(this.state.estandar),
        tallaId: this.state.tallaId,
        subseccionId: this.state.subseccionId,
        usuario: localStorage.getItem(CryUsername),
      };
      const resultado = await crearEstandarOperacionTalla(datos);
      if (!resultado.error) {
        await this.setState({ guardando: false, errores: EstandaresOperacionTallaForm.estadoErroresInicial() });
        this.props.onSave();
      } else {
        const errores = { ...resultado.datos };
        errores.hayErrores = true;
        await this.setState({ guardando: false, errores });
      }
    }
  }
  async cancelar() {
    this.props.onCancel();
  }
  async resetearEstadoErrores() {
    const errores = EstandaresOperacionTallaForm.estadoErroresInicial();
    await this.setState({ errores });
  }
  mensajeError() {
    const { errores } = this.state;
    if (errores.hayErrores) {
      if ((errores.estandar && errores.estandar.length > 0) ||
        (errores.subseccionId && errores.subseccionId.length) ||
        (errores.tallaId && errores.tallaId.length) ||
        (errores.operacionGeneralId && errores.operacionGeneralId.length)) {
        return 'Hay errores en el formulario. Por favor revise y corríjalos';
      }
      return 'Se presentaron errores. Por favor intente nuevamente';
    }
    return '';
  }
  render() {
    return (
      <Form>
        <Alerta
          texto={this.mensajeError()}
          toggle={this.resetearEstadoErrores}
          isVisible={this.state.errores.hayErrores}
        />
        <div className="container text-center">
          <div className="col align-self-start">
            <SelectorCascada
              primaryLabel="Sección"
              filteredLabel="Subsección"
              primaryInputName="seccion"
              filteredInputName="subseccionId"
              primaryValue={this.state.seccion}
              filteredValue={this.state.subseccionId}
              onChange={this.handleChange}
              onFilteredChange={this.handleChange}
              getParents={obtenerSecciones}
              getChilds={obtenerSubseccionesPorSeccion}
              primaryCol={4}
              filteredCol={4}
              filteredInvalid={
                this.state.errores &&
                this.state.errores.subseccionId &&
                this.state.errores.subseccionId.length > 0
              }
              filteredInvalidMessage={mostrarValidacion(this.state.errores.subseccionId)}
              disabled={this.state.guardando}
            />
          </div>
          <div className="col align-self-start">
            <SelectorCascada
              primaryLabel="Grupo Talla"
              filteredLabel="Talla"
              primaryInputName="grupoTalla"
              filteredInputName="tallaId"
              primaryValue={this.state.grupoTalla}
              filteredValue={this.state.tallaId}
              onChange={this.handleChange}
              onFilteredChange={this.handleChange}
              getParents={obtenerGruposTallas}
              getChilds={obtenerTallasPorGrupo}
              primaryCol={4}
              filteredCol={4}
              filteredInvalid={
                this.state.errores &&
                this.state.errores.tallaId &&
                this.state.errores.tallaId.length > 0
              }
              filteredInvalidMessage={mostrarValidacion(this.state.errores.tallaId)}
              disabled={this.state.guardando}
            />
          </div>
        </div>
        <div className="container text-center">
          <div className="row">
            <div className="col-md-4 custom-margin-column">
              <FormGroup>
                <SelectorSimple
                  className="selectOperacion"
                  inputName="operacionGeneralId"
                  value={this.state.operacionGeneralId}
                  primaryLabel="Operación"
                  onChange={this.handleChange}
                  obtenerDatos={obtenerOperaciones}
                  mostrarEtiqueta
                  campoId="id"
                  campoDesc="descripcion"
                  disabled={this.state.guardando}
                  invalid={
                    this.state.errores &&
                    this.state.errores.operacionGeneralId &&
                    this.state.errores.operacionGeneralId.length > 0
                  }
                  invalidMessage={mostrarValidacion(this.state.errores.operacionGeneralId)}
                />
              </FormGroup>
            </div>
            <div className="col-7">
              <FormGroup>
                <InputLabel>Estandar</InputLabel>
                <TextField
                  required
                  id="estandar"
                  name="estandar"
                  placeholder="estandar"
                  size="small"
                  onChange={this.handleChange}
                  value={this.state.estandar}
                  disabled={this.state.guardando}
                  invalid={
                    this.state.errores &&
                    this.state.errores.estandar &&
                    this.state.errores.estandar.length > 0
                  }
                />
                <FormFeedback>{mostrarValidacion(this.state.errores.estandar)}</FormFeedback>
                <Label>MIN DZ</Label>
              </FormGroup>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-12 text-center">
            <Button
              className="btn btn-primary"
              onClick={this.guardarEstandar}
              disabled={this.state.guardando}
            >
              Guardar
            </Button>
            <Button
              className="btn btn-secondary"
              onClick={this.cancelar}
              disabled={this.state.guardando}
            >
              Cancelar
            </Button>
          </div>
        </div>
      </Form>
    );
  }
}

EstandaresOperacionTallaForm.propTypes = {
  history: PropTypes.shape({ push: PropTypes.func.isRequired }).isRequired,
  match: PropTypes.shape({ params: PropTypes.shape({ id: PropTypes.string.isRequired }) }).isRequired,
  onSave: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
};

export default EstandaresOperacionTallaForm;
