import React from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
} from '@material-ui/core';
import Alert from '../../../../shared/components/alert/Alert';

export default function EliminarMaquina(props) {
  return (
    <Dialog
      open={props.open}
      onClose={() => { props.handleClose(1); }}
      aria-labelledby="form-dialog-title"
    >
      <DialogTitle align="center" id="alert-dialog-title">
        ¿Está seguro de eliminar esta maquina?
      </DialogTitle>
      <DialogContent>
        <Alert
          color="danger"
          className="alert--bordered"
          icon
          visible={props.alert}
        >
          ESTA MAQUINA TIENE DETALLES ASOCIADOS.
        </Alert>
      </DialogContent>
      <DialogActions align="center" >
        <Button
          onClick={() => { props.handleDelete(); }}
          color="primary"
        >
            SI
        </Button>
        <Button onClick={() => { props.handleClose(1); }} color="primary">
            NO
        </Button>
      </DialogActions>
    </Dialog>
  );
}
EliminarMaquina.propTypes = {
  handleClose: PropTypes.func.isRequired,
  handleDelete: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  alert: PropTypes.bool.isRequired,
};
