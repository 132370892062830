import React from 'react';
import { Route, Switch } from 'react-router-dom';
import PropTypes from 'prop-types';

import Layout from '../Layout/index';
import MainWrapper from './MainWrapper';
import LogIn from '../LogIn/index';
import Logout from '../Logout/Logout';

import Home from '../Home/Home';

import EficienciasUnidades from '../EficienciasUnidades/EficienciasUnidades';
import EficienciasPlantas from '../EficienciasPlantas/EficienciasPlantas';
import EficienciasSecciones from '../EficienciasSecciones/EficienciasSecciones';
import EficienciasSubsecciones from '../EficienciasSubsecciones/EficienciasSubsecciones';
import EficienciasUbicaciones from '../EficienciasUbicaciones/EficienciasUbicaciones';
import Operaciones from '../Operaciones/PaginaPrincipal/Simulador';
import Context from '../Operaciones/store';

import EficienciasTejido from '../EficienciaTejido/EficienciaTejido';
import EficienciaPasillo from '../EficienciaTejidoDetalle/EficienciaPasillos';
import DetallesMaquina from '../DetallesMaquina/DetallesMaquina';

import DetallesPlanta from '../DetallesPlanta/DetallesPlanta';
import DetallesSubseccion from '../DetallesSubseccion/DetallesSubseccion';
import DetallesUbicacion from '../DetallesUbicacion/DetallesUbicacion';

import PrivateRoute from '../../shared/components/privateRoute/PrivateRoute';

import Estandares from '../Estandares/Estandares';
import EstandaresOperacionTallaForm from '../Estandares/EstandaresOperacionTallaForm';

import ListaEventos from '../CorrecionDatos/ListaEventos';

import ErrorBoundary from '../../shared/components/errorBoundary/ErrorBoundary';
import NotFound from '../../shared/components/notFound/NotFound';

import PanelAlarmas from '../PanelAlarmas';
import DetallesAlarmas from '../PanelAlarmas/DetallesAlarmas';

import Simulador from '../Simulador';

import Incentivos from '../Incentivos';

import InformeLote from '../Reportes/Lote/index';
import ProcesosEspeciales from '../Maestros/ProcesosEspeciales';
import Tallas from '../Maestros/Tallas';
import Turnos from '../Maestros/Turnos';
import Maquinas from '../Maestros/Maquinas';
import MaestroSecciones from '../Maestros/Secciones';
import ReporteBasicoSegundas from '../Reportes/ReporteBasicoSegundas/ReporteBasicoSegundas';
import ReporteBasicoTejido from '../Reportes/ReporteBasicoTejido/ReporteBasicoTejido';
import PasillosTurnos from '../PasillosTurnos';
import IndicadoresTextil from '../IndicadoresTextil';
import MetasTextil from '../MetasTextil';
import ReporteCalceteriaPoolGanttOrdenesReservas from '../Reportes/Calceteria/PoolGanttOrdenesReservas';
import CargaEstandares from '../CargaEstandares/CargaEstandares';
/**
 * Inyección de parametros de ruta de las
 * Unidades de negocio.
 * @param {match} match - Parametros de la ruta
 */


const Plantas = ({ match }) =>
  <EficienciasPlantas match={match} />;

const Secciones = ({ match }) =>
  <EficienciasSecciones match={match} />;

const Subsecciones = ({ match }) =>
  <EficienciasSubsecciones match={match} />;

const TejidoPasillos = ({ match }) =>
  <EficienciaPasillo match={match} />;

const TejidoMaquinaDetalle = ({ match }) =>
  <DetallesMaquina match={match} />;

const Ubicaciones = ({ match }) =>
  <EficienciasUbicaciones match={match} />;

const DetallesSubsecciones = ({ match }) =>
  <DetallesSubseccion match={match} />;

const DetallesUbicaciones = ({ match }) =>
  <DetallesUbicacion match={match} />;

const DetallesPlantas = ({ match }) =>
  <DetallesPlanta match={match} />;

const PasillosTurnosPredeterminado = () => <PasillosTurnos tipo="P" />;

const SeccionesTextilTurnosPredeterminado = () => <PasillosTurnos tipo="S" />;

const VisorIndicadoresTextil = ({ match }) => {
  const { planta } = match.params;
  return (<IndicadoresTextil planta={planta} />);
};

const VisorMetasTextil = () => (
  <MetasTextil />
);

const NotFoundRoute = () => (
  <RouteLayout>
    <NotFound />
  </RouteLayout>
);

const RouteLayout = ({ children }) => (
  <div>
    <Layout />
    <div className="container__wrap">
      <ErrorBoundary>{ children }</ErrorBoundary>
    </div>
  </div>
);

/**
 * Construcción de Matches para ruteo.
 */

const RutasEficienciasUnidades = () => (
  <RouteLayout>
    <Switch>
      <Route path="/unidades" component={EficienciasUnidades} />
    </Switch>
  </RouteLayout>
);

const RutasEficienciasPlantas = () => (
  <RouteLayout>
    <Switch>
      <Route path="/plantas/unidad/:codigo" component={Plantas} />
    </Switch>
  </RouteLayout>
);

const RutasEficienciasSecciones = () => (
  <RouteLayout>
    <Switch>
      <Route path="/secciones/unidad/:unidad/planta/:planta" component={Secciones} />
    </Switch>
  </RouteLayout>
);

const RutasEficienciasSubsecciones = () => (
  <RouteLayout>
    <Switch>
      <Route path="/subsecciones/unidad/:unidad/planta/:planta/seccion/:seccion" component={Subsecciones} />
    </Switch>
  </RouteLayout>
);

const RutasEficienciasUbicaciones = () => (
  <RouteLayout>
    <Switch>
      <Route
        path="/ubicaciones/unidad/:unidad/planta/:planta/seccion/:seccion/subseccion/:subseccion"
        component={Ubicaciones}
      />
    </Switch>
  </RouteLayout>
);

const RutasTejido = () => (
  <RouteLayout>
    <Switch>
      <Route
        path="/tejido"
        component={EficienciasTejido}
        exact
      />
      <Route
        path="/tejido/pasillo/:pasillo"
        component={TejidoPasillos}
        exact
      />
      <Route
        path="/tejido/pasillo/:pasillo/maquina/:maquina"
        component={TejidoMaquinaDetalle}
        exact
      />
    </Switch>
  </RouteLayout>
);

const RutasMaestros = () => (
  <RouteLayout>
    <Context.Provider>
      <Switch>
        <Route path="/maestro/operaciones" component={Operaciones} />
        <Route path="/maestro/procesosEspeciales" component={ProcesosEspeciales} />
        <Route path="/maestro/tallas" component={Tallas} />
        <Route path="/maestro/turnos" component={Turnos} />
        <Route path="/maestro/maquinas" component={Maquinas} />
        <Route path="/maestro/secciones" component={MaestroSecciones} />
        <Route exact path="/maestro/pasillos-turnos" component={PasillosTurnosPredeterminado} />
        {/* <Route exact path="/maestro/pasillos-turnos/:ano/:semana" component={PasillosTurnosSemana} />
        <Route path="/maestro/pasillo-turnos/:pasillo/:ano/:semana" component={PasillosTurnosPorMaquina} /> */}
        <Route exact Path="/maestro/secciones-textil-turnos" component={SeccionesTextilTurnosPredeterminado} />
      </Switch>
    </Context.Provider>
  </RouteLayout>
);

const RutasInformes = () => (
  <RouteLayout>
    <Context.Provider>
      <Switch>
        <Route exact path="/informes/lote" component={InformeLote} />
        <Route exact path="/informes/basico-segundas" component={ReporteBasicoSegundas} />
        <Route exact path="/informes/basico-tejido" component={ReporteBasicoTejido} />
        <Route
          exact
          path="/informes/calceteria/ordenes-programadas-reservas"
          component={ReporteCalceteriaPoolGanttOrdenesReservas}
        />
      </Switch>
    </Context.Provider>
  </RouteLayout>
);

const RutasDetalles = () => (
  <RouteLayout>
    <Switch>
      <Route path="/detalles/planta/:planta/unidad/:unidad" component={DetallesPlantas} exact />
      <Route
        path="/detalles/planta/:planta/unidad/:unidad/seccion/:seccion/subseccion/:subseccion"
        component={DetallesSubsecciones}
        exact
      />
      <Route
        path="/detalles/ubicacion/:ubicacion"
        component={DetallesUbicaciones}
        exact
      />
    </Switch>
  </RouteLayout>
);
const RutaEstandares = () => (
  <RouteLayout>
    <Switch>
      <Route exact path="/estandares" component={Estandares} />
      <Route path="/estandares/operacion-talla/nuevo" component={EstandaresOperacionTallaForm} />
      <Route path="/estandares/operacion-talla/:id/editar" component={EstandaresOperacionTallaForm} />
    </Switch>
  </RouteLayout>
);

const RutaCorreccionEventos = () => (
  <RouteLayout>
    <Switch>
      <Route path="/eventos/correccion" component={ListaEventos} />
    </Switch>
  </RouteLayout>
);

const RutaCargaEstandaresReferenciaCategoria = () => (
  <RouteLayout>
    <Switch>
      <Route path="/cargaestandares" component={CargaEstandares} />
    </Switch>
  </RouteLayout>
);

const RutasIndicadores = () => (
  <RouteLayout>
    <Switch>
      <Route path="/indicadores/textil/planta/:planta" component={VisorIndicadoresTextil} />
      <Route path="/indicadores/textil/metas" component={VisorMetasTextil} />
    </Switch>
  </RouteLayout>
);
/**
 * Rutas Base
 */

const HomeComponent = ({ match }) => <Home match={match} />;

const RutasHome = () => (
  <RouteLayout>
    <Switch>
      <Route path="/" component={HomeComponent} exact />
    </Switch>
  </RouteLayout>
);

const RutasAlarmas = () => (
  <RouteLayout>
    <Switch>
      <Route path="/alarmas/:id" component={DetallesAlarmas} exact />
      <Route path="/alarmas" component={PanelAlarmas} exact />
    </Switch>
  </RouteLayout>
);

const RutasSimulador = () => (
  <RouteLayout>
    <Switch>
      <Route path="/simulador" component={Simulador} />
    </Switch>
  </RouteLayout>
);
const RutaIncentivos = () => (
  <RouteLayout>
    <Switch>
      <Route path="/incentivos" component={Incentivos} />
    </Switch>
  </RouteLayout>
);
/**
 * Router
 */

const Router = () => (
  <MainWrapper>
    <main>
      <Switch>
        <PrivateRoute exact path="/" component={RutasHome} />
        <PrivateRoute path="/unidades" component={RutasEficienciasUnidades} />
        <PrivateRoute path="/plantas" component={RutasEficienciasPlantas} />
        <PrivateRoute path="/secciones" component={RutasEficienciasSecciones} />
        <PrivateRoute path="/subsecciones" component={RutasEficienciasSubsecciones} />
        <PrivateRoute path="/ubicaciones" component={RutasEficienciasUbicaciones} />
        <PrivateRoute path="/detalles" component={RutasDetalles} />
        <PrivateRoute path="/alarmas" component={RutasAlarmas} />
        <PrivateRoute path="/estandares" component={RutaEstandares} />
        <PrivateRoute path="/eventos" component={RutaCorreccionEventos} />
        <PrivateRoute path="/simulador" component={RutasSimulador} />
        <PrivateRoute path="/maestro" component={RutasMaestros} />
        <PrivateRoute path="/incentivos" component={RutaIncentivos} />
        <PrivateRoute Route path="/informes" component={RutasInformes} />
        <PrivateRoute path="/tejido" component={RutasTejido} />
        <PrivateRoute path="/indicadores" component={RutasIndicadores} />
        <PrivateRoute path="/cargaestandares" component={RutaCargaEstandaresReferenciaCategoria} />
        <Route path="/login" component={LogIn} />
        <PrivateRoute path="/logout" component={Logout} />
        <Route path="/" component={NotFoundRoute} />
      </Switch>
    </main>
  </MainWrapper>
);


/**
 * Prop-types
 */

HomeComponent.propTypes = {
  match: PropTypes.shape().isRequired,
};

Plantas.propTypes = {
  match: PropTypes.shape().isRequired,
};

Secciones.propTypes = {
  match: PropTypes.shape().isRequired,
};

Subsecciones.propTypes = {
  match: PropTypes.shape().isRequired,
};

Ubicaciones.propTypes = {
  match: PropTypes.shape().isRequired,
};

DetallesPlantas.propTypes = {
  match: PropTypes.shape().isRequired,
};

DetallesSubsecciones.propTypes = {
  match: PropTypes.shape().isRequired,
};

DetallesUbicaciones.propTypes = {
  match: PropTypes.shape().isRequired,
};

RouteLayout.propTypes = {
  children: PropTypes.element.isRequired,
};

TejidoPasillos.propTypes = {
  match: PropTypes.shape().isRequired,
};

TejidoMaquinaDetalle.propTypes = {
  match: PropTypes.shape().isRequired,
};

VisorIndicadoresTextil.propTypes = {
  match: PropTypes.shape.isRequired,
};

export default Router;
