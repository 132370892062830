import React from 'react';
import PropTypes from 'prop-types';
import { Pie, PieChart, ResponsiveContainer } from 'recharts';

import { ToleranciaEficiencia } from './../../../appConfig.json';
import { getEfficiencyColor, getUnefficiencyColor } from '../../funtions/ColoresEficiencias';
import { redondear } from './../../../containers/Comunes/datosComunes';

/**
 * Regula el porcentaje de eficiencia que se debe
 * mostrar en el visor.
 * @param {number} porcentaje - Porcentaje bruto de eficiencia.
 */
const checkUmbralEficiencia = (porcentaje, r) => {
  // eslint-disable-next-line no-restricted-globals
  if (isNaN(porcentaje) || porcentaje < 0) return 0;
  return redondear(porcentaje, r) < ToleranciaEficiencia ? redondear(porcentaje, r) : ToleranciaEficiencia;
};

/**
 * Establece los valores que se mostraran
 * en el indicador de eficiencia, así como
 * el color que le corresponde a cada una.
 * @param {number} porcentaje - Porcentaje bruto de eficiencia
 */
const getData = (porcentaje, r) => (
  [
    {
      value: checkUmbralEficiencia(porcentaje, r),
      fill: getEfficiencyColor(porcentaje),
    },
    {
      value: (100 - checkUmbralEficiencia(porcentaje, r)),
      fill: getUnefficiencyColor(porcentaje),
    },
  ]
);

const Eficiencia = props => (
  <div className="dashboard__weekly-stat">
    <div className={`dashboard__weekly-stat-chart-${props.modo}`} >
      <div className={`dashboard__weekly-stat-chart-${props.modo}-item`}>
        <div className={`dashboard__weekly-stat-chart-${props.modo}-pie`}>
          <ResponsiveContainer>
            <PieChart className="dashboard__weekly-stat-chart-pie-wrapper">
              <Pie
                data={getData(props.porcentaje, props.round)}
                dataKey="value"
                cx={props.cx}
                cy={props.cy}
                innerRadius={props.innerRadius}
                outerRadius={props.outerRadius}
              />
            </PieChart>
          </ResponsiveContainer>
          <p className="dashboard__weekly-stat-label">
            {checkUmbralEficiencia(props.porcentaje, props.round).toFixed(props.round)}%
          </p>
          <div className="dashboard__weekly-stat-info">
            <p>EFICIENCIA</p>
          </div>
        </div>
      </div>
    </div>
  </div>
);

Eficiencia.defaultProps = {
  cx: 100,
  cy: 95,
  innerRadius: 90,
  outerRadius: 95,
  modo: 'normal',
  round: 0,
};

Eficiencia.propTypes = {
  porcentaje: PropTypes.number.isRequired,
  cx: PropTypes.number,
  cy: PropTypes.number,
  innerRadius: PropTypes.number,
  outerRadius: PropTypes.number,
  round: PropTypes.number,
  modo: PropTypes.string,
};

export default Eficiencia;
