import React from 'react';
import PropTypes from 'prop-types';
import {
  Row,
  Col,
  Card,
  CardBody,
  ListGroup,
  ListGroupItem,
  Badge,
} from 'reactstrap';

import Eficiencia from '../../../shared/components/eficiencia/Eficiencia';

const IndicadorSubseccion = ({ subseccion }) => (
  <Card>
    <CardBody className="d-flex flex-column justify-content-around">
      <div className="row card__title d-flex">
        <Col sm={12} className="d-flex justify-content-center align-items-center">
          <h5 className="bold-text text-center">{ subseccion.nombre }</h5>
        </Col>
      </div>
      <Row>
        <Col lg={6}>
          <Eficiencia
            porcentaje={subseccion.eficiencia}
            cy={93}
            innerRadius={82}
            outerRadius={90}
          />
        </Col>
        <Col lg={6} className="d-flex flex-column justify-content-center">
          <Row>
            <Col sm={12}>
              <ListGroup flush>
                <ListGroupItem className="d-flex justify-content-between align-items-center">
                  <b>T. DE PARO: </b>
                  <h3>
                    <Badge color="dark" pill>{subseccion.tiempoParo} MINS</Badge>
                  </h3>
                </ListGroupItem>
                <ListGroupItem className="d-flex justify-content-between align-items-center">
                  <b>REAL:</b>
                  <h3>
                    <Badge color="dark" pill>{Math.round(subseccion.docenasNotificadas)} DZ</Badge>
                  </h3>
                </ListGroupItem>
                {/* <ListGroupItem className="d-flex justify-content-between align-items-center">
                  <b>ESPERADO:</b>
                  <h3>
                    <Badge color="dark" pill>{subseccion.esperado} DZ</Badge>
                  </h3>
                </ListGroupItem> */}
                {/* <ListGroupItem className="d-flex justify-content-between align-items-center">
                  <b>META</b>
                  <h3>
                    <Badge color="dark" pill>{meta} DZ</Badge>
                  </h3>
                </ListGroupItem> */}
              </ListGroup>
            </Col>
          </Row>
        </Col>
      </Row>
    </CardBody>
  </Card>
);

IndicadorSubseccion.propTypes = {
  subseccion: PropTypes.objectOf(PropTypes.any).isRequired,
  // tiemposParo: PropTypes.number.isRequired,
};

export default IndicadorSubseccion;
