import React from 'react';
import { makeStyles, Grid, Divider, Typography, Paper } from '@material-ui/core';
import Context from '../../store';
import OperacionInicial from './OperacionInicial';

const useStyles = makeStyles(theme => ({
  root1: {
    margin: '3%',
    width: '90%',
    backgroundColor: theme.palette.background.paper,
  },
  root2: {
    display: 'flex',
    '& > *': {
      width: theme.spacing(5),
      height: theme.spacing(4),
    },
  },
  section1: {
    margin: theme.spacing(3, 2),
  },
}));

function Operacion() {
  const classes = useStyles();

  return (
    <>
      <Context.Consumer>
        {
          (value) => {
            if (value.operacion === null) {
              return (
                <OperacionInicial />
              );
            }

            return (
              <Paper elevation={3}>
                <div className={classes.root1}>
                  <Grid container alignItems="center">
                    <Grid item xs style={{ marginTop: '3%' }} >
                      <Typography gutterBottom variant="h6" style={{ margintop: '5%', fontSize: '120%' }}>
                        {value.operacion.descripcion.toUpperCase()}
                      </Typography>
                    </Grid>
                    <Grid item style={{ marginTop: '6%' }} className={classes.root2}>
                      <Typography gutterBottom variant="h6" style={{ fontSize: '120%' }} >
                        {value.operacion.operacionGeneral}
                      </Typography>
                    </Grid>
                  </Grid>
                  <Paper elevation={0}>
                    <div className={classes.section1}>
                      <Grid container alignItems="center">
                        <Grid item xs>
                          <Typography gutterBottom variant="button">
                            Homologo SAP:
                          </Typography>
                        </Grid>
                        <Grid item>
                          <Typography gutterBottom variant="button" style={{ fontSize: '115%' }} >
                            {value.operacion.homologoSAP}
                          </Typography>
                        </Grid>
                      </Grid>
                      <Divider variant="middle" />
                    </div>
                    <div className={classes.section1}>
                      <Grid container alignItems="center">
                        <Grid item xs>
                          <Typography gutterBottom variant="button">
                            Estandar:
                          </Typography>
                        </Grid>
                        <Grid item>
                          <Typography gutterBottom variant="button" style={{ fontSize: '115%' }}>
                            {value.operacion.estandar}
                          </Typography>
                        </Grid>
                      </Grid>
                      <Divider variant="middle" />
                    </div>
                    <div className={classes.section1}>
                      <Grid container alignItems="center">
                        <Grid item xs>
                          <Typography gutterBottom variant="button">
                            Subsección:
                          </Typography>
                        </Grid>
                        <Grid item>
                          <Typography variant="button">
                            {value.operacion.subseccion.descripcion.toUpperCase()}
                          </Typography>
                        </Grid>
                      </Grid>
                      <Divider variant="middle" />
                    </div>
                    <div className={classes.section1}>
                      <Grid container alignItems="center">
                        <Grid item xs>
                          <Typography gutterBottom variant="button">
                            F.CREACIÓN:
                          </Typography>
                        </Grid>
                        <Grid item>
                          <Typography variant="button">
                            {value.operacion.fechaCreacion}
                          </Typography>
                        </Grid>
                      </Grid>
                      <Divider variant="middle" />
                    </div>
                    <div className={classes.section1} style={{ marginBlockEnd: '3%' }}>
                      <Grid container alignItems="center">
                        <Grid item xs>
                          <Typography gutterBottom variant="button">
                            F.EDICIÓN:
                          </Typography>
                        </Grid>
                        <Grid item>
                          <Typography variant="button">
                            {value.operacion.fechaModificacion}
                          </Typography>
                        </Grid>
                      </Grid>
                      <Divider variant="middle" />
                    </div>
                  </Paper>
                </div>
              </Paper>
              );
          }
        }
      </Context.Consumer>
    </>
  );
}

export default Operacion;
