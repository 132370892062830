import React from 'react';
import PropTypes from 'prop-types';
import { Paper, Table, TableBody, TableRow, TableCell, Typography } from '@material-ui/core';

function TablaInfoLote(props) {
  const { Lote } = props;
  return (
    <Paper >
      <Typography
        gutterBottom
        variant="button"
        style={{
          lineHeight: '287%',
          marginLeft: '2%',
          }}
      >
        TRAZABILIDAD DE LOTE
      </Typography>
      <div style={{ textAlign: 'center' }} >
        <Table
          aria-label="simple table"
          style={{
          margin: '0 auto',
          width: '80%',
          }}
        >
          <TableBody>
            <TableRow>
              <TableCell
                style={{
                  fontSize: '0.75rem',
                  fontWeight: 500,
              }}
              ><strong>Lote:</strong>
              </TableCell>
              <TableCell style={{ fontSize: 'small' }} >{Lote.codigoLote}</TableCell>
              <TableCell
                style={{ fontSize: '0.75rem', fontWeight: 500 }}
                align="right"
              >
                <strong>Talla:</strong>
              </TableCell>
              <TableCell style={{ fontSize: 'small' }} >{Lote.codigoTalla}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell style={{ fontSize: '0.75rem', fontWeight: 500 }} >
                <strong>Orden de fabricación del fert:</strong>
              </TableCell>
              <TableCell style={{ fontSize: 'small' }} >{Lote.ordenFert}</TableCell>
              <TableCell style={{ fontSize: '0.75rem', fontWeight: 500 }} align="right">
                <strong>Material fert:</strong>
              </TableCell>
              <TableCell style={{ fontSize: 'small' }} >{Lote.materialFert}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell style={{ fontSize: '0.75rem', fontWeight: 500 }} >
                <strong>Oden de fabricación del halb:</strong>
              </TableCell>
              <TableCell style={{ fontSize: 'small' }} >{Lote.ordenHalb}</TableCell>
              <TableCell style={{ fontSize: '0.75rem', fontWeight: 500 }} align="right">
                <strong>Material halb:</strong>
              </TableCell>
              <TableCell style={{ fontSize: 'small' }} >{Lote.materialHalb}</TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </div>
    </Paper>
  );
}
export default TablaInfoLote;

TablaInfoLote.propTypes = {
  Lote: PropTypes.objectOf(PropTypes.string).isRequired,
};
