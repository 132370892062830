import React from 'react';
import {
  Button,
  Checkbox,
  Grid,
  Fab,
  Badge,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  FormControlLabel,
} from '@material-ui/core';
import startOfWeek from 'date-fns/startOfWeek';
import format from 'date-fns/format';
import PropTypes from 'prop-types';
import 'react-table/react-table.css';
import getWeek from 'date-fns/getWeek';
import { obtenerMetas, actualizarMetas } from './datos';
import { separarGruposMiles, perteneceRolIngenieria } from '../Comunes/datosComunes';
import MetasDia from './MetasDia';

class MetasSecciones extends React.Component {
  static consolidar(metas) {
    const acum = {};
    for (let i = 0; i < metas.length; i += 1) {
      const x = metas[i];
      if (!acum[x.seccionId]) {
        const nuevo = {
          seccionId: x.seccionId,
          codigoSeccion: x.codigoSeccion,
          descripcionSeccion: x.descripcionSeccion,
          unidadMedida: x.unidadMedida,
          valorDiario: x.valorDiario,
          valorSemanal: x.valorDiario,
          seleccionado: false,
        };
        acum[x.seccionId] = nuevo;
      } else {
        const actual = acum[x.seccionId];
        if (actual.valorDiario < x.valorDiario) {
          actual.valorDiario = x.valorDiario;
        }
        actual.valorSemanal += x.valorDiario;
      }
    }
    const result = [];
    for (const x in acum) {
      if (Object.prototype.hasOwnProperty.call(acum, x)) {
        result.push(acum[x]);
      }
    }
    return result;
  }
  constructor(props) {
    super(props);
    this.consultarDatos = this.consultarDatos.bind(this);
    this.seccionSeleccionada = this.seccionSeleccionada.bind(this);
    this.seleccionados = this.seleccionados.bind(this);
    this.iniciarRegistroMetas = this.iniciarRegistroMetas.bind(this);
    this.registroMetasConfirmado = this.registroMetasConfirmado.bind(this);
    this.registroMetasCancelado = this.registroMetasCancelado.bind(this);
    this.calcularRegistroMetas = this.calcularRegistroMetas.bind(this);
    this.notificarError = this.notificarError.bind(this);
    this.state = {
      registrandoMetas: false,
      metas: [],
      secciones: [],
      cargando: false,
      unidadSeleccionada: null,
    };
  }
  async componentDidMount() {
    await this.consultarDatos();
  }
  async componentDidUpdate(prev) {
    const s = getWeek(this.props.fecha, { weekStartsOn: 1, firstWeekContainsDate: 4 });
    const sp = getWeek(prev.fecha, { weekStartsOn: 1, firstWeekContainsDate: 4 });
    if (s !== sp) {
      await this.consultarDatos();
    }
  }
  async seccionSeleccionada(e, i) {
    const xm = this.state.secciones;
    xm[i].seleccionado = e.target.checked;
    await this.setState({ secciones: xm });
    const n = this.seleccionados();
    if (n < 2) {
      let unidad = null;
      if (n === 0) {
        unidad = null;
      } else {
        unidad = xm[i].unidadMedida;
      }
      await this.setState({ unidadSeleccionada: unidad });
    }
  }
  async iniciarRegistroMetas() {
    await this.setState({ registrandoMetas: true });
  }
  seleccionados() {
    return (
      this.state.secciones.reduce((t, x) => {
        if (x.seleccionado) {
          return t + 1;
        }
        return t;
      }, 0));
  }
  async registroMetasConfirmado(v) {
    const s = this.state.secciones;
    const dias = v.destinos.map((x, i) => ({ dia: i, seleccionado: x })).filter(x => x.seleccionado);
    for (let i = 0; i < s.length; i += 1) {
      if (s[i].seleccionado) {
        s[i].valorDiario = v.meta;
        s[i].valorSemanal = v.meta * dias.length;
      }
    }
    const actualizadas = await this.calcularRegistroMetas(dias, v.actualizarMetasPlanta);
    const respuesta = await actualizarMetas(this.props.planta, actualizadas);
    if (!respuesta.error) {
      const secciones = MetasSecciones.consolidar(this.state.metas);
      await this.setState({
        secciones,
        registrandoMetas: false,
        unidadSeleccionada: null,
      });
    } else {
      await this.setState({ registrandoMetas: false });
      this.notificarError('Ocurrió un error actualizando las metas.');
    }
  }
  async registroMetasCancelado() {
    await this.setState({ registrandoMetas: false });
  }
  async calcularRegistroMetas(dias, actualizarMetasPlanta) {
    const nuevasMetas = [];
    const { metas } = this.state;
    for (let i = 0; i < this.state.secciones.length; i += 1) {
      const seccion = this.state.secciones[i];
      if (seccion.seleccionado) {
        const inicioSemana = startOfWeek(this.props.fecha, { weekStartsOn: 1 /* lunes */ });
        for (let j = 0; j < dias.length; j += 1) {
          const diaMeta = new Date(inicioSemana.getFullYear(), inicioSemana.getMonth(), inicioSemana.getDate());
          diaMeta.setDate(inicioSemana.getDate() + dias[j].dia);
          const meta = this.state.metas.find(x =>
            x.seccionId === seccion.seccionId &&
            new Date(Date.parse(x.fecha)).getTime() === diaMeta.getTime());
          if (meta) {
            meta.valorDiario = seccion.valorDiario;
            nuevasMetas.push({
              ...meta,
              esTeorica: false,
              aplicaParaPlanta: actualizarMetasPlanta,
            });
          }
        }
      }
    }
    await this.setState({ metas });
    return nuevasMetas;
  }
  async consultarDatos() {
    await this.setState({ cargando: true });
    const textoFecha = format(this.props.fecha, 'yyyy-MM-dd');
    const respuesta = await obtenerMetas(this.props.planta, textoFecha);
    if (!respuesta.error) {
      const secciones = MetasSecciones.consolidar(respuesta.datos);
      await this.setState({ cargando: false, metas: respuesta.datos, secciones });
    } else {
      await this.setState({ cargando: false });
      this.notificarError('Falló la consulta');
    }
  }
  notificarError(error) {
    if (this.props.enError) {
      this.props.enError();
    }
  }
  render() {
    if (this.state.cargando) {
      // TODO: Cambiar
      return (<span>Cargando...</span>);
    }
    return (
      <Grid container direction="column" xs={12} alignItems="center" >
        <Grid item>
          <Badge
            color="primary"
            badgeContent={this.seleccionados()}
            max={99}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'left',
            }}
          >
            <Button
              variant="outlined"
              onClick={this.iniciarRegistroMetas}
              disabled={!perteneceRolIngenieria() || this.seleccionados() < 1}
            >
              Metas
            </Button>
          </Badge>
        </Grid>
        <Grid item>
          <Table size="medium">
            <TableHead>
              <TableCell>Sección</TableCell>
              <TableCell align="right">Meta día</TableCell>
              <TableCell align="right">Meta semana</TableCell>
            </TableHead>
            <TableBody>
              {
                // this.state.metas.filter(x => x && x.unidadMedida && x.unidadMedida.toUpperCase() == 'UND')
                this.state.secciones.sort((x, y) => {
                  if (x.unidadMedida == null && y.unidadMedida == null) {
                    return 0;
                  } else if (x.unidadMedida == null) {
                    return -1;
                  } else if (y.unidadMedida == null) {
                    return 1;
                  }
                  return x.unidadMedida.localeCompare(y.unidadMedida);
                })
                .map((x, i) => (
                  <TableRow>
                    <TableCell size="small">
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={x.seleccionado}
                            color="primary"
                            onChange={async e => this.seccionSeleccionada(e, i)}
                            disabled={this.state.unidadSeleccionada &&
                            this.state.unidadSeleccionada.toUpperCase() !== x.unidadMedida.toUpperCase()}
                          />
                        }
                        label={`${x.descripcionSeccion} (${x.unidadMedida})`}
                      />
                    </TableCell>
                    <TableCell align="right">
                      {separarGruposMiles(Math.round(x.valorDiario))}
                    </TableCell>
                    <TableCell align="right">
                      {separarGruposMiles(Math.round(x.valorSemanal))}
                    </TableCell>
                  </TableRow>
                ))
              }
            </TableBody>
          </Table>
        </Grid>
        <Grid item>
          <MetasDia
            activo={this.state.registrandoMetas}
            confirmado={this.registroMetasConfirmado}
            cancelado={this.registroMetasCancelado}
            enError={this.props.enError}
          />
        </Grid>
      </Grid>
    );
  }
}

MetasSecciones.propTypes = {
  fecha: PropTypes.instanceOf(Date).isRequired,
  planta: PropTypes.number.isRequired,
  enError: PropTypes.func,
};

MetasSecciones.defaultProps = {
  enError: null,
};

export default MetasSecciones;
