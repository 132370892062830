import React, { useEffect, useState, useCallback } from 'react';
import { Paper, Divider } from '@material-ui/core';
import { createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles';
import config from '../../../appConfig.json';
import Alerta from '../../Comunes/Alerta';
import Loader from '../../../shared/components/loader/Loader';

import Filtro from '../Filtro';
import TablaMaquinas from './Components/TablaMaquinas';
import EliminarMaquina from './Components/EliminarMaquina';
import CrearMaquina from './Components/CrearMaquina';
import ModificarMaquina from './Components/ModificarMaquina';

const theme = createMuiTheme({
  palette: {
    primary: { main: '#263238' },
    secondary: { main: '#1a237e' },
  },
});

const Maquina = () => {
  const [loading, setLoading] = React.useState(true);
  const [openD, setOpenD] = React.useState(false);
  const [alertD, setAlertD] = React.useState(false);
  const [openI, setOpenI] = React.useState(false);
  const [error, setError] = useState(false);
  const [alertI, setAlertI] = React.useState(false);
  const [openU, setOpenU] = React.useState(false);
  const [maquinas, setMaquinas] = useState([]);
  const [maquinasOrigen, setMaquinasOrigen] = useState([]);
  const [maquina, setMaquina] = useState({});
  const [pasillos, setPasillos] = useState([]);
  const [estado, setEstado] = useState(false);
  const [select, setSelect] = React.useState('pasillos');
  const [recurso, setRecurso] = useState([]);
  const resetearError = () => {
    setError(false);
  };
  async function fetchData() {
    try {
      const [pasillosResponse, maquinasResponse, recursosResponse] = await Promise.all([
        fetch(`${config.ApiUrl}/maquinas/pasillos`, {
          headers: {
            'Content-Type': 'application/json;charset=utf-8',
            Authorization: localStorage.getItem(config.AuthTokenKey),
          },
        }).then(res => res.json()),
        fetch(`${config.ApiUrl}/maquinas/lista`, {
          headers: {
            'Content-Type': 'application/json;charset=utf-8',
            Authorization: localStorage.getItem(config.AuthTokenKey),
          },
        }).then(res => res.json()),
        fetch(`${config.ApiUrl}/maquinas/recursos`, {
          headers: {
            'Content-Type': 'application/json;charset=utf-8',
            Authorization: localStorage.getItem(config.AuthTokenKey),
          },
        }).then(res => res.json()),
      ]);
      setPasillos(pasillosResponse);
      setMaquinas(maquinasResponse);
      setMaquinasOrigen(maquinasResponse);
      setRecurso(recursosResponse);
      setLoading(false);
    } catch (e) {
      setError(true);
      console.error('Error al obtener datos:', e);
    }
  }
  useEffect(() => {
    fetchData();
  }, []);
  const filterMaquinas = inputValue =>
    maquinas.filter(m => m.codigo.toLowerCase().indexOf(inputValue.toLowerCase()) > -1);

  const handleChange = (e) => {
    const inputValue = e.target.value;
    const filteredMaquinas = filterMaquinas(inputValue);

    if (inputValue !== '') {
      setMaquinas(filteredMaquinas);
    } else {
      setMaquinas(maquinasOrigen);
    }
  };
  const handleOpen = useCallback((sw) => {
    const stateMapping = {
      1: setOpenD,
      2: setOpenI,
      3: setOpenU,
    };
    const setStateFunction = stateMapping[sw];
    if (setStateFunction) {
      setStateFunction(true);
    }
  });
  const handleClose = useCallback((sw) => {
    if (sw === 1) { setOpenD(false); setAlertD(false); }
    if (sw === 2) { setOpenI(false); setAlertI(false); }
    if (sw === 3) { setOpenU(false); }
    setSelect('pasillos');
    setEstado(false);
  });

  const handleAlertOpen = (sw) => {
    if (sw === 1) setAlertD(true);
    if (sw === 2) setAlertI(true);
  };

  const handleDialogDelete = (M) => {
    setMaquina(M);
    handleOpen(1);
  };

  const handleDelete = async () => {
    const request = await fetch(`${config.ApiUrl}/maquinas/eliminar/${maquina.id}`, {
      mode: 'cors',
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json;charset=utf-8',
        Authorization: localStorage.getItem(config.AuthTokenKey),
      },
    });
    if (request.status === 400) {
      handleAlertOpen(1);
    } else {
      console.log('handleDelete');
      fetchData();
      handleClose(1);
    }
  };

  const handleDialogInsert = () => {
    handleOpen(2);
  };

  const handleChangeInputs = (e) => {
    const array = { ...maquina };
    array[e.target.id] = e.target.value;
    setMaquina(array);
  };

  const handleSelect = (e, sw) => {
    const array = { ...maquina };
    if (sw === 1) {
      array[e.target.name] = e.target.checked;
      setEstado(e.target.checked);
    }
    if (sw === 2) {
      array[e.target.name] = e.target.value;
      setSelect(e.target.value);
    }
    setMaquina(array);
  };
  const handleInsert = async () => {
    const array = {
      ...maquina,
      fechaCreacion: new Date(),
      fechaModificacion: new Date(),
      usuario: localStorage.getItem(config.CryUsername),
    };
    console.log(array);
    const request = await fetch(`${config.ApiUrl}/maquinas/nueva`, {
      mode: 'cors',
      method: 'PUT',
      body: JSON.stringify(array),
      headers: {
        'Content-Type': 'application/json',
        Authorization: localStorage.getItem(config.AuthTokenKey),
        Accept: 'application/json',
      },
    });
    const response = await request.json();
    if (response.sw === 1) {
      handleAlertOpen(2);
    } else {
      console.log('handleInsert');
      fetchData();
      handleClose(2);
    }
  };

  const handleDialogUpdate = (M) => {
    const array = { ...M };
    pasillos.forEach((p) => {
      if (p.codigo === array.pasillo) { array.pasillo = p.id; }
    });

    setSelect(array.pasillo);
    setEstado(array.estado);
    setMaquina(array);
    handleOpen(3);
  };

  const handleUpdate = async () => {
    const request = await fetch(`${config.ApiUrl}/maquinas/editar/${maquina.id}`, {
      mode: 'cors',
      method: 'POST',
      body: JSON.stringify(maquina),
      headers: {
        'Content-Type': 'application/json',
        Authorization: localStorage.getItem(config.AuthTokenKey),
        Accept: 'application/json',
      },
    });
    const response = await request.json();
    console.log('handleUpdate');
    fetchData();
    handleClose(3);
  };

  // if (loading) return (<div> <Loader loading={loading} /></div>);
  return (
    <>
      <MuiThemeProvider theme={theme}>
        <Alerta
          texto="Ocurrió un error de ejecución. Intente nuevamente."
          toggle={resetearError}
          isVisible={error}
        />
        <Paper style={{ marginRight: '1%', marginLeft: '1%', marginTop: '2%' }}>
          <div style={{ width: '30%' }}>
            <Filtro onChange={handleChange} label="Maquina" helperText="Buscar Maquina" margen="5%" />
          </div>
          <Divider />
          <TablaMaquinas
            maquinas={maquinas}
            onDialogInsert={handleDialogInsert}
            onDialogUpdate={handleDialogUpdate}
            onDialogDelete={handleDialogDelete}
            handleAlertOpen={handleAlertOpen}
            fetch={fetchData}
          />
          <EliminarMaquina
            open={openD}
            alert={alertD}
            handleClose={handleClose}
            handleDelete={handleDelete}
          />
          <CrearMaquina
            open={openI}
            alert={alertI}
            handleClose={handleClose}
            handleInsert={handleInsert}
            pasillos={pasillos}
            select={select}
            estado={estado}
            recurso={recurso}
            handleChangeInputs={handleChangeInputs}
            handleSelect={handleSelect}
          />
          <ModificarMaquina
            open={openU}
            handleClose={handleClose}
            handleUpdate={handleUpdate}
            pasillos={pasillos}
            select={select}
            estado={estado}
            handleChangeInputs={handleChangeInputs}
            handleSelect={handleSelect}
            maquina={maquina}
          />
        </Paper>
      </MuiThemeProvider>
    </>
  );
};
export default Maquina;
