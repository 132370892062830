import React from 'react';
import { Grid, Paper, TextField, Checkbox, FormControlLabel, ButtonGroup } from '@material-ui/core';

import Context from '../../store';
import CrearProceso from './CrearProceso';
import ModificarProceso from './ModificarProceso';
import { perteneceRolIngenieria } from '../../../Comunes/datosComunes';

export default function Form(props) {
  return (
    <>
      <Context.Consumer>
        {
          value => (
            <Paper style={{ margin: '2%', padding: '3%' }}>
              <Grid container>
                <Grid item xs={12} style={{ marginBlockEnd: '2%' }}>
                  <h3>Información proceso especial </h3>
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    label="Código"
                    value={value.proceso === null ? '' : value.proceso.codigo}
                    style={{ marginBlockEnd: '2%' }}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    label="Sección"
                    value={value.proceso === null ? '' : value.proceso.seccion}
                    style={{ marginBlockEnd: '2%' }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    label="Descripción"
                    value={value.proceso === null ? '' : value.proceso.descripcion}
                    style={{ marginBlockEnd: '2%' }}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    label="Planta"
                    value={value.proceso === null ? '' : value.proceso.planta}
                    style={{ marginBlockEnd: '2%' }}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    label="Unidad de negocio"
                    value={value.proceso === null ? '' : value.proceso.unidadNegocio}
                  />
                </Grid>
                <Grid item xs={12}>
                  <FormControlLabel
                    style={{ marginTop: '2%' }}
                    control={<Checkbox
                      color="primary"
                      checked={value.proceso === null ? false : value.proceso.switchParo}
                      name="checkedA"
                    />}
                    label="Corresponde a un paro"
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    label="Fecha de Creación"
                    value={value.proceso === null ? '' : value.proceso.fechaCreacion.slice(0, 10)}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    label="Fecha de Modificación"
                    value={value.proceso === null ? '' : value.proceso.fechaModificacion.slice(0, 10)}
                  />
                </Grid>
                <Grid item xs={12}>
                  <ButtonGroup
                    style={{ display: 'flex', alignContent: 'center', marginTop: '5%' }}
                    variant="contained"
                  >
                    {
                      perteneceRolIngenieria() &&
                      <React.Fragment>
                        <CrearProceso unidades={props.unidades} fetch={props.fetch} />
                        <ModificarProceso unidades={props.unidades} fetch={props.fetch} />
                      </React.Fragment>
                    }
                  </ButtonGroup>
                </Grid>
              </Grid>
            </Paper>
          )
        }
      </Context.Consumer>
    </>
  );
}
