import React from 'react';
import {
  makeStyles,
  Button,
  TextField,
  Dialog,
  DialogContent,
  DialogTitle,
  Typography,
  DialogActions,
  Tooltip,
} from '@material-ui/core';

import { CreateIcon } from 'mdi-react';

import Alert from '../../../../shared/components/alert/Alert';

import config from '../../../../appConfig.json';
import Context from '../../store';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 200,
  },
  textField2: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 200,
    '& label.Mui-focused': {
      color: 'red',
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: 'red',
    },
    '& .MuiOutlinedInput-root': {
      '&:hover fieldset': {
        borderColor: 'blue',
      },
      '&.Mui-focused fieldset': {
        borderColor: 'red',
      },
    },
  },
  colorSub: {
    '& label.Mui-focused': {
      color: 'red',
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: 'red',
    },
    '& .MuiOutlinedInput-root': {
      '&.Mui-focused fieldset': {
        borderColor: 'red',
      },
    },
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
}));

export default function ModificarOperacion(props) {
  const [open, setOpen] = React.useState(false);
  const [alert, setAlert] = React.useState(false);
  const [id, setId] = React.useState(0);
  const [OperacionGeneral, setOperacionGeneral] = React.useState({
    OperacionGeneral: '',
    Descripcion: '',
    HomologoSAP: '',
    SubseccionId: 0,
    TipoOperacion: true,
    Estandar: 0,
    Usuario: '',
  });
  const classes = useStyles();

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = (op) => {
    const array = OperacionGeneral;
    array.OperacionGeneral = op.operacionGeneral;
    array.Descripcion = op.descripcion;
    array.HomologoSAP = op.homologoSAP;
    array.SubseccionId = op.subseccion.id;
    array.TipoOperacion = op.tipoOperacion;
    array.Estandar = op.estandar;
    array.Usuario = op.usuario;
    setOperacionGeneral(array);
    setId(op.id);
    setOpen(true);
  };

  const handleAlert = () => {
    setAlert(true);
    setOpen(true);
  };

  const handleChange = (e) => {
    const array = OperacionGeneral;
    array[e.target.id] = e.target.value;
    setOperacionGeneral(array);
  };

  const handleChangeEstandar = (e) => {
    const array = OperacionGeneral;
    array[e.target.id] = Number.parseFloat(e.target.value);
    setOperacionGeneral(array);
  };


  const handleSave = async () => {
    const request = await fetch(`${config.ApiUrl}/operaciones/general/${id}/editar`, {
      mode: 'cors',
      method: 'POST',
      body: JSON.stringify(OperacionGeneral),
      headers: {
        'Content-Type': 'application/json',
        Authorization: localStorage.getItem(config.AuthTokenKey),
        Accept: 'application/json',
      },
    });
    return request.json();
  };
  return (
    <>
      <Context.Consumer>
        {
          (value) => {
            const handleModifyO = () => {
              const ope = { ...value.operacion };
              ope.descripcion = OperacionGeneral.Descripcion;
              ope.homologoSAP = OperacionGeneral.HomologoSAP;
              ope.estandar = OperacionGeneral.Estandar;
              value.activateOp(ope);
              const { handleOperacionesReload } = props;
              handleOperacionesReload();
            };
              if (value.operacion === null) {
                return (
                  <>
                    <Tooltip title="Modifica una operación general" >
                      <Button
                        disable
                        variant="contained"
                        style={{ backgroundColor: 'rgb(18, 24, 88)', width: '50%' }}
                        onClick={() => {
                          handleAlert();
                        }}
                      ><CreateIcon style={{ color: 'white' }} />
                        <h5 style={{ color: 'white' }}><strong>MODIFICAR</strong></h5>
                      </Button>
                    </Tooltip>
                    <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title" >
                      <DialogContent>
                        <Alert
                          color="info"
                          className="alert--bordered"
                          icon
                          visible={alert}
                        >
                        SELECCIONA PRIMERO UNA OPERACIÓN GENERAL.
                        </Alert>
                      </DialogContent>
                      <DialogActions>
                        <Button onClick={handleClose} color="primary">
                            OK
                        </Button>
                      </DialogActions>
                    </Dialog>
                  </>
                );
              }
          return (
            <>
              <Tooltip title="Modifica esta operación general" >
                <Button
                  variant="contained"
                  style={{ backgroundColor: 'rgb(18, 24, 88)', width: '50%' }}
                  onClick={() => {
                  handleOpen(value.operacion);
                }}
                ><CreateIcon style={{ color: 'white' }} />
                  <h5 style={{ color: 'white' }}><strong>MODIFICAR</strong></h5>
                </Button>
              </Tooltip>
              <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title" >
                <form onSubmit={(e) => {
                    e.preventDefault();
                    if (handleSave()) {
                      handleModifyO();
                    }
                    handleClose();
                  }}
                >
                  <DialogTitle id="form-dialog-title">
                    <Typography gutterBottom variant="h4" align="center">
                      MODIFICAR OPERACIÓN
                    </Typography>
                  </DialogTitle>
                  <DialogContent>
                    <div className={classes.root}>
                      <div>
                        <TextField
                          readonly
                          label="Código"
                          id="OperacionGeneral"
                          className={classes.textField2}
                          variant="outlined"
                          color="secondary"
                          margin="dense"
                          type="text"
                          value={OperacionGeneral.OperacionGeneral}
                        />
                        <TextField
                          required
                          id="Descripcion"
                          label="Modifica nombre o descripción de la operación general"
                          style={{ margin: 8 }}
                          fullWidth
                          type="text"
                          defaultValue={OperacionGeneral.Descripcion.toUpperCase()}
                          margin="normal"
                          InputLabelProps={{
                         shrink: true,
                      }}
                          onChange={handleChange}
                        />
                        <TextField
                          label="HomologoSAP"
                          id="HomologoSAP"
                          className={classes.textField}
                          margin="dense"
                          type="number"
                          defaultValue={OperacionGeneral.HomologoSAP}
                          onChange={handleChange}
                        />
                        <TextField
                          required
                          label="Estandar"
                          id="Estandar"
                          className={classes.textField}
                          variant="outlined"
                          color="secondary"
                          margin="dense"
                          type="text"
                          defaultValue={OperacionGeneral.Estandar}
                          style={{ width: 100 }}
                          onChange={handleChangeEstandar}
                        />
                      </div>
                      <div>
                        <TextField
                          readonly
                          id="Subseccion"
                          label="Subseccion"
                          style={{ margin: 8 }}
                          fullWidth
                          type="text"
                          value={value.operacion.subseccion.descripcion.toUpperCase()}
                          margin="normal"
                          className={classes.colorSub}
                          InputLabelProps={{
                         shrink: true,
                      }}
                        />
                      </div>
                    </div>
                  </DialogContent>
                  <DialogActions>
                    <Button onClick={handleClose} color="primary">
                       Cancel
                    </Button>
                    <Button
                      type="submit"
                      color="primary"
                    >
                      Guardar
                    </Button>
                  </DialogActions>
                </form>
              </Dialog>
            </>
          );
        }
        }
      </Context.Consumer>

    </>
  );
}
