import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  select: {
    border: 'none',
    background: 'transparent',
    minWidth: '100%',
    maxWidth: '100%',
    '&:focus': {
      background: 'transparent',
    },
  },
}));

function SelectorSimpleMaterial(props) {
  const classes = useStyles();

  const [loading, setLoading] = useState(true);
  const [datos, setDatos] = useState([]);
  const [error, setError] = useState(false);

  const abortController = new AbortController();
  let montado = true;

  useEffect(() => {
    const obtenerDatos = async () => {
      try {
        const r = await props.obtenerDatos(abortController.signal);
        if (montado) {
          setDatos(r);
          setLoading(false);
        }
      } catch (e) {
        if (montado) {
          setError(true);
          setLoading(false);
        }
      }
    };

    obtenerDatos();

    return () => {
      montado = false;
      abortController.abort();
    };
  }, [props.obtenerDatos]);

  let marcador = () => {
    if (loading) {
      marcador = `${props.etiqueta} - Cargando...`;
    } else if (error) {
      marcador = `${props.etiqueta} - Error`;
    } else {
      marcador = props.etiqueta;
    }
    return marcador;
  };

  const opciones = () => {
    if (datos && datos.length) {
      let elementos;
      if (props.elementoVacio) {
        const vacio = [
          { [props.campoId]: -1, [props.campoDesc]: props.etiquetaValorInicial },
        ];
        elementos = vacio.concat(datos);
      } else {
        elementos = datos;
      }
      return elementos.map(x => (
        <MenuItem key={x[props.campoId]} value={x[props.campoId]}>
          {x[props.campoDesc]}
        </MenuItem>
      ));
    }
    return null;
  };

  return (
    <FormControl fullWidth>
      <InputLabel id={`label-${props.inputName}`}>{marcador()}</InputLabel>
      <Select
        value={props.value}
        name={props.inputName}
        onChange={props.onChange}
        disabled={props.disabled}
        invalid={props.invalid}
        label={props.etiqueta}
        helperText={props.invalidMessage}
      >
        {opciones()}
      </Select>
    </FormControl>
  );
}

SelectorSimpleMaterial.propTypes = {
  inputName: PropTypes.string.isRequired,
  campoId: PropTypes.string.isRequired,
  campoDesc: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  etiqueta: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  obtenerDatos: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  invalid: PropTypes.bool,
  invalidMessage: PropTypes.string,
  elementoVacio: PropTypes.bool,
  etiquetaValorInicial: PropTypes.string,
};

SelectorSimpleMaterial.defaultProps = {
  value: null,
  etiqueta: null,
  disabled: false,
  invalid: false,
  invalidMessage: null,
  elementoVacio: true,
  etiquetaValorInicial: '(Ninguno)',
};

export default SelectorSimpleMaterial;
