import React, { PureComponent } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import Topbar from './topbar/Topbar';
import Sidebar from './sidebar/Sidebar';

import { changeThemeToDark, changeThemeToLight } from '../../redux/actions/themeActions';
import { changeMobileSidebarVisibility, changeSidebarVisibility } from '../../redux/actions/sidebarActions';
import { SidebarProps, NotificationProps, UbicacionProps } from '../../shared/prop-types/ReducerProps';
import { SetRoleEventListeners, RemoveRoleEventListeners } from '../../shared/funtions/Eventos/RoleEventListeners';

import { CryUsername } from './../../appConfig.json';

const ROLE = 'INGENIERIA';

class Layout extends PureComponent {
  static propTypes = {
    dispatch: PropTypes.func.isRequired,
    sidebar: SidebarProps.isRequired,
    connection: NotificationProps.isRequired,
    ubicacion: UbicacionProps.isRequired,
  };

  componentDidMount = async () => {
    const { connection: { hubConnection } } = this.props;
    // this.startHubConnection(hubConnection);
  }

  componentWillUnmount = () => {
    const { connection: { hubConnection } } = this.props;
    RemoveRoleEventListeners(ROLE, hubConnection);
  }

  // getSessionUsername = () => localStorage.getItem(CryUsername)

  // TODO: Restablecer cuando se implementen notificaciones.
  // startHubConnection = async (hubConnection) => {
  //   console.log('INICIALIZANDO NUEVA CONEXIÓN ');
  //   SetRoleEventListeners(ROLE, hubConnection);

  //   await hubConnection
  //     .start()
  //     .then(() => {
  //       console.log('Hub Connection Started');
  //     })
  //     .catch((err) => {
  //       console.log(`Error while establishing connection :( - ${err}`);
  //       setTimeout(() => {
  //         RemoveRoleEventListeners(ROLE, hubConnection);
  //         this.startHubConnection(hubConnection);
  //       }, 5000);
  //     });
  // }

  changeSidebarVisibility = () => {
    this.props.dispatch(changeSidebarVisibility());
  };

  changeMobileSidebarVisibility = () => {
    this.props.dispatch(changeMobileSidebarVisibility());
  };

  changeToDark = () => {
    this.props.dispatch(changeThemeToDark());
  };

  changeToLight = () => {
    this.props.dispatch(changeThemeToLight());
  };

  render() {
    const layoutClass = classNames({
      layout: true,
      'layout--collapse': this.props.sidebar.collapse,
    });

    return (
      <div className={layoutClass}>
        <Topbar
          changeMobileSidebarVisibility={this.changeMobileSidebarVisibility}
          changeSidebarVisibility={this.changeSidebarVisibility}
          username={localStorage.getItem(CryUsername)}
        />
        <Sidebar
          sidebar={this.props.sidebar}
          changeToDark={this.changeToDark}
          changeToLight={this.changeToLight}
          changeMobileSidebarVisibility={this.changeMobileSidebarVisibility}
          username={localStorage.getItem(CryUsername)}
          Ubicacion={this.props.ubicacion.Ubicacion}
        />
      </div>
    );
  }
}

export default withRouter(connect(state => ({
  sidebar: state.sidebar,
  connection: state.notification,
  ubicacion: state.ubicacion,
}))(Layout));
