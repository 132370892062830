import React, { useState, useEffect } from 'react';
import {
  IconButton,
  Button,
  Dialog,
  DialogContent,
  DialogActions,
  makeStyles,
  Grid,
} from '@material-ui/core';
import { InformationIcon } from 'mdi-react';
import Alerta from '../../Comunes/Alerta';
import config from '../../../appConfig.json';
import Alert from '../../../shared/components/alert/Alert';

import Context from '../store';
import Form from './Components/Form';
import TablaGrupos from './Components/TablaGrupos';

import Filtro from '../Filtro';

const useStyles = makeStyles(() => ({
  tableSize: {
    '& .MuiDialog-paperWidthSm': {
      maxWidth: '100%',
    },
  },
}));

export default function GrupoTalla(props) {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const [alert, setAlert] = React.useState(false);
  const [grupos, setGrupos] = React.useState([]);
  const [gruposOriginal, setGruposOriginal] = React.useState([]);
  const [grupo, setGrupo] = React.useState(null);
  const [codigo, setCodigo] = React.useState('');
  const [descripcion, setDescripcion] = React.useState('');
  const [unidad, setUnidad] = React.useState('');
  const [error, setError] = useState(false);

  const resetearError = () => {
    setError(false);
  };
  function fetchData() {
    try {
      window.fetch(
        `${config.ApiUrl}/tallas/grupos-talla`,
        {
          headers: {
            'Content-Type': 'application/json;charset=utf-8',
            Authorization: localStorage.getItem(config.AuthTokenKey),
          },
        },
      ).then(res => res.json())
        .then((response) => {
          setGrupos(response);
          setGruposOriginal(response);
        });
    } catch {
      setError(false);
      console.error('Error al obtener datos:', error);
    }
  }

  useEffect(fetchData, []);

  const handleClose = () => {
    handleClean();
    setAlert(false);
    setGrupos(gruposOriginal);
    setOpen(false);
  };
  const handleOpen = () => {
    setOpen(true);
    setGrupos(grupos);
    setGruposOriginal(gruposOriginal);
  };

  const handleChangeFilter = (e) => {
    const result = grupos.filter(t =>
      (t.descripcion.toLowerCase().indexOf(e.target.value.toLowerCase()) !== -1 && t));
    if (result.length !== 0 && e.target.value !== '') { setGrupos(result); }
    if (result.length === 0 && e.target.value === '') { setGrupos(gruposOriginal); }
    if (result.length !== 0 && e.target.value === '') { setGrupos(gruposOriginal); }
    if (result.length === 0 && e.target.value !== '') { setGrupos(gruposOriginal); }
  };

  const handleActivate = (g) => {
    setGrupo(g);
    setCodigo(g.codigo);
    setDescripcion(g.descripcion);
    setUnidad(g.unidadNegocio.id);
  };

  const handleChangeInputs = (e) => {
    if (e.target.id === 'codigo') setCodigo(e.target.value);
    if (e.target.id === 'descripcion') setDescripcion(e.target.value);
    if (e.target.name === 'unidadNegocio') setUnidad(e.target.value);
  };

  const handleClean = () => {
    setGrupo(null);
    setCodigo('');
    setDescripcion('');
    setUnidad('');
  };

  const handleInsert = async () => {
    const request = await fetch(`${config.ApiUrl}/tallas/nuevo/grupo`, {
      mode: 'cors',
      method: 'PUT',
      body: JSON.stringify({
        id: 0,
        codigo,
        descripcion,
        unidadNegocio: unidad,
        fechaCreacion: new Date(),
        fechaModificacion: new Date(),
      }),
      headers: {
        'Content-Type': 'application/json',
        Authorization: localStorage.getItem(config.AuthTokenKey),
        Accept: 'application/json',
      },
    });
    const response = await request.json();
    if (response.sw === 1) {
      setAlert(true);
    } else {
      fetchData();
      props.fetch();
    }
  };

  const handleUpdate = async () => {
    grupo.descripcion = descripcion;
    grupo.unidadNegocio = unidad;
    const request = await fetch(`${config.ApiUrl}/Tallas/editar/grupo/${grupo.id}`, {
      mode: 'cors',
      method: 'POST',
      body: JSON.stringify(grupo),
      headers: {
        'Content-Type': 'application/json',
        Authorization: localStorage.getItem(config.AuthTokenKey),
        Accept: 'application/json',
      },
    });
    const response = await request.json();
    fetchData();
    props.fetch();
  };

  const handleDelete = async (grupoTalla) => {
    const request = await fetch(
      `${config.ApiUrl}/Tallas/eliminar/grupo/${grupoTalla.id}`,
      {
        mode: 'cors',
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json;charset=utf-8',
          Authorization: localStorage.getItem(config.AuthTokenKey),
        },
      },
    );
    if (request.status === 400) {
      setAlert(true);
    } else {
      fetchData();
      props.fetch();
    }
  };

  const handleSave = async () => {
    if (!((codigo === '' || descripcion === '' || unidad === '') && grupo === null)) {
      if (grupo !== null) {
        handleUpdate();
      } else {
        handleInsert();
      }
    }
  };
  return (
    <>
      <Context.Provider>
        <Grid item>
          <Alerta
            texto="Ocurrió un error de ejecución. Intente nuevamente."
            toggle={resetearError}
            isVisible={error}
          />
        </Grid>
        <IconButton onClick={handleOpen} size="small">
          <InformationIcon style={{ color: '#214589' }} color="primary" />
        </IconButton>
        <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="form-dialog-title"
          className={classes.tableSize}
        >
          <DialogContent style={{ paddingTop: '3%' }} >
            <Alert
              color="danger"
              className="alert--bordered"
              icon
              visible={alert}
            >
              ESTE GRUPO YA ESTA REGISTRADO O TIENE DETALLES ASOCIADOS.
            </Alert>
            <Grid container>
              <Grid item xs={6}>
                <div style={{ width: '50%' }}>
                  <Filtro
                    onChange={handleChangeFilter}
                    label="Grupo Talla"
                    helperText="Buscar grupos tallas"
                    margen="5%"
                  />
                </div>
              </Grid>
              <Grid item xs={6}>
                <Form
                  codigo={codigo}
                  descripcion={descripcion}
                  unidad={unidad}
                  onChange={handleChangeInputs}
                  save={handleSave}
                  unidades={props.unidades}
                />
              </Grid>
              <Grid item xs={12}>
                <TablaGrupos grupos={grupos} activate={handleActivate} clean={handleClean} delete={handleDelete} />
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="primary">
              Cerrar
            </Button>
          </DialogActions>
        </Dialog>
      </Context.Provider>
    </>
  );
}
