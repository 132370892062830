import React from 'react';
import {
  Tooltip,
  Button,
  Dialog,
  DialogContent,
  DialogActions,
  DialogTitle,
  FormControlLabel,
  Checkbox,
  Grid,
  Typography,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from '@material-ui/core';
import { DoneIcon } from 'mdi-react';
import Alerta from '../../../Comunes/Alerta';
import config from '../../../../appConfig.json';
import Alert from '../../../../shared/components/alert/Alert';

export default function CrearProceso(props) {
  const [open, setOpen] = React.useState(false);
  const [select, setSelect] = React.useState('Unidad');
  const [checked, setChecked] = React.useState(false);
  const [alert, setAlert] = React.useState(false);
  const [proceso, setProceso] = React.useState({});

  const handleSave = async () => {
    const array = {
      ...proceso,
      seccion: 4,
      planta: 1110,
      switchAfectaEficiencia: false,
      fechaCreacion: new Date(),
      fechaModificacion: new Date(),
      usuario: localStorage.getItem(config.CryUsername),
    };
    const request = await fetch(`${config.ApiUrl}/procesosEspeciales/nuevo`, {
      mode: 'cors',
      method: 'PUT',
      body: JSON.stringify(array),
      headers: {
        'Content-Type': 'application/json',
        Authorization: localStorage.getItem(config.AuthTokenKey),
        Accept: 'application/json',
      },
    });
    const response = await request.json();
    if (response.sw === 1) {
      setAlert(true);
    } else {
      props.fetch();
      handleClose();
    }
  };

  const handleChange = (e) => {
    const array = { ...proceso };
    array[e.target.id] = e.target.value;
    setProceso(array);
  };

  const handleChecked = (e) => {
    const array = { ...proceso };
    array.SwitchParo = e.target.checked;
    setProceso(array);
    setChecked(e.target.checked);
  };

  const handleSelect = (e) => {
    const array = { ...proceso };
    array[e.target.name] = e.target.value;
    setProceso(array);
    setSelect(e.target.value);
  };

  const handleClose = () => {
    setAlert(false);
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  return (
    <>
      <Tooltip title="Crea un proceso especial" >
        <Button
          style={{ backgroundColor: '#263238', width: '50%' }}
          onClick={handleOpen}
        >
          <DoneIcon style={{ color: 'white' }} />
          <h5 style={{ color: 'white' }}><strong>CREAR</strong></h5>
        </Button>
      </Tooltip>
      <Dialog
        open={open}
        onClose={handleClose}
        scroll="paper"
        aria-labelledby="form-dialog-title"
        maxWidth="sm"
      >
        <form onSubmit={(e) => {
          e.preventDefault();
          handleSave();
        }}
        >
          <DialogTitle>
            <Typography gutterBottom variant="h6" align="center">
              ADICIONAR PROCESO ESPECIAL
            </Typography>
          </DialogTitle>

          <DialogContent dividers>
            <Alert
              color="danger"
              className="alert--bordered"
              icon
              visible={alert}
            >
              ESTE PROCESO ESPECIAL YA ESTA REGISTRADO.
            </Alert>
            <Grid container>
              <Grid item xs={6}>
                <TextField
                  required
                  label="Código"
                  id="Codigo"
                  onChange={handleChange}
                  type="number"
                />
              </Grid>
              <Grid item xs={6}>
                <FormControl style={{ minWidth: '242px' }} required>
                  <InputLabel id="unidadNegocio">Unidad de Negocio</InputLabel>
                  <Select
                    labelId="unidadNegocio"
                    name="unidadNegocio"
                    onChange={handleSelect}
                    value={select}
                  >
                    {props.unidades.map(uni => (<MenuItem key={uni.id} value={uni.id}>{uni.descripcion}</MenuItem>))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={9} >
                <TextField
                  required
                  label="Descripción"
                  id="Descripcion"
                  fullWidth
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={3}>
                <FormControlLabel
                  control={<Checkbox
                    color="primary"
                    checked={checked}
                    onChange={handleChecked}
                    name="checkedA"
                  />}
                  label="Paro"
                />
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="default">
              Cerrar
            </Button>
            <Button color="primary" type="submit" >
              Añadir
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </>
  );
}
