import React from 'react';
import { MenuItem, Select, InputLabel, FormHelperText, FormControl } from '@material-ui/core';
import PropTypes from 'prop-types';
import { obtenerSubsecciones } from '../../Comunes/datosComunes';

class SelectorSubsecciones extends React.Component {
  constructor(props) {
    super(props);
    this.cargarSubsecciones = this.cargarSubsecciones.bind(this);
    this.valorCambio = this.valorCambio.bind(this);
    this.state = {
      subsecciones: [],
      cargando: true,
    };
  }
  async componentDidMount() {
    await this.cargarSubsecciones();
  }
  async cargarSubsecciones() {
    const subsecciones = await obtenerSubsecciones();
    this.setState({ cargando: false, subsecciones });
  }
  valorCambio(e) {
    if (this.props.onChange) {
      this.props.onChange(e);
    }
  }
  render() {
    return (
      <FormControl fullWidth size="medium">
        <InputLabel id="subseccionlabel">Subsección</InputLabel>
        <Select
          onChange={this.valorCambio}
          value={this.props.value}
        >
          {
            this.state.cargando ?
              <MenuItem key={-1} value="">Cargando...</MenuItem> :
              this.state.subsecciones
              .map(x => (
                <MenuItem key={x.id === '' ? -1 : x.id} value={x.id.toString()} disabled={x.id === ''}>
                  {x.descripcion}
                </MenuItem>))
          }
        </Select>
        {this.props.error && <FormHelperText error={this.props.error}>{this.props.mensaje}</FormHelperText>}
      </FormControl>
    );
  }
}

SelectorSubsecciones.propTypes = {
  onChange: PropTypes.func.isRequired,
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  error: PropTypes.bool.isRequired,
  mensaje: PropTypes.string,
};
SelectorSubsecciones.defaultProps = {
  value: null,
  mensaje: '',
};

export default SelectorSubsecciones;
