import React, { PureComponent } from 'react';
import { Col, Container, Row } from 'reactstrap';
import UnidadesNegocio from './components/UnidadesNegocio';

import config from './../../appConfig.json';
import Loader from '../../shared/components/loader/Loader';

class EficienciasUnidades extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      unidades: [],
      loading: true,
    };
  }

  componentDidMount = () => {
    this.getUnidadesNegocioAsync();
  };

  /**
   * Obtiene todas las unidades de Negocio
   * y las establece en el estado.
   */
  getUnidadesNegocioAsync = async () => {
    const { ApiUrl, AuthTokenKey } = config;

    const response = await fetch(`${ApiUrl}/UnidadesNegocio`, {
      mode: 'cors',
      method: 'GET',
      headers: {
        'Content-Type': 'application/json;charset=utf-8',
        Authorization: localStorage.getItem(AuthTokenKey),
      },
    });

    const unidades = await response.json();

    this.setState({
      unidades,
      loading: false,
    });
  }

  render() {
    const { unidades, loading } = this.state;
    return (
      <Container className="dashboard">
        <Row>
          <Col md={12} className="text-center">
            <h3 className="page-title">Unidades de Negocio</h3>
          </Col>
        </Row>
        { !loading ?
          <UnidadesNegocio unidades={unidades} tamanio={unidades.length} /> : <Loader loading={loading} />
        }
      </Container>
    );
  }
}

export default EficienciasUnidades;
