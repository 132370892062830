import React from 'react';
import {
  SearchIcon,
  FileExcelBoxIcon, DeleteIcon, AddCircleIcon, EditIcon,
} from 'mdi-react';
import { createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles';
import {
  Container,
  Grid,
  TextField,
  Button,
  IconButton,
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Paper,
  TablePagination,
  withStyles,
} from '@material-ui/core';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import { es } from 'date-fns/locale';
import CorreccionEvento from './CorreccionEvento';
import CreacionEvento from './CreacionEvento';
import BorradoEvento from './BorradoEvento';
import {
  obtenerEficienciaEventosPaginado,
  obtenerEficienciaEventosExcel,
} from './datosCorreccion';
import { obtenerTurnos, obtenerUnidadesNegocio, truncarFecha, perteneceRolIngenieria } from '../Comunes/datosComunes';
import ListaSeccionesMultiple from '../Comunes/ListaSeccionesMultiple';
import SelectorSimpleMaterial from '../Comunes/SelectorSimpleMaterial';
import ModalForm from '../Comunes/ModalForm';
import Alerta from '../Comunes/Alerta';
import StyledTableCell from '../Comunes/StyledTableCell';

const theme = createMuiTheme({
  palette: {
    primary: { main: '#263238' },
    secondary: { main: '#1a237e' },
  },
});

class ListaEventos extends React.Component {
  static formatDate(d) {
    const xd = new Date(d.getTime());
    xd.setHours(0, 0, 0, 0);
    return xd.toISOString();
  }
  static quinceDias = 86400 * 15;
  constructor(props) {
    super(props);
    this.obtenerDatos = this.obtenerDatos.bind(this);
    this.iniciarCorreccion = this.iniciarCorreccion.bind(this);
    this.iniciarCreacion = this.iniciarCreacion.bind(this);
    this.iniciarBorrado = this.iniciarBorrado.bind(this);
    this.descargar = this.descargar.bind(this);
    this.correccionFinalizada = this.correccionFinalizada.bind(this);
    this.formularioCorreccionCerrado = this.formularioCorreccionCerrado.bind(this);
    this.filterControlChanged = this.filterControlChanged.bind(this);
    this.fechaInicioCambiada = this.fechaInicioCambiada.bind(this);
    this.fechaFinCambiada = this.fechaFinCambiada.bind(this);
    this.seccionSeleccionada = this.seccionSeleccionada.bind(this);
    this.resetearError = this.resetearError.bind(this);
    this.filtrar = this.filtrar.bind(this);
    const d = new Date();
    this.state =
    {
      filtro: {
        unidadNegocio: '',
        fechaInicio: ListaEventos.formatDate(d),
        fechaFin: ListaEventos.formatDate(d),
        turno: '',
        operario: '',
        codigoLote: '',
        codigoMaterial: '',
        secciones: [],
      },
      unidadNegocio: '',
      fechaInicio: ListaEventos.formatDate(d),
      fechaFin: ListaEventos.formatDate(d),
      turno: '',
      operario: '',
      codigoLote: '',
      codigoMaterial: '',
      secciones: [],
      corrigiendoEvento: false,
      eventoId: '',
      errorForm: false,
      mensajeErrorForm: null,
      loading: true,
      errorConsulta: false,
      mensajeErrorConsulta: null,
      creandoEvento: false,
      borrandoEvento: false,
      descargandoArchivo: false,
      consulta: {
        cantidadRegistros: 0,
        lista: [],
      },
      page: 0,
      rowsPerPage: 10,
    };
  }
  componentDidMount() {
    this.obtenerDatos(this.state.filtro);
  }
  handleChangePage = (event, newPage) => {
    this.setState({ page: newPage });
  };
  handleChangeRowsPerPage = (event) => {
    this.setState({
      rowsPerPage: parseInt(event.target.value, 10),
      page: 0,
    });
  };
  async filterControlChanged(e) {
    await this.setState({ [e.target.name]: e.target.value });
  }
  async fechaInicioCambiada(v) {
    await this.setState({ fechaInicio: truncarFecha(v) });
  }
  async fechaFinCambiada(v) {
    await this.setState({ fechaFin: truncarFecha(v) });
  }
  async seccionSeleccionada(valores) {
    await this.setState({ secciones: valores });
  }
  async filtrar() {
    const filtro = {
      unidadNegocio: this.state.unidadNegocio !== -1 ? this.state.unidadNegocio : null,
      fechaInicio: this.state.fechaInicio,
      fechaFin: this.state.fechaFin,
      turno: this.state.turno !== -1 ? this.state.turno : null,
      operario: this.state.operario,
      codigoLote: this.state.codigoLote,
      codigoMaterial: this.state.codigoMaterial,
      secciones: this.state.secciones,
    };
    if (await this.validarFormulario(filtro)) {
      try {
        await this.obtenerDatos(filtro);
        this.setState({
          loading: false,
          errorConsulta: false,
        });
      } catch (error) {
        console.error('Error al obtener eventos filtrados:', error);
        this.setState({ loading: false, errorConsulta: true });
      }
    }
  }
  async resetearError() {
    await this.setState({
      errorForm: false,
      errorConsulta: false,
      mensajeErrorForm: null,
      mensajeErrorConsulta: null,
    });
  }
  async validarFormulario() {
    const fechaInicio = Date.parse(this.state.fechaInicio);
    const fechaFin = Date.parse(this.state.fechaFin);
    let mensaje = null;
    let ok =
      (fechaInicio && fechaFin) || this.state.turno || this.state.operario ||
      this.state.codigoLote || this.state.codigoMaterial ||
      (this.state.secciones && this.state.secciones.length > 0);
    if (!ok) {
      mensaje = 'Debe especificar el menos un criterio de búsqueda. ' +
        'La fecha de inicio y la fecha de finalización constituyen un solo criterio.';
    }
    if (ok && fechaFin < fechaInicio) {
      ok = false;
      mensaje = 'La fecha inicial debe ser menor que la final';
    }
    if (ok && ((fechaFin - fechaInicio) / 1000) > ListaEventos.quinceDias) {
      ok = false;
      mensaje = 'La diferencia entre la fecha de inicio y fin de la consulta no puede ser ' +
        'superior a quince días';
    }
    await this.setState({ errorForm: !ok, mensajeErrorForm: mensaje });
    return ok;
  }
  async descargar() {
    let error = false;
    await this.setState({ descargandoArchivo: true });
    try {
      await obtenerEficienciaEventosExcel(this.state.filtro);
    } catch (e) {
      error = true;
    }
    await this.setState({
      descargandoArchivo: false,
      errorConsulta: error,
      mensajeErrorConsulta: error && 'Ocurrió un error al ejecutar la consulta. ' +
        'Por favor intente de nuevo',
    });
  }
  async iniciarCorreccion(eventoId) {
    console.log(eventoId);
    await this.setState({ corrigiendoEvento: true, eventoId });
  }
  async iniciarCreacion() {
    await this.setState({ creandoEvento: true });
  }
  async iniciarBorrado(eventoId) {
    await this.setState({ borrandoEvento: true, eventoId });
  }
  async obtenerDatos(filtro) {
    this.setState({ loading: true, errorConsulta: false });
    try {
      const resultado = await obtenerEficienciaEventosPaginado(filtro);
      if (!resultado.error) {
        const consulta = {
          cantidadRegistros: resultado.datos.cantidadRegistros,
          lista: resultado.datos.eventos,
        };
        this.setState({
          consulta,
          loading: false,
          errorConsulta: false,
          mensajeErrorConsulta: null,
        });
      } else {
        this.setState({
          consulta: { cantidadRegistros: 0, lista: [] },
          loading: false,
          errorConsulta: true,
          mensajeErrorConsulta: 'Ocurrió un error al hacer la consulta. Intente nuevamente por favor.',
        });
      }
    } catch (error) {
      this.setState({
        consulta: { cantidadRegistros: 0, lista: [] },
        loading: false,
        errorConsulta: true,
        mensajeErrorConsulta: 'Ocurrió un error al hacer la consulta. Intente nuevamente por favor.',
      });
    }
  }
  async correccionFinalizada() {
    await this.setState({ borrandoEvento: false });
    this.filtrar();
  }
  async formularioCorreccionCerrado() {
    await this.setState({ corrigiendoEvento: false, creandoEvento: false, borrandoEvento: false });
  }
  renderFormularioCorreccion() {
    if (this.state.corrigiendoEvento) {
      return (
        <CorreccionEvento
          eventoId={this.state.eventoId}
          onSave={this.correccionFinalizada}
          onClose={this.formularioCorreccionCerrado}
          visible={this.state.corrigiendoEvento}
        />
      );
    }
    return null;
  }
  renderFormularioCreacion() {
    if (this.state.creandoEvento) {
      return (
        <CreacionEvento
          eventoId={this.state.eventoId}
          onSave={this.correccionFinalizada}
          onClose={this.formularioCorreccionCerrado}
        />
      );
    }
    return null;
  }
  renderFormularioBorrado() {
    if (this.state.borrandoEvento) {
      return (
        <BorradoEvento
          eventoId={this.state.eventoId}
          onDelete={this.correccionFinalizada}
          onClose={this.formularioCorreccionCerrado}
        />
      );
    }
    return null;
  }
  render() {
    const { page, rowsPerPage } = this.state;
    const emptyRows = rowsPerPage - Math.min(rowsPerPage, this.state.consulta.lista.length - page * rowsPerPage);
    return (
      <React.Fragment>
        <MuiThemeProvider theme={theme}>
          <Container style={{ marginTop: '16px' }}>
            <Grid container xs={12} spacing={1} direction="column">
              <Grid container xs={12} justify="center" spacing={1}>
                <Grid item xs={12}>
                  <Alerta
                    texto={this.state.mensajeErrorForm || this.state.mensajeErrorConsulta}
                    toggle={this.resetearError}
                    isVisible={this.state.errorForm || this.state.errorConsulta}
                  />
                </Grid>
              </Grid>
              <Grid container direction="row" xs={12} spacing={1} justify="center">
                <Grid item xs={1}>
                  <SelectorSimpleMaterial
                    inputName="unidadNegocio"
                    value={this.state.unidadNegocio}
                    onChange={this.filterControlChanged}
                    obtenerDatos={obtenerUnidadesNegocio}
                    campoId="id"
                    campoDesc="descripcion"
                    etiqueta="U. Neg."
                  />
                </Grid>
                <Grid container item xs={2} direction="column" spacing={1}>
                  <MuiPickersUtilsProvider utils={DateFnsUtils} locale={es}>
                    <Grid item>
                      <KeyboardDatePicker
                        name="fechaInicio"
                        size="small"
                        inputVariant="standard"
                        autoOk
                        format="dd/MM/yyyy"
                        label="Fecha inicial"
                        value={this.state.fechaInicio}
                        onChange={this.fechaInicioCambiada}
                        clearable
                        clearLabel="Limpiar"
                        cancelLabel="Cancelar"
                        okLabel="Aceptar"
                      />
                    </Grid>
                    <Grid item>
                      <KeyboardDatePicker
                        locale="es-ES"
                        name="fechaFin"
                        size="small"
                        inputVariant="standard"
                        autoOk
                        format="dd/MM/yyyy"
                        label="Fecha final"
                        value={this.state.fechaFin}
                        onChange={this.fechaFinCambiada}
                        clearable
                        clearLabel="Limpiar"
                        cancelLabel="Cancelar"
                        okLabel="Aceptar"
                      />
                    </Grid>
                  </MuiPickersUtilsProvider>
                </Grid>
                <Grid item xs={1}>
                  <SelectorSimpleMaterial
                    inputName="turno"
                    value={this.state.turno}
                    onChange={this.filterControlChanged}
                    obtenerDatos={obtenerTurnos}
                    campoId="id"
                    campoDesc="descripcionTurno"
                    etiqueta="Turno"
                  />
                </Grid>
                <Grid item xs={2}>
                  <TextField
                    size="small"
                    name="operario"
                    label="Operario"
                    onChange={this.filterControlChanged}
                  />
                </Grid>
                <Grid item xs={2}>
                  <TextField
                    size="small"
                    name="codigoLote"
                    label="Lote"
                    onChange={this.filterControlChanged}
                  />
                </Grid>
                <Grid item xs={2}>
                  <ListaSeccionesMultiple
                    onChange={this.seccionSeleccionada}
                    secciones={this.state.secciones}
                  />
                </Grid>
                <Grid item xs={2}>
                  <TextField
                    size="small"
                    name="codigoMaterial"
                    onChange={this.filterControlChanged}
                    label="Material"
                  />
                </Grid>
              </Grid>
              <Grid container direction="column" xs={12} spacing={1}>
                <Grid container item direction="row" xs={12} spacing={1} justify="center">
                  <Grid item>
                    <Button
                      className="btn btn-primary"
                      variant="outlined"
                      disabled={this.state.loading}
                      onClick={this.filtrar}
                    >
                      <SearchIcon />
                      Consultar
                    </Button>
                  </Grid>
                  <Grid item>
                    <Button
                      className="btn btn-secondary"
                      variant="outlined"
                      disabled={this.state.loading || this.state.descargandoArchivo}
                      onClick={this.descargar}
                    >
                      <FileExcelBoxIcon />
                      Exportar
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <TableContainer component={Paper}>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <StyledTableCell>Fecha</StyledTableCell>
                        <StyledTableCell>Lote/Paro</StyledTableCell>
                        <StyledTableCell>Material</StyledTableCell>
                        <StyledTableCell>Talla</StyledTableCell>
                        <StyledTableCell>Estandar</StyledTableCell>
                        <StyledTableCell>Turno</StyledTableCell>
                        <StyledTableCell>Sección</StyledTableCell>
                        <StyledTableCell>Cédula operario</StyledTableCell>
                        <StyledTableCell>Código/operario</StyledTableCell>
                        <StyledTableCell>Nombre</StyledTableCell>
                        <StyledTableCell>Operación</StyledTableCell>
                        <StyledTableCell>Unidades</StyledTableCell>
                        <StyledTableCell>Usuario</StyledTableCell>
                        <StyledTableCell>Modificación</StyledTableCell>
                        {perteneceRolIngenieria() && (
                          <StyledTableCell>
                            Nueva
                            <IconButton size="small" onClick={this.iniciarCreacion}>
                              <AddCircleIcon color="primary" />
                            </IconButton>
                          </StyledTableCell>
                        )}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {(rowsPerPage > 0
                        ? this.state.consulta.lista.slice(
                          page * rowsPerPage,
                          page * rowsPerPage + rowsPerPage,
                        )
                        : this.state.consulta.lista
                      ).map(row => (
                        <TableRow key={row.eficienciaEventoId}>
                          <TableCell>{new Intl.DateTimeFormat('es-CO').format(new Date(row.fechaInicio))}</TableCell>
                          <TableCell>{row.codigoLote !== '0' ?
                            row.codigoLote : row.descripcionProcesoEspecial}
                          </TableCell>
                          <TableCell>{row.codigoMaterial}</TableCell>
                          <TableCell>{row.descripcionTalla}</TableCell>
                          <TableCell>{row.estandar}</TableCell>
                          <TableCell>{row.descripcionTurno}</TableCell>
                          <TableCell>{row.descripcionSeccion}</TableCell>
                          <TableCell>{row.cedulaOperario}</TableCell>
                          <TableCell>{row.codigoOperario}</TableCell>
                          <TableCell>{row.nombreOperario}</TableCell>
                          <TableCell>{row.descripcionOperacion}</TableCell>
                          <TableCell>{row.cantidadLote}</TableCell>
                          <TableCell>{row.usuario}</TableCell>
                          <TableCell>
                            {new Intl.DateTimeFormat('es-CO').format(new Date(row.fechaModificacion))}
                          </TableCell>
                          <TableCell>
                            <IconButton
                              type="button"
                              variant="outlined"
                              size="small"
                              color="default"
                              disabled={!perteneceRolIngenieria()}
                              onClick={() =>
                                this.iniciarCorreccion(row.eficienciaEventoId)
                              }
                            >
                              <EditIcon />
                            </IconButton>
                            <IconButton
                              size="small"
                              disabled={!perteneceRolIngenieria()}
                              onClick={() => this.iniciarBorrado(row.eficienciaEventoId)}
                            >
                              <DeleteIcon style={{ color: '#e10000' }} />
                            </IconButton>
                          </TableCell>
                        </TableRow>
                      ))}
                      {emptyRows > 0 && (
                        <TableRow style={{ height: 33 * emptyRows }}>
                          <TableCell colSpan={6} />
                        </TableRow>
                      )}
                    </TableBody>
                  </Table>
                  <TablePagination
                    rowsPerPageOptions={[10, 20, 30, 40, 50]}
                    component="div"
                    count={this.state.consulta.cantidadRegistros}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onChangePage={this.handleChangePage}
                    onChangeRowsPerPage={this.handleChangeRowsPerPage}
                    labelRowsPerPage=""
                  />
                </TableContainer>
              </Grid>
            </Grid>
          </Container>
        </MuiThemeProvider>
        <ModalForm
          open={this.state.corrigiendoEvento}
          boton={
            <Button onClick={this.formularioCorreccionCerrado}>x</Button>
          }
          // title={`Corección de evento ${this.state.eventoId}`}
          title={
            <h4 className="text-center font-weight-bold custom-title">
              Corrección de evento : {this.state.eventoId}
            </h4>
          }
          content={this.renderFormularioCorreccion()}
        />
        <ModalForm
          open={this.state.creandoEvento}
          title={<h4 className="text-center font-weight-bold custom-title">Creación de evento</h4>}
          // boton={
          //   <Button outline className="btn btn-xs py-1 my-1" onClick={this.formularioCorreccionCerrado}>x</Button>
          // }
          content={this.renderFormularioCreacion()}
        />
        <ModalForm
          open={this.state.borrandoEvento}
          title={`Borrado del evento ${this.state.eventoId}`}
          size=""
          toggle={this.formularioCorreccionCerrado}
          content={this.renderFormularioBorrado()}
        />
      </React.Fragment>
    );
  }
}

export default ListaEventos;
