import React from 'react';
import PropTypes from 'prop-types';

import Loader from '../../shared/components/loader/Loader';
import config from '../../appConfig.json';
import Pasillo from './components/Pasillo';

class EficienciaPasillo extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      datosPasillo: [],
      maquinas: [],
      maquinasFilter: [],
      eficiencia: [],
      loading: true,
      horario: 'Turno',
    };
  }
  componentDidMount() {
    this.fetchMaquinas(this.state.horario);
  }

  handleChangeTime = async (horario) => {
    await this.setState({ horario: horario.target.value });
    this.fetchMaquinas(this.state.horario);
  }
  handleChangeFilterMaquina = (filtro) => {
    this.setState({ maquinasFilter: this.state.maquinas });
    let valor = this.state.maquinas
      .filter(op => (
        op.codigoMaquina.indexOf(filtro.target.value) >= 0
      ));
    if (valor.length === 0) { valor = this.state.maquinas; }
    this.setState({ maquinasFilter: valor });
  }
  handleChangeFilterParqueadero = (filtro) => {
    this.setState({ maquinasFilter: this.state.maquinas });
    let valor = this.state.maquinas
      .filter(op => (
        op.parqueadero.indexOf(filtro.target.value) >= 0
      ));
    if (valor.length === 0) { valor = this.state.maquinas; }
    this.setState({ maquinasFilter: valor });
  }

  fetchMaquinas = async (turno) => {
    this.setState({ loading: true });
    try {
      let respuesta = null;
      if (turno === 'Dia') {
        respuesta = await fetch(
          `${config.ApiUrl}/tejido/eficiencia/pasillo/${this.props.match.params.pasillo}/dia`,
          {
            headers: {
              'Content-Type': 'application/json;charset=utf-8',
              Authorization: localStorage.getItem(config.AuthTokenKey),
            },
          },
        );
      } else {
        respuesta = await fetch(
          `${config.ApiUrl}/tejido/eficiencia/pasillo/${this.props.match.params.pasillo}/turno`,
          {
            headers: {
              'Content-Type': 'application/json;charset=utf-8',
              Authorization: localStorage.getItem(config.AuthTokenKey),
            },
          },
        );
      }
      const pasillo = await respuesta.json();
      this.setState({
        loading: false,
        datosPasillo: pasillo,
        maquinas: pasillo.listaMaquinas,
        maquinasFilter: pasillo.listaMaquinas,
        eficiencia: pasillo.eficiencia,
      });
    } catch (error) {
      console.log('falló');
    }
  }

  render() {
    const {
      maquinasFilter,
      loading,
      eficiencia,
      datosPasillo,
    } = this.state;
    return (
      !loading ?
        <Pasillo
          horario={this.state.horario}
          pasillo={datosPasillo}
          maquinasFiltradas={maquinasFilter}
          eficiencia={eficiencia}
          urlBase={`/tejido/pasillo/${this.props.match.params.pasillo}`}
          handleChangeTime={this.handleChangeTime}
          handleChangeFilterMaquina={this.handleChangeFilterMaquina}
          handleChangeFilterParqueadero={this.handleChangeFilterParqueadero}
        />
        : <Loader loading={loading} />
    );
  }
}

EficienciaPasillo.propTypes = {
  match: PropTypes.objectOf(PropTypes.shape()).isRequired,
};

export default EficienciaPasillo;
