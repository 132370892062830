import { HubConnectionBuilder, LogLevel, HttpTransportType } from '@aspnet/signalr';
import config from './../../appConfig.json';
import {
  SUBSCRIBRE_TO_HUB,
  UNSUBSCRIBRE_TO_HUB,
} from '../actions/NotificationsActions';

const initialState = {
  hubConnection: null,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case SUBSCRIBRE_TO_HUB:
      return {
        hubConnection: new HubConnectionBuilder()
          .withUrl(`${config.ApiUrl}/EficienciaEventoHub`, {
            skipNegotiation: true,
            transport: HttpTransportType.WebSockets,
          })
          .configureLogging(LogLevel.Information)
          .build(),
      };
    case UNSUBSCRIBRE_TO_HUB:
      return {
        hubConnection: state.hubConnection.stop(),
      };
    default:
      return state;
  }
}
