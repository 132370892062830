import React from 'react';
import { FormGroup, Label, Input, Form, Button, FormFeedback } from 'reactstrap';
import { TextField, InputLabel } from '@material-ui/core';
import PropTypes from 'prop-types';
import SelectorSimple from '../Comunes/SelectorSimple';
import {
  obtenerGruposTallas,
  obtenerTallasPorGrupo,
  obtenerTurnos,
  obtenerOperaciones,
  obtenerProcesosEspeciales,
  mostrarValidacion,
} from '../Comunes/datosComunes';
import SelectorCascada from '../Comunes/SelectorCascada';
import { obtenerEficienciaEvento, registrarCorreccion } from './datosCorreccion';
import { CryUsername } from '../../appConfig.json';
import Alerta from '../Comunes/Alerta';

class CorreccionEvento extends React.Component {
  static iniciarEstadoErrores() {
    return {
      cedulaOperario: [],
      codigoLote: [],
      codigoMaterial: [],
      operacionId: [],
      turno: [],
      tallaId: [],
      cantidadLote: [],
    };
  }
  constructor(props) {
    super(props);
    this.obtenerEvento = this.obtenerEvento.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.guardarEvento = this.guardarEvento.bind(this);
    this.cerrarFormulario = this.cerrarFormulario.bind(this);
    this.actualizarEstado = this.actualizarEstado.bind(this);
    this.validarFormulario = this.validarFormulario.bind(this);
    this.resetearEstadoErrores = this.resetearEstadoErrores.bind(this);
    this.state = {
      cedulaOperario: '',
      codigoLote: '',
      codigoMaterial: '',
      operacionId: '',
      turno: '',
      tallaId: '',
      grupoTallaId: '',
      procesoEspecial: '',
      cantidadLote: 0,
      cantidadEsperada: 0,
      cantidadProducida: 0,
      minutosEsprerados: 0,
      minutosProducidos: 0,
      estandar: 0,
      loading: false,
      saving: false,
      eventoModificado: false,
      errores: CorreccionEvento.iniciarEstadoErrores(),
      errorForm: false,
      loadingError: false,
      loadingMensajeError: null,
    };
  }
  componentDidMount() {
    this.obtenerEvento(this.props.eventoId);
  }
  componentWillUnmount() {
    this.abortController.abort();
  }
  abortController = new AbortController();
  handleChange(e) {
    this.setState({ [e.target.name]: e.target.value });
  }
  async obtenerEvento(eventoId) {
    await this.setState({ loading: true, loadingError: false });
    const resultado = await obtenerEficienciaEvento(eventoId, this.abortController.signal);
    if (!resultado.error) {
      await this.actualizarEstado(resultado.datos, false);
    } else {
      await this.setState({ loadingError: true, loading: false, loadingMensajeError: resultado.datos });
    }
  }
  async actualizarEstado(evento, eventoModificado) {
    await this.setState({
      cedulaOperario: evento.cedulaOperario,
      codigoLote: evento.codigoLote,
      codigoMaterial: evento.codigoMaterial,
      operacionId: evento.operacionId,
      turno: evento.turno,
      tallaId: evento.tallaId.toString(),
      grupoTallaId: evento.grupoTallaId.toString(),
      procesoEspecial: evento.procesoEspecial || '',
      cantidadLote: evento.cantidadLote,
      cantidadEsperada: evento.cantidadEsperada,
      cantidadProducida: evento.cantidadProducida,
      minutosEsprerados: evento.minutosEsprerados,
      minutosProducidos: evento.minutosProducidos,
      estandar: evento.estandar,
      loading: false,
      saving: false,
      eventoModificado,
      errores: CorreccionEvento.iniciarEstadoErrores(),
    });
  }
  async guardarEvento() {
    if (await this.validarFormulario()) {
      const evento = {
        eficienciaEventoId: this.props.eventoId,
        cedulaOperario: this.state.cedulaOperario,
        codigoLote: this.state.codigoLote,
        codigoMaterial: this.state.codigoMaterial,
        operacionId: this.state.operacionId,
        turno: this.state.turno,
        tallaId: this.state.tallaId,
        grupoTallaId: this.state.grupoTallaId,
        procesoEspecial: this.state.procesoEspecial,
        cantidadLote: this.state.cantidadLote,
        cantidadEsperada: this.state.cantidadEsperada,
        cantidadProducida: this.state.cantidadProducida,
        minutosEsprerados: this.state.minutosEsprerados,
        minutosProducidos: this.state.minutosProducidos,
        usuario: localStorage.getItem(CryUsername),
      };
      await this.setState({ saving: true });
      const resultado = await registrarCorreccion(evento, this.abortController.signal);
      if (!resultado.error) {
        if (this.props.onSave) {
          this.props.onSave(resultado.datos);
        }
        await this.actualizarEstado(resultado.datos, true);
      } else {
        const xerrores = CorreccionEvento.iniciarEstadoErrores();
        const errores = { ...xerrores, ...resultado.datos };
        await this.setState({ saving: false, errorForm: true, errores });
      }
    }
  }
  async validarFormulario() {
    const errores = CorreccionEvento.iniciarEstadoErrores();
    let hayError = false;
    if (this.state.cedulaOperario == null || this.state.cedulaOperario === '') {
      errores.cedulaOperario.push('Debe especificar el empleado');
      hayError = true;
    }
    if (this.state.codigoLote == null || this.state.codigoLote === '') {
      errores.codigoLote.push('Debe especificar el lote');
      hayError = true;
    }
    if (this.state.codigoMaterial == null || this.state.codigoMaterial === '') {
      errores.codigoMaterial.push('Debe especificar el material');
      hayError = true;
    }
    if (this.state.operacionId == null || this.state.operacionId === '') {
      errores.operacionId.push('Debe especificar la operación');
      hayError = true;
    }
    if (this.state.turno == null || this.state.turno === '') {
      errores.turno.push('Debe especificar el turno');
      hayError = true;
    }
    if (this.state.tallaId == null || this.state.tallaId === '') {
      errores.tallaId.push('Debe especificar una talla');
      hayError = true;
    }
    if (this.state.cantidadLote == null || this.state.cantidadLote === '') {
      errores.cantidadLote.push('Debe especificar una cantidad');
      hayError = true;
    }

    await this.setState({ errores, errorForm: hayError });
    return !hayError;
  }
  async resetearEstadoErrores() {
    const errores = CorreccionEvento.iniciarEstadoErrores();
    await this.setState({ errores, errorForm: false });
  }
  cerrarFormulario() {
    if (this.props.onClose) {
      this.props.onClose();
    }
  }
  render() {
    if (this.state.loading) {
      return <div>Cargando...</div>;
    } else if (this.state.loadingError) {
      return (
        <div>
          Ocurrió un error cargando la información del evento
          {this.state.loadingMensajeError ? ` (${this.state.loadingMensajeError})` : ''}
        </div>
      );
    }
    return (
      <Form>
        <Alerta
          texto="Hay errores en el formulario. Por favor verifique y corríjalos."
          toggle={this.resetearEstadoErrores}
          isVisible={this.state.errorForm}
        />
        <div className="row">
          <FormGroup className="col-4">
            <InputLabel>Empleado</InputLabel>
            <TextField
              type="text"
              name="cedulaOperario"
              value={this.state.cedulaOperario}
              onChange={this.handleChange}
              disabled={this.state.saving}
              invalid={this.state.errores.cedulaOperario.length > 0}
            />
            <FormFeedback>{mostrarValidacion(this.state.errores.cedulaOperario)}</FormFeedback>
          </FormGroup>
          <FormGroup className="col-4">
            <InputLabel>Lote</InputLabel>
            <TextField
              type="text"
              name="codigoLote"
              value={this.state.codigoLote}
              onChange={this.handleChange}
              disabled={this.state.saving}
              invalid={this.state.errores.codigoLote.length > 0}
            />
            <FormFeedback>{mostrarValidacion(this.state.errores.codigoLote)}</FormFeedback>
          </FormGroup>
          <FormGroup className="col-4">
            <InputLabel>Material</InputLabel>
            <TextField
              type="text"
              name="codigoMaterial"
              value={this.state.codigoMaterial}
              onChange={this.handleChange}
              disabled={this.state.saving}
              invalid={this.state.errores.codigoMaterial.length > 0}
            />
            <FormFeedback>{mostrarValidacion(this.state.errores.codigoMaterial)}</FormFeedback>
          </FormGroup>
        </div>
        <div className="row">
          <SelectorSimple
            inputName="operacionId"
            value={this.state.operacionId}
            etiqueta="Operación general"
            onChange={this.handleChange}
            obtenerDatos={obtenerOperaciones}
            mostrarEtiqueta
            className="col-4"
            campoId="id"
            campoDesc="descripcion"
            disabled={this.state.saving}
            invalid={this.state.errores.operacionId.length > 0}
            invalidMessage={mostrarValidacion(this.state.errores.operacionId)}
          />
          <SelectorSimple
            inputName="turno"
            value={this.state.turno}
            etiqueta="Turno"
            onChange={this.handleChange}
            obtenerDatos={obtenerTurnos}
            mostrarEtiqueta
            className="col-4"
            campoId="id"
            campoDesc="descripcionTurno"
            disabled={this.state.saving}
            invalid={this.state.errores.turno.length > 0}
            invalidMessage={mostrarValidacion(this.state.errores.turno)}
          />
          <SelectorSimple
            inputName="procesoEspecial"
            value={this.state.procesoEspecial}
            etiqueta="Paro"
            onChange={this.handleChange}
            obtenerDatos={obtenerProcesosEspeciales}
            mostrarEtiqueta
            className="col-4"
            campoId="id"
            campoDesc="descripcion"
            disabled={this.state.saving}
          />
        </div>
        <div className="row">
          <InputLabel className="selectGTCorrecionEvent">Talla</InputLabel>
          <InputLabel className="selectGTCorrecionEvent2">Grupo Talla</InputLabel>
          <div className="talla">
            <SelectorCascada
              etiqueta="Grupo Talla"
              primaryInputName="grupoTallaId"
              filteredInputName="tallaId"
              primaryValue={this.state.grupoTallaId}
              filteredValue={this.state.tallaId}
              onChange={this.handleChange}
              onFilteredChange={this.handleChange}
              getParents={obtenerGruposTallas}
              getChilds={obtenerTallasPorGrupo}
              primaryCol={4}
              filteredCol={4}
              disabled={this.state.saving}
              filteredInvalid={this.state.errores.tallaId.length > 0}
              filteredInvalidMessage={mostrarValidacion(this.state.errores.tallaId)}
            />
          </div>
          <FormGroup className="col-4">
            <InputLabel className="selectCantidadEvent">Cantidad</InputLabel>
            <TextField
              className="selectCantidadEvent"
              type="number"
              name="cantidadLote"
              value={this.state.cantidadLote}
              onChange={this.handleChange}
              disabled={this.state.saving}
              invalid={this.state.errores.cantidadLote.length > 0}
            />
            <FormFeedback>{mostrarValidacion(this.state.errores.cantidadLote)}</FormFeedback>
          </FormGroup>
        </div>
        <div className="row mt-3 pt-3 border-top">
          <span className="col" />
          <FormGroup className="col-2">
            <Label className="labelEvent">Estandar</Label>
            <TextField
              type="text"
              name="estandar"
              value={this.state.estandar}
              onChange={this.handleChange}
              readOnly
            />
          </FormGroup>
          <FormGroup className="col-2">
            <Label className="labelEvent">Cantidad esperada</Label>
            <TextField
              type="text"
              name="cantidadEsperada"
              value={this.state.cantidadEsperada}
              onChange={this.handleChange}
              readOnly
            />
          </FormGroup>
          <FormGroup className="col-2">
            <Label className="labelEvent">Cantidad producida</Label>
            <TextField
              type="text"
              name="cantidadProducida"
              value={this.state.cantidadProducida}
              onChange={this.handleChange}
              readOnly
            />
          </FormGroup>
          <FormGroup className="col-2">
            <Label className="labelEvent">Minutos esperados</Label>
            <TextField
              type="text"
              name="minutosEsprerados"
              value={this.state.minutosEsprerados}
              onChange={this.handleChange}
              readOnly
            />
          </FormGroup>
          <FormGroup className="col-2">
            <Label className="labelEvent" >Minutos producidos</Label>
            <TextField
              type="text"
              name="minutosProducidos"
              value={this.state.minutosProducidos}
              onChange={this.handleChange}
              readOnly
            />
          </FormGroup>
          <span className="col" />
        </div>
        <div className="row">
          <div className="col text-center">
            <Button
              className="btn btn-primary"
              type="button"
              name="guardar"
              onClick={this.guardarEvento}
              disabled={this.state.saving || this.state.eventoModificado}
            >
              {this.state.saving ? 'Guardando' : 'Guardar'}
            </Button>
            <Button type="button" name="cerrar" onClick={this.cerrarFormulario}>Cerrar</Button>
          </div>
        </div>
      </Form>
    );
  }
}
CorreccionEvento.propTypes = {
  eventoId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  onSave: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default CorreccionEvento;
