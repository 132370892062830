/* eslint-disable import/prefer-default-export */

/**
 * Retorna la clase del color establecido para un
 * rango de eficiencias.
 * @param {number} porcentaje - Porcentaje bruto de eficiencia.
 */
export function getColorEficienciaBadge(porcentaje) {
  if (porcentaje < 85) return 'danger';
  if (porcentaje >= 85 && porcentaje < 100) return 'warning';
  if (porcentaje >= 100 && porcentaje < 120) return 'success';
  if (porcentaje >= 120) return 'primary';
  return 'danger';
}

/**
 * Retorna la clase del degradado de color establecido para un
 * rango de eficiencias.
 * @param {number} porcentaje - Porcentaje bruto de eficiencia.
 */
export function getColorEficienciaLinear(eficiencia) {
  if (eficiencia < 85) return 'progress-wrap--pink-gradient';
  if (eficiencia >= 85 && eficiencia < 100) return 'progress-wrap--orange-gradient';
  if (eficiencia >= 100 && eficiencia < 120) return 'progress-wrap--lime-gradient';
  if (eficiencia >= 120) return 'progress-wrap--blue-gradient';
  return 'progress-wrap--pink-gradient';
}

/**
 * Retorna el color establecido para un
 * rango de eficiencias.
 * @param {number} porcentaje - Porcentaje bruto de eficiencia.
 */
export function getEfficiencyColor(porcentaje) {
  if (porcentaje < 85) return '#e10000';
  if (porcentaje >= 85 && porcentaje < 100) return '#ff6c00';
  if (porcentaje >= 100 && porcentaje < 120) return '#018e4c';
  if (porcentaje >= 120) return '#004093';
  return '#e10000';
}

/**
 * Retorna el color del porcentaje de eficiencia
 * restante para el 100%, o en caso de sobrepasar
 * el 100%, el color establecido para ese rango.
 * @param {number} porcentaje - Porcentaje bruto de eficiencia.
 */
export function getUnefficiencyColor(porcentaje) {
  if (porcentaje >= 100 && porcentaje < 120) return 'hsl(130, 38%, 28%)';
  if (porcentaje >= 120) return 'hsl(238, 33%, 29%)';
  return '#eeeeee';
}
