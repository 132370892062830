import React, { Component } from 'react';
// import { Table } from 'reactstrap';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
// import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';

import { NotificationProps } from '../../../shared/prop-types/ReducerProps';
import AlarmaItem from './AlarmaItem';

class AlarmasTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      alarmas: props.alarmas,
      connection: props.connection,
      alarmasItem: [],
    };
  }

  componentWillMount = () => {
    const { alarmas } = this.state;

    const alarmasItem = alarmas.map(alarma => <AlarmaItem key={alarma.id} {...alarma} />);

    this.setState({
      alarmasItem,
    });
  }

  componentDidMount = () => {
    const { connection, alarmasItem } = this.state;

    connection.hubConnection.on('NuevaAlarma', (alarma) => {
      const alarmasItemNew = alarmasItem;
      alarmasItemNew.push(<AlarmaItem {...alarma} />);

      this.setState({
        alarmasItem: alarmasItemNew,
      });
    });
  }

  render() {
    const { alarmasItem } = this.state;
    return (
      <Paper className="margin-bottom-21 overflow-x-auto">
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>#</TableCell>
              <TableCell>Alarma</TableCell>
              <TableCell>Operario</TableCell>
              <TableCell>Fecha Origen</TableCell>
              <TableCell>Fecha Check</TableCell>
              <TableCell>Seccion</TableCell>
              <TableCell>Observación</TableCell>
              <TableCell>Estado</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            { alarmasItem }
          </TableBody>
        </Table>
      </Paper>
    );
  }
}

AlarmasTable.propTypes = {
  alarmas: PropTypes.arrayOf(PropTypes.object).isRequired,
  connection: NotificationProps.isRequired,
};

export default connect(state => ({
  connection: state.notification,
}))(AlarmasTable);
