import React from 'react';
import PropTypes from 'prop-types';
import { Alert, AlertTitle } from '@material-ui/lab';
import { IconButton } from '@material-ui/core';
import { CloseIcon } from 'mdi-react';

class Alerta extends React.Component {
  constructor(props) {
    super(props);
  }
  render() {
    if (this.props.isVisible) {
      return (
        <Alert
          severity="error"
          style={{
            zIndex: 1,
            position: 'fixed',
            top: '12%',
            right: '15%',
            transform: 'translate(-50%, -50%)',
            background: '#ffff',
            boxShadow: '0 0 10px rgba(0, 0, 0, 0.2)',
            padding: 20,
            borderRadius: 5,
          }}
          action={
            <IconButton
              variant="outlined"
              color="light"
              onClick={this.props.toggle}
            >
              <CloseIcon />
            </IconButton>
          }
        >
          <AlertTitle>Error</AlertTitle>
          {this.props.texto}
        </Alert>
      );
    }
    return null;
  }
}
Alerta.propTypes = {
  texto: PropTypes.string.isRequired,
  isVisible: PropTypes.bool.isRequired,
  toggle: PropTypes.func.isRequired,
};


export default Alerta;
