import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import Subsecciones from './components/Subsecciones';
import Loader from '../../shared/components/loader/Loader';
import config from './../../appConfig.json';

class EficienciasSubsecciones extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      seccion: null,
      loading: true,
    };
  }

  componentDidMount = () => {
    const { seccion } = this.props.match.params;

    this.getSubseccionesAsync(seccion);
  };

  /**
   * Obtiene todas las secciones de una planta
   * y las establece en el estado.
   */
  getSubseccionesAsync = async (seccionId) => {
    const { ApiUrl, AuthTokenKey } = config;
    const response = await
    fetch(
      `${ApiUrl}/eficiencia/seccion/${seccionId}`,
      {
        mode: 'cors',
        method: 'GET',
        headers: {
          'Content-Type': 'application/json;charset=utf-8',
          Authorization: localStorage.getItem(AuthTokenKey),
        },
      },
    );

    const seccion = await response.json();

    this.setState({
      seccion,
      loading: false,
    });
  }

  render() {
    const { seccion, loading } = this.state;
    const { unidad, planta } = this.props.match.params;
    return (
      !loading ?
        <Subsecciones
          key={`${seccion.elementoId}`}
          seccion={seccion.nombre}
          plantaId={planta}
          unidadId={unidad}
          subsecciones={seccion.subsecciones}
          seccionId={seccion.elementoId}
        />
        : <Loader loading={loading} />
    );
  }
}

EficienciasSubsecciones.propTypes = {
  match: PropTypes.objectOf(PropTypes.shape()).isRequired,
};

export default EficienciasSubsecciones;
