/* eslint-disable import/prefer-default-export */
import React from 'react';
import PropTypes from 'prop-types';
import { Dialog, DialogTitle, DialogContent,
  DialogActions, Button, DialogContentText } from '@material-ui/core';

export const OperacionDialog = ({
  operacion, open, toggleModal, handleProcessSelect,
}) => (
  <Dialog
    open={open}
    onClose={toggleModal}
    scroll="paper"
    aria-labelledby="scroll-dialog-title"
    maxWidth="sm"
  >
    <DialogTitle id="scroll-dialog-title">{ operacion.nombre } <small>({operacion.id})</small></DialogTitle>
    <DialogContent dividers>
      <DialogContentText>{ operacion.descripcion }</DialogContentText>
    </DialogContent>
    <DialogActions>
      <Button onClick={toggleModal} color="default">
        Cerrar
      </Button>
      <Button onClick={() => handleProcessSelect(operacion)} color="secondary">
        Añadir
      </Button>
    </DialogActions>
  </Dialog>
);

OperacionDialog.propTypes = {
  operacion: PropTypes.objectOf(PropTypes.string).isRequired,
  open: PropTypes.bool.isRequired,
  toggleModal: PropTypes.func.isRequired,
  handleProcessSelect: PropTypes.func.isRequired,
};
