import React from 'react';
import PropTypes from 'prop-types';
import { Row } from 'reactstrap';
import IndicadorEficienciaUnidad from './IndicadorEficienciaUnidad';

const UnidadesNegocio = props => (
  <Row className="justify-content-center">
    { props.unidades.map(unidad => (<IndicadorEficienciaUnidad
      key={unidad.id}
      codigo={unidad.id}
      base="/plantas/unidad"
      titulo={unidad.descripcion}
      eficiencia={0}
    />))
    }
  </Row>
);

UnidadesNegocio.propTypes = {
  unidades: PropTypes.arrayOf(PropTypes.shape()).isRequired,
};

export default UnidadesNegocio;
