import React, { createContext, useState } from 'react';
import PropTypes from 'prop-types';
import {
  calcularIncentivos,
  consultarIncentivos,
  exportarIncentivosExcel,
  generarPlanoNomina,
  guardarCorreccionIncentivo,
} from './datosIncentivos';

const fecha = new Date();
const inicio = () => {
  fecha.setHours(0, 0, 0);
  const x = new Date(fecha.getTime());
  if (x.getDate() <= 15) {
    x.setDate(1);
  } else {
    x.setDate(16);
  }
  return x.toISOString().substring(0, 10);
};
const fin = () => {
  fecha.setHours(0, 0, 0);
  return fecha.toISOString().substring(0, 10);
};
const ContextoIncentivos = createContext();
const Provider = ({ children }) => {
  const [fechaInicial, setFechaInicial] = useState(inicio());
  const [fechaFinal, setFechaFinal] = useState(fin());
  const [cedulaOperario, setCedulaOperario] = useState('');
  const [modoSimulacion, setModoSimulacion] = useState(false);
  const [confirmacionCalculoRequerida, setConfirmacionCalculoRequerida] = useState(false);
  const [cargando, setCargando] = useState(false);
  const [listaIncentivos, setListaIncentivos] = useState([]);
  const [incentivoId, setIncentivoId] = useState(null);
  const [minutosEsperados, setMinutosEsperados] = useState('');
  const [minutosProducidos, setMinutosProducidos] = useState('');
  const [valorIncentivo, setValorIncentivo] = useState('');
  const [editandoIncentivo, setEstadoEdicionIncentivo] = useState(false);
  const [registrandoAjuste, setEstadoRegistroAjuste] = useState(false);
  const [incentivoActual, setIncentivoActual] = useState(null);
  const [error, setError] = useState({ hayError: false, mensaje: null });
  const [paginaConsulta, setPaginaConsulta] = useState(0);
  const abortController = new AbortController();

  const value = {
    fechaInicial,
    fijarFechaInicial: async (f) => {
      setFechaInicial(f);
    },
    fechaFinal,
    fijarFechaFinal: async (f) => {
      setFechaFinal(f);
    },
    cedulaOperario,
    fijarCedulaOperario: async (cedula) => {
      setCedulaOperario(cedula);
    },
    modoSimulacion,
    fijarModoSimulacion: async (esModoSimulacion) => {
      setModoSimulacion(esModoSimulacion);
    },
    cargando,
    fijarEstadoCarga: async (estaCargando) => {
      setCargando(estaCargando);
    },
    listaIncentivos,
    fijarListaIncentivos: async (datos) => {
      setListaIncentivos(datos);
    },
    incentivoId,
    fijarIncentivoId: async (info) => {
      setIncentivoId(info);
    },
    minutosEsperados,
    fijarMinutosEsperados: async (esperados) => {
      setMinutosEsperados(esperados);
    },
    minutosProducidos,
    fijarMinutosProducidos: async (producidos) => {
      setMinutosProducidos(producidos);
    },
    valorIncentivo,
    fijarValorIncentivo: async (valor) => {
      setValorIncentivo(valor);
    },
    confirmacionCalculoRequerida,
    fijarConfirmacionCalculoRequerida: async (confirmacionRequerida) => {
      setConfirmacionCalculoRequerida(confirmacionRequerida);
    },
    editandoIncentivo,
    fijarEstadoEdicionIncentivo: async (editando) => {
      if (!editando) {
        setIncentivoId(null);
      }
      setEstadoEdicionIncentivo(editando);
    },
    registrandoAjuste,
    fijarEstadoRegistroAjuste: async (registrando) => {
      setEstadoRegistroAjuste(registrando);
    },
    incentivoActual,
    fijarIncentivoActual: async (incentivo) => {
      setIncentivoActual(incentivo);
    },
    error,
    fijarInfoError: async (hayError, mensaje) => {
      setError({ hayError, mensaje });
    },
    paginaConsulta,
    fijarPaginaConsulta: async (p) => {
      setPaginaConsulta(p);
    },
    ejecutarCalculoIncentivo: async (confirmar) => {
      setCargando(true);
      setListaIncentivos([]);
      setPaginaConsulta(0);
      setError({ hayError: false, mensaje: '' });
      const resultado = await calcularIncentivos({
        modoSimulacion: false, // ToDo: Enlazar a elemento de UI
        fechaInicioEvento: fechaInicial,
        fechaFinEvento: fechaFinal,
        calcularConfirmado: confirmar,
      }, abortController.signal);
      if (!resultado.error) {
        if (resultado.datos.listaIncentivos && resultado.datos.listaIncentivos.length) {
          setListaIncentivos(resultado.datos.listaIncentivos);
        } else if (resultado.datos.existeLiquidacion) {
          setConfirmacionCalculoRequerida(true);
        }
      } else {
        setError({ hayError: true, mensaje: resultado.datos });
      }
      setCargando(false);
    },
    ejecutarConsultaIncentivo: async (conservarPagina) => {
      setCargando(true);
      setListaIncentivos([]);
      if (!conservarPagina) {
        setPaginaConsulta(0);
      }
      setError({ hayError: false, mensaje: '' });
      const resultado = await consultarIncentivos({
        modoSimulacion: false, // ToDo: Enlazar a elemento de UI
        fechaInicioEvento: fechaInicial,
        fechaFinEvento: fechaFinal,
        cedula: cedulaOperario,
      }, abortController.signal);
      if (!resultado.error) {
        if (resultado.datos.listaIncentivos && resultado.datos.listaIncentivos.length) {
          setListaIncentivos(resultado.datos.listaIncentivos);
        }
      } else {
        setError({ hayError: true, mensaje: resultado.datos });
      }
      setCargando(false);
    },
    exportarExcel: async () => {
      setCargando(true);
      setError({ hayError: false, mensaje: '' });
      try {
        await exportarIncentivosExcel({
          modoSimulacion: false, // ToDo: Enlazar a elemento de UI
          fechaInicioEvento: fechaInicial,
          fechaFinEvento: fechaFinal,
          cedula: cedulaOperario,
        }, abortController.signal);
      } catch (e) {
        setError({ hayError: true, mensaje: e });
      } finally {
        setCargando(false);
      }
    },
    generarPlanoNomina: async () => {
      setCargando(true);
      setError({ hayError: false, mensaje: '' });
      try {
        await generarPlanoNomina({
          fechaInicioEvento: fechaInicial,
          fechaFinEvento: fechaFinal,
        }, abortController.signal);
      } catch (e) {
        setError({ hayError: true, mensaje: e });
      } finally {
        setCargando(false);
      }
    },
    registrarCorreccionIncentivo: async (info) => {
      setCargando(true);
      setError({ hayError: false, mensaje: '' });
      try {
        await guardarCorreccionIncentivo(info, abortController.signal);
      } catch (e) {
        setError({ hayError: true, mensaje: e });
      } finally {
        setCargando(false);
      }
    },
  };
  return (
    <ContextoIncentivos.Provider value={value}>
      { children }
    </ContextoIncentivos.Provider>
  );
};

Provider.propTypes = {
  children: PropTypes.element.isRequired,
};

export default {
  Provider,
  Consumer: ContextoIncentivos.Consumer,
};
