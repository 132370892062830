import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import {
  Col,
  UncontrolledButtonDropdown,
  Row,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from 'reactstrap';

import config from '../../appConfig.json';
import EficienciaGeneralMaquina from './components/EficienciaGeneralMaquina';
import EficienciaTabla from './components/EficienciaMaquinaTabla';

class DetallesMaquina extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      maquina: {},
      turno: 'Turno',
      loading: true,
    };
  }
  async componentDidMount() {
    const { maquina } = this.props.match.params;
    await this.chooseFetch(maquina, this.state.turno);
  }

  async chooseFetch(maquinaId, turnoCheck) {
    if (turnoCheck === 'Día') {
      await this.fetchDataMaquinaDia(maquinaId);
    } else {
      await this.fetchDataMaquinaTurno(maquinaId);
    }
  }

  fetchDataMaquinaDia = async (maquinaId) => {
    try {
      const requestMaquinas = await fetch(
        `${config.ApiUrl}/tejido/eficiencia/maquina/dia/${maquinaId}`,
        {
          headers: {
            'Content-Type': 'application/json;charset=utf-8',
            Authorization: localStorage.getItem(config.AuthTokenKey),
          },
        },
      );
      const maquinas = await requestMaquinas.json();
      this.setState({ maquina: maquinas, loading: false });
    } catch (error) {
      console.log('falló');
    }
  }

  fetchDataMaquinaTurno = async (maquinaId) => {
    try {
      const respuesta = await fetch(
        `${config.ApiUrl}/tejido/eficiencia/maquina/turno/${maquinaId}`,
        {
          headers: {
            'Content-Type': 'application/json;charset=utf-8',
            Authorization: localStorage.getItem(config.AuthTokenKey),
          },
        },
      );
      const maquinas = await respuesta.json();
      await this.setState({ maquina: maquinas, loading: false });
    } catch (error) {
      console.log('falló');
    }
  }

  handleClickDia = async () => {
    this.setState({ turno: 'Día', loading: true });
    this.chooseFetch(this.props.match.params.maquina, 'Día');
  }

  handleClickTurno = async () => {
    this.setState({ turno: 'Turno', loading: true });
    this.chooseFetch(this.props.match.params.maquina, 'Turno');
  }
  render() {
    return (
      <Fragment>
        <Col sm={12} className="card detallesCard">
          <Row>
            { this.state.loading ?
              <h4 className="text-center">Cargando ...</h4>
              :
              <Col md={6}>
                <UncontrolledButtonDropdown>
                  <DropdownToggle caret className="btn-rounded" outline>
                    <span className="lnr lnr-calendar-full" /> {this.state.turno}
                  </DropdownToggle>
                  <DropdownMenu>
                    <DropdownItem key="1" onClick={this.handleClickDia}>Día</DropdownItem >
                    <DropdownItem key="2" onClick={this.handleClickTurno}>Turno</DropdownItem >
                  </DropdownMenu>
                </UncontrolledButtonDropdown>
              </Col>
            }
          </Row>
          <EficienciaGeneralMaquina
            turno={this.state.turno}
            eficiencia={this.state.maquina.eficiencia}
            parqueadero={this.state.maquina.parqueadero}
            maquina={this.state.maquina.codigoMaquina}
          />
          <hr />
          {
              this.state.loading ?
                <h4 className="text-center">Cargando ...</h4>
          :
                <EficienciaTabla data={this.state.maquina.lotes} />
          }
        </Col>
      </Fragment>
    );
  }
}

DetallesMaquina.propTypes = {
  match: PropTypes.objectOf(PropTypes.shape()).isRequired,
};

export default DetallesMaquina;
