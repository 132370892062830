import React, { PureComponent } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import TopbarSidebarButton from './TopbarSidebarButton';
import TopbarProfile from './TopbarProfile';
import TopbarNotification from './TopbarNotification';
import { UsuarioRestriccion } from './../../../appConfig.json';

class Topbar extends PureComponent {
  static propTypes = {
    changeMobileSidebarVisibility: PropTypes.func.isRequired,
    changeSidebarVisibility: PropTypes.func.isRequired,
    username: PropTypes.string.isRequired,
  };

  render() {
    const { changeMobileSidebarVisibility, changeSidebarVisibility, username } = this.props;

    return (
      <div className="topbar">
        <div className="topbar__wrapper">
          <div className="topbar__left">
            {
              username !== UsuarioRestriccion && <TopbarSidebarButton
                changeMobileSidebarVisibility={changeMobileSidebarVisibility}
                changeSidebarVisibility={changeSidebarVisibility}
              />
            }
            {
              username !== UsuarioRestriccion
              ? <Link className="topbar__logo" to="/" />
              : <div className="topbar__logo" />
            }

          </div>
          <div className="topbar__right">
            {
              username !== UsuarioRestriccion && <TopbarNotification />
            }
            <TopbarProfile username={username} />
          </div>
        </div>
      </div>
    );
  }
}

export default Topbar;
