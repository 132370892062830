import React from 'react';
import PropTypes from 'prop-types';
import { Card, CardBody, Col } from 'reactstrap';
import { Link } from 'react-router-dom';
import Eficiencia from '../../../shared/components/eficiencia/Eficiencia';
import IndicadoresNumericosEstandar from '../../../shared/components/indicadoresNumericos/IndicadoresNumericosEstandar';

const IndicadorEficienciaSubseccion = (props) => {
  const {
    base, codigo, seccion, eficiencia, tiempoParo, real,
  } = props;
  return (
    <Col md={6} lg={3} className="card-no-padding">
      <Link to={`${base}/${codigo}`}>
        <Card>
          <CardBody>
            <div className="card__title">
              <h5 className="bold-text text-center">{seccion}</h5>
            </div>
            <Eficiencia
              modo="medium"
              porcentaje={eficiencia}
              cx={85}
              cy={85}
              innerRadius={85}
              outerRadius={90}
            />
            <IndicadoresNumericosEstandar
              real={real}
              tiempoParo={tiempoParo}
            />
          </CardBody>
        </Card>
      </Link>
    </Col>
  );
};

IndicadorEficienciaSubseccion.propTypes = {
  seccion: PropTypes.string.isRequired,
  codigo: PropTypes.number.isRequired,
  base: PropTypes.string.isRequired,
  eficiencia: PropTypes.number.isRequired,
  tiempoParo: PropTypes.number.isRequired,
  real: PropTypes.number.isRequired,
};

export default IndicadorEficienciaSubseccion;
