import React from 'react';
import { Tooltip,
  Button,
  Dialog,
  DialogContent,
  DialogActions,
  DialogTitle,
  Grid,
  Typography,
  TextField,
  IconButton } from '@material-ui/core';
import { AddCircleIcon } from 'mdi-react';
import config from '../../../../appConfig.json';
import Alert from '../../../../shared/components/alert/Alert';
import Context from '../../../Operaciones/store';

export default function CrearSubseccion(props) {
  const [open, setOpen] = React.useState(false);
  const [alert, setAlert] = React.useState(false);
  const [seccion, setSeccion] = React.useState({});

  const handleChange = (e) => {
    const array = { ...seccion };
    array[e.target.id] = e.target.value;
    setSeccion(array);
  };

  const handleClose = () => {
    setAlert(false);
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  return (
    <>
      <Context.Consumer>
        {
          (value) => {
            const handleSave = async () => {
              const array = {
                ...seccion,
                id: 0,
                seccion: value.operacion.id,
                fechaCreacion: new Date(),
                fechaModificacion: new Date(),
                usuario: localStorage.getItem(config.CryUsername),
              };
              const request = await fetch(`${config.ApiUrl}/secciones/nueva/subseccion`, {
                mode: 'cors',
                method: 'PUT',
                body: JSON.stringify(array),
                headers: {
                  'Content-Type': 'application/json',
                    Authorization: localStorage.getItem(config.AuthTokenKey),
                    Accept: 'application/json',
                },
              });
              const response = await request.json();
              if (response.sw === 1) {
                setAlert(true);
              } else {
                props.fetch();
                handleClose();
              }
            };

            if (value.operacion === null) {
              return (
                <Tooltip title="Crea una subsección" >
                  <IconButton size="small">
                    <AddCircleIcon color="primary" />
                  </IconButton>
                </Tooltip>
              );
            }

            return (
              <>
                <Tooltip title="Crea una subsección" >
                  <IconButton size="small" onClick={() => { handleOpen(); }} >
                    <AddCircleIcon color="primary" />
                  </IconButton>
                </Tooltip>
                <Dialog
                  open={open}
                  onClose={handleClose}
                  scroll="paper"
                  aria-labelledby="form-dialog-title"
                  maxWidth="sm"
                >
                  <form onSubmit={(e) => {
                    e.preventDefault();
                    handleSave();
                    }}
                  >
                    <DialogTitle>
                      <Typography gutterBottom variant="h6" align="center">
                        ADICIONAR SUBSECCIÓN
                      </Typography>
                    </DialogTitle>
                    <DialogContent dividers>
                      <Alert
                        color="danger"
                        className="alert--bordered"
                        icon
                        visible={alert}
                      >
                        ESTA SUBSECCIÓN YA ESTA REGISTRADA.
                      </Alert>
                      <Grid container>
                        <Grid item xs={6}>
                          <TextField
                            required
                            label="Código"
                            id="codigo"
                            onChange={handleChange}
                            type="number"
                          />
                        </Grid>
                        <Grid item xs={6}>
                          <TextField
                            label="Seccion"
                            id="seccion"
                            value={value.operacion.descripcion}
                          />
                        </Grid>
                        <Grid item xs={12} >
                          <TextField
                            required
                            label="Descripción"
                            id="descripcion"
                            fullWidth
                            onChange={handleChange}
                          />
                        </Grid>
                        <Grid item xs={3}>
                          <TextField
                            label="Criterio uno"
                            id="criterioBusquedaUno"
                            onChange={handleChange}
                            type="number"
                          />
                        </Grid>
                        <Grid item xs={3}>
                          <TextField
                            label="Criterio dos"
                            id="criterioBusquedaDos"
                            onChange={handleChange}
                            type="number"
                          />
                        </Grid>
                        <Grid item xs={3}>
                          <TextField
                            label="Criterio tres"
                            id="criterioBusquedaTres"
                            onChange={handleChange}
                            type="number"
                          />
                        </Grid>
                        <Grid item xs={3}>
                          <TextField
                            label="Criterio cuatro"
                            id="criterioBusquedaCuatro"
                            onChange={handleChange}
                            type="number"
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <TextField
                            required
                            label="Alarma falta"
                            id="alarmaFaltaEstandar"
                            onChange={handleChange}
                            type="number"
                          />
                        </Grid>
                      </Grid>
                    </DialogContent>
                    <DialogActions>
                      <Button onClick={handleClose} color="default">
                        Cerrar
                      </Button>
                      <Button color="primary" type="submit" >
                        Añadir
                      </Button>
                    </DialogActions>
                  </form>
                </Dialog>
              </>
            );
          }
        }
      </Context.Consumer>
    </>
  );
}
