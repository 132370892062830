import React, { Component } from 'react';
import PropTypes from 'prop-types';
import SidebarCategory from './SidebarCategory';

class MenuSeamless extends Component {
  static propTypes = {
    onClick: PropTypes.func.isRequired,
    changeToDark: PropTypes.func.isRequired,
    changeToLight: PropTypes.func.isRequired,
  };

  hideSidebar = () => {
    this.props.onClick();
  };

  render() {
    return (
      <div className="sidebar__content">
        <ul className="sidebar__block">
          {/* <SidebarCategory title="Monitor Eficiencias" icon="chart-bars"> */}
          {/* <SidebarLink title="Unidades de Negocio" route="/unidades" onClick={this.hideSidebar} /> */}
          {/* <SidebarLink title="Plantas" route="/plantas" onClick={this.hideSidebar} /> */}
          {/* <SidebarLink title="Secciones" route="/secciones" onClick={this.hideSidebar} /> */}
          {/* </SidebarCategory> */}
        </ul>
        <ul className="sidebar__block">
          <SidebarCategory title="Temas" icon="layers">
            <button className="sidebar__link" onClick={this.props.changeToLight}>
              <p className="sidebar__link-title">Tema Claro seamless</p>
            </button>
            <button className="sidebar__link" onClick={this.props.changeToDark}>
              <p className="sidebar__link-title">Tema Oscuro</p>
            </button>
          </SidebarCategory>
        </ul>
      </div>
    );
  }
}
export default MenuSeamless;
