import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles, List, ListItem, ListItemText } from '@material-ui/core';

import config from '../../../../appConfig.json';

import Context from '../../../Operaciones/store';
import EliminarSeccion from '../Components/EliminarSeccion';
import { perteneceRolIngenieria } from '../../../Comunes/datosComunes';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.paper,
    position: 'relative',
    overflowY: 'auto',
    height: `${window.screen.height - 270}px`,
    width: '100%',
  },
  color: {
    color: '#717575',
  },
  textoColor: {
    '.MuiTypography-body1': {
      backgroundColor: 'aquamarine',
    },
  },
}));

export default function ListaSecciones(props) {
  const classes = useStyles();
  const {
    secciones,
  } = props;

  const item = p => (
    <ListItemText id={p.id}>
      <span
        style={{ fontSize: '90%' }}
        className="MuiTypography-root MuiListItemText-primary MuiTypography-body1"
      >
        {p.codigo}
        <span> : </span>
        {p.descripcion}
      </span>
    </ListItemText>
  );

  return (
    <>
      <Context.Consumer>
        {
          (value) => {
            const handleSelect = async (s) => {
              const request = await fetch(
                `${config.ApiUrl}/Secciones/${s.id}/subsecciones`,
                {
                  headers: {
                    'Content-Type': 'application/json',
                    Authorization: localStorage.getItem(config.AuthTokenKey),
                    Accept: 'application/json',
                  },
                },
              );
              const response = await request.json();
              value.activateOp(s);
              value.activateDet(response);
              value.activateDetOrigin(response);
            };

            return (
              <List className={classes.root} >
                {secciones.map(s => (
                  <ListItem
                    button
                    divider
                    key={s.id}
                    onClick={() => { handleSelect(s); }}
                  >
                    {item(s)}
                    {
                      perteneceRolIngenieria() &&
                      <EliminarSeccion fetch={props.fetch} />
                    }
                  </ListItem>
              ))}
              </List>
            );
          }
        }
      </Context.Consumer>
    </>
  );
}
ListaSecciones.propTypes = {
  secciones: PropTypes.arrayOf(PropTypes.object).isRequired,
};
