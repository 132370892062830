import React, { useState } from 'react';
import {
  Grid,
  CircularProgress,
  Fab,
  Chip,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TablePagination,
} from '@material-ui/core';
import { EditIcon } from 'mdi-react';
import { getEfficiencyColor } from '../../../shared/funtions/ColoresEficiencias';
import ContextoIncentivos from '../ContextoIncentivos';
import { esAjuste } from '../datosIncentivos';

const fd = new Intl.DateTimeFormat();
const nd = new Intl.NumberFormat({ minimumFractionDigits: 2, maximumFractionDigits: 2 });
const ndi = new Intl.NumberFormat({ minimumFractionDigits: 0 });

const ListaIncentivos = () => {
  const [paginas, setPaginas] = useState(10);
  const [pagina, setPagina] = useState(0);

  return (
    <ContextoIncentivos.Consumer>
      {({
        listaIncentivos,
        cargando,
        fijarEstadoEdicionIncentivo,
        fijarIncentivoId,
        fijarIncentivoActual,
        fijarPaginaConsulta,
        fijarMinutosProducidos,
        fijarMinutosEsperados,
        fijarValorIncentivo,
      }) => {
        const iniciarEdicion = async (incentivo) => {
          await fijarIncentivoId(incentivo.incentivoId);
          await fijarIncentivoActual(incentivo);
          await fijarMinutosProducidos(incentivo.minutosProducidos);
          await fijarMinutosEsperados(incentivo.minutosEsperados);
          await fijarValorIncentivo(incentivo.valorIncentivo);
          await fijarEstadoEdicionIncentivo(true);
        };

        const handleChangePage = (event, newPage) => {
          setPagina(newPage);
          fijarPaginaConsulta(newPage);
        };

        const handleChangeRowsPerPage = (event) => {
          setPaginas(parseInt(event.target.value, 10));
          setPagina(0);
          fijarPaginaConsulta(0);
        };

        const renderTurno = (row) => {
          if (esAjuste(row.tipo)) {
            return '';
          }
          return row.esOrdinario ? 'Ord.' : 'Ext.';
        };

        const renderMinsEsp = (row) => {
          if (esAjuste(row.tipo)) {
            return '';
          }
          let x = nd.format(row.minutosEsperados);
          if (row.tipo === 1) {
            x = <span style={{ color: 'red' }}>{x}</span>;
          }
          return x;
        };

        const renderMinsProd = (row) => {
          if (esAjuste(row.tipo)) {
            return '';
          }
          let x = nd.format(row.minutosProducidos);
          if (row.tipo === 1) {
            x = <span style={{ color: 'red' }}>{x}</span>;
          }
          return x;
        };

        const renderEficiencia = (row) => {
          if (esAjuste(row.tipo)) {
            return '';
          }
          return (
            <Chip
              size="small"
              label={`${nd.format(row.eficiencia * 100)}%`}
              style={{
                width: '100%',
                color: 'white',
                background: getEfficiencyColor((row.eficiencia * 100).toFixed(2)),
              }}
            />
          );
        };

        const renderIncentivo = (row) => {
          let x = ndi.format(row.valorIncentivo);
          if (row.tipo === 3) {
            x = <span style={{ color: 'red' }}>{x}</span>;
          }
          return x;
        };

        const renderUsuario = (row) => {
          if (row.tipo === 0) {
            return '';
          }
          return <span>{row.usuario}</span>;
        };

        return (
          <Grid container spacing={2}>
            {cargando ? (
              <CircularProgress />
            ) : (
              <>
                <TableContainer component={Paper}>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell>Fecha</TableCell>
                        <TableCell>Turno</TableCell>
                        <TableCell>Código</TableCell>
                        <TableCell>Cédula</TableCell>
                        <TableCell>Nombres</TableCell>
                        <TableCell>Apellidos</TableCell>
                        <TableCell>Dz</TableCell>
                        <TableCell>Mins. Esp.</TableCell>
                        <TableCell>Mins. Prod</TableCell>
                        <TableCell>Eficiencia</TableCell>
                        <TableCell>Incentivo</TableCell>
                        <TableCell>Periodo</TableCell>
                        <TableCell>Sección</TableCell>
                        <TableCell>F. Mod.</TableCell>
                        <TableCell>Usuario</TableCell>
                        <TableCell>Editar</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {listaIncentivos.slice(pagina * paginas, pagina * paginas + paginas).map(row => (
                        <TableRow key={row.incentivoId} style={{ color: row.borrado ? 'rgb(168,168,168)' : '' }}>
                          <TableCell title={row.incentivoId}>
                            {fd.format(Date.parse(row.fecha, { year: 'numeric', month: '2-digit', day: '2-digit' }))}
                          </TableCell>
                          <TableCell>{renderTurno(row)}</TableCell>
                          <TableCell>{row.codigo}</TableCell>
                          <TableCell>{row.cedula}</TableCell>
                          <TableCell>{row.nombres}</TableCell>
                          <TableCell>{row.apellidos}</TableCell>
                          <TableCell>{!esAjuste(row.tipo) ? nd.format(row.docenas) : ''}</TableCell>
                          <TableCell>{renderMinsEsp(row)}</TableCell>
                          <TableCell>{renderMinsProd(row)}</TableCell>
                          <TableCell>{renderEficiencia(row)}</TableCell>
                          <TableCell>{renderIncentivo(row)}</TableCell>
                          <TableCell>{row.periodo}</TableCell>
                          <TableCell>{row.nombreSeccion}</TableCell>
                          <TableCell>
                            {fd.format(Date.parse(row.fechaModificacion, {
                              year: 'numeric',
                              month: '2-digit',
                              day: '2-digit',
                            }))}
                          </TableCell>
                          <TableCell>{renderUsuario(row)}</TableCell>
                          <TableCell>
                            {row.borrado ? null : (
                              <Fab size="small">
                                <EditIcon color="action" onClick={() => iniciarEdicion(row)} />
                              </Fab>
                            )}
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                  <TablePagination
                    rowsPerPageOptions={[5, 10, 15, 20, 30, 50, 100]}
                    component="div"
                    count={listaIncentivos.length}
                    rowsPerPage={paginas}
                    page={pagina}
                    onChangePage={handleChangePage}
                    onChangeRowsPerPage={handleChangeRowsPerPage}
                  />
                </TableContainer>
              </>
            )}
          </Grid>
        );
      }}
    </ContextoIncentivos.Consumer>
  );
};

export default ListaIncentivos;
