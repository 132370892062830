import React from 'react';
import PropTypes from 'prop-types';
import { Button,
  Dialog,
  DialogContent,
  DialogActions,
  DialogTitle,
  Typography,
  Grid,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Switch,
  FormControlLabel } from '@material-ui/core';

export default function ModificarMaquina(props) {
  const { maquina } = props;
  return (
    <>
      <Dialog
        open={props.open}
        onClose={() => { props.handleClose(3); }}
        scroll="paper"
        aria-labelledby="scroll-dialog-title"
        maxWidth="sm"
      >
        <form
          onSubmit={(e) => {
          e.preventDefault();
          props.handleUpdate();
            }}
        >
          <DialogTitle>
            <Typography variant="h6" align="center">
              MODIFICAR MAQUINA
            </Typography>
          </DialogTitle>
          <DialogContent dividers>
            <Grid container>
              <Grid item xs={3}>
                <TextField
                  readOnly
                  style={{ width: '90%' }}
                  label="Código"
                  id="codigo"
                  value={maquina.codigo}
                />
              </Grid>
              <Grid item xs={3} >
                <TextField
                  required
                  style={{ width: '90%' }}
                  label="Parqueadero"
                  id="parqueadero"
                  defaultValue={maquina.parqueadero}
                  onChange={props.handleChangeInputs}
                />
              </Grid>
              <Grid item xs={3}>
                <FormControl style={{ width: '100px' }} required>
                  <InputLabel id="pasilllo">Pasillos</InputLabel>
                  <Select
                    labelId="pasillo"
                    name="pasillo"
                    onChange={(e) => { props.handleSelect(e, 2); }}
                    value={props.select}
                  >
                    {props.pasillos.map(p => (<MenuItem key={p.id} value={p.id}>{p.codigo}</MenuItem>))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={3}>
                <FormControlLabel
                  style={{ marginTop: '8%' }}
                  control={
                    <Switch
                      size="small"
                      checked={props.estado}
                      onChange={(e) => { props.handleSelect(e, 1); }}
                      color="primary"
                      name="estado"
                    />
                  }
                  label="Estado"
                />
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => { props.handleClose(3); }} color="default">
              Cerrar
            </Button>
            <Button color="primary" type="submit">
              Modificar
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </>
  );
}
ModificarMaquina.propTypes = {
  handleClose: PropTypes.func.isRequired,
  handleUpdate: PropTypes.func.isRequired,
  handleChangeInputs: PropTypes.func.isRequired,
  handleSelect: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  pasillos: PropTypes.arrayOf(PropTypes.object),
  select: PropTypes.string.isRequired,
  estado: PropTypes.bool.isRequired,
  maquina: PropTypes.objectOf(PropTypes.string).isRequired,
};
