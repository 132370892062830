import React from 'react';
import PropTypes from 'prop-types';
import {
  Row,
  Col,
  Container,
} from 'reactstrap';

import {
  Button,
  Dialog,
  DialogTitle,
  DialogActions,
} from '@material-ui/core';

import { Link } from 'react-router-dom';

import IndicadorGeneral from '../Components/IndicadorGeneral';
import IndicadorPasillo from '../Components/indicadorPasillo';

function Pasillos(props) {
  const {
    pasillos, base, dataTejido, handleChangeTime, proyeccion, meta, notificada, time,
  } = props;

  const [open, setOpen] = React.useState(false);

  return (
    <Container className="dashboard">
      <Row>
        <Col md={12} lg={6} className="card-no-padding">
          <IndicadorGeneral
            planta={dataTejido}
            handleChangeTime={handleChangeTime}
            proyeccion={proyeccion}
            meta={meta}
            notificada={notificada}
            time={time}
          />
        </Col>
        {pasillos.length > 0 ?
          pasillos.map(pasillo => (
            <IndicadorPasillo
              key={pasillo.pasilloId}
              pasillo={pasillo}
              base={`${base}/tejido/pasillo`}
            />))
        :
        <>
          <Dialog
            open={open}
            aria-labelledby="form-dialog-title"
          >
            <DialogTitle align="center" id="alert-dialog-title">
                No hay pasillos activos para tejido
            </DialogTitle>
            <DialogActions align="center" >
              <Link to="/secciones/unidad/11/planta/1110">
                <Button
                  color="primary"
                >
                      Cerrar
                </Button>
              </Link>

            </DialogActions>
          </Dialog>
          </>
        }
      </Row>
    </Container>
  );
}

Pasillos.propTypes = {
  pasillos: PropTypes.objectOf(PropTypes.string).isRequired,
  dataTejido: PropTypes.objectOf(PropTypes.string).isRequired,
  base: PropTypes.string.isRequired,
  proyeccion: PropTypes.string.isRequired,
  meta: PropTypes.string.isRequired,
  notificada: PropTypes.string.isRequired,
  handleChangeTime: PropTypes.isRequired,
  time: PropTypes.string.isRequired,
};

export default Pasillos;
