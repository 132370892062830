import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import Secciones from './components/Secciones';
import Loader from '../../shared/components/loader/Loader';
import config from './../../appConfig.json';

class EficienciasSecciones extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      seccionesPlanta: [],
      dataTejido: [],
      loadingPla: true,
      loadingTe: true,
      time: 'Turno',
    };
  }

  componentDidMount = () => {
    const { unidad, planta } = this.props.match.params;

    // ToDo: Verificar el tiempo (día o turno)
    this.getSeccionesAsync(unidad, planta);
    this.fetchDataTejido(this.state.time);
  };
  /**
   * Obtiene todas las secciones de una planta
   * y las establece en el estado.
   */
  getSeccionesAsync = async (unidadId, plantaId) => {
    this.setState({ loadingPla: true });
    const { ApiUrl, AuthTokenKey } = config;
    const response = await fetch(`${ApiUrl}/eficiencia/planta/${plantaId}`, {
      mode: 'cors',
      method: 'GET',
      headers: {
        'Content-Type': 'application/json;charset=utf-8',
        Authorization: localStorage.getItem(AuthTokenKey),
      },
    });

    const seccionesPlanta = await response.json();
    console.log('seccionesPlanta');
    console.log(seccionesPlanta);
    this.setState({
      seccionesPlanta,
      loadingPla: false,
    });
  }
  handleChangeTime = async (horario) => {
    await this.setState({ time: horario.target.value });
    this.fetchDataTejido(this.state.time);
  }
  fetchDataTejido = async (time) => {
    this.setState({ loadingTe: true });
    try {
      const { ApiUrl, AuthTokenKey } = config;
      const requestTejido = await fetch(
        `${ApiUrl}/tejido/eficiencia/${time}`,
        {
          headers: {
            'Content-Type': 'application/json;charset=utf-8',
            Authorization: localStorage.getItem(AuthTokenKey),
          },
        },
      );
      const consolidadoTejido = await requestTejido.json();
      this.setState({ dataTejido: consolidadoTejido, loadingTe: false });
    } catch (error) {
      // ToDo: Gestionar mejor este error
      console.log('Falló');
    }
  };
  render() {
    const {
      seccionesPlanta,
      loadingPla,
      loadingTe,
      dataTejido,
    } = this.state;
    const { unidad } = this.props.match.params;
    return (
      !(loadingPla) && !(loadingTe) ?
        <Secciones
          dataTejido={dataTejido}
          key={`${seccionesPlanta.elementoId}`}
          unidad={unidad}
          planta={seccionesPlanta}
          time={this.state.time}
          handleChangeTime={this.handleChangeTime}
        />
        : <Loader loading={loadingPla} />
    );
  }
}

EficienciasSecciones.propTypes = {
  match: PropTypes.objectOf(PropTypes.shape()).isRequired,
};

export default EficienciasSecciones;
