import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import Plantas from './components/Plantas';
import Loader from '../../shared/components/loader/Loader';
import config from './../../appConfig.json';

class EficienciasPlantas extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      unidadPlantas: [],
      loading: true,
    };
  }

  componentDidMount = () => {
    this.getPlantasAsync(this.props.match.params.codigo);
  };

  /**
   * Obtiene todas las unidades de Negocio
   * y las establece en el estado.
   */
  getPlantasAsync = async (unidadId) => {
    const { ApiUrl, AuthTokenKey } = config;
    const response = await fetch(`${ApiUrl}/plantas/unidad/${unidadId}`, {
      mode: 'cors',
      method: 'GET',
      headers: {
        'Content-Type': 'application/json;charset=utf-8',
        Authorization: localStorage.getItem(AuthTokenKey),
      },
    });

    const unidadPlantas = await response.json();

    this.setState({
      unidadPlantas,
      loading: false,
    });
  }

  render() {
    const { unidadPlantas, loading } = this.state;
    return (
      !loading ?
        unidadPlantas.map((unidadPlanta, index) => (
          <Plantas
            key={unidadPlanta.plantas[index].id}
            unidad={unidadPlanta.unidad.descripcion}
            unidadId={unidadPlanta.unidad.id}
            plantas={unidadPlanta.plantas}
          />))
        : <Loader loading={loading} />
    );
  }
}

EficienciasPlantas.propTypes = {
  match: PropTypes.objectOf(PropTypes.shape()).isRequired,
};

export default EficienciasPlantas;
