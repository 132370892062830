import React from 'react';
import { Tooltip,
  Button,
  Dialog,
  DialogContent,
  DialogActions,
  DialogTitle,
  FormControlLabel,
  Checkbox,
  Grid,
  Typography,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem } from '@material-ui/core';
import { CreateIcon } from 'mdi-react';
import config from '../../../../appConfig.json';
import Context from '../../store';

export default function ModificarProceso(props) {
  const [open, setOpen] = React.useState(false);
  const [select, setSelect] = React.useState('Unidad');
  const [checked, setChecked] = React.useState(false);
  const [proceso, setProceso] = React.useState({});

  const handleChecked = (e) => {
    const array = { ...proceso };
    array.switchParo = e.target.checked;
    setProceso(array);
    setChecked(e.target.checked);
  };

  const handleSelect = (e) => {
    const array = { ...proceso };
    array[e.target.name] = e.target.value;
    setProceso(array);
    setSelect(e.target.value);
  };

  const handleChange = (e) => {
    const array = { ...proceso };
    array[e.target.id] = e.target.value;
    setProceso(array);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = (procesoEspecial) => {
    const array = { ...procesoEspecial };
    array.seccion = 4;
    array.planta = 1110;
    array.usuario = localStorage.getItem(config.CryUsername);
    props.unidades.forEach((u) => {
      if (u.descripcion === array.unidadNegocio) { array.unidadNegocio = u.id; }
    });
    setProceso(array);
    setChecked(array.switchParo);
    setSelect(array.unidadNegocio);
    setOpen(true);
  };

  return (
    <>
      <Context.Consumer>
        {
          (value) => {
            const handleSave = async () => {
              const p = { ...value.proceso };
              p.descripcion = proceso.descripcion;
              p.switchParo = proceso.switchParo;
              props.unidades.forEach((u) => {
                if (u.id === proceso.unidadNegocio) {
                   p.unidadNegocio = u.descripcion;
                }
              });
              value.activateProceso(p);
              const request = await fetch(`${config.ApiUrl}/procesosEspeciales/editar/${proceso.id}`, {
                mode: 'cors',
                method: 'POST',
                body: JSON.stringify(proceso),
                headers: {
                  'Content-Type': 'application/json',
                  Authorization: localStorage.getItem(config.AuthTokenKey),
                  Accept: 'application/json',
                },
              });
              const response = await request.json();
              props.fetch();
              setOpen(false);
            };
            if (value.proceso === null) {
              return (
                <Tooltip title="Modifica un proceso especial" >
                  <Button
                    variant="contained"
                    style={{ backgroundColor: 'rgb(18, 24, 88)', width: '50%' }}
                  >
                    <CreateIcon style={{ color: 'white' }} />
                    <h5 style={{ color: 'white' }}><strong>MODIFICAR</strong></h5>
                  </Button>
                </Tooltip>);
            }
            return (
              <>
                <Tooltip title="Modifica un proceso especial" >
                  <Button
                    onClick={() => { handleOpen(value.proceso); }}
                    variant="contained"
                    style={{ backgroundColor: 'rgb(18, 24, 88)', width: '50%' }}
                  >
                    <CreateIcon style={{ color: 'white' }} />
                    <h5 style={{ color: 'white' }}><strong>MODIFICAR</strong></h5>
                  </Button>
                </Tooltip>
                <Dialog
                  open={open}
                  onClose={handleClose}
                  scroll="paper"
                  aria-labelledby="scroll-dialog-title"
                  maxWidth="sm"
                >
                  <form
                    onSubmit={(e) => {
                    e.preventDefault();
                    handleSave();
                    }}
                  >
                    <DialogTitle>
                      <Typography gutterBottom variant="h6" align="center">
                        MODIFICAR PROCESO ESPECIAL
                      </Typography>
                    </DialogTitle>
                    <DialogContent dividers>
                      <Grid container>
                        <Grid item xs={4}>
                          <TextField
                            readOnly
                            label="Código"
                            id="codigo"
                            style={{ marginRight: '10%' }}
                            value={proceso.codigo}
                          />
                        </Grid>
                        <Grid item xs={6} >
                          <TextField
                            required
                            label="Descripción"
                            id="descripcion"
                            fullWidth
                            defaultValue={proceso.descripcion}
                            onChange={handleChange}
                          />
                        </Grid>
                        <Grid item xs={2}>
                          <FormControlLabel
                            control={<Checkbox
                              color="primary"
                              checked={checked}
                              onChange={handleChecked}
                              name="checkedA"
                            />}
                            label="Paro"
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <FormControl style={{ minWidth: '242px' }} required>
                            <InputLabel id="unidadNegocio">Unidad de Negocio</InputLabel>
                            <Select
                              labelId="unidadNegocio"
                              name="unidadNegocio"
                              onChange={handleSelect}
                              value={select}
                            >
                              {props.unidades.map(uni => (<MenuItem value={uni.id}>{uni.descripcion}</MenuItem>))}
                            </Select>
                          </FormControl>
                        </Grid>
                      </Grid>
                    </DialogContent>
                    <DialogActions>
                      <Button onClick={handleClose} color="default">
                        Cerrar
                      </Button>
                      <Button color="primary" type="submit" >
                        Modificar
                      </Button>
                    </DialogActions>
                  </form>
                </Dialog>
              </>
            );
          }
        }
      </Context.Consumer>
    </>
  );
}
