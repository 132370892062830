import config from './../../appConfig.json';

function perteneceRolIngenieria() {
  const valorRoles = localStorage.getItem('roles');
  if (valorRoles) {
    const roles = valorRoles.split(',');
    return roles.some(x => x.toUpperCase() === config.RolIngenieria.toUpperCase());
  }
  return false;
}
async function obtener(url, configReq) {
  let datos = null;
  try {
    const respuesta = await
    fetch(
      url,
      configReq,
    );
    if (respuesta.status !== 200) {
      datos = await respuesta.text();
      console.error(datos);
    } else {
      datos = await respuesta.json();
    }
  } catch (e) {
    if (e.name !== 'AbortError') {
      throw e;
    }
  }
  return datos;
}
async function ejecutarConsulta(url, method, parametros, signal) {
  const resultado = { error: false, datos: null };
  const peticion = {
    signal,
    mode: 'cors',
    method,
    headers: {
      'Content-Type': 'application/json;charset=utf-8',
      Authorization: localStorage.getItem(config.AuthTokenKey),
    },
  };
  if (parametros != null) {
    peticion.body = JSON.stringify(parametros);
  }
  try {
    const respuesta = await
    fetch(
      url,
      peticion,
    );
    if (respuesta.status === 200) {
      resultado.error = false;
      resultado.datos = await respuesta.json();
    } else {
      resultado.error = true;
      resultado.datos = await respuesta.text();
      console.error(resultado.datos);
    }
  } catch (e) {
    resultado.error = true;
    resultado.datos = e.message;
    console.error(resultado.datos);
  }
  return resultado;
}
async function ejecutarActualizacion(url, method, peticion, signal) {
  let result = null;
  try {
    const respuesta = await
    fetch(
      url,
      {
        signal,
        mode: 'cors',
        method,
        body: JSON.stringify(peticion),
        headers: {
          'Content-Type': 'application/json;charset=utf-8',
          Authorization: localStorage.getItem(config.AuthTokenKey),
        },
      },
    );
    if (respuesta.status === 200) {
      const datos = await respuesta.json();
      result = { error: false, datos };
    } else {
      const datos = await respuesta.json();
      result = { error: true, datos };
      console.error(datos);
    }
  } catch (ex) {
    result = { error: true, datos: null };
  }
  return result;
}
async function descargarArchivo(url, method, peticion, nombrePredeterminado, signal) {
  let body = null;
  if (peticion != null) {
    body = JSON.stringify(peticion);
  }
  const respuesta = await
  fetch(
    url,
    {
      signal,
      mode: 'cors',
      method,
      body,
      headers: {
        'Content-Type': 'application/json;charset=utf-8',
        Authorization: localStorage.getItem(config.AuthTokenKey),
      },
    },
  );
  if (respuesta.status === 200) {
    const blob = await respuesta.blob();
    const tempUrl = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = tempUrl;
    a.target = '_blank';
    a.download = extraerNombreArchivo(respuesta) || nombrePredeterminado;
    a.click();
    a.remove();
  } else {
    throw await respuesta.text();
  }
}
function extraerNombreArchivo(respuesta) {
  let nombre = '';
  const disposition = respuesta.headers.get('Content-Disposition');
  if (disposition && disposition.indexOf('attachment') !== -1) {
    const exp = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
    const matches = exp.exec(disposition);
    if (matches != null && matches[1]) {
      nombre = matches[1].replace(/['"]/g, '');
    }
  }
  return nombre;
}
async function obtenerTurnos(signal) {
  return obtener(
    `${config.ApiUrl}/turnos/`,
    {
      signal,
      mode: 'cors',
      method: 'GET',
      headers: {
        'Content-Type': 'application/json;charset=utf-8',
        Authorization: localStorage.getItem(config.AuthTokenKey),
      },
    },
  );
}
async function obtenerTurnosPrincipales(signal) {
  const turnos = await obtener(
    `${config.ApiUrl}/turnos/principales`,
    {
      signal,
      mode: 'cors',
      method: 'GET',
      headers: {
        'Content-Type': 'application/json;charset=utf-8',
        Authorization: localStorage.getItem(config.AuthTokenKey),
      },
    },
  );
  return turnos.sort((a, b) => a.horaInicial.localeCompare(b.horaInicial));
}
async function obtenerSeccionesUnidadNegocioCalcetines(signal) {
  return obtener(
    `${config.ApiUrl}/secciones/por-unidad-negocio/${config.unidadNegocioCalcetines}`,
    {
      signal,
      mode: 'cors',
      method: 'GET',
      headers: {
        'Content-Type': 'application/json;charset=utf-8',
        Authorization: localStorage.getItem(config.AuthTokenKey),
      },
    },
  );
}
async function obtenerSubsecciones(signal) {
  return obtener(
    `${config.ApiUrl}/secciones/subsecciones`,
    {
      signal,
      mode: 'cors',
      method: 'GET',
      headers: {
        'Content-Type': 'application/json;charset=utf-8',
        Authorization: localStorage.getItem(config.AuthTokenKey),
      },
    },
  );
}
async function obtenerSubseccionesPorSeccion(seccionId, signal) {
  if (!seccionId) {
    return [];
  }
  return obtener(
    `${config.ApiUrl}/secciones/${seccionId}/subsecciones`,
    {
      signal,
      mode: 'cors',
      method: 'GET',
      headers: {
        'Content-Type': 'application/json;charset=utf-8',
        Authorization: localStorage.getItem(config.AuthTokenKey),
      },
    },
  );
}
async function obtenerSubseccionesPorSecciones(secciones, signal) {
  return obtener(
    `${config.ApiUrl}/secciones/subsecciones-por-secciones`,
    {
      signal,
      mode: 'cors',
      method: 'POST',
      body: JSON.stringify(secciones),
      headers: {
        'Content-Type': 'application/json;charset=utf-8',
        Authorization: localStorage.getItem(config.AuthTokenKey),
      },
    },
  );
}
async function obtenerGruposTallas(signal) {
  return obtener(
    `${config.ApiUrl}/tallas/grupos-talla`,
    {
      signal,
      mode: 'cors',
      method: 'GET',
      headers: {
        'Content-Type': 'application/json;charset=utf-8',
        Authorization: localStorage.getItem(config.AuthTokenKey),
      },
    },
  );
}
async function obtenerTallas(signal) {
  return obtener(
    `${config.ApiUrl}/tallas`,
    {
      signal,
      mode: 'cors',
      method: 'GET',
      headers: {
        'Content-Type': 'application/json;charset=utf-8',
        Authorization: localStorage.getItem(config.AuthTokenKey),
      },
    },
  );
}
async function obtenerTallasPorGrupo(grupoId, signal) {
  if (!grupoId) {
    return [];
  }
  return obtener(
    `${config.ApiUrl}/tallas/grupo-talla/${grupoId}/tallas`,
    {
      signal,
      mode: 'cors',
      method: 'GET',
      headers: {
        'Content-Type': 'application/json;charset=utf-8',
        Authorization: localStorage.getItem(config.AuthTokenKey),
      },
    },
  );
}
async function obtenerOperaciones(signal) {
  return obtener(
    `${config.ApiUrl}/operaciones`,
    {
      signal,
      mode: 'cors',
      method: 'GET',
      headers: {
        'Content-Type': 'application/json;charset=utf-8',
        Authorization: localStorage.getItem(config.AuthTokenKey),
      },
    },
  );
}
async function obtenerOperacionesPorSubseccion(subseccionId, signal) {
  return obtener(
    `${config.ApiUrl}/subseccion/${subseccionId}/operaciones`,
    {
      signal,
      mode: 'cors',
      method: 'GET',
      headers: {
        'Content-Type': 'application/json;charset=utf-8',
        Authorization: localStorage.getItem(config.AuthTokenKey),
      },
    },
  );
}
async function obtenerOperacionesDetalladas(signal) {
  return obtener(
    `${config.ApiUrl}/operaciones/detalladas`,
    {
      signal,
      mode: 'cors',
      method: 'GET',
      headers: {
        'Content-Type': 'application/json;charset=utf-8',
        Authorization: localStorage.getItem(config.AuthTokenKey),
      },
    },
  );
}
async function obtenerOperacionesDetalladasPorOperacion(operacionId, signal) {
  return obtener(
    `${config.ApiUrl}/operaciones/${operacionId}/operaciones-detalladas`,
    {
      signal,
      mode: 'cors',
      method: 'GET',
      headers: {
        'Content-Type': 'application/json;charset=utf-8',
        Authorization: localStorage.getItem(config.AuthTokenKey),
      },
    },
  );
}
async function obtenerUnidadesNegocio(signal) {
  return obtener(
    `${config.ApiUrl}/unidadesnegocio`,
    {
      signal,
      mode: 'cors',
      method: 'GET',
      headers: {
        'Content-Type': 'application/json;charset=utf-8',
        Authorization: localStorage.getItem(config.AuthTokenKey),
      },
    },
  );
}
async function obtenerUbicaciones(signal) {
  return obtener(
    `${config.ApiUrl}/ubicaciones`,
    {
      signal,
      mode: 'cors',
      method: 'GET',
      headers: {
        'Content-Type': 'application/json;charset=utf-8',
        Authorization: localStorage.getItem(config.AuthTokenKey),
      },
    },
  );
}
async function obtenerUbicacionesPorSubseccion(subseccionId, signal) {
  if (!subseccionId) {
    return [];
  }
  return obtener(
    `${config.ApiUrl}/ubicaciones/subseccion/${subseccionId}`,
    {
      signal,
      mode: 'cors',
      method: 'GET',
      headers: {
        'Content-Type': 'application/json;charset=utf-8',
        Authorization: localStorage.getItem(config.AuthTokenKey),
      },
    },
  );
}
async function obtenerUbicacionesPorSubsecciones(subsecciones, signal) {
  return obtener(
    `${config.ApiUrl}/subsecciones/ubicaciones-por-subsecciones`,
    {
      signal,
      mode: 'cors',
      method: 'POST',
      body: JSON.stringify(subsecciones),
      headers: {
        'Content-Type': 'application/json;charset=utf-8',
        Authorization: localStorage.getItem(config.AuthTokenKey),
      },
    },
  );
}
async function obtenerProgramas(signal) {
  return obtener(
    `${config.ApiUrl}/programas`,
    {
      signal,
      mode: 'cors',
      method: 'GET',
      headers: {
        'Content-Type': 'application/json;charset=utf-8',
        Authorization: localStorage.getItem(config.AuthTokenKey),
      },
    },
  );
}
async function obtenerProcesosEspeciales(signal) {
  return obtener(
    `${config.ApiUrl}/procesosespeciales`,
    {
      signal,
      mode: 'cors',
      method: 'GET',
      headers: {
        'Content-Type': 'application/json;charset=utf-8',
        Authorization: localStorage.getItem(config.AuthTokenKey),
      },
    },
  );
}
async function obtenerCategorias(signal) {
  return obtener(
    `${config.ApiUrl}/categorias`,
    {
      signal,
      mode: 'cors',
      method: 'GET',
      headers: {
        'Content-Type': 'application/json;charset=utf-8',
        Authorization: localStorage.getItem(config.AuthTokenKey),
      },
    },
  );
}
async function obtenerPasillos(signal) {
  return obtener(
    `${config.ApiUrl}/maquinas/pasillos-activos`,
    {
      signal,
      mode: 'cors',
      method: 'GET',
      headers: {
        'Content-Type': 'application/json;charset=utf-8',
        Authorization: localStorage.getItem(config.AuthTokenKey),
      },
    },
  );
}
async function obtenerInfoOperario(cedula, signal) {
  return ejecutarConsulta(
    `${config.ApiUrl}/empleados/${cedula}`,
    'GET',
    null,
    signal,
  );
}
function mostrarValidacion(errores) {
  let mensaje = '';
  if (errores) {
    for (let i = 0; i < errores.length; i += 1) {
      mensaje += errores[i];
    }
  }
  return mensaje;
}
function separarGruposMiles(x) {
  let sp = separadorMiles();
  if (sp === '.') {
    sp = `\\${sp}`;
  }
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, sp);
}
function separadorMiles() {
  return config.separadorMiles || separadorMilesSistema();
}
function separadorMilesSistema() {
  return (10000).toLocaleString().substr(2, 1);
}
function separadorDecimal() {
  return config.separadorDecimal || separadorDecimalSistema();
}
function separadorDecimalSistema() {
  return (1.1).toLocaleString().substr(1, 1);
}
function esDecimalValido(valor) {
  let sp = separadorDecimal();
  if (sp === '.') {
    sp = `\\${sp}`;
  }
  const regExp = new RegExp(`^[-|\\d]+${sp}{0,1}\\d*$`);
  return regExp.test(valor);
}
function convertirDecimal(s) {
  let xs = s;
  if (typeof s === 'string') {
    const sp = separadorDecimal();
    xs = s.replace(sp, '.');
  }
  return parseFloat(xs);
}
function esEnteroValido(valor) {
  const regExp = new RegExp('^\\d+$');
  return regExp.test(valor);
}
function redondear(p, r) {
  const x = Math.pow(10, r);
  return Math.round(p * x) / x;
}
function truncarFecha(f) {
  if (f != null) {
    const x = new Date(f.getTime());
    x.setHours(0, 0, 0, 0);
    return x;
  }
  return null;
}
export {
  perteneceRolIngenieria,
  obtener,
  ejecutarConsulta,
  ejecutarActualizacion,
  descargarArchivo,
  obtenerTurnos,
  obtenerTurnosPrincipales,
  obtenerSeccionesUnidadNegocioCalcetines as obtenerSecciones,
  obtenerSubsecciones,
  obtenerSubseccionesPorSeccion,
  obtenerSubseccionesPorSecciones,
  obtenerGruposTallas,
  obtenerTallas,
  obtenerTallasPorGrupo,
  obtenerOperaciones,
  obtenerOperacionesPorSubseccion,
  obtenerOperacionesDetalladas,
  obtenerOperacionesDetalladasPorOperacion,
  obtenerUnidadesNegocio,
  obtenerUbicaciones,
  obtenerUbicacionesPorSubseccion,
  obtenerProgramas,
  obtenerProcesosEspeciales,
  obtenerCategorias,
  obtenerPasillos,
  obtenerInfoOperario,
  mostrarValidacion,
  obtenerUbicacionesPorSubsecciones,
  esEnteroValido,
  esDecimalValido,
  convertirDecimal,
  separarGruposMiles,
  redondear,
  truncarFecha,
};
