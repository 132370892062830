import React from 'react';
import { Grid,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableBody,
  TableCell,
  Divider, makeStyles } from '@material-ui/core';

import config from '../../../../appConfig.json';

import Context from '../../../Operaciones/store';
import Filtro from '../../Filtro';
import DetallesSubseccion from './DetalleSubseccion';
import CrearSubseccion from './CrearSubseccion';
import ModificarSubseccion from './ModificarSubseccion';
import EliminarSubseccion from './EliminarSubseccion';
import { perteneceRolIngenieria } from '../../../Comunes/datosComunes';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.paper,
    position: 'relative',
    overflowY: 'auto',
    height: `${window.screen.height - 320}px`,
    width: '100%',
  },
  color: {
    color: '#717575',
  },
  textoColor: {
    '.MuiTypography-body1': {
      backgroundColor: 'aquamarine',
    },
  },
}));

export default function Subsecciones() {
  const classes = useStyles();
  return (
    <>
      <Context.Consumer>
        {
          (value) => {
            const handleChange = (e) => {
              const result = value.detalladas
              .filter(s => (
                s.descripcion.toLowerCase().indexOf(e.target.value.toLowerCase()) !== -1 && s
              ));
              if (result.length !== 0 && e.target.value !== '') { value.activateDet(result); }
              if (result.length === 0 && e.target.value === '') { value.activateDet(value.detalladasOrigin); }
              if (result.length !== 0 && e.target.value === '') { value.activateDet(value.detalladasOrigin); }
              if (result.length === 0 && e.target.value !== '') { value.activateDet(value.detalladasOrigin); }
             };

             const fetchData = async () => {
              const requestSubsecciones =
              await fetch(
                `${config.ApiUrl}/Secciones/${value.operacion.id}/subsecciones`,
                {
                  headers: {
                    'Content-Type': 'application/json',
                    Authorization: localStorage.getItem(config.AuthTokenKey),
                  },
                },
              );
              const responseSubsecciones = await requestSubsecciones.json();
              value.activateDet(responseSubsecciones);
              value.activateDetOrigin(responseSubsecciones);
             };

            return (
              <Grid container style={{ marginTop: '10%' }}>
                <Grid item xs={12}>
                  <div style={{ width: '100%' }} >
                    <Filtro onChange={handleChange} label="Subsección" helperText="Buscar subsecciones" margen="2%" />
                  </div>
                </Grid>
                <Grid item xs={12}>
                  <Paper style={{ marginRight: '1%', marginLeft: '1%' }} className={classes.root} >
                    <Divider />
                    <Table size="small">
                      <TableHead>
                        <TableRow>
                          <TableCell size="small">Código</TableCell>
                          <TableCell>Descripción</TableCell>
                          <TableCell>Fecha de creación</TableCell>
                          <TableCell>Fecha de modificación</TableCell>
                          {
                            perteneceRolIngenieria() &&
                            <TableCell>
                              Nueva
                              <CrearSubseccion fetch={fetchData} />
                            </TableCell>
                          }
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {value.detalladas.map(d => (
                          <TableRow key={d.id}>
                            <TableCell>
                              <DetallesSubseccion detalle={d} />
                              {d.codigo}
                            </TableCell>
                            <TableCell>
                              {d.descripcion}
                            </TableCell>
                            <TableCell>
                              {d.fechaCreacion.slice(0, 10)}
                            </TableCell>
                            <TableCell>
                              {d.fechaModificacion.slice(0, 10)}
                            </TableCell>
                            {
                              perteneceRolIngenieria() &&
                              <TableCell>
                                <ModificarSubseccion subseccion={d} fetch={fetchData} />
                                <EliminarSubseccion subseccion={d} fetch={fetchData} />
                              </TableCell>
                            }
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </Paper>
                </Grid>
              </Grid>
            );
          }
        }
      </Context.Consumer>
    </>
  );
}
