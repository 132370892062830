import React, { Component } from 'react';

import config from './../../appConfig.json';
import Loader from '../../shared/components/loader/Loader';
import History from '../../shared/components/history/History';

class Logout extends Component {
  componentDidMount = () => {
    const { AuthTokenKey } = config;
    localStorage.setItem(AuthTokenKey, '');
    localStorage.clear();
    History.push('/login');
  }

  render() {
    return (
      <Loader loading />
    );
  }
}

export default Logout;
