import React from 'react';
import PropTypes from 'prop-types';
import { Button,
  Dialog,
  DialogContent,
  DialogActions,
  DialogTitle,
  Typography,
  Grid,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem } from '@material-ui/core';

export default function ModificarTalla(props) {
  const { talla } = props;
  return (
    <>
      <Dialog
        open={props.open}
        onClose={() => { props.handleClose(3); }}
        scroll="paper"
        aria-labelledby="scroll-dialog-title"
        maxWidth="sm"
      >
        <form
          onSubmit={(e) => {
          e.preventDefault();
          props.handleUpdate();
            }}
        >
          <DialogTitle>
            <Typography variant="h6" align="center">
              MODIFICAR TALLA
            </Typography>
          </DialogTitle>
          <DialogContent dividers>
            <Grid container>
              <Grid item xs={6}>
                <TextField
                  readOnly
                  label="Código"
                  id="codigo"
                  type="number"
                  value={talla.codigo}
                />
              </Grid>
              <Grid item xs={9} >
                <TextField
                  required
                  label="Descripción"
                  id="descripcion"
                  fullWidth
                  defaultValue={talla.descripcion}
                  onChange={props.handleChangeInputs}
                />
              </Grid>
              <Grid item xs={6}>
                <FormControl style={{ minWidth: '242px', margin: '2%' }} required>
                  <InputLabel id="grupoTalla">Grupo talla</InputLabel>
                  <Select
                    labelId="grupoTalla"
                    name="grupoTalla"
                    onChange={(e) => { props.handleSelect(e, 1); }}
                    value={props.select}
                  >
                    {props.grupos.map(g => (<MenuItem key={g.id} value={g.id}>{g.descripcion}</MenuItem>))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={6}>
                <FormControl style={{ minWidth: '242px', margin: '2%' }} required>
                  <InputLabel id="unidadNegocio">Unidad de Negocio</InputLabel>
                  <Select
                    labelId="unidadNegocio"
                    name="unidadNegocio"
                    onChange={(e) => { props.handleSelect(e, 2); }}
                    value={props.selectU}
                  >
                    {props.unidades.map(uni => (<MenuItem key={uni.id} value={uni.id}>{uni.descripcion}</MenuItem>))}
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => { props.handleClose(3); }} color="default">
              Cerrar
            </Button>
            <Button color="primary" type="submit">
              Modificar
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </>
  );
}
ModificarTalla.propTypes = {
  handleClose: PropTypes.func.isRequired,
  handleUpdate: PropTypes.func.isRequired,
  handleChangeInputs: PropTypes.func.isRequired,
  handleSelect: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  unidades: PropTypes.arrayOf(PropTypes.object),
  grupos: PropTypes.arrayOf(PropTypes.object),
  select: PropTypes.string.isRequired,
  selectU: PropTypes.string.isRequired,
  talla: PropTypes.objectOf(PropTypes.string).isRequired,
};
