/* eslint-disable import/prefer-default-export */
import React from 'react';
import { Paper, Table, TableHead, TableRow, TableCell, TableBody, Checkbox, makeStyles } from '@material-ui/core';
import { CircleOutlineIcon, CheckCircleIcon } from 'mdi-react';

import Context from '../Context';
import { Proceso } from './Proceso';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    marginTop: theme.spacing(3),
    overflowX: 'auto',
    height: '100%',
    maxHeight: 'calc(100% - 74px)',
    overflowY: 'auto',
  },
}));

export const ProcesosSeleccionados = () => {
  const classes = useStyles();
  return (
    <Context.Consumer>
      {
      ({
        procesos, bebesCheck, niniosCheck, adultosCheck,
        activateBebes, activateNinios, activateAdultos,
      }) => (
        <Paper className={classes.root}>
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell>OPERACION</TableCell>
                <TableCell>BEBÉS
                  <Checkbox
                    icon={<CircleOutlineIcon />}
                    checkedIcon={<CheckCircleIcon />}
                    color="primary"
                    checked={bebesCheck}
                    onChange={e => activateBebes(e.target.checked)}
                  />
                </TableCell>
                <TableCell>NIÑOS
                  <Checkbox
                    icon={<CircleOutlineIcon />}
                    checkedIcon={<CheckCircleIcon />}
                    color="primary"
                    checked={niniosCheck}
                    onChange={e => activateNinios(e.target.checked)}
                  />
                </TableCell>
                <TableCell>ADULTOS
                  <Checkbox
                    icon={<CircleOutlineIcon />}
                    checkedIcon={<CheckCircleIcon />}
                    color="primary"
                    checked={adultosCheck}
                    onChange={e => activateAdultos(e.target.checked)}
                  />
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {
                procesos.map(process => (
                  <Proceso proc={process} />
                ))
              }
            </TableBody>
          </Table>
        </Paper>
        )
    }
    </Context.Consumer>
  );
};
