import React from 'react';
import PropTypes from 'prop-types';
import { Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Typography } from '@material-ui/core';

class TablaSegundas extends React.Component {
  render() {
    const { data } = this.props;
    return (
      <>
        <div style={{ textAlign: 'center' }} >
          <Table size="small" aria-label="a dense table" style={{ margin: '0 auto' }} >
            <TableHead>
              <TableRow>
                <TableCell >Código subsección</TableCell>
                <TableCell >Descripción subsección</TableCell>
                <TableCell >Fecha evento</TableCell>
                <TableCell >Ubicación</TableCell>
                <TableCell >Cédula operario</TableCell>
                <TableCell >Nombre completo</TableCell>
                <TableCell >Código daño</TableCell>
                <TableCell >Descripción daño</TableCell>
                <TableCell >Unidades de segundas</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data.map(ev => (
                <TableRow >
                  <TableCell style={{ fontSize: 'small' }}>
                    {ev.codigoSubseccion}
                  </TableCell>
                  <TableCell style={{ fontSize: 'small' }} >{ev.descripcionSubseccion}</TableCell>
                  <TableCell style={{ fontSize: 'small' }}>{ev.fecha.slice(0, 10)}</TableCell>
                  <TableCell style={{ fontSize: 'small' }}>{ev.descripcionUbicacion}</TableCell>
                  <TableCell style={{ fontSize: 'small' }}>{ev.cedula}</TableCell>
                  <TableCell style={{ fontSize: 'small' }}>{ev.nombreOperario}</TableCell>
                  <TableCell style={{ fontSize: 'small' }}>{ev.codigoDano}</TableCell>
                  <TableCell style={{ fontSize: 'small' }}>{ev.descripcionDano}</TableCell>
                  <TableCell align="center" style={{ fontSize: 'small' }}>
                    <strong>{ev.segundas}</strong>
                  </TableCell>
                </TableRow>
                ))}
            </TableBody>
          </Table>
        </div>
      </>
    );
  }
}
export default TablaSegundas;
TablaSegundas.propTypes = {
  data: PropTypes.string.isRequired,
};
