import React from 'react';
import PropTypes from 'prop-types';
import { Paper,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Typography } from '@material-ui/core';
import DialogSegundas from './DialogSegundas';

function TablaEventos(props) {
  const { eventos } = props;
  return (
    <Paper >
      <Typography
        gutterBottom
        variant="button"
        style={{
          lineHeight: '287%',
          marginLeft: '2%',
          }}
      >
        EVENTOS
      </Typography>
      <div style={{ textAlign: 'center', minHeight: '400px' }} >
        <Table size="small" aria-label="a dense table" style={{ margin: '0 auto', width: '97%' }} >
          <TableHead>
            <TableRow>
              <TableCell >Código subsección</TableCell>
              <TableCell >Descripción subsección</TableCell>
              <TableCell >Fecha evento</TableCell>
              <TableCell >Turno</TableCell>
              <TableCell >Código operación</TableCell>
              <TableCell >Descripción operación</TableCell>
              <TableCell >Ubicación</TableCell>
              <TableCell >Cédula operario</TableCell>
              <TableCell >Nombre completo</TableCell>
              <TableCell >Unidades primeras</TableCell>
              <TableCell >
                U.Segundas
                <DialogSegundas />
              </TableCell>
              {/* <TableCell >Eficiencia</TableCell> */}
            </TableRow>
          </TableHead>
          <TableBody>
            {eventos.map(ev => (
              <TableRow >
                <TableCell style={{ fontSize: 'small' }}>
                  {ev.codigoSubseccion}
                </TableCell>
                <TableCell style={{ fontSize: 'small' }} >{ev.descripcionSubseccion}</TableCell>
                <TableCell style={{ fontSize: 'small' }}>{ev.fecha.slice(0, 10)}</TableCell>
                <TableCell style={{ fontSize: 'small' }}>{ev.turno}</TableCell>
                <TableCell style={{ fontSize: 'small' }}>{ev.codigoOperacion}</TableCell>
                <TableCell style={{ fontSize: 'small' }}>{ev.descripcionOperacion}</TableCell>
                <TableCell style={{ fontSize: 'small' }}>{ev.descripcionUbicacion}</TableCell>
                <TableCell style={{ fontSize: 'small' }}>{ev.cedula}</TableCell>
                <TableCell style={{ fontSize: 'small' }}>{ev.nombreOperario}</TableCell>
                <TableCell align="center"style={{ fontSize: 'small' }}>{ev.primeras}</TableCell>
                <TableCell align="center" style={{ fontSize: 'small' }}>
                  <strong>{ev.segundas}</strong>
                </TableCell>
              </TableRow>
              ))}
          </TableBody>
        </Table>
      </div>
    </Paper>
  );
}
export default TablaEventos;
TablaEventos.propTypes = {
  eventos: PropTypes.arrayOf(PropTypes.object).isRequired,
};
