import React, { PureComponent, Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import EficienciasUnidades from '../EficienciasUnidades/EficienciasUnidades';
// import { NotificationProps } from '../../shared/prop-types/ReducerProps';
// import { SetRoleEventListeners } from '../../shared/funtions/Eventos/SetRoleEventListeners';
import { restablecerUbicacion } from '../../redux/actions/ubicacionActions';

class Home extends PureComponent {
  static propTypes = {
    // connection: NotificationProps.isRequired,
    dispatch: PropTypes.func.isRequired,
  }

  componentWillMount = () => {
    // this.listenNotifications();
    this.restablecerUbicacion();
  }

  listenNotifications = () => {
    // const { connection } = this.props;

    // SetRoleEventListeners('INGENIERIA', connection.hubConnection);
  }
  restablecerUbicacion = () => {
    this.props.dispatch(restablecerUbicacion());
  };
  render() {
    return (
      <EficienciasUnidades />
    );
  }
}

export default connect(state => ({
  connection: state.notification,
}))(Home);

