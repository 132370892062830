import React from 'react';
import { Grid, Container } from '@material-ui/core';
import { createMuiTheme } from '@material-ui/core/styles';
import { ThemeProvider } from '@material-ui/styles';
import ContextIncentivos from './ContextoIncentivos';
import FiltroIncentivos from './Componentes/FiltroIncentivos';
import ResultadoCalculo from './Componentes/ResultadoCalculo';
import CorreccionIncentivos from './Componentes/CorreccionIncentivo';
import Ajuste from './Componentes/Ajuste';
import Error from './Componentes/Error';

const theme = createMuiTheme({
  palette: {
    primary: { main: '#263238' },
    secondary: { main: '#1a237e' },
  },
});

const Incentivos = () => (
  <ContextIncentivos.Provider>
    <ThemeProvider theme={theme}>
      <Container style={{ marginTop: '16px' }}>
        <Grid container spacing={1}>
          <Grid item md={12} direction="column">
            <Grid item>
              <Error />
            </Grid>
            <CorreccionIncentivos />
            <Ajuste />
            <Grid md={12}>
              <FiltroIncentivos />
            </Grid>
            <Grid md={12}>
              <ResultadoCalculo />
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </ThemeProvider>
  </ContextIncentivos.Provider>
);

export default Incentivos;
