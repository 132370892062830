import React from 'react';
import PropTypes from 'prop-types';
import { Card, CardBody, Col } from 'reactstrap';
import { Link } from 'react-router-dom';
import Eficiencia from '../../../shared/components/eficiencia/Eficiencia';
import IndicadoresNumericosEstandar from '../../../shared/components/indicadoresNumericos/IndicadoresNumericosEstandar';
import IndicadoresNumericosTejido from '../../../shared/components/indicadoresNumericos/IndicadoresNumericosTejido';

function IndicadorEficienciaSeccion(props) {
  let link = `${props.base}/${props.codigo}`;
  if (props.codigo === '1') {
    link = '/tejido';
  }
  return (
    <Col md={6} lg={3} className="card-no-padding">
      <Link to={link}>
        <Card>
          <CardBody className="d-flex flex-column justify-content-between">
            <div className="card__title">
              <h5 className="bold-text text-center">{props.seccion}</h5>
            </div>
            {
              // ToDo Parametrizar
              props.codigo === '1' ?
                <Eficiencia
                  modo="medium"
                  porcentaje={props.dataTejido.encabezado.porcentajeEficienciaLote * 100}
                  cx={85}
                  cy={85}
                  innerRadius={85}
                  outerRadius={90}
                />
                :
                <Eficiencia
                  modo="medium"
                  porcentaje={props.eficiencia}
                  cx={85}
                  cy={85}
                  innerRadius={85}
                  outerRadius={90}
                />
            }
            {
              // ToDo: Parametrizar el código
              props.codigo === '1' ?
                <IndicadoresNumericosTejido
                  proyeccion={props.dataTejido.proyeccion}
                  docenasPLC={props.dataTejido.encabezado.docenasPLC}
                  porcentajeEficienciaOperativa={props.dataTejido.encabezado.porcentajeEficienciaOperativa * 100}
                />
                : <IndicadoresNumericosEstandar
                  tiempoParo={props.tiempoParo}
                  real={props.real}
                />
            }
          </CardBody>
        </Card>
      </Link>
    </Col>
  );
}

IndicadorEficienciaSeccion.propTypes = {
  seccion: PropTypes.string.isRequired,
  codigo: PropTypes.number.isRequired,
  base: PropTypes.string.isRequired,
  eficiencia: PropTypes.number.isRequired,
  tiempoParo: PropTypes.number.isRequired,
  real: PropTypes.number.isRequired,
  dataTejido: PropTypes.objectOf(PropTypes.string).isRequired,
};

export default IndicadorEficienciaSeccion;
