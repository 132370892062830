import React from 'react';
import {
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  IconButton,
  TablePagination,
  withStyles,
  createMuiTheme,
  MuiThemeProvider,
  Paper,
  Grid,
  Container,
  Typography,
  TableContainer,
} from '@material-ui/core';
import 'react-table/react-table.css';
import FiltroReporteBasicoTejido from './FiltroReporteBasicoTejido';
import { consultarTejidoPaginado, exportarInformeBasicoTejidoExcel } from './datosInformBasicoTejido';
import { redondear } from '../../Comunes/datosComunes';
import Alerta from '../../Comunes/Alerta';

const StyledTableCell = withStyles(theme => ({
  head: {
    backgroundColor: '#F5F5F5',
    color: '#263238',
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);


const fd = new Intl.DateTimeFormat();
const theme = createMuiTheme({
  palette: {
    primary: { main: '#263238' },
    secondary: { main: '#1a237e' },
  },
});

class ReporteBasicoTejido extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      tejido: [],
      cargando: false,
      exportando: false,
      paginas: 0,
      campoOrden: 'fecha',
      ordenAscendente: true,
      filtro: null,
      error: false,
      page: 0,
      rowsPerPage: 10,
    };
    this.filtroConsultaEstablecido = this.filtroConsultaEstablecido.bind(this);
    this.filtroExportacionEstablecido = this.filtroExportacionEstablecido.bind(this);
    this.ejecutarConsulta = this.ejecutarConsulta.bind(this);
    this.obtenerDatos = this.obtenerDatos.bind(this);
    this.resetearError = this.resetearError.bind(this);
  }
  abortController = new AbortController();
  async filtroConsultaEstablecido(filtro) {
    await this.setState({ filtro, tejido: [], error: false });
    await this.ejecutarConsulta({
      ...this.state.filtro,
      pagina: 0,
      campoOrdenacion: this.state.campoOrden,
      ordenAscendente: this.state.ordenAscendente,
      cantidadRegistros: this.state.registrosPagina,
    });
  }
  async filtroExportacionEstablecido(filtro) {
    await this.setState({ filtro, exportando: true, error: false });
    try {
      await exportarInformeBasicoTejidoExcel(filtro, this.abortController.signal);
    } catch (e) {
      await this.setState({ error: true });
    } finally {
      await this.setState({ exportando: false });
    }
  }
  async resetearError() {
    await this.setState({ error: false });
  }
  async ejecutarConsulta(f) {
    await this.setState({ cargando: true });
    const resultado = await consultarTejidoPaginado(f, this.abortController.signal);
    if (!resultado.error) {
      const paginas = Math.ceil(resultado.datos.cantidadRegistros);
      for (let i = 0; i < resultado.datos.tejido.length; i += 1) {
        const x = resultado.datos.tejido[i];
        x.teoricaMuestras = redondear(x.teoricaMuestras, 2).toFixed(2);
        x.teoricaMaquina = redondear(x.teoricaMaquina, 2).toFixed(2);
        x.tiempoParoCambioReferencia = redondear(x.tiempoParoCambioReferencia, 2).toFixed(2);
        x.tiempoParoCambioReferencia = redondear(x.tiempoParoCambioReferencia, 2).toFixed(2);
        x.tiempoParoElectronico = redondear(x.tiempoParoElectronico, 2).toFixed(2);
        x.tiempoParoColaCambioReferencia = redondear(x.tiempoParoColaCambioReferencia, 2).toFixed(2);
        x.tiempoParoLimpieza = redondear(x.tiempoParoLimpieza, 2).toFixed(2);
        x.tiempoParoMecanico = redondear(x.tiempoParoMecanico, 2).toFixed(2);
        x.tiempoParoMateriaPrimaBodega = redondear(x.tiempoParoMateriaPrimaBodega, 2).toFixed(2);
        x.tiempoParoMateriaPrimaTejido = redondear(x.tiempoParoMateriaPrimaTejido, 2).toFixed(2);
        x.tiempoParoOperativo = redondear(x.tiempoParoOperativo, 2).toFixed(2);
        x.tiempoTejido = redondear(x.tiempoTejido, 2).toFixed(2);
        x.tiempoParoServicio = redondear(x.tiempoParoServicio, 2).toFixed(2);
        x.tiempoParoFaltaRepuesto = redondear(x.tiempoParoFaltaRepuesto, 2).toFixed(2);
        x.tiempoParoOrdenSuperior = redondear(x.tiempoParoOrdenSuperior, 2).toFixed(2);
        x.tiempoParoOff = redondear(x.tiempoParoOff, 2).toFixed(2);
        x.tiempoParoControlTecnico = redondear(x.tiempoParoControlTecnico, 2).toFixed(2);
        x.tiempoParoCambioPinta = redondear(x.tiempoParoCambioPinta, 2).toFixed(2);
      }
      await this.setState({ tejido: resultado.datos.tejido, paginas, cargando: false });
    } else {
      await this.setState({
        tejido: [],
        cargando: false,
        paginas: 0,
        error: true,
      });
    }
  }
  async obtenerDatos(state) {
    if (this.state.filtro != null) {
      let orden = 'fecha';
      if (state && state.sorted && state.sorted.length > 0) {
        [orden] = state.sorted;
      }
      await this.ejecutarConsulta({
        ...this.state.filtro,
        pagina: state.page,
        cantidadRegistros: state.pageSize,
        campoOrdenacion: orden.id,
        ordenAscendente: !orden.desc,
      });
    }
  }
  render() {
    const { page, rowsPerPage } = this.state;
    const emptyRows = rowsPerPage - Math.min(rowsPerPage, this.state.tejido.length - page * rowsPerPage);
    return (
      <MuiThemeProvider theme={theme}>
        <Container style={{ marginTop: '16px' }}>
          <Grid container item direction="column" spacing={1} alignItems="center">
            <Grid item xs={12}>
              <Alerta
                texto="Ocurrió un error de ejecución. Intente nuevamente."
                toggle={this.resetearError}
                isVisible={this.state.error}
              />
            </Grid>
            <Grid item xs={12}>
              <Typography variant="h6">
                Informe básico de tejido
              </Typography>
            </Grid>
            <Grid item>
              <FiltroReporteBasicoTejido
                filtroConsultaEstablecido={this.filtroConsultaEstablecido}
                filtroExportacionEstablecido={this.filtroExportacionEstablecido}
                disabled={this.state.cargando || this.state.exportando}
              />
            </Grid>
            <Grid item xs={12}>
              <TableContainer component={Paper}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <StyledTableCell align="left">Máquina</StyledTableCell>
                      <StyledTableCell align="right">Recurso</StyledTableCell>
                      <StyledTableCell align="left">Pasillo. Sección</StyledTableCell>
                      <StyledTableCell align="left">Parqueadero</StyledTableCell>
                      <StyledTableCell align="left">Lote </StyledTableCell>
                      <StyledTableCell align="right">Material TP</StyledTableCell>
                      <StyledTableCell align="right">Talla</StyledTableCell>
                      <StyledTableCell align="right">Teórica muestras</StyledTableCell>
                      <StyledTableCell align="right">Teorica real</StyledTableCell>
                      <StyledTableCell align="right">Turno</StyledTableCell>
                      <StyledTableCell align="right">Primeras</StyledTableCell>
                      <StyledTableCell align="right">Segundas</StyledTableCell>
                      <StyledTableCell align="right">TCr</StyledTableCell>
                      <StyledTableCell align="right">TElec</StyledTableCell>
                      <StyledTableCell align="right">TCcr</StyledTableCell>
                      <StyledTableCell align="right">TLimp</StyledTableCell>
                      <StyledTableCell align="right">TMec</StyledTableCell>
                      <StyledTableCell align="right">TMpb</StyledTableCell>
                      <StyledTableCell align="right">TMpt</StyledTableCell>
                      <StyledTableCell align="right">TOpe</StyledTableCell>
                      <StyledTableCell align="right">TTej</StyledTableCell>
                      <StyledTableCell align="right">TServ</StyledTableCell>
                      <StyledTableCell align="right">TFr</StyledTableCell>
                      <StyledTableCell align="right">TOs</StyledTableCell>
                      <StyledTableCell align="right">TOff</StyledTableCell>
                      <StyledTableCell align="right">TCt</StyledTableCell>
                      <StyledTableCell align="right">TCp</StyledTableCell>
                      <StyledTableCell align="right">Fecha real</StyledTableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {(rowsPerPage > 0
                      ? this.state.tejido.slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage,
                      )
                      : this.state.tejido
                    ).map(row => (
                      <TableRow key={row.id}>
                        <TableCell align="right">{row.codigoMaquina}</TableCell>
                        <TableCell align="left">{row.codigoRecurso}</TableCell>
                        <TableCell align="left">{row.codigoPasillo}</TableCell>
                        <TableCell align="left">{row.codigoParqueadero}</TableCell>
                        <TableCell align="right">{row.codigoLote}</TableCell>
                        <TableCell align="right">{row.codigoMaterial}</TableCell>
                        <TableCell align="right">{row.codigoTalla}</TableCell>
                        <TableCell align="right">{row.teoricaMuestras}</TableCell>
                        <TableCell align="right">{row.teoricaMaquina}</TableCell>
                        <TableCell align="right">{row.codigoTurno}</TableCell>
                        <TableCell align="right">{row.primeras}</TableCell>
                        <TableCell align="right">{row.segundas}</TableCell>
                        <TableCell align="right">{row.tiempoParoCambioReferencia}</TableCell>
                        <TableCell align="right">{row.tiempoParoElectronico}</TableCell>
                        <TableCell align="right">{row.tiempoParoColaCambioReferencia}</TableCell>
                        <TableCell align="right">{row.tiempoParoLimpieza}</TableCell>
                        <TableCell align="right">{row.tiempoParoMecanico}</TableCell>
                        <TableCell align="right">{row.tiempoParoMateriaPrimaBodega}</TableCell>
                        <TableCell align="right">{row.tiempoParoMateriaPrimaTejido}</TableCell>
                        <TableCell align="right">{row.tiempoParoOperativo}</TableCell>
                        <TableCell align="right">{row.tiempoTejido}</TableCell>
                        <TableCell align="right">{row.tiempoParoServicio}</TableCell>
                        <TableCell align="right">{row.tiempoParoFaltaRepuesto}</TableCell>
                        <TableCell align="right">{row.tiempoParoOrdenSuperior}</TableCell>
                        <TableCell align="right">{row.tiempoParoOff}</TableCell>
                        <TableCell align="right">{row.tiempoParoControlTecnico}</TableCell>
                        <TableCell align="right">{row.tiempoParoCambioPinta}</TableCell>
                        <TableCell align="left">
                          {fd.format(Date.parse(row.fecha, { year: 'numeric', month: '2-digit', day: '2-digit' }))}
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
                <Grid item xs={12}>
                  <TablePagination
                    align="right"
                    rowsPerPageOptions={[5, 10, 15, 20, 30, 50, 100]}
                    component="div"
                    count={this.state.paginas}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    labelRowsPerPage="Registros por página:"
                    onChangePage={(event, newPage) => this.setState({ page: newPage })}
                    onChangeRowsPerPage={
                      event => this.setState({
                      rowsPerPage: parseInt(event.target.value, 10),
                       page: 0,
                    })
                    }
                  />
                </Grid>
              </TableContainer>
            </Grid>
          </Grid>
        </Container>
      </MuiThemeProvider>);
  }
}

export default ReporteBasicoTejido;
