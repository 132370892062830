import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import DownIcon from 'mdi-react/ChevronDownIcon';
import { Collapse } from 'reactstrap';
import TopbarMenuLink from './TopbarMenuLink';
import { UsuarioRestriccion } from '../../../appConfig.json';

export default class TopbarProfile extends PureComponent {
  static propTypes = {
    username: PropTypes.string.isRequired,
  }

  constructor(props) {
    super(props);
    this.state = {
      collapse: false,
    };
  }

  toggle = () => {
    this.setState({ collapse: !this.state.collapse });
  };

  render() {
    const { username } = this.props;
    const shortenedUsername = username.charAt(0) + username.charAt(username.length - 1);

    return (
      <div className="topbar__profile">
        <button className="topbar__avatar" onClick={this.toggle}>
          <p className="topbar__avatar-name">{shortenedUsername}</p>
          { username !== UsuarioRestriccion && <DownIcon className="topbar__icon" /> }
        </button>
        {this.state.collapse && <button className="topbar__back" onClick={this.toggle} />}
        {
          username !== UsuarioRestriccion &&
          <Collapse isOpen={this.state.collapse} className="topbar__menu-wrap">
            <div className="topbar__menu">
              <TopbarMenuLink title="Salir" icon="exit" path="/logout" />
            </div>
          </Collapse>
        }
      </div>
    );
  }
}
