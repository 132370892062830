/* eslint-disable import/prefer-default-export */
import styled from 'styled-components';
import {
  Paper as MuiPaper,
  Table as MuiTable,
  Chip as MuiChip,
} from '@material-ui/core';

export const Paper = styled(MuiPaper)`
  width: 100%;
  margin-top: 24px;
  max-height: calc(100vh - 80px);
  min-height: calc(100vh - 80px);
  overflow-y: auto;
`;


export const Table = styled(MuiTable)`
  position: relative;
  height: 99.9%;
`;

export const Chip = styled(MuiChip)`
    width: 100%;
    justify-content: space-between !important;
    max-width: 100%;
    border: none !important;
    height: auto !important;
    min-height: 32px;
    letter-spacing: 0px;

    & span.MuiChip-label {
      max-width: 90%;
      word-break: break-word;
      white-space: normal;
      letter-spacing: 0px;
    };
`;

export const Iframe = styled.iframe`
  
`;
