export const SUBSCRIBRE_TO_HUB = 'SUBSCRIBRE_TO_HUB';
export const UNSUBSCRIBRE_TO_HUB = 'UNSUBSCRIBRE_TO_HUB';

export function SubscribeToHub() {
  return {
    type: SUBSCRIBRE_TO_HUB,
  };
}

export function UnsubscribeToHub() {
  return {
    type: UNSUBSCRIBRE_TO_HUB,
  };
}
