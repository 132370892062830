import React, { PureComponent } from 'react';
import { Alert } from 'reactstrap';
import PropTypes from 'prop-types';
import InformationOutlineIcon from 'mdi-react/InformationOutlineIcon';
import ThumbUpOutlineIcon from 'mdi-react/ThumbUpOutlineIcon';
import CommentAlertOutlineIcon from 'mdi-react/CommentAlertOutlineIcon';
import CloseCircleOutlineIcon from 'mdi-react/CloseCircleOutlineIcon';

export default class AlertComponent extends PureComponent {
  static propTypes = {
    color: PropTypes.string,
    icon: PropTypes.bool,
    className: PropTypes.string,
    children: PropTypes.element.isRequired,
    visible: PropTypes.bool,
    toggle: PropTypes.func,
  };

  static defaultProps = {
    color: '',
    icon: false,
    className: '',
    visible: true,
    toggle: null,
  };

  render() {
    const {
      color, className, icon, children,
    } = this.props;
    let Icon;

    switch (color) {
      case 'info':
        Icon = <InformationOutlineIcon />;
        break;
      case 'success':
        Icon = <ThumbUpOutlineIcon />;
        break;
      case 'warning':
        Icon = <CommentAlertOutlineIcon />;
        break;
      case 'danger':
        Icon = <CloseCircleOutlineIcon />;
        break;
      default:
        break;
    }
    return (
      <Alert color={color} className={className} isOpen={this.props.visible} toggle={this.props.toggle}>
        {icon && <div className="alert__icon">{Icon}</div>}
        <div className="alert__content">
          {children}
        </div>
      </Alert>
    );
  }
}
