import React from 'react';
import { createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles';
import {
  Container,
  Grid,
  Typography,
  Button,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import Alerta from '../Comunes/Alerta';
import TablaMetas from './TablaMetas';
import OtrosProcesos from './OtrosProcesos';
import { obtenerMetas, obtenerInformeBasico, textoFechaHoraActualizacion } from './datos';
import InformeBasico from './InformeBasico';

const theme = createMuiTheme({
  palette: {
    primary: { main: '#263238' },
    secondary: { main: '#1a237e' },
  },
});

class IndicadoresTextil extends React.Component {
  static formatearNombreSeccion(d) {
    if (d.unidadMedida) {
      return `${d.descripcionSeccion} (${d.unidadMedida})`;
    }
    return `${d.descripcionSeccion}`;
  }
  constructor(props) {
    super(props);
    this.cargarMetas = this.cargarMetas.bind(this);
    this.mostrarOtrosProcesos = this.mostrarOtrosProcesos.bind(this);
    this.finOtrosProcesos = this.finOtrosProcesos.bind(this);
    this.resetearError = this.resetearError.bind(this);
    this.state = {
      cargandoMetas: false,
      cargandoIndicadores: false,
      metas: [],
      indicadores: [],
      dialogOtrosProcesos: false,
      errorMetas: false,
      errorIndicadores: false,
      error: false,
    };
  }
  componentDidMount() {
    this.cargarMetas();
    this.cargarIndicadores();
  }
  componentWillUnmount() {
    this.abortController.abort();
  }
  abortController = new AbortController();
  async cargarMetas() {
    await this.setState({ cargandoMetas: true, error: false });
    const respuesta = await obtenerMetas(this.props.planta, this.abortController.signal);
    if (!respuesta.error) {
      const metas = TablaMetas.transformar(respuesta.datos);
      await this.setState({ cargandoMetas: false, errorMetas: false, metas });
    } else {
      await this.setState({
        cargandoMetas: false,
        errorMetas: true,
        metas: [],
        error: true,
      });
    }
  }
  async cargarIndicadores() {
    await this.setState({ cargandoIndicadores: true, error: false });
    const respuesta = await obtenerInformeBasico(this.props.planta, this.abortController.signal);
    if (!respuesta.error) {
      await this.setState({ indicadores: respuesta.datos, cargandoIndicadores: false, errorIndicadores: false });
    } else {
      await this.setState({ cargandoIndicadores: false, errorIndicadores: true, error: true });
    }
  }
  async resetearError() {
    await this.setState({ error: false });
  }
  async mostrarOtrosProcesos() {
    await this.setState({ dialogOtrosProcesos: true });
  }
  async finOtrosProcesos() {
    await this.setState({ dialogOtrosProcesos: false });
  }
  render() {
    return (
      <React.Fragment>
        <MuiThemeProvider theme={theme}>
          <Container style={{ marginTop: '16px' }}>
            <Grid container md={12} spacing={3} direction="column" alignItems="center">
              <Grid item xs={12}>
                <Alerta
                  texto="Ocurrió un error. Intente nuevamente."
                  toggle={this.resetearError}
                  isVisible={this.state.error}
                />
              </Grid>
              <Grid item xs={12}>
                <Typography variant="subtitle1">
                  Última actualización {textoFechaHoraActualizacion(this.state.indicadores)}
                </Typography>
              </Grid>
              <Grid md={12}>
                <TablaMetas
                  cargando={this.state.cargandoMetas}
                  metas={this.state.metas}
                  error={this.state.errorMetas}
                />
              </Grid>
              <Grid md={12}>
                <br />
              </Grid>
              <Grid md={12}>
                {
                  !this.state.cargandoIndicadores &&
                  <Grid container item direction="row" spacing={2}>
                    <Grid item>
                      <Button onClick={this.mostrarOtrosProcesos} variant="outlined" size="small">
                        Otros procesos
                      </Button>
                    </Grid>
                    <Grid item>
                      <Button disabled variant="outlined" size="small">
                        Inventario
                      </Button>
                    </Grid>
                  </Grid>
                }
              </Grid>
              <Grid md={12}>
                <br />
              </Grid>
              {
                this.state.dialogOtrosProcesos &&
                <Grid container md={12}>
                  <OtrosProcesos
                    activo={this.state.dialogOtrosProcesos}
                    planta={this.props.planta}
                    finalizar={this.finOtrosProcesos}
                    formatearSeccion={IndicadoresTextil.formatearNombreSeccion}
                  />
                </Grid>
              }
              <Grid container item md={12}>
                <Grid md={12} item justify="center">
                  <InformeBasico
                    cargando={this.state.cargandoIndicadores}
                    indicadores={this.state.indicadores}
                    error={this.state.errorIndicadores}
                    formatearSeccion={IndicadoresTextil.formatearNombreSeccion}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Container>
        </MuiThemeProvider>
      </React.Fragment>
    );
  }
}

IndicadoresTextil.propTypes = {
  planta: PropTypes.number.isRequired,
};

export default IndicadoresTextil;
