import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Col, Row } from 'reactstrap';
import { Link } from 'react-router-dom';

import Eficiencia from '../../shared/components/eficiencia/Eficiencia';
import Occupancy from './components/Occupancy';
import config from './../../appConfig.json';

class DetallesPlanta extends Component {
  constructor(props) {
    super(props);
    this.state = {
      planta: {},
      loading: true,
    };
  }


  componentDidMount = () => {
    const { unidad, planta } = this.props.match.params;
    this.getSeccionesAsync(unidad, planta);
  };

  /**
   * Obtiene todas las secciones de una planta
   * y las establece en el estado.
   */
  getSeccionesAsync = async (unidadId, plantaId) => {
    const { ApiUrl, AuthTokenKey } = config;
    const response = await fetch(`${ApiUrl}/eficiencia/planta/${plantaId}`, {
      mode: 'cors',
      method: 'GET',
      headers: {
        'Content-Type': 'application/json;charset=utf-8',
        Authorization: localStorage.getItem(AuthTokenKey),
      },
    });

    const planta = await response.json();

    this.setState({
      planta,
      loading: false,
    });
  };

  render() {
    const { unidad, planta } = this.props.match.params;

    return (
      <Col sm={12} className="card detallesCard">
        <Row className="justify-content-center">
          <h3>Planta Marinilla Calcetines</h3>
        </Row>
        <Row className="justify-content-center">
          <Col sm={6}>
            <Link to={`/secciones/unidad/${unidad}/planta/${planta}`}>
              {
                this.state.loading ?
                  <>
                    <Eficiencia porcentaje={0} />
                    <h3 className="text-center">Cargando ...</h3>
                  </>
                : <Eficiencia
                  porcentaje={this.state.planta.eficiencia}
                />
              }
            </Link>
          </Col>
        </Row>
        <Row>
          <Occupancy />
        </Row>
      </Col>
    );
  }
}

DetallesPlanta.propTypes = {
  match: PropTypes.objectOf(PropTypes.shape()).isRequired,
};

export default DetallesPlanta;
