import React, { useState, useEffect } from 'react';
import { Container, Row, Col } from 'reactstrap';
import PropTypes from 'prop-types';

import AlarmasTable from './components/AlarmasTable';
import Loader from '../../shared/components/loader/Loader';
import { ApiUrl, AuthTokenKey } from '../../appConfig.json';

const PanelAlarmas = ({ match: { params: { id } } }) => {
  const [alarmas, setAlarmas] = useState([]);
  const [loading, setLoading] = useState(true);
  try {
    useEffect(() => {
      fetch(`${ApiUrl}/Alarmas`, {
        method: 'GET',
        mode: 'cors',
        headers: {
          'Content-Type': 'application/json;charset=utf-8',
          Authorization: localStorage.getItem(AuthTokenKey),
        },
      }).then(res => res.json())
        .then((response) => {
          setAlarmas(response);
          setLoading(false);
        });
    }, []);
  } catch (error) {
    console.error('Error al obtener los datos', error);
  }
  return (
    loading ?
      <Loader loading={loading} />
      :
      <Container className="padding-top-21">
        <Row className="justify-content-center">
          <h3>Reporte de Alarmas</h3>
        </Row>
        <Row className="justify-content-center padding-top-21">
          <Col md={12}>
            <AlarmasTable alarmas={alarmas} />
          </Col>
        </Row>
      </Container>
  );
};

PanelAlarmas.propTypes = {
  match: PropTypes.objectOf().isRequired,
};

export default PanelAlarmas;
