import React from 'react';

import { TextField,
  InputAdornment,
  Tooltip,
  Button,
  Dialog,
  DialogContent,
  DialogActions } from '@material-ui/core';
import { MagnifyIcon, ViewHeadlineIcon } from 'mdi-react';
import Context from '../../../Operaciones/store';
import Alert from '../../../../shared/components/alert/Alert';
import config from '../../../../../src/appConfig.json';
import './styles/style.css';

function Filtro() {
  const [input, setInput] = React.useState('');
  const [open, setOpen] = React.useState(false);
  const [alert, setAlert] = React.useState(false);
  const [sw, setSw] = React.useState(0);
  const [mensaje, setMensaje] = React.useState('');

  const handleClose = () => {
    setOpen(false);
    setAlert(false);
  };

  const handleOpen = () => {
    setOpen(true);
    setAlert(true);
  };

  return (
    <>
      <Context.Consumer>
        {
          (value) => {
            const handleChange = (e) => {
              setInput(e.target.value);
            };
            const handleBlur = (e) => {
              if (e.target.value) {
                setInput(e.target.value.padStart(10, '0'));
              }
            };
            const handleClick = async () => {
              if (input === '') {
                handleOpen();
                value.activateLote(null);
                setSw(0);
              } else {
                const request = await fetch(`${config.ApiUrl}/eventos/porlote/${input}`, {
                  headers: {
                    Authorization: localStorage.getItem(config.AuthTokenKey),
                  },
                });
                if (request.status === 200) {
                  setMensaje('');
                  const response = await request.json();
                  handleClose();
                  value.activateLote(response);
                } else {
                  setMensaje(await request.text());
                  handleOpen();
                  value.activateLote(null);
                }
              }
            };
            return (
              <>
                <div>
                  <TextField
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={input}
                    style={{ margin: '2%', width: '70%' }}
                    placeholder="Consultar lote"
                    type="number"
                    id="standard-start-adornment"
                    InputProps={{
startAdornment: <InputAdornment position="start"><MagnifyIcon style={{ color: 'gray' }} /></InputAdornment>,
                  }}
                  />
                  <Tooltip title="Listar los eventos de el lote" aria-label="add">
                    <button
                      onClick={handleClick}
                      size="small"
                      className="size"
                    >
                      <ViewHeadlineIcon />
                    </button>
                  </Tooltip>
                </div>
                <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title" >
                  <DialogContent>
                    <Alert
                      color={sw === 0 ? 'danger' : 'info'}
                      className="alert--bordered"
                      icon
                      visible={alert}
                    >
                      {mensaje}
                    </Alert>
                  </DialogContent>
                  <DialogActions>
                    <Button onClick={handleClose}>
                        OK
                    </Button>
                  </DialogActions>
                </Dialog>
              </>
            );
          }
        }
      </Context.Consumer>
    </>
  );
}
export default Filtro;
