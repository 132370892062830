import React, { useState, useEffect, Fragment } from 'react';
import { Row, Col } from 'reactstrap';
import PropTypes from 'prop-types';

import Eficiencia from '../../../shared/components/eficiencia/Eficiencia';

const eficienciaGeneral = (data = {}, docenasNotificadas = 0, restricted = false) => (
  <Fragment>
    <Row className="justify-content-center">
      <Col sm={8}>
        <Row className={`d-flex justify-content-center align-items-center ${restricted && 'fullDeviceHeight'}`}>
          { data.empleados.length <= 0 ?
            <Col>
              <Eficiencia
                porcentaje={0}
              />
            </Col>
                : data.empleados.map(empleado => (
                  <Col sm={12} md={6} lg={4}>
                    <Row className="justify-content-center">
                      <h3>{empleado.nombre}</h3>
                    </Row>
                    <Eficiencia
                      porcentaje={empleado.eficiencia}
                    />
                  </Col>
                ))
              }
        </Row>
      </Col>
      <Col sm={2} className="d-flex flex-column justify-content-center align-items-center">
        <h1><b>{Math.round(docenasNotificadas)}</b></h1>
        <h3 className="text-primary"><b>REAL (DZ)</b></h3>
      </Col>
    </Row>
  </Fragment>
);

const EficienciaGeneralUbicacion = ({ data, load, restricted }) => {
  const [docenasNotificadas, setDocenasNotificadas] = useState(0);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setLoading(load);
    !loading && getDocenas();
  });

  const getDocenas = () => {
    setDocenasNotificadas(data.docenasNotificadas);
  };

  return (
    !loading ? eficienciaGeneral(data, docenasNotificadas, restricted) : <Eficiencia porcentaje={0} />
  );
};

EficienciaGeneralUbicacion.propTypes = {
  data: PropTypes.objectOf(PropTypes.object).isRequired,
  load: PropTypes.bool.isRequired,
  restricted: PropTypes.bool.isRequired,
};

export default EficienciaGeneralUbicacion;
