import React from 'react';
import {
  Button,
  Dialog,
  DialogTitle,
  IconButton,
  Tooltip,
  DialogActions,
} from '@material-ui/core';
import { DeleteOutlineIcon } from 'mdi-react';

import Context from '../../store';

import config from '../../../../appConfig.json';


function EliminarDetallada(props) {
  const [open, setOpen] = React.useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  return (
    <>
      <Context.Consumer>
        {
          (value) => {
              const handleDelete = async () => {
                await fetch(
                  `${config.ApiUrl}/operaciones/detallada/${props.detallada.id}/eliminar`,
                  {
                    mode: 'cors',
                    method: 'DELETE',
                    headers: {
                      'Content-Type': 'application/json',
                      Authorization: localStorage.getItem(config.AuthTokenKey),
                      Accept: 'application/json',
                    },
                  },
                );
                const nuevasDet =
                await fetch(
                  `${config.ApiUrl}/Operaciones/${value.operacion.id}/OperacionesDet`,
                  {
                    headers: {
                      'Content-Type': 'application/json',
                      Authorization: localStorage.getItem(config.AuthTokenKey),
                      Accept: 'application/json',
                    },
                  },
                );
                const det = await nuevasDet.json();
                value.activateDet(det);
                value.activateDetOrigin(det);
                handleClose();
              };
              return (
              <>
                <Tooltip title="Elimina esta operación detallada" >
                  <IconButton
                    aria-label="Elimina esta operación detallada"
                    onClick={() => {
                      handleOpen();
                     }}
                  >
                    <DeleteOutlineIcon />
                  </IconButton>
                </Tooltip>
                <Dialog
                  open={open}
                  onClose={handleClose}
                  aria-labelledby="form-dialog-title"
                >
                  <DialogTitle align="center" id="alert-dialog-title">
                       ¿Está seguro de eliminar esta operación detallada?
                  </DialogTitle>
                  <DialogActions align="center" >
                    <Button
                      onClick={handleDelete}
                      color="primary"
                    >
                           SI
                    </Button>
                    <Button onClick={handleClose} color="primary">
                          NO
                    </Button>
                  </DialogActions>
                </Dialog>
               </>
               );
}
          }
      </Context.Consumer>
    </>
  );
}

export default EliminarDetallada;
