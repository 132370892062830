import React from 'react';
import PropTypes from 'prop-types';
import { Badge } from 'reactstrap';
import { Link } from 'react-router-dom';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';

const AlarmaItem = ({
  id,
  fechaOrigen,
  nombre,
  seccion,
  operario,
  checked,
  observacion,
  fechaCheck,
}) => (
  <TableRow key={id}>
    <TableCell component="th" scope="row">
      {id}
    </TableCell>
    <TableCell align="left"><Link className="btn-link" to={`/Alarmas/${id}`}>{nombre}</Link></TableCell>
    <TableCell align="left">{operario.nombre}</TableCell>
    <TableCell align="right">{fechaOrigen}</TableCell>
    <TableCell align="right">{fechaCheck}</TableCell>
    <TableCell align="right">{seccion.descripcion}</TableCell>
    <TableCell align="right">{observacion}</TableCell>
    <TableCell align="right">
      <Badge pill color={checked ? 'success' : 'danger'}>
        { checked ? 'Atendida' : 'Pendiente' }
      </Badge>
    </TableCell>
  </TableRow>
);

{ /* <tr>
    <td>{id}</td>
    <td>{fechaOrigen}</td>
    <td><Link className="btn-link" to={`/Alarmas/${id}`}>{nombre.toUpperCase()}</Link></td>
    <td>{seccion.descripcion.toUpperCase()}</td>
    <td>{operario.nombre.toUpperCase()}</td>
    <td>
      <Badge pill color={checked ? 'success' : 'danger'}>
        { checked ? 'Atendido' : 'Pendiente' }
      </Badge>
    </td>
    <td>
      {observacion.toUpperCase()}
    </td>
    <td>{fechaCheck}</td>
  </tr> */ }

AlarmaItem.propTypes = {
  id: PropTypes.number.isRequired,
  fechaOrigen: PropTypes.string.isRequired,
  nombre: PropTypes.string.isRequired,
  seccion: PropTypes.objectOf().isRequired,
  operario: PropTypes.objectOf().isRequired,
  checked: PropTypes.bool.isRequired,
  observacion: PropTypes.string.isRequired,
  fechaCheck: PropTypes.string.isRequired,
};

export default AlarmaItem;
