import React from 'react';
import { DownloadIcon } from 'mdi-react';
import { Tooltip } from '@material-ui/core';
import Context from '../../../Operaciones/store';
import './styles/style.css';
import config from '../../../../../src/appConfig.json';
// import ReactHTMLTableToExcel from 'react-html-table-to-excel';

function reactdataexport(props) {
  const { sw, data } = props;
  return (
   <>
     <Context.Consumer>
       {
          (value) => {
            const Descargar = async () => {
              if (value.lote !== null) {
                if (sw === 0) {
                  try {
                    await obtenerEficienciaEventosExcel(value.lote.codigoLote);
                  } catch (e) {
                    console.log(e);
                  }
                } else {
                  try {
                    await obtenerSegundasExcel(data);
                  } catch (e) {
                    console.log(e);
                  }
                }
              }
            };

           const obtenerEficienciaEventosExcel = async (codigoLote, signal) => {
             await
             descargarArchivo(
              `${config.ApiUrl}/eventos/seguimiento-lote-excel/${codigoLote}`,
             'GET', null, 'EventosLote.xlsx', signal,
             );
            };

            const obtenerSegundasExcel = async (segundas, signal) => {
              await
              descargarArchivo(
               `${config.ApiUrl}/segundas/segundas-lote-excel/${value.lote.codigoLote}`,
              'GET', null, `SegundasLote${value.lote.codigoLote}.xlsx`, signal,
              );
             };

            async function descargarArchivo(url, method, peticion, nombrePredeterminado, signal) {
              let body = null;
              if (peticion != null) {
                body = JSON.stringify(peticion);
              }
              const respuesta = await
              fetch(
                url,
                {
                  signal,
                  mode: 'cors',
                  method,
                  body,
                  headers: {
                    'Content-Type': 'application/json;charset=utf-8',
                    Authorization: localStorage.getItem(config.AuthTokenKey),
                  },
                },
              );
              if (respuesta.status === 200) {
                const blob = await respuesta.blob();
                const tempUrl = window.URL.createObjectURL(blob);
                const a = document.createElement('a');
                a.href = tempUrl;
                a.download = extraerNombreArchivo(respuesta) || nombrePredeterminado;
                a.click();
                a.remove();
              } else {
                throw await respuesta.text();
              }
            }
            function extraerNombreArchivo(respuesta) {
              let nombre = '';
              const disposition = respuesta.headers.get('Content-Disposition');
              if (disposition && disposition.indexOf('attachment') !== -1) {
                const exp = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
                const matches = exp.exec(disposition);
                if (matches != null && matches[1]) {
                  nombre = matches[1].replace(/['"]/g, '');
                }
              }
              return nombre;
            }

            return (
              <Tooltip title="Exportar a excel" aria-label="Exportar a excel">
                <button onClick={Descargar} className="size" style={{ marginRight: '2%' }}><DownloadIcon /></button>
              </Tooltip>
            );
          }
        }
     </Context.Consumer>
   </>
  /* <ReactHTMLTableToExcel
      id="test-table-xls-button"
      className="size"
      table="table-to-xls"
      filename="Informe Lote Unidades primeras"
      sheet="Unidades primeras"
      buttonText={<DownloadIcon />}
    />
    */
  );
}

export default reactdataexport;
