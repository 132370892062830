import React from 'react';
import PropTypes from 'prop-types';

import { makeStyles, TextField } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  root: {
    '& > *': {
      margin: '5%',
      width: '90%',
    },
  },
}));

export default function BarraDeBusqueda(props) {
  const classes = useStyles();
  const { Input, onChange } = props;
  return (
    <div className={classes.root} >
      <TextField
        id="standard-basic"
        label="Operación"
        type="text"
        value={Input}
        onChange={onChange}
        helperText="Buscar Operaciones"
      />
    </div>
  );
}
BarraDeBusqueda.propTypes = {
  Input: PropTypes.string.isRequired,
  onChange: PropTypes.isRequired,
};
