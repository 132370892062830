import React from 'react';
import PropTypes from 'prop-types';
import { Table,
  Grid,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Paper,
  CircularProgress } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { Badge } from 'reactstrap';
import { separarGruposMiles } from '../Comunes/datosComunes';
import { getColorEficienciaBadge } from '../../shared/funtions/ColoresEficiencias';
import { ordenarIndicadores } from './datos';

const useStyles = makeStyles(theme => ({
  // TODO: hacer más dinámico
  tableRightBorder: {
    borderRight: '1px solid rgba(224, 224, 224, 1);',
  },
}));


export default function InformeBasico(props) {
  const classes = useStyles();
  const crearFila = (i, formatearSeccion) => {
    if (i.registraEficiencia) {
      return (
        <TableRow>
          <TableCell align="left">{formatearSeccion(i)}</TableCell>
          <TableCell align="right">
            <Badge
              color={getColorEficienciaBadge(100)}
              pill
            >
              {i.eficienciaDiaria != null ? Math.round(i.eficienciaDiaria) : '(%)'}
            </Badge>
          </TableCell>
          <TableCell align="right">{separarGruposMiles(Math.round(i.metaAcumuladaDiaria))}</TableCell>
          <TableCell align="right">{separarGruposMiles(Math.round(i.valorDiario))}</TableCell>
          <TableCell align="right" className={classes.tableRightBorder}>
            <Badge
              color={getColorEficienciaBadge(i.cumplimientoDiario)}
              pill
            >
              {Math.round(i.cumplimientoDiario)} %
            </Badge>
          </TableCell>
          <TableCell align="right">
            <Badge
              color={getColorEficienciaBadge(100)}
              pill
            >
              {i.eficienciaSemanal != null ? Math.round(i.eficienciaSemanal) : '(%)'}
            </Badge>
          </TableCell>
          <TableCell align="right">{separarGruposMiles(Math.round(i.metaAcumuladaSemanal))}</TableCell>
          <TableCell align="right">{separarGruposMiles(Math.round(i.valorSemanal))}</TableCell>
          <TableCell align="right">
            <Badge
              color={getColorEficienciaBadge(i.cumplimientoSemanal)}
              pill
            >
              {Math.round(i.cumplimientoSemanal)} %
            </Badge>
          </TableCell>
        </TableRow>
      );
    }
    return (
      <TableRow>
        <TableCell align="left">{props.formatearSeccion(i)}</TableCell>
        <TableCell colSpan={2} align="right">{separarGruposMiles(Math.round(i.metaAcumuladaDiaria))}</TableCell>
        <TableCell align="right">{separarGruposMiles(Math.round(i.valorDiario))}</TableCell>
        <TableCell align="right" className={classes.tableRightBorder}>
          <Badge
            color={getColorEficienciaBadge(i.cumplimientoDiario)}
            pill
          >
            {Math.round(i.cumplimientoDiario)} %
          </Badge>
        </TableCell>
        <TableCell colSpan={2} align="right">{separarGruposMiles(Math.round(i.metaAcumuladaSemanal))}</TableCell>
        <TableCell align="right">{separarGruposMiles(Math.round(i.valorSemanal))}</TableCell>
        <TableCell align="right">
          <Badge
            color={getColorEficienciaBadge(i.cumplimientoSemanal)}
            pill
          >
            {Math.round(i.cumplimientoSemanal)} %
          </Badge>
        </TableCell>
      </TableRow>
    );
  };
  ordenarIndicadores(props.indicadores);
  if (props.cargando) {
    return (
      <Grid container justify="center"><CircularProgress color="primary" /></Grid>);
  } else if (props.error) {
    return <span>Ocurrió un error. Por favor intente nuevamente.</span>;
  }
  return (
    <Paper elevation={3}>
      <Table size="medium">
        <TableHead>
          <TableRow>
            <TableCell rowSpan={2} align="left">Sección</TableCell>
            <TableCell colSpan={4} align="center" className={classes.tableRightBorder}>Información en línea</TableCell>
            <TableCell colSpan={4} align="center">Acumulado semana</TableCell>
          </TableRow>
          <TableRow>
            <TableCell align="right">Eficiencia</TableCell>
            <TableCell align="right">Meta</TableCell>
            <TableCell align="right">Producción</TableCell>
            <TableCell align="right" className={classes.tableRightBorder}>Cumplimiento</TableCell>
            <TableCell align="right">Eficiencia</TableCell>
            <TableCell align="right">Meta</TableCell>
            <TableCell align="right">Producción</TableCell>
            <TableCell align="right">Cumplimiento</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {props.indicadores.map(i => crearFila(i, props.formatearSeccion))}
        </TableBody>
      </Table>
    </Paper>
  );
}

InformeBasico.propTypes = {
  cargando: PropTypes.bool.isRequired,
  error: PropTypes.bool,
  indicadores: PropTypes.arrayOf(PropTypes.object).isRequired,
  formatearSeccion: PropTypes.func.isRequired,
};

InformeBasico.defaultProps = {
  error: false,
};
