import React, { Fragment } from 'react';
import {
  Col,
  Row,
} from 'reactstrap';
import PropTypes from 'prop-types';

import Eficiencia from '../../../shared/components/eficiencia/Eficiencia';

function EficienciaGeneralMaquina(props) {
  const {
    turno,
    eficiencia,
    parqueadero,
    maquina,
  } = props;
  return (
    <Fragment>
      <Row className="justify-content-center">
        <Col sm={2} className="d-flex flex-column justify-content-center align-items-center">
          <h2 className="text-primary"><b>Orden</b></h2>
          <h3><b>{turno}</b></h3>
        </Col>
        <Col sm={8}>
          <Row className="d-flex justify-content-center align-items-center">
            <Col>
              <Row className="justify-content-center">
                <h3>{`Maquina ${maquina}`}</h3>
              </Row>
              <Eficiencia
                modo="medium"
                porcentaje={eficiencia && (eficiencia.porcentajeEficienciaLote * 100)}
                cx={85}
                cy={85}
                innerRadius={85}
                outerRadius={90}
              />
            </Col>
          </Row>
        </Col>
        <Col sm={2} className="d-flex flex-column justify-content-center align-items-center">
          <h3 className="text-success"><b>Parqueadero</b></h3>
          <h1><b>{parqueadero}</b></h1>
        </Col>
      </Row>
    </Fragment>
  );
}

EficienciaGeneralMaquina.propTypes = {
  turno: PropTypes.string.isRequired,
  eficiencia: PropTypes.string.isRequired,
  parqueadero: PropTypes.string.isRequired,
  maquina: PropTypes.string.isRequired,
};
export default EficienciaGeneralMaquina;
