/* eslint-disable import/prefer-default-export */
import React, { useState } from 'react';
import { Grid, makeStyles, ButtonGroup, Button, Fab } from '@material-ui/core';
import { CheckIcon, EditIcon, TrashIcon } from 'mdi-react';
import { withStyles } from '@material-ui/styles';
import { red } from '@material-ui/core/colors';

import { crearEstandar, modificarEstandar, eliminarEstandar } from '../functions/crudSimulador';
import EstandaresDialog from './EstandaresDialog';
import Context from '../Context';

const useStyles = makeStyles(theme => ({
  root: {
    marginTop: theme.spacing(3),
    textAlign: 'center',
  },
}));

const DeleteFab = withStyles(theme => ({
  root: {
    color: theme.palette.getContrastText(red[700]),
    backgroundColor: red[700],
    '&:hover': {
      backgroundColor: red[900],
    },
  },
}))(Fab);

export const ActionButtons = () => {
  const CREAR = 'crear';
  const MODIFICAR = 'modificar';
  const ELIMINAR = 'eliminar';

  const classes = useStyles();

  const [open, setOpen] = useState(false);
  const [accion, setAccion] = useState('');
  const [loading, setLoading] = useState(false);

  const toggleModal = (action) => {
    setAccion(action);
    setOpen(!open);
  };

  const handleAcceptClose = async (
    referencia, categoria, normaEmpaque,
    procesos, estandarBebes, estandarNinios,
    estandarAdultos, bebesCheck, niniosCheck,
    adultosCheck, empaqueManualCheck, empaqueAutotexCheck,
    procesosEliminados, procesosAgregados,
  ) => {
    let response;

    if (accion === CREAR) {
      response = await crearEstandar(
        referencia, categoria, normaEmpaque,
        procesos, estandarBebes, estandarNinios,
        estandarAdultos, bebesCheck, niniosCheck,
        adultosCheck, empaqueManualCheck, empaqueAutotexCheck, procesos,
      );
    }

    if (accion === MODIFICAR) {
      response = await modificarEstandar(
        referencia, categoria, normaEmpaque,
        estandarBebes, estandarNinios,
        estandarAdultos, bebesCheck, niniosCheck,
        adultosCheck, empaqueManualCheck, empaqueAutotexCheck,
        procesosEliminados, procesosAgregados, procesos,
      );
    }

    if (accion === ELIMINAR) {
      response = await eliminarEstandar(referencia, categoria);
    }

    return response;
  };

  return (
    <Context.Consumer>
      {
        ({
          referencia, categoria, normaEmpaque,
          procesos, estandarBebes, estandarNinios,
          estandarAdultos, bebesCheck, niniosCheck,
          adultosCheck, empaqueManualCheck, empaqueAutotexCheck,
          procesosEliminados, procesosAgregados, activateProcesosEliminados,
          activateProcesosAgregados, activateError,
        }) => {
          let resp;
          const handleClose = async () => {
            setLoading(true);

            resp = await handleAcceptClose(
              referencia, categoria, normaEmpaque,
              procesos, estandarBebes, estandarNinios,
              estandarAdultos, bebesCheck, niniosCheck,
              adultosCheck, empaqueManualCheck, empaqueAutotexCheck,
              procesosEliminados, procesosAgregados, procesos,
            );

            if (!resp.status) {
              activateError(resp);
              setLoading(false);
              return;
            }

            toggleModal(accion);

            if (resp.status) {
              activateProcesosEliminados([]);
              activateProcesosAgregados([]);
              alert(resp.mensaje);
            }

            setLoading(false);
          };

          const handleToggleModal = (action) => {
            activateError({
              status: true,
              mensaje: '',
            });

            toggleModal(action);
          };

          return (
            <>
              <Grid className={classes.root} container>
                <Grid item xs={12}>
                  <ButtonGroup
                    fullWidth
                    variant="contained"
                    color="primary"
                    size="medium"
                  >
                    <Button onClick={() => handleToggleModal(CREAR)}>
                      <CheckIcon /> Crear
                    </Button>
                    <Button color="secondary" onClick={() => handleToggleModal(MODIFICAR)}>
                      <EditIcon /> Modificar
                    </Button>
                  </ButtonGroup>
                </Grid>
                <Grid item xs={12} className={classes.root}>
                  <DeleteFab size="medium" onClick={() => handleToggleModal(ELIMINAR)} title="Eliminar Estándar">
                    <TrashIcon />
                  </DeleteFab>
                </Grid>
              </Grid>
              <EstandaresDialog
                open={open}
                toggleModal={() => toggleModal(accion)}
                handleAcceptClose={handleClose}
                accion={accion}
                referencia={referencia}
                categoria={categoria}
                loading={loading}
              />
            </>
          );
        }
      }
    </Context.Consumer>
  );
};
