import React, { Component } from 'react';
import { Col, Container, Row } from 'reactstrap';
// import { Link } from 'react-router-dom';

// import Eficiencia from '../../shared/components/eficiencia/Eficiencia';

class DetallesSubseccion extends Component {
  state = {};
  render() {
    return (
      <Container className="dashboard">
        <Row>
          <Col md={12} lg={6} className="card-no-padding">
            <h1>Hola</h1>
            {/* <IndicadorPlanta planta={planta} /> */}
          </Col>
          {/* {
            secciones.map(seccion => (
              <IndicadorEficienciaSeccion
                key={seccion.id}
                codigo={seccion.id}
                base={`/subsecciones/unidad/${unidad}/planta/${plantaId}/seccion`}
                seccion={seccion.descripcion}
                eficiencia={Math.floor((Math.random() * (130 - 89)) + 89)}
              />))
          } */}
        </Row>
      </Container>
    );
  }
}

export default DetallesSubseccion;
