import React from 'react';
import PropTypes from 'prop-types';
import {
  Row,
  Container,
} from 'reactstrap';
import IndicadorEficienciaSubseccion from './IndicadorEficienciaSubseccion';

const Subsecciones = ({
  unidadId, plantaId, seccionId, subsecciones,
}) => (
  <Container className="dashboard">
    <Row className="d-flex justify-content-center">
      {
        subsecciones.map(subseccion => (
          <IndicadorEficienciaSubseccion
            key={subseccion.elementoId}
            codigo={subseccion.elementoId}
            base={`/ubicaciones/unidad/${unidadId}/planta/${plantaId}/seccion/${seccionId}/subseccion`}
            seccion={subseccion.nombre}
            eficiencia={subseccion.eficiencia}
            real={subseccion.docenasNotificadas}
            tiempoParo={subseccion.tiempoParo}
          />))
      }
    </Row>
  </Container>
);


Subsecciones.propTypes = {
  subsecciones: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  plantaId: PropTypes.number.isRequired,
  unidadId: PropTypes.string.isRequired,
  seccionId: PropTypes.number.isRequired,
};

export default Subsecciones;
