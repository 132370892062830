import React from 'react';
import { Grid, Button, Checkbox, FormControlLabel, TextField, Typography } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import PropTypes from 'prop-types';
import { diasSemana } from './datos';
import { esDecimalValido, convertirDecimal } from '../Comunes/datosComunes';

class MetasDia extends React.Component {
  constructor(props) {
    super(props);
    this.diaSeleccionado = this.diaSeleccionado.bind(this);
    this.confirmar = this.confirmar.bind(this);
    this.cancelar = this.cancelar.bind(this);
    this.restablecerEstado = this.restablecerEstado.bind(this);
    this.metaCambiada = this.metaCambiada.bind(this);
    this.validarValorMeta = this.validarValorMeta.bind(this);
    this.actualizarMetaPlantaCambio = this.actualizarMetaPlantaCambio.bind(this);
    this.state = {
      lunes: true,
      martes: true,
      miercoles: true,
      jueves: true,
      viernes: true,
      sabado: true,
      domingo: false,
      textoMeta: '0',
      actualizarMetasPlanta: true,
      errorMeta: false,
    };
  }
  async diaSeleccionado(e) {
    await this.setState({ [e.target.name]: e.target.checked });
  }
  async actualizarMetaPlantaCambio(e) {
    await this.setState({ actualizarMetasPlanta: e.target.checked });
  }
  async metaCambiada(e) {
    await this.setState({ textoMeta: e.target.value });
  }
  async confirmar() {
    if (await this.validarValorMeta()) {
      const meta = convertirDecimal(this.state.textoMeta);
      if (this.props.confirmado) {
        const x = {
          meta,
          actualizarMetasPlanta: this.state.actualizarMetasPlanta,
          destinos: [
            this.state.lunes,
            this.state.martes,
            this.state.miercoles,
            this.state.jueves,
            this.state.viernes,
            this.state.sabado,
            this.state.domingo,
          ],
        };
        this.props.confirmado(x);
        // await this.restablecerEstado();
      }
    }
  }
  async cancelar() {
    if (this.props.cancelado) {
      this.props.cancelado();
      // await this.restablecerEstado();
    }
  }
  async restablecerEstado() {
    await this.setState({
      lunes: true,
      martes: true,
      miercoles: true,
      jueves: true,
      viernes: true,
      sabado: true,
      domingo: false,
    });
  }
  async validarValorMeta() {
    const v = esDecimalValido(this.state.textoMeta);
    await this.setState({ errorMeta: !v });
    return v;
  }
  render() {
    return (
      <Grid>
        <Dialog
          open={this.props.activo}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          maxWidth="md"
        >
          <DialogTitle id="alert-dialog-title">
            Metas por día
          </DialogTitle>
          <DialogContent>
            <Grid contanier direction="column">
              <Grid container item xs={12} direction="row" alignItems="center">
                <Grid item>
                  <Typography>Meta:&nbsp;</Typography>
                </Grid>
                <Grid item>
                  <TextField
                    inputName="meta"
                    variant="outlined"
                    onChange={this.metaCambiada}
                    value={this.state.textoMeta}
                    etiqueta="Día origen"
                    elementoVacio={false}
                    size="small"
                    error={this.state.errorMeta}
                    helperText={this.state.errorMeta ? 'El valor de la meta no es válido' : ''}
                  />
                </Grid>
              </Grid>
              <Grid container item xs={12} direction="row">
                <Grid>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={this.state.actualizarMetasPlanta}
                        onChange={this.actualizarMetaPlantaCambio}
                        name="lunes"
                        color="primary"
                      />
                    }
                    label="Actualizar metas planta"
                  />
                </Grid>
              </Grid>
              <Grid container item xs={12} direction="row">
                <Grid item>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={this.state.lunes}
                        onChange={this.diaSeleccionado}
                        name="lunes"
                        color="primary"
                      />
                    }
                    label="Lunes"
                  />
                </Grid>
                <Grid item>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={this.state.martes}
                        onChange={this.diaSeleccionado}
                        name="martes"
                        color="primary"
                      />
                    }
                    label="Martes"
                  />
                </Grid>
                <Grid item>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={this.state.miercoles}
                        onChange={this.diaSeleccionado}
                        name="miercoles"
                        color="primary"
                      />
                    }
                    label="Miércoles"
                  />
                </Grid>
                <Grid item>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={this.state.jueves}
                        onChange={this.diaSeleccionado}
                        name="jueves"
                        color="primary"
                      />
                    }
                    label="Jueves"
                  />
                </Grid>
                <Grid item>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={this.state.viernes}
                        onChange={this.diaSeleccionado}
                        name="viernes"
                        color="primary"
                      />
                    }
                    label="Viernes"
                  />
                </Grid>
                <Grid item>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={this.state.sabado}
                        onChange={this.diaSeleccionado}
                        name="sabado"
                        color="primary"
                      />
                    }
                    label="Sábado"
                  />
                </Grid>
                <Grid item>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={this.state.domingo}
                        onChange={this.diaSeleccionado}
                        name="domingo"
                        color="primary"
                      />
                    }
                    label="Domingo"
                  />
                </Grid>
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.confirmar} color="primary">
              Aceptar
            </Button>
            <Button onClick={this.cancelar} color="primary">
              Cancelar
            </Button>
          </DialogActions>
        </Dialog>
      </Grid>
    );
  }
}

MetasDia.propTypes = {
  activo: PropTypes.bool.isRequired,
  confirmado: PropTypes.func.isRequired,
  cancelado: PropTypes.func.isRequired,
};

export default MetasDia;
