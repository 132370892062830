import { combineReducers, createStore } from 'redux';
import { reducer as reduxFormReducer } from 'redux-form';
import { sidebarReducer, themeReducer, NotificationReducer, ubicacionReducer } from '../../redux/reducers/index';

const reducer = combineReducers({
  form: reduxFormReducer, // mounted under "form",
  theme: themeReducer,
  sidebar: sidebarReducer,
  notification: NotificationReducer,
  ubicacion: ubicacionReducer,
});

const loadStateFromLocalStorate = () => {
  try {
    const serializedState = localStorage.getItem('state');
    if (serializedState === null) {
      return undefined;
    }
    return JSON.parse(serializedState);
  } catch (err) {
    return undefined;
  }
};

const saveState = (state) => {
  try {
    const serializedState = JSON.stringify(state);
    localStorage.setItem('state', serializedState);
  } catch {
    console.log('Ocurrió un error cargando el estado desde la sesión del usuario');
  }
};

const savedState = loadStateFromLocalStorate();

const store = createStore(reducer, savedState);

store.subscribe(() => {
  saveState(store.getState());
});

export default store;
