import React from 'react';
import Eventos from './Eventos';
import InfoLote from './InfoLote';

class Tablas extends React.Component {
  render() {
    return (
      <>
        <table id="table-to-xls" >
          <div className="col" >
            <InfoLote />
          </div>
          <div />
          <div className="col" style={{ marginTop: '2%' }} >
            <Eventos />
          </div>
        </table>
      </>
    );
  }
}
export default Tablas;
