import React, { useState } from 'react';
import { TextField, Grid, Button } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import ContextIncentivos from '../ContextoIncentivos';
import { convertirDecimal, esDecimalValido } from '../../Comunes/datosComunes';
import { esAjuste } from '../datosIncentivos';

const fd = new Intl.DateTimeFormat();
const nd = new Intl.NumberFormat({ minimumFractionDigits: 2, maximumFractionDigits: 2 });
const ndinc = new Intl.NumberFormat({ minimumFractionDigits: 0 });
const estadoErrorinicial = () => (
  {
    hayErrores: false,
    minutosEsperados: [],
    minutosProducidos: [],
    valorIncentivo: [],
  }
);
const CorreccionIncentivo = () => {
  const [eficiencia, setEficiencia] = useState(0);
  const [valorIncentivoCalculado, setValorIncentivo] = useState(0);
  const [errores, setError] = useState(estadoErrorinicial());
  return (
    <ContextIncentivos.Consumer>
      {
        ({
          minutosEsperados,
          fijarMinutosEsperados,
          minutosProducidos,
          fijarMinutosProducidos,
          valorIncentivo,
          fijarValorIncentivo,
          ejecutarConsultaIncentivo,
          fijarEstadoEdicionIncentivo,
          incentivoId,
          registrarCorreccionIncentivo,
          editandoIncentivo,
          incentivoActual,
        }) => {
          const calcularIncentivo = () => {
            if (!esAjuste(incentivoActual.tipo)) {
              setEficiencia((Math.round((parseFloat(minutosProducidos) /
                parseFloat(minutosEsperados)) * 100) / 100) * 100);
              setValorIncentivo(Math.round(Math.max((
                parseFloat(minutosProducidos) -
                  parseFloat(minutosEsperados)) * incentivoActual.valorMinuto, 0)));
              } else {
                setValorIncentivo(parseFloat(valorIncentivo));
              }
          };
          if (incentivoActual) {
            calcularIncentivo();
          }
          const cancelarCorreccion = () => {
            fijarEstadoEdicionIncentivo(false);
            setError(estadoErrorinicial());
          };
          const guardarCorreccion = async () => {
            if (validar()) {
              await registrarCorreccionIncentivo({
                incentivoId,
                minutosEsperados,
                minutosProducidos,
                valorIncentivo,
              });
              await fijarEstadoEdicionIncentivo(false);
              await ejecutarConsultaIncentivo(true);
            }
          };
          const validar = () => {
            if (!esAjuste(incentivoActual.tipo)) {
              return validarMinutos();
            }
            return validarIncentivo();
          };
          const validarIncentivo = () => {
            const xerrores = estadoErrorinicial();
            const valor = convertirDecimal(valorIncentivo);
            if (valorIncentivo == null || valorIncentivo === '') {
              xerrores.hayErrores = true;
              xerrores.valorIncentivo.push('Es obligatorio');
            } else if (isNaN(valor) || !esDecimalValido(valorIncentivo)) {
              xerrores.hayErrores = true;
              xerrores.valorIncentivo.push('Formato no válido');
            } else if (valor === 0) {
              xerrores.hayErrores = true;
              xerrores.valorIncentivo.push('Debe ser distinto de cero');
            }
            setError(xerrores);
            return !xerrores.hayErrores;
          };
          const validarMinutos = () => {
            const xerrores = estadoErrorinicial();
            let minutos = convertirDecimal(minutosProducidos);
            if (minutosProducidos == null || minutosProducidos === '') {
              xerrores.hayErrores = true;
              xerrores.minutosProducidos.push('Es obligatorio');
            } else if (isNaN(minutos) || !esDecimalValido(minutosProducidos)) {
              xerrores.hayErrores = true;
              xerrores.minutosProducidos.push('Formato no válido');
            } else if (minutos < 0) {
              xerrores.hayErrores = true;
              xerrores.minutosProducidos.push('Debe ser positivo');
            }
            minutos = convertirDecimal(minutosEsperados);
            if (minutosEsperados == null || minutosEsperados === '') {
              xerrores.hayErrores = true;
              xerrores.minutosEsperados.push('Es obligatrio');
            } else if (isNaN(minutos) || !esDecimalValido(minutosEsperados)) {
              xerrores.hayErrores = true;
              xerrores.minutosEsperados.push('Formato no válido');
            } else if (minutos < 0) {
              xerrores.hayErrores = true;
              xerrores.minutosEsperados.push('Debe ser positivo');
            }
            setError(xerrores);
            return !xerrores.hayErrores;
          };
          const mostrarError = (campo) => {
            let mensaje = null;
            if (errores && errores.hayErrores) {
              const mensajes = errores[campo];
              mensaje = mensajes.join(' - ');
            }
            return mensaje;
          };
          const minutosProducidosCambio = (e) => {
            fijarMinutosProducidos(e.target.value);
            setError({ ...errores, minutosProducidos: [] });
            calcularIncentivo();
          };
          const minutosEsperadosCambio = (e) => {
            fijarMinutosEsperados(e.target.value);
            setError({ ...errores, minutosEsperados: [] });
            calcularIncentivo();
          };
          const valorIncentivoCambio = (e) => {
            fijarValorIncentivo(e.target.value);
            setError({ ...errores, valorIncentivo: [] });
            calcularIncentivo();
          };
          const incentivoForm = () => (
            <Grid xs={2}>
              <Dialog
                open={editandoIncentivo}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                maxWidth="md"
              >
                <DialogTitle id="alert-dialog-title">
                  {incentivoId && `Corrección de incentivo (${incentivoId})`}
                </DialogTitle>
                <DialogContent>
                  <Grid container spacing={2} xs={12} direction="column">
                    <Grid container item direction="column" spaing={3}>
                      <Grid item xs={12}>
                        <strong>{`${incentivoActual.nombres} ${incentivoActual.apellidos}`}</strong>
                      </Grid>
                      <Grid item xs={12}>
                        <strong>Fecha </strong>
                        {fd.format(Date.parse(incentivoActual.fecha))}
                      </Grid>
                      {
                        !esAjuste(incentivoActual.tipo) &&
                        <Grid container item direction="column">
                          <Grid item xs={12}>
                            <strong>Turno </strong>{incentivoActual.esOrdinario ? 'ordinario' : 'extra'}
                          </Grid>
                          <Grid item x={12}>
                            <strong>Valor minuto </strong>{nd.format(incentivoActual.valorMinuto)}
                          </Grid>
                          <Grid item>
                            <strong>Eficiencia </strong>{`${nd.format(eficiencia || 0)}%`}
                          </Grid>
                        </Grid>
                      }
                      <Grid item xs={12}>
                        <strong>Incentivo </strong>{ndinc.format(valorIncentivoCalculado || 0)}
                      </Grid>
                    </Grid>
                    <Grid container item direction="column" spacing={2}>
                      {
                      !esAjuste(incentivoActual.tipo) &&
                      <React.Fragment>
                        <Grid item xs={12}>
                          <TextField
                            label="Minutos esperados"
                            value={minutosEsperados}
                            onChange={minutosEsperadosCambio}
                            error={errores.hayErrores && errores.minutosEsperados.length > 0}
                            helperText={mostrarError('minutosEsperados')}
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <TextField
                            label="Minutos producidos"
                            value={minutosProducidos}
                            onChange={minutosProducidosCambio}
                            error={errores.hayErrores && errores.minutosProducidos.length > 0}
                            helperText={mostrarError('minutosProducidos')}
                          />
                        </Grid>
                      </React.Fragment>
                      }
                    </Grid>
                    <Grid container item direction="column" spacing={2}>
                      {
                      esAjuste(incentivoActual.tipo) &&
                      <Grid item xs={12}>
                        <TextField
                          label="Valor ajuste"
                          value={valorIncentivo}
                          onChange={valorIncentivoCambio}
                          error={errores.hayErrores && errores.valorIncentivo.length > 0}
                          helperText={mostrarError('valorIncentivo')}
                        />
                      </Grid>
                      }
                    </Grid>
                  </Grid>
                </DialogContent>
                <DialogActions>
                  <Button onClick={guardarCorreccion} color="primary">
                    Guardar
                  </Button>
                  <Button onClick={cancelarCorreccion} color="primary">
                    Cancelar
                  </Button>
                </DialogActions>
              </Dialog>
            </Grid>
          );
          if (incentivoActual) {
            return incentivoForm();
          }
          return null;
        }
      }
    </ContextIncentivos.Consumer>
  );
};


export default CorreccionIncentivo;
