import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import Ubicaciones from './components/Ubicaciones';
import Loader from './../../shared/components/loader/Loader';
import { ApiUrl, AuthTokenKey } from './../../appConfig.json';
import { NotificationProps } from '../../shared/prop-types/ReducerProps';

class EficienciasUbicaciones extends Component {
  constructor(props) {
    super(props);
    this.state = {
      connection: props.connection,
      loading: true,
      infoSubseccion: {},
      temporizadorId: 0,
    };
  }

  componentDidMount = () => {
    const { subseccion } = this.props.match.params;

    this.getInfoSubseccionUbicacionesAsync(subseccion);

    const temporizadorId = setInterval(() => {
      this.timerActualizarPanel(subseccion);
    }, 60000);
    this.setState({ temporizadorId });

    this.state.connection.hubConnection.on('ActualizarPanel', (alarma) => {
      if (Number(alarma.subseccion) === Number(subseccion)) {
        this.timerActualizarPanel(subseccion);
      }
    });
  };
  componentWillUnmount = () => {
    clearInterval(this.state.temporizadorId);
  }

  /**
   * Obtiene todas las ubicaciones de una subseccion
   * y las establece en el estado.
   */
  getInfoSubseccionUbicacionesAsync = async (subseccionId) => {
    const response = await fetch(
      `${ApiUrl}/eficiencia/subseccion/${subseccionId}`,
      {
        mode: 'cors',
        method: 'GET',
        headers: {
          'Content-Type': 'application/json;charset=utf-8',
          Authorization: localStorage.getItem(AuthTokenKey),
        },
      },
    );

    const infoSubseccion = await response.json();

    this.setState({
      infoSubseccion,
      loading: false,
    });
  };

  timerActualizarPanel = async (subseccion) => {
    await this.getInfoSubseccionUbicacionesAsync(subseccion);

    this.setState({
      loading: true,
    });

    setTimeout(() => {
      this.setState({
        loading: false,
      });
    }, 1);
  };

  render() {
    const {
      infoSubseccion,
      loading,
    } = this.state;

    return (
      !loading ?
        <Ubicaciones
          subseccion={infoSubseccion}
        />
        : <Loader loading={loading} />
    );
  }
}

EficienciasUbicaciones.propTypes = {
  match: PropTypes.objectOf(PropTypes.any).isRequired,
  connection: NotificationProps.isRequired,
};

export default connect(state => ({
  connection: state.notification,
}))(EficienciasUbicaciones);
