import React from 'react';
import TablaInfoLote from './TablaInfoLote';
import Context from '../../../Operaciones/store';

function InfoLote() {
  return (
    <>
      <Context.Consumer>
        {
          (value) => {
            const Lote = {
              codigoLote: '',
              codigoTalla: '',
              ordenFert: '',
              ordenHalb: '',
              materialFert: '',
              materialHalb: '',
            };
            if (value.lote === null) {
              return (
                <TablaInfoLote Lote={Lote} />
              );
            }
            return (
              <TablaInfoLote Lote={value.lote} />
            );
          }
        }
      </Context.Consumer>
    </>
  );
}
export default InfoLote;
