import React from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import IndicadorPasillo from './IndicadorPasillo';
import MaquinaTabla from './MaquinaTabla';


const Pasillo = ({
  horario, maquinasFiltradas, urlBase, handleChangeTime, eficiencia,
  handleChangeFilterParqueadero, handleChangeFilterMaquina, pasillo,
}) => (
  <>
    <Grid container>
      <>
        {
          <Grid item xs={12}>
            { maquinasFiltradas.length > 0 &&
              <IndicadorPasillo
                horario={horario}
                notificada={pasillo.notificada}
                proyeccion={pasillo.proyeccion}
                meta={pasillo.meta}
                NumPasillo={pasillo.codigoPasillo}
                handleChangeTime={handleChangeTime}
                eficiencia={eficiencia}
              />
            }
          </Grid>
        }
        <Grid item xs={12}>
          <MaquinaTabla
            data={maquinasFiltradas}
            urlBase={urlBase}
            handleChangeFilterParqueadero={handleChangeFilterParqueadero}
            handleChangeFilterMaquina={handleChangeFilterMaquina}
          />
        </Grid>
      </>
    </Grid>
    </>
);

Pasillo.propTypes = {
  horario: PropTypes.string.isRequired,
  pasillos: PropTypes.arrayOf(PropTypes.any).isRequired,
  consolidadoMaquina: PropTypes.arrayOf(PropTypes.any).isRequired,
  pasilloData: PropTypes.objectOf(PropTypes.any).isRequired,
  base: PropTypes.string.isRequired,
  pasillo: PropTypes.objectOf(PropTypes.any).isRequired,
  handleChangeTime: PropTypes.isRequired,
  handleChangeFilterParqueadero: PropTypes.isRequired,
  handleChangeFilterMaquina: PropTypes.isRequired,
};

export default Pasillo;
