import React from 'react';
import {
  makeStyles,
  Button,
  TextField,
  Dialog,
  DialogContent,
  DialogTitle,
  Typography,
  MenuItem,
  Select,
  InputLabel,
  FormControl,
  DialogActions,
  Radio,
  RadioGroup,
  FormLabel,
  FormControlLabel,
  Tooltip,
} from '@material-ui/core';

import { DoneIcon } from 'mdi-react';

import config from '../../../../appConfig.json';
import Context from '../../store';

import Alert from '../../../../shared/components/alert/Alert';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 200,
  },
  formControl: {
    margin: theme.spacing(5),
    minWidth: 242,
  },
  formControl2: {
    margin: theme.spacing(3),
  },
  size: {
    width: 149,
  },
  sizeTextField: {
    height: '10px',
  },
}));

export default function AdicionarOperacion(props) {
  const [open, setOpen] = React.useState(false);
  const [alert, setAlert] = React.useState(false);
  const [valu, setValue] = React.useState('si');
  const [subseccion, setSubseccion] = React.useState('Subsección');
  const [OperacionGeneral, setOperacionGeneral] = React.useState({
    OperacionGeneral: '',
    Descripcion: '',
    HomologoSAP: '',
    SubseccionId: 0,
    TipoOperacion: true,
    Estandar: 0,
    Usuario: localStorage.getItem(config.CryUsername),
  });
  const classes = useStyles();

  const handleClose = () => {
    setAlert(false);
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };
  const handleChangeOpc = (e) => {
    if (e.target.value === 'si') {
      const array = OperacionGeneral;
      array.TipoOperacion = true;
    } else {
      const array = OperacionGeneral;
      array.TipoOperacion = false;
    }
    setValue(e.target.value);
  };

  const handleChange = (e) => {
    const array = OperacionGeneral;
    array[e.target.id] = e.target.value;
    setOperacionGeneral(array);
  };

  const handleChangeEstandar = (e) => {
    const array = OperacionGeneral;
    array[e.target.id] = Number.parseFloat(e.target.value);
    setOperacionGeneral(array);
  };


  const handleChangeSubseccionId = (e) => {
    const array = OperacionGeneral;
    array[e.target.name] = e.target.value;
    setOperacionGeneral(array);
    setSubseccion(e.target.value);
  };

  const handleSave = async () => {
    const { handleOperacionesReload } = props;
    const request = await fetch(`${config.ApiUrl}/operaciones/nueva`, {
      mode: 'cors',
      method: 'PUT',
      body: JSON.stringify(OperacionGeneral),
      headers: {
        'Content-Type': 'application/json',
        Authorization: localStorage.getItem(config.AuthTokenKey),
        Accept: 'application/json',
      },
    });
    const response = await request.json();
    if (!response) {
      setAlert(true);
    } else {
      setAlert(false);
      handleClose();
      handleOperacionesReload();
    }
  };
  return (
    <>
      <Context.Consumer>
        {
          value =>
            (
            <>
              <Tooltip title="Crea una operación general" >
                <Button
                  style={{ backgroundColor: '#263238', width: '50%' }}
                  onClick={handleOpen}
                >
                  <DoneIcon style={{ color: 'white' }} />
                  <h5 style={{ color: 'white' }}><strong>CREAR</strong></h5>
                </Button>
              </Tooltip>
              <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title" >
                <form
                  onSubmit={(e) => {
                  e.preventDefault();
                  handleSave();
                   }}
                >
                  <DialogTitle id="form-dialog-title">
                    <Typography gutterBottom variant="h4" align="center">
                      ADICIONAR OPERACIÓN
                    </Typography>
                  </DialogTitle>
                  <DialogContent>
                    <Alert
                      color="danger"
                      className="alert--bordered"
                      icon
                      visible={alert}
                    >
                      ESTA OPERACIÓN GENERAL YA ESTA REGISTRADA.
                    </Alert>
                    <div className={classes.root}>
                      <div>
                        <TextField
                          required
                          label="Código"
                          id="OperacionGeneral"
                          className={classes.textField}
                          variant="outlined"
                          color="secondary"
                          size="small"
                          type="number"
                          margin="dense"
                          onChange={handleChange}
                        />
                        <TextField
                          required
                          id="Descripcion"
                          label="Nombre o Descripción de la operación general"
                          style={{ margin: 8 }}
                          fullWidth
                          type="text"
                          margin="normal"
                          InputLabelProps={{
                         shrink: true,
                      }}
                          onChange={handleChange}
                        />
                        <TextField
                          label="HomologoSAP"
                          id="HomologoSAP"
                          className={classes.textField}
                          margin="dense"
                          type="number"
                          onChange={handleChange}
                        />
                        <FormControl style={{ marginLeft: '16%', margin: '5px', minWidth: '242px' }} required>
                          <InputLabel id="Subseccion">Subsección</InputLabel>
                          <Select
                            labelId="Subseccion"
                            name="SubseccionId"
                            onChange={handleChangeSubseccionId}
                            value={subseccion}
                          >
                            {props.subsecciones.map(sub => (<MenuItem value={sub.id}>{sub.descripcion}</MenuItem>))}
                          </Select>
                        </FormControl>
                      </div>
                      <div>
                        <FormControl component="fieldset" className={classes.formControl2}>
                          <FormLabel component="legend">¿Es una operación especial?</FormLabel>
                          <RadioGroup aria-label="gender" name="gender1" value={valu} onChange={handleChangeOpc}>
                            <FormControlLabel value="si" control={<Radio color="primary" />} label="Si" />
                            <FormControlLabel value="no" control={<Radio color="primary" />} label="No" />
                          </RadioGroup>
                        </FormControl>
                        <TextField
                          required
                          label="Estandar"
                          id="Estandar"
                          className={classes.textField}
                          variant="outlined"
                          color="secondary"
                          margin="dense"
                          size="small"
                          type="text"
                          style={{ width: 100 }}
                          onChange={handleChangeEstandar}
                        />

                      </div>
                    </div>
                  </DialogContent>
                  <DialogActions>
                    <Button onClick={handleClose} color="primary">
                       Cancel
                    </Button>
                    <Button
                      type="submit"
                      color="primary"
                    >
                      Guardar
                    </Button>
                  </DialogActions>
                </form>
              </Dialog>
            </>)
        }
      </Context.Consumer>

    </>
  );
}
