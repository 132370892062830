import React from 'react';
import PropTypes from 'prop-types';
import {
  Col,
  Badge,
  UncontrolledButtonDropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from 'reactstrap';
import Grid from '@material-ui/core/Grid';

import Eficiencia from '../../../shared/components/eficiencia/Eficiencia';
import { redondear } from '../../Comunes/datosComunes';

const IndicadorPasillo = ({
  horario,
  NumPasillo,
  handleChangeTime,
  eficiencia,
  proyeccion,
  meta,
  notificada,
}) => (
  <Grid container style={{ marginTop: '2%' }} >
    <Grid style={{ marginBlockEnd: '2%' }} item xs={12} className="d-flex justify-content-center align-items-center">
      <Col xs={2} sm={6} className="d-flex justify-content-start">
        <UncontrolledButtonDropdown>
          <DropdownToggle caret className="btn-rounded" outline>
            <span className="lnr lnr-calendar-full" /> {horario}
          </DropdownToggle>
          <DropdownMenu>
            <DropdownItem value="Turno" onClick={handleChangeTime} >Turno</DropdownItem >
            <DropdownItem value="Dia" onClick={handleChangeTime} >Día</DropdownItem >
          </DropdownMenu>
        </UncontrolledButtonDropdown>
      </Col>
      <Col xs={10} sm={6}>
        <h5 className="bold-text">{`PASILLO ${NumPasillo}`}</h5>
      </Col>
    </Grid>
    <Grid item xs={5}>
      <Eficiencia
        porcentaje={eficiencia.porcentajeEficienciaLote * 100}
        cy={93}
        innerRadius={82}
        outerRadius={90}
        round={1}
      />
    </Grid>
    <Grid item xs={7}>
      <Grid container>
        <Grid style={{ marginBlockEnd: '2%' }} item xs={3}>
          <b>2DAS REP: </b>
          <h4>
            <Badge style={{ width: '50%' }} color="dark" pill>{
              redondear(eficiencia.porcentajeSegundasReportadas * 100, 1)} %
            </Badge>
          </h4>
        </Grid>
        <Grid style={{ marginBlockEnd: '2%' }} item xs={3}>
          <b>Ef. Op: </b>
          <h4>
            <Badge style={{ width: '50%' }} color="dark" pill>{
              redondear(eficiencia.porcentajeEficienciaOperativa * 100, 1)} %
            </Badge>
          </h4>
        </Grid>
        <Grid style={{ marginBlockEnd: '2%' }} item xs={3}>
          <b>PLC: </b>
          <h4>
            <Badge style={{ width: '50%' }} color="dark" pill>{Math.round(eficiencia.docenasPLC)} </Badge>
          </h4>
        </Grid>
        <Grid style={{ marginBlockEnd: '2%' }} item xs={3}>
          <b>PRYCN: </b>
          <h4>
            <Badge style={{ width: '50%' }} color="dark" pill>{Math.round(proyeccion)} </Badge>
          </h4>
        </Grid>
        <Grid style={{ marginBlockEnd: '2%' }} item xs={3}>
          <b>PO: </b>
          <h4>
            <Badge style={{ width: '50%' }} color="dark" pill>{
              redondear(eficiencia.porcentajeParoOperativo * 100, 1)}%
            </Badge>
          </h4>
        </Grid>
        <Grid style={{ marginBlockEnd: '2%' }} item xs={3}>
          <b>DM: </b>
          <h4>
            <Badge style={{ width: '50%' }} color="dark" pill>{
              redondear(eficiencia.porcentajeParoMecanico * 100, 1)} %
            </Badge>
          </h4>
        </Grid>
        <Grid style={{ marginBlockEnd: '2%' }} item xs={3}>
          <b>CR+CCR: </b>
          <h4>
            <Badge style={{ width: '50%' }} color="dark" pill>{
              redondear((eficiencia.porcentajeParoColaCambioReferencia +
              eficiencia.porcentajeParoCambioReferencia) * 100, 1)} %
            </Badge>
          </h4>
        </Grid>
        <Grid style={{ marginBlockEnd: '2%' }} item xs={3}>
          <b>MPB+MPT: </b>
          <h4>
            <Badge style={{ width: '50%' }} color="dark" pill>{
              redondear((eficiencia.porcentajeParoMateriaPrimaBodega +
              eficiencia.porcentajeParoMateriaPrimaTej) * 100, 1)}%
            </Badge>
          </h4>
        </Grid>
        <Grid style={{ marginBlockEnd: '2%' }} item xs={3}>
          <b>OTROS: </b>
          <h4>
            <Badge style={{ width: '70%' }} color="dark" pill>{
            redondear((eficiencia.porcentajeParoElectronico +
            eficiencia.porcentajeParoFaltaRepuesto + eficiencia.porcentajeParoServicios +
            eficiencia.porcentajeParoCambioPinta + eficiencia.porcentajeParoLimpieza
            + eficiencia.porcentajeParoOff) * 100, 1)} %
            </Badge>
          </h4>
        </Grid>
      </Grid>
    </Grid>
  </Grid>
);

IndicadorPasillo.propTypes = {
  horario: PropTypes.string.isRequired,
  eficiencia: PropTypes.objectOf(PropTypes.any).isRequired,
  NumPasillo: PropTypes.number.isRequired,
  proyeccion: PropTypes.number.isRequired,
  meta: PropTypes.number.isRequired,
  notificada: PropTypes.number.isRequired,
  handleChangeTime: PropTypes.isRequired,
};

export default IndicadorPasillo;
