import React from 'react';
import PropTypes from 'prop-types';
import { Card, CardBody, Col } from 'reactstrap';
import { Link, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

import Eficiencia from '../../../shared/components/eficiencia/Eficiencia';
import { changeUbicaciontoCalcetines as changeUbicaciontoCalcetinesAction,
  changeUbicaciontoVestuario as changeUbicaciontoVestuarioAction,
  changeUbicaciontoSeamless as changeUbicaciontoSeamlessAction } from '../../../redux/actions/ubicacionActions';
import Config from '../../../appConfig.json';

const IndicadorEficienciaPlantas = (props) => {
  if (props.unidadNegocioId === Config.unidadNegocioCalcetines) {
    props.changeUbicaciontoCalcetines();
  } else if (props.unidadNegocioId === Config.unidadNegocioVestuario) {
    props.changeUbicaciontoVestuario();
  } else if (props.unidadNegocioId === Config.unidadNegocioSeamless) {
    props.changeUbicaciontoSeamless();
  }
  return (
    <Col md={6} lg={4}>
      <Link to={`${props.base}`}>
        <Card>
          <CardBody>
            <div className="card__title">
              <h5 className="bold-text text-center">{props.planta}</h5>
            </div>
            <Eficiencia porcentaje={props.eficiencia} />
          </CardBody>
        </Card>
      </Link>
    </Col>);
};

const mapDispatchToProps = dispatch => ({
  changeUbicaciontoCalcetines: () => dispatch(changeUbicaciontoCalcetinesAction()),
  changeUbicaciontoVestuario: () => dispatch(changeUbicaciontoVestuarioAction()),
  changeUbicaciontoSeamless: () => dispatch(changeUbicaciontoSeamlessAction()),
});

const mapStateToProps = state => ({
  changeUbicaciontoCalcetines: state.changeUbicaciontoCalcetines,
  changeUbicaciontoVestuario: state.changeUbicaciontoVestuario,
  changeUbicaciontoSeamless: state.changeUbicaciontoSeamless,

});

IndicadorEficienciaPlantas.propTypes = {
  unidadNegocioId: PropTypes.number.isRequired,
  planta: PropTypes.string.isRequired,
  // codigo: PropTypes.number.isRequired,
  base: PropTypes.string.isRequired,
  eficiencia: PropTypes.number.isRequired,
  changeUbicaciontoCalcetines: PropTypes.func.isRequired,
  changeUbicaciontoVestuario: PropTypes.func.isRequired,
  changeUbicaciontoSeamless: PropTypes.func.isRequired,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(IndicadorEficienciaPlantas);
