import React from 'react';
import { IconButton,
  TextField,
  Paper,
  Grid } from '@material-ui/core';
import { AddCircleIcon } from 'mdi-react';
import { perteneceRolIngenieria } from '../../../Comunes/datosComunes';

export default function Form(props) {
  return (
    <>
      <Paper>
        <Grid container style={{ margin: '2%', padding: '2%' }} >
          <Grid item xs={12}>
            <h5 style={{ marginTop: '3%', marginBlockEnd: '1%' }} >Información pasillo</h5>
          </Grid>
          <Grid item xs={8} style={{ marginBlockEnd: '3%' }}>
            <TextField
              id="codigo"
              label="Código"
              value={props.codigo}
              onChange={(e) => { props.onChange(e); }}
            />
          </Grid>
          <Grid item xs={4} style={{ marginTop: '3%' }}>
            {
              perteneceRolIngenieria() &&
              <IconButton size="small" onClick={() => props.save()} style={{ marginTop: '7%' }} >
                <AddCircleIcon color="primary" />
              </IconButton>
            }
          </Grid>
        </Grid>
      </Paper>
    </>
  );
}
