import {
  CHANGE_UBICACION_TO_CALCETINES,
  CHANGE_UBICACION_TO_VESTUARIO,
  CHANGE_UBICACION_TO_SEAMLESS,
  RESTABLECER_UBICACION,
} from '../actions/ubicacionActions';
import Config from '../../appConfig.json';

const initialState = {
  Ubicacion: 0,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case CHANGE_UBICACION_TO_CALCETINES:
      return { Ubicacion: Config.unidadNegocioCalcetines };
    case CHANGE_UBICACION_TO_VESTUARIO:
      return { Ubicacion: Config.unidadNegocioVestuario };
    case CHANGE_UBICACION_TO_SEAMLESS:
      return { Ubicacion: Config.unidadNegocioSeamless };
    case RESTABLECER_UBICACION:
      return { Ubicacion: 0 };
    default:
      return state;
  }
}
