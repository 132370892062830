import config from './../../../../appConfig.json';
import { ejecutarConsulta, descargarArchivo } from './../../../Comunes/datosComunes';

async function obtenerOrdenesProgramadasReserva(signal) {
  return ejecutarConsulta(
    `${config.ApiUrl}/ordenes-calceteria/ordenes-programadas-reserva`,
    'GET',
    null,
    signal,
  );
}
async function descargarExcelOrdenesProgramadasReservas(signal) {
  return descargarArchivo(
    `${config.ApiUrl}/ordenes-calceteria/ordenes-programadas-reserva/excel`,
    'GET',
    null,
    'Secciones_turnos.xlsx',
    signal,
  );
}

export { obtenerOrdenesProgramadasReserva, descargarExcelOrdenesProgramadasReservas };
