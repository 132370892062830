import React from 'react';
import {
  makeStyles,
  Button,
  TextField,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Tooltip,
  DialogActions,
} from '@material-ui/core';
import { EditIcon } from 'mdi-react';

import Context from '../../store';

import config from '../../../../appConfig.json';

const useStyles = makeStyles(theme => ({
  textField: {
    width: '200px',
    '& label.Mui-focused': {
      color: 'red',
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: 'red',
    },
    '& .MuiOutlinedInput-root': {
      '&:hover fieldset': {
        borderColor: 'blue',
      },
      '&.Mui-focused fieldset': {
        borderColor: 'red',
      },
    },
  },
  textField2: {
    width: '189px',
    '& label.Mui-focused': {
      color: 'red',
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: 'red',
    },
    '& .MuiOutlinedInput-root': {
      '&:hover fieldset': {
        borderColor: 'blue',
      },
      '&.Mui-focused fieldset': {
        borderColor: 'red',
      },
    },
  },
  textField3: {
    '& label.Mui-focused': {
      color: 'red',
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: 'red',
    },
    '& .MuiOutlinedInput-root': {
      '&.Mui-focused fieldset': {
        borderColor: 'red',
      },
    },
  },
}));

function ModificarDetallada(props) {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const [Detallada, setDetallada] = React.useState({
    CodigoOperacionDetallada: '',
    DescripcionOperacionDetallada: '',
    OperacionGeneral: 0,
    Subseccion: 0,
    Usuario: '',
  });

  const handleOpen = (op, det) => {
    const array = Detallada;
    array.CodigoOperacionDetallada = det.codigoOperacionDetallada;
    array.DescripcionOperacionDetallada = det.descripcionOperacionDetallada;
    array.OperacionGeneral = op.id;
    array.Subseccion = op.subseccion.id;
    array.Usuario = op.usuario;
    setDetallada(array);
    setOpen(true);
  };

  const handleChange = (e) => {
    const array = Detallada;
    array[e.target.id] = e.target.value;
    setDetallada(array);
  };

  const handleClose = () => {
    setOpen(false);
  };
  return (
    <>
      <Context.Consumer>
        {
          value =>
            (
               <>
                 <Tooltip title="Modifica esta operación detallada" >
                   <IconButton
                     aria-label="Modifica esta operación detallada"
                     onClick={() => {
                       handleOpen(value.operacion, props.detallada);
                      }}
                   >
                     <EditIcon />
                   </IconButton>
                 </Tooltip>
                 <Dialog
                   open={open}
                   onClose={handleClose}
                   aria-labelledby="form-dialog-title"
                 >
                   <form
                     onSubmit={async (e) => {
                     e.preventDefault();
                      await fetch(`${config.ApiUrl}/operaciones/detallada/${props.detallada.id}/editar`, {
                      mode: 'cors',
                      method: 'POST',
                      body: JSON.stringify(Detallada),
                      headers: {
                        'Content-Type': 'application/json',
                        Authorization: localStorage.getItem(config.AuthTokenKey),
                        Accept: 'application/json',
                      },
                    });
                    const response2 = await
                    fetch(
                      `${config.ApiUrl}/Operaciones/${Detallada.OperacionGeneral}/OperacionesDet`,
                      {
                        headers: {
                          'Content-Type': 'application/json',
                          Authorization: localStorage.getItem(config.AuthTokenKey),
                          Accept: 'application/json',
                        },
                      },
                    );
                    const det = await response2.json();
                    value.activateDet(det);
                    value.activateDetOrigin(det);
                    }}
                   >
                     <DialogTitle id="form-dialog-title" align="center">MODIFICAR OPERACIÓN DETALLADA</DialogTitle>
                     <DialogContent>
                       <div className="container">
                         <div className="row">
                           <div className="col-12">
                             <TextField
                               className={classes.textField3}
                               readonly
                               label="OperacionGeneral"
                               id="OperacionGeneral"
                               value={value.operacion.descripcion.toUpperCase()}
                               fullWidth
                               margin="dense"
                             />
                           </div>
                           <div className="col-6">
                             <TextField
                               readonly
                               label="Código"
                               id="CodigoOperacionDetallada"
                               className={classes.textField}
                               margin="dense"
                               variant="outlined"
                               value={Detallada.CodigoOperacionDetallada}
                             />
                           </div>
                           <div className="col-6">
                             <TextField
                               readonly
                               label="Subsección"
                               id="Subseccion"
                               className={classes.textField2}
                               margin="dense"
                               value={value.operacion.subseccion.descripcion.toUpperCase()}
                               variant="outlined"
                             />
                           </div>
                           <div className="col-12">
                             <TextField
                               required
                               defaultValue={Detallada.DescripcionOperacionDetallada.toUpperCase()}
                               id="DescripcionOperacionDetallada"
                               label="Descripción"
                               style={{ margin: 8 }}
                               helperText="Modifica la descripción o nombre la operación detallada."
                               fullWidth
                               onChange={handleChange}
                               margin="normal"
                               InputLabelProps={{
                                        shrink: true,
                                   }}
                             />
                           </div>
                         </div>
                       </div>
                     </DialogContent>
                     <DialogActions>
                       <Button type="submit" onClick={handleClose} color="primary">
                           Guardar
                       </Button>
                       <Button onClick={handleClose} color="primary">
                          Cancelar
                       </Button>
                     </DialogActions>
                   </form>
                 </Dialog>
               </>
           )
          }
      </Context.Consumer>
    </>
  );
}

export default ModificarDetallada;
