import React from 'react';
import {
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Paper,
} from '@material-ui/core';

import ModificarDetallada from './ModificarDetallada';
import EliminarDetallada from './EliminarDetallada';
import Context from '../../store';
import { perteneceRolIngenieria } from '../../../Comunes/datosComunes';

const useStyles = makeStyles(theme => ({
  container: {
    height: `${window.screen.height - 240}px`,
    position: 'relative',
    overflowY: 'auto',
    width: '100%',
  },
  letter: {
    color: 'black',
  },
}));

export default function ListaOperacionesDetalladas() {
  const classes = useStyles();

  return (
    <>
      <Context.Consumer>
        {
          (value) => {
            if (value.detalladas.length === 0) {
              return (
                <Paper>
                  <div className={classes.container}>
                    <Table stickyHeader aria-label="sticky table" size="small">
                      <TableHead>
                        <TableRow>
                          {
                            perteneceRolIngenieria() &&
                            <TableCell className={classes.letter} >
                              <span
                                style={{ fontSize: '100%' }}
                                className="MuiTypography-root MuiTypography-button MuiTypography-gutterBottom"
                              >
                                ACCIONES
                              </span>
                            </TableCell >
                          }
                          <TableCell className={classes.letter}>
                            <span
                              style={{ fontSize: '100%' }}
                              className="MuiTypography-root MuiTypography-button MuiTypography-gutterBottom"
                            >
                              CÓDIGO
                            </span>
                          </TableCell>
                          <TableCell className={classes.letter}>
                            <span
                              style={{ fontSize: '100%' }}
                              className="MuiTypography-root MuiTypography-button MuiTypography-gutterBottom"
                            >
                              DESCRIPCIÓN
                            </span>
                          </TableCell>
                        </TableRow>
                      </TableHead>
                    </Table>
                  </div>
                </Paper>
              );
            }
              return (
                <Paper >
                  <div className={classes.container}>
                    <Table stickyHeader aria-label="sticky table" size="small">
                      <TableHead>
                        <TableRow>
                          {
                            perteneceRolIngenieria() &&
                            <TableCell className={classes.letter} >
                              <span
                                style={{ fontSize: '100%' }}
                                className="MuiTypography-root MuiTypography-button MuiTypography-gutterBottom"
                              >
                                ACCIONES
                              </span>
                            </TableCell >
                          }
                          <TableCell className={classes.letter}>
                            <span
                              style={{ fontSize: '100%' }}
                              className="MuiTypography-root MuiTypography-button MuiTypography-gutterBottom"
                            >
                                CÓDIGO
                            </span>
                          </TableCell>
                          <TableCell className={classes.letter}>
                            <span
                              style={{ fontSize: '100%' }}
                              className="MuiTypography-root MuiTypography-button MuiTypography-gutterBottom"
                            >
                                DESCRIPCIÓN
                            </span>
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {value.detalladas.map(row => (
                          <TableRow key={row.id} size="small">
                            {
                              perteneceRolIngenieria() &&
                              <TableCell >
                                <ModificarDetallada detallada={row} />
                                <EliminarDetallada detallada={row} />
                              </TableCell>
                            }
                            <TableCell>
                              {row.codigoOperacionDetallada}
                            </TableCell>
                            <TableCell >{row.descripcionOperacionDetallada.toUpperCase()}</TableCell>
                          </TableRow>
                  ))}
                      </TableBody>
                    </Table>
                  </div>
                </Paper>
              );
          }
        }
      </Context.Consumer>
</>);
}

