import React, { useState, useEffect } from 'react';
import { createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles';
import {
  Container,
  Grid,
  Typography,
  Button,
} from '@material-ui/core';
import { FileExcelIcon } from 'mdi-react';
import { obtenerOrdenesProgramadasReserva, descargarExcelOrdenesProgramadasReservas } from './datos';
import Alerta from '../../../Comunes/Alerta';
import Reporte from './Reporte';

const theme = createMuiTheme({
  palette: {
    primary: { main: '#263238' },
    secondary: { main: '#1a237e' },
  },
});

class ReporteCalceteriaPoolGanttOrdenesReservas extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      consultando: false,
      datos: [],
      error: false,
    };
    this.obtenerDatos = this.obtenerDatos.bind(this);
    this.exportarExcel = this.exportarExcel.bind(this);
  }
  async componentDidMount() {
    await this.obtenerDatos();
  }
  abortController = new AbortController();
  async obtenerDatos() {
    await this.setState({ consultando: true, error: false });
    const respuesta = await obtenerOrdenesProgramadasReserva(this.abortController.signal);
    if (!respuesta.error) {
      await this.setState({ datos: respuesta.datos, consultando: false });
    } else {
      await this.setState({ datos: [], consultando: false, error: true });
    }
  }
  async exportarExcel() {
    try {
      await this.setState({ consultando: true, error: false });
      await descargarExcelOrdenesProgramadasReservas(this.abortController.signal);
      await this.setState({ consultando: false });
    } catch (e) {
      await this.setState({ error: true });
    } finally {
      await this.setState({ consultando: false });
    }
  }
  resetearError = async () => this.setState({ error: false })
  render() {
    return (
      <React.Fragment>
        <MuiThemeProvider theme={theme}>
          <Container style={{ marginTop: '16px' }} >
            <Grid xs={12} container spacing={1} direction="column" alignItems="center">
              <Grid item xs={12}>
                <Alerta
                  texto="Ocurrió un error de ejecución. Intente nuevamente."
                  toggle={this.resetearError}
                  isVisible={this.state.error}
                />
              </Grid>
              <Grid item xs={12}>
                <Typography variant="h5">INFORME GANTT RESERVAS</Typography>
              </Grid>
              <Grid container item direction="row" justify="center" xs={12} spacing={1} alignContent="center">
                <Grid item>
                  <Button
                    className="btn btn-primary"
                    variant="outlined"
                    onClick={async () => {
                      await this.obtenerDatos();
                    }}
                    disabled={this.state.consultando}
                  >
                    Refrescar
                  </Button>
                </Grid>
                <Grid item>
                  <Button
                    className="btn btn-secondary"
                    variant="outlined"
                    onClick={this.exportarExcel}
                    disabled={
                      this.state.consultando
                    }
                  >
                    <FileExcelIcon />&nbsp;
                    Exportar
                  </Button>
                </Grid>
              </Grid>
              <Grid item xs={12} direction="column">
                <Reporte
                  consultando={this.state.consultando}
                  ordenes={this.state.datos}
                />
              </Grid>
            </Grid>
          </Container>
        </MuiThemeProvider>
      </React.Fragment>
    );
  }
}

export default ReporteCalceteriaPoolGanttOrdenesReservas;
