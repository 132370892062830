import React from 'react';
import { Tooltip,
  Button,
  Dialog,
  DialogContent,
  DialogActions,
  DialogTitle,
  Grid,
  Typography,
  TextField,
  IconButton } from '@material-ui/core';
import { EditIcon } from 'mdi-react';
import config from '../../../../appConfig.json';
import Context from '../../../Operaciones/store';

export default function ModificarSubseccion(props) {
  const [open, setOpen] = React.useState(false);
  const [seccion, setSeccion] = React.useState(props.subseccion);

  const handleChange = (e) => {
    const array = { ...seccion };
    array[e.target.id] = e.target.value;
    setSeccion(array);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  return (
    <>
      <Context.Consumer>
        {
          (value) => {
            const handleSave = async () => {
              const array = {
                ...seccion,
                seccion: value.operacion.id,
                usuario: localStorage.getItem(config.CryUsername),
              };
              const request = await fetch(`${config.ApiUrl}/secciones/editar/subseccion/${seccion.id}`, {
                mode: 'cors',
                method: 'POST',
                body: JSON.stringify(array),
                headers: {
                  'Content-Type': 'application/json',
                    Authorization: localStorage.getItem(config.AuthTokenKey),
                    Accept: 'application/json',
                },
              });
              const response = await request.json();
              props.fetch();
              handleClose();
            };
            return (
              <>
                <Tooltip title="Crea una subsección" >
                  <IconButton size="small" onClick={() => { handleOpen(); }} >
                    <EditIcon fontSize="small" color="primary" />
                  </IconButton>
                </Tooltip>
                <Dialog
                  open={open}
                  onClose={handleClose}
                  scroll="paper"
                  aria-labelledby="form-dialog-title"
                  maxWidth="sm"
                >
                  <form onSubmit={(e) => {
                    e.preventDefault();
                    handleSave();
                    }}
                  >
                    <DialogTitle>
                      <Typography gutterBottom variant="h6" align="center">
                        MODIFICAR SUBSECCIÓN
                      </Typography>
                    </DialogTitle>
                    <DialogContent dividers>
                      <Grid container>
                        <Grid item xs={6}>
                          <TextField
                            readOnly
                            label="Código"
                            id="codigo"
                            value={seccion.codigo}
                            type="number"
                          />
                        </Grid>
                        <Grid item xs={6}>
                          <TextField
                            readOnly
                            label="Seccion"
                            id="seccion"
                            value={value.operacion.descripcion}
                          />
                        </Grid>
                        <Grid item xs={12} >
                          <TextField
                            required
                            label="Descripción"
                            id="descripcion"
                            fullWidth
                            defaultValue={seccion.descripcion}
                            onChange={handleChange}
                          />
                        </Grid>
                        <Grid item xs={3}>
                          <TextField
                            label="Criterio uno"
                            id="criterioBusquedaUno"
                            defaultValue={seccion.criterioBusquedaUno}
                            onChange={handleChange}
                            type="number"
                          />
                        </Grid>
                        <Grid item xs={3}>
                          <TextField
                            label="Criterio dos"
                            id="criterioBusquedaDos"
                            defaultValue={seccion.criterioBusquedaDos}
                            onChange={handleChange}
                            type="number"
                          />
                        </Grid>
                        <Grid item xs={3}>
                          <TextField
                            label="Criterio tres"
                            id="criterioBusquedaTres"
                            defaultValue={seccion.criterioBusquedaTres}
                            onChange={handleChange}
                            type="number"
                          />
                        </Grid>
                        <Grid item xs={3}>
                          <TextField
                            label="Criterio cuatro"
                            id="criterioBusquedaCuatro"
                            defaultValue={seccion.criterioBusquedaCuatro}
                            onChange={handleChange}
                            type="number"
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <TextField
                            required
                            label="Alarma falta"
                            id="alarmaFaltaEstandar"
                            defaultValue={seccion.alarmaFaltaEstandar}
                            onChange={handleChange}
                            type="number"
                          />
                        </Grid>
                      </Grid>
                    </DialogContent>
                    <DialogActions>
                      <Button onClick={handleClose} color="default">
                        Cerrar
                      </Button>
                      <Button color="primary" type="submit" >
                        Modificar
                      </Button>
                    </DialogActions>
                  </form>
                </Dialog>
              </>
            );
          }
        }
      </Context.Consumer>
    </>
  );
}
