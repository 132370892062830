import React, { Component } from 'react';

import LogInForm from './components/LogInForm';
import config from './../../appConfig.json';
import Loader from '../../shared/components/loader/Loader';
import Alert from '../../shared/components/alert/Alert';
import History from '../../shared/components/history/History';

class LogIn extends Component {
  static extractRoles(jwt) {
    const token = jwt.split('.');
    const tkn = atob(token[1]);
    const obj = JSON.parse(tkn);
    return obj.role || [];
  }
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      errorMessage: '',
      requestError: false,
      errorStatus: 0,
    };
  }

  setUserSession = (AuthTokenKey, RolesKey, crystalJwt, CryUsername, username) => {
    localStorage.setItem(AuthTokenKey, `Bearer ${crystalJwt}`);
    localStorage.setItem(CryUsername, username.toUpperCase());
    const roles = LogIn.extractRoles(crystalJwt);
    localStorage.setItem(RolesKey, roles);
    this.setState({
      loading: false,
    });
    History.push('/');
  }

  checkUserIdentity = async (ApiUrl, username, password) => {
    const ApiDir = `${ApiUrl}/Token/Authorize`;

    const dataToSend = JSON.stringify({
      Username: username,
      Password: password,
    });

    const apiData = await fetch(ApiDir, {
      mode: 'cors',
      method: 'POST',
      body: dataToSend,
      headers: {
        'Content-Type': 'application/json;charset=utf-8',
        Authorization: localStorage.getItem(config.AuthTokenKey),
      },
    });

    return apiData;
  }

  checkUserData = (username, password) => {
    if (username === '' || password === '' || username === undefined || password === undefined) {
      this.setState({
        errorMessage: 'Usuario y contraseña deben ser diligenciados para acceder.',
        requestError: true,
        errorStatus: '',
      });
      return false;
    }
    return true;
  }

  handleFormData = async (data) => {
    const { username, password } = data;

    if (!this.checkUserData(username, password)) return;

    this.setState({
      loading: true,
      errorMessage: '',
      requestError: false,
      errorStatus: 0,
    });

    try {
      const {
        ApiUrl,
        AuthTokenKey,
        RolesKey,
        CryUsername,
      } = config;
      const response = await this.checkUserIdentity(ApiUrl, username, password);

      if (response.ok) {
        const result = await response.json();
        this.setUserSession(AuthTokenKey, RolesKey, result.crystalJwt, CryUsername, username);
      } else {
        throw response;
      }
    } catch (err) {
      this.setState({
        loading: false,
        errorStatus: err.status,
        errorMessage: 'Algo salio mal, por favor verifica usuario y contraseña e intenta de nuevo.',
        requestError: true,
      });
    }
  };

  render() {
    const {
      loading, errorMessage, errorStatus, requestError,
    } = this.state;
    return (
      <div>
        { loading && <Loader loading={loading} /> }
        <div className="account">
          <div className="account__wrapper">
            <div className="account__card">
              <div className="account__head">
                <h3 className="account__title">
                  <span className="account__logo">
                    <span className="account__logo-accent">Gestión de producción</span>
                    <img className="img" src="img/screenIcon.png" />
                  </span>
                </h3>
                <h4 className="account__subhead subhead">CRYSTAL S.A.S</h4>
              </div>
              <LogInForm
                onSubmit={
                  (formData) => { this.handleFormData(formData); }
                }
                loading={loading}
              />
              {
                requestError &&
                <Alert color="danger" className="alert--bordered" icon>
                  <p><span className="bold-text">{ errorStatus }</span> {errorMessage}.
                  </p>
                </Alert>
              }
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default LogIn;
