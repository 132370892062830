import React, { useEffect, useState } from 'react';
import {
  IconButton,
  Button,
  Dialog,
  DialogContent,
  DialogActions,
  makeStyles,
  Grid,
} from '@material-ui/core';
import { InformationIcon } from 'mdi-react';

import config from '../../../appConfig.json';
import Alert from '../../../shared/components/alert/Alert';
import Alerta from '../../Comunes/Alerta';

import TablaPasillos from './Components/TablaPasillos';
import Form from './Components/Form';

import Filtro from '../Filtro';

const useStyles = makeStyles(() => ({
  tableSize: {
    '& .MuiDialog-paperWidthSm': {
      maxWidth: '50%',
    },
  },
}));


export default function MestroPasillos(props) {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [alert, setAlert] = useState(false);
  const [alert2, setAlert2] = useState(false);
  const [pasillos, setPasillos] = useState([]);
  const [pasillosOriginales, setPasillosOriginales] = useState([]);
  const [codigo, setCodigo] = useState('');
  const [error, setError] = useState(false);

  const resetearError = () => {
    setError(false);
  };
  function fetchData() {
    try {
      window.fetch(
        `${config.ApiUrl}/maquinas/pasillos`,
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: localStorage.getItem(config.AuthTokenKey),
          },
        },
      )
        .then(res => res.json())
        .then((response) => {
          setPasillos(response);
          setPasillosOriginales(response);
        });
    } catch (e) {
      setError(false);
      console.error('Error al obtener datos:', e);
    }
  }

  useEffect(fetchData, []);

  const handleClose = () => {
    setAlert(false);
    setAlert2(false);
    setPasillos(pasillosOriginales);
    setOpen(false);
  };
  const handleOpen = () => {
    setOpen(true);
    setPasillos(pasillos);
    setPasillosOriginales(pasillosOriginales);
  };

  const handleChangeFilter = (e) => {
    const result = pasillos.filter(p =>
      (p.codigo.toLowerCase().indexOf(e.target.value.toLowerCase()) !== -1 && p));
    if (result.length !== 0 && e.target.value !== '') { setPasillos(result); }
    if (result.length === 0 && e.target.value === '') { setPasillos(pasillosOriginales); }
    if (result.length !== 0 && e.target.value === '') { setPasillos(pasillosOriginales); }
    if (result.length === 0 && e.target.value !== '') { setPasillos(pasillosOriginales); }
  };

  const handleClean = () => {
    setCodigo('');
  };

  const handleDelete = async (P) => {
    const request = await fetch(
      `${config.ApiUrl}/Maquinas/eliminar/pasillo/${P.id}`,
      {
        mode: 'cors',
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
          Authorization: localStorage.getItem(config.AuthTokenKey),
        },
      },
    );
    if (request.status === 400) {
      setAlert2(true);
    } else {
      fetchData();
      props.fetch();
    }
  };

  const handleInsert = async () => {
    const request = await fetch(`${config.ApiUrl}/maquinas/nuevo/pasillo`, {
      mode: 'cors',
      method: 'PUT',
      body: JSON.stringify({
        id: 0,
        codigo,
        eficienciaPasillo: 0,
        fechaCreacion: new Date(),
        fechaModificacion: new Date(),
      }),
      headers: {
        'Content-Type': 'application/json',
        Authorization: localStorage.getItem(config.AuthTokenKey),
        Accept: 'application/json',
      },
    });
    const response = await request.json();
    if (response.sw === 1) {
      setAlert(true);
    } else {
      fetchData();
      props.fetch();
    }
  };

  const handleChangeInputs = (e) => {
    if (e.target.id === 'codigo') setCodigo(e.target.value);
  };

  const handleSave = async () => {
    if (codigo !== '') {
      handleInsert();
    }
  };
  return (
    <>
      <Alerta
        texto="Ocurrió un error de ejecución. Intente nuevamente."
        toggle={resetearError}
        isVisible={error}
      />
      <IconButton onClick={handleOpen} size="small">
        <InformationIcon color="primary" />
      </IconButton>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
        className={classes.tableSize}
      >
        <DialogContent style={{ paddingTop: '3%' }} >
          <Alert
            color="danger"
            className="alert--bordered"
            icon
            visible={alert}
          >
            ESTE PASILLO YA ESTA REGISTRADO.
          </Alert>
          <Alert
            color="danger"
            className="alert--bordered"
            icon
            visible={alert2}
          >
            ESTE PASILLO TIENE DETALLES ASOCIADOS.
          </Alert>
          <Grid container>
            <Grid item xs={8}>
              <div style={{ width: '50%' }}>
                <Filtro onChange={handleChangeFilter} label="Pasillo" helperText="Buscar pasillos" margen="5%" />
              </div>
            </Grid>
            <Grid item xs={4}>
              <Form
                codigo={codigo}
                onChange={handleChangeInputs}
                save={handleSave}
              />
            </Grid>
            <Grid item xs={12}>
              <TablaPasillos pasillos={pasillos} clean={handleClean} delete={handleDelete} />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cerrar
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
