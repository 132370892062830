import React, { useRef } from 'react';
import { PrinterIcon } from 'mdi-react';
import ReactToPrint from 'react-to-print';
import Filtro from './Components/Filtro';
import Tablas from './Components/Tablas';
import Reactdataexport from './Components/reactdataexport';
import './Components/styles/style.css';

function InformeLote() {
  const componentRef = useRef();
  return (
    <div
      className="container"
      style={{ paddingRight: '15px', paddingLeft: '15px', marginTop: '1.5%' }}
    >
      <div className="row">
        <div className="col col-lg-6 col-md-6 col-sm-6">
          <Filtro />
        </div>
        <div className="col col-lg-6 col-md-6 col-sm-6" style={{ textAlign: 'right' }}>
          <Reactdataexport sw={0} />
          <ReactToPrint
            trigger={() => <button className="size" style={{ marginRight: '2%' }}><PrinterIcon /></button>}
            content={() => componentRef.current}
          />
        </div>
        <div className="col col-lg-12 col-md-12 col-sm-12">
          <Tablas ref={componentRef} />
        </div>
      </div>
    </div>
  );
}


export default InformeLote;
