import React from 'react';
import { Container, Row, Col } from 'reactstrap';
import Alert from '../alert/Alert';

const NotFound = () => (
  <Container className="dashboard">
    <Row className="justify-content-center">
      <Col sm={8}>
        <h1 className="text-center">404</h1>
        <Alert color="info" className="alert--bordered" icon>
          Al parecer no hemos podido llevarte a donde quieres, o tal vez esta ruta no existe.
        </Alert>
      </Col>
    </Row>
  </Container>
);

export default NotFound;
