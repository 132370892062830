import React from 'react';
import { createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles';
import {
  Container,
  Grid,
  Typography,
} from '@material-ui/core';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import getWeek from 'date-fns/getWeek';
import getDaysInMonth from 'date-fns/getDaysInMonth';
import startOfWeek from 'date-fns/startOfWeek';
import DateFnsUtils from '@date-io/date-fns';
import addMonths from 'date-fns/addMonths';
import { es } from 'date-fns/locale';
import PropTypes from 'prop-types';
import Programacion from './Programacion';
import Alerta from '../Comunes/Alerta';

const theme = createMuiTheme({
  palette: {
    primary: { main: '#263238' },
    secondary: { main: '#1a237e' },
  },
});
const fechaRef = new Date();

class PasillosTurnos extends React.Component {
  static fechaFinalCalendario() {
    const hoy = new Date();
    const dic31 = new Date(fechaRef.getFullYear(), 11, 31);
    let siguienteMes = addMonths(hoy, 1);
    if (siguienteMes.getMilliseconds() > dic31.getMilliseconds()) {
      const diasSiguienteMes = getDaysInMonth(siguienteMes);
      siguienteMes = new Date(siguienteMes.getFullYear(), siguienteMes.getMonth(), diasSiguienteMes);
      return siguienteMes;
    }
    return dic31;
  }
  constructor(props) {
    super(props);
    this.fechaCambiada = this.fechaCambiada.bind(this);
    this.obtenerAno = this.obtenerAno.bind(this);
    this.obtenerSemana = this.obtenerSemana.bind(this);
    this.alOcurrirError = this.alOcurrirError.bind(this);
    this.resetearError = this.resetearError.bind(this);
    this.fijarInfoFechaSesion = this.fijarInfoFechaSesion.bind(this);
    this.componenteMostrado = this.componenteMostrado.bind(this);
    let d = new Date();
    if (this.props.ano > 0 && this.props.tipo !== 'M') {
      let s = getWeek(d, { weekStartsOn: 1, firstWeekContainsDate: 4 });
      const x = s > this.props.semana ? -1 : 1;
      while (s != this.props.semana) {
        d.setDate(d.getDate() + x);
        s = getWeek(d, { weekStartsOn: 1, firstWeekContainsDate: 4 });
      }
      d = startOfWeek(d, { weekStartsOn: 1 });
    }
    this.state = {
      ano: d.getFullYear(),
      fecha: d.toISOString(),
      semana: getWeek(d, { weekStartsOn: 1, firstWeekContainsDate: 4 }),
      error: false,
    };
    this.fijarInfoFechaSesion();
  }
  fijarInfoFechaSesion() {
    if (this.props.tipo === 'P') {
      sessionStorage.setItem('ano', this.state.ano);
      sessionStorage.setItem('semana', this.state.semana);
    }
  }
  async alOcurrirError() {
    await this.setState({ error: true });
  }
  async resetearError() {
    await this.setState({ error: false });
  }
  async fechaCambiada(v) {
    const s = getWeek(v, { weekStartsOn: 1, firstWeekContainsDate: 4 });
    const a = v.getFullYear();
    if (a !== this.state.ano || s !== this.state.semana) {
      await this.setState({ fecha: v, ano: a, semana: s });
    } else {
      await this.setState({ fecha: v });
    }
    this.setState({ error: false });
    this.fijarInfoFechaSesion();
  }
  obtenerSemana() {
    if (this.props.semana > 0) {
      return this.props.semana;
    }
    return this.state.semana;
  }
  obtenerAno() {
    if (this.props.ano > 0) {
      return this.props.ano;
    }
    return this.state.ano;
  }
  async componenteMostrado(titulo) {
    await this.setState({ titulo });
  }
  render() {
    return (
      <React.Fragment>
        <MuiThemeProvider theme={theme}>
          <Container style={{ marginTop: '16px' }}>
            <Grid container md={12} spacing={3} direction="column" alignItems="center">
              <Grid item xs={12}>
                <Alerta
                  texto="Ocurrió un error de ejecución. Intente nuevamente."
                  toggle={this.resetearError}
                  isVisible={this.state.error}
                />
              </Grid>
              <Grid item xs={12}>
                <Typography variant="h5">
                  {this.state.titulo}
                </Typography>
              </Grid>
              {this.props.tipo !== 'M' &&
              <Grid container item xs={12} direction="column" alignItems="center">
                <MuiPickersUtilsProvider utils={DateFnsUtils} locale={es}>
                  <Grid item xs={12}>
                    <KeyboardDatePicker
                      name="semana"
                      size="small"
                      inputVariant="standard"
                      minDate={new Date(fechaRef.getFullYear() - 1, 11, 15)}
                      maxDate={PasillosTurnos.fechaFinalCalendario()}
                      // maxDate={new Date(fechaRef.getFullYear(), 11, 31)}
                      format={`'Semana ${this.state.semana} - 'MMMM dd`}
                      label="Semana"
                      value={this.state.fecha}
                      onChange={this.fechaCambiada}
                      cancelLabel="Cancelar"
                      okLabel="Consultar"
                    />
                  </Grid>
                </MuiPickersUtilsProvider>
              </Grid>
              }
              <Grid md={12}>
                <Programacion
                  ano={this.obtenerAno()}
                  semana={this.obtenerSemana()}
                  tipo={this.props.tipo}
                  pasillo={this.props.pasillo}
                  ocurrioError={this.alOcurrirError}
                  cargado={this.componenteMostrado}
                />
              </Grid>
            </Grid>
          </Container>
        </MuiThemeProvider>
      </React.Fragment>
    );
  }
}

PasillosTurnos.propTypes = {
  pasillo: PropTypes.number,
  ano: PropTypes.number,
  semana: PropTypes.number,
  tipo: PropTypes.string,
};

PasillosTurnos.defaultProps = {
  pasillo: -1,
  ano: 0,
  semana: 0,
  tipo: 'P',
};

export default PasillosTurnos;
