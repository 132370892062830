import React from 'react';
import PropTypes from 'prop-types';
import { Modal, Button, ModalHeader, ModalBody } from 'reactstrap';

class ModalConfirmacion extends React.Component {
  render() {
    return (
      <div>
        <Modal isOpen={this.props.open} toggle={this.toggle}>
          <ModalHeader>{this.props.title}</ModalHeader>
          <ModalBody>
            {this.props.text}
          </ModalBody>
          <div className="row">
            <div className="col text-center">
              <Button onClick={this.props.onConfirm}>{this.props.confirmLabel}</Button>{' '}
              <Button onClick={this.props.onCancel}>{this.props.cancelLabel}</Button>
            </div>
          </div>
        </Modal>
      </div>
    );
  }
}

ModalConfirmacion.propTypes = {
  onCancel: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
  confirmLabel: PropTypes.string,
  cancelLabel: PropTypes.string,
  open: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  // colorAcceptButton: PropTypes.string,
  // colorCancelButton: PropTypes.string,
};

ModalConfirmacion.defaultProps = {
  confirmLabel: 'Aceptar',
  cancelLabel: 'Cancelar',
  // colorAcceptButton: '',
  // colorCancelButton: '',
};

export default ModalConfirmacion;
