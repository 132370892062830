import React from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

class MaterialDialog extends React.Component {
  constructor(props) {
    super(props);
    this.afirmativoSeleccionado = this.afirmativoSeleccionado.bind(this);
    this.negativoSeleccionado = this.negativoSeleccionado.bind(this);
  }
  async afirmativoSeleccionado() {
    await this.props.afirmativo();
  }

  async negativoSeleccionado() {
    await this.props.negativo();
  }
  render() {
    return (
      <div>
        <Dialog
          open={this.props.mostrar}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">{this.props.titulo}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              {this.props.texto}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.afirmativoSeleccionado} color="primary">
              {this.props.textoAfirmativo}
            </Button>
            <Button onClick={this.negativoSeleccionado} color="primary" autoFocus>
              {this.props.textoNegativo}
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

MaterialDialog.propTypes = {
  mostrar: PropTypes.bool,
  titulo: PropTypes.string.isRequired,
  texto: PropTypes.string.isRequired,
  textoAfirmativo: PropTypes.string.isRequired,
  textoNegativo: PropTypes.string.isRequired,
  afirmativo: PropTypes.func.isRequired,
  negativo: PropTypes.func.isRequired,
};

MaterialDialog.defaultProps = {
  mostrar: true,
};


export default MaterialDialog;
