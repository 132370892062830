import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  IconButton,
  TablePagination,
  withStyles,
} from '@material-ui/core';
import { DeleteIcon, EditIcon, AddCircleIcon } from 'mdi-react';

import GrupoTalla from '../../GrupoTalla';
import { perteneceRolIngenieria } from '../../../Comunes/datosComunes';

const StyledTableCell = withStyles(theme => ({
  head: {
    backgroundColor: '#F5F5F5',
    color: '#263238',
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

export default function TablaTallas(props) {
  const { tallas } = props;
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const emptyRows =
    rowsPerPage - Math.min(rowsPerPage, tallas.length - page * rowsPerPage);
  return (
    <>
      <Table size="small">
        <TableHead>
          <TableRow>
            <StyledTableCell>Código talla</StyledTableCell>
            <StyledTableCell>Descripción talla </StyledTableCell>
            <StyledTableCell>
              Grupo talla
              <GrupoTalla unidades={props.unidades} fetch={props.fetch} />
            </StyledTableCell>
            <StyledTableCell>Unidad de negocio</StyledTableCell>
            <StyledTableCell>Fecha de creación</StyledTableCell>
            <StyledTableCell>Fecha de modificación</StyledTableCell>
            {
              perteneceRolIngenieria() &&
              <StyledTableCell>
                Nueva
                <IconButton size="small" onClick={() => { props.onDialogInsert(); }} >
                  <AddCircleIcon color="primary" />
                </IconButton>
              </StyledTableCell>
            }
          </TableRow>
        </TableHead>
        <TableBody>
          {(rowsPerPage > 0
            ? tallas.slice(
              page * rowsPerPage,
              page * rowsPerPage + rowsPerPage,
            )
            : tallas
          ).map(t => (
            <TableRow key={t.id}>
              <TableCell>
                {t.codigo}
              </TableCell>
              <TableCell>
                {t.descripcion}
              </TableCell>
              <TableCell>
                {t.grupoTalla}
              </TableCell>
              <TableCell>
                {t.unidadNegocio}
              </TableCell>
              <TableCell>
                {t.fechaCreacion.slice(0, 10)}
              </TableCell>
              <TableCell>
                {t.fechaModificacion.slice(0, 10)}
              </TableCell>
              {
                perteneceRolIngenieria() &&
                <TableCell>
                  <IconButton size="small" >
                    <EditIcon
                      fontSize="small"
                      color="primary"
                      onClick={() => { props.onDialogUpdate(t); }}
                    />
                  </IconButton>
                  <IconButton size="small" onClick={() => { props.onDialogDelete(t); }}>
                    <DeleteIcon fontSize="small" style={{ color: '#e10000' }} />
                  </IconButton>
                </TableCell>
              }
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <TablePagination
        rowsPerPageOptions={[10, 25, 50]}
        component="div"
        count={tallas.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
        labelRowsPerPage=""
      />
    </>
  );
}
TablaTallas.propTypes = {
  tallas: PropTypes.arrayOf(PropTypes.object).isRequired,
  onDialogDelete: PropTypes.func.isRequired,
  onDialogInsert: PropTypes.func.isRequired,
  onDialogUpdate: PropTypes.func.isRequired,
  unidades: PropTypes.arrayOf(PropTypes.object).isRequired,
  fetch: PropTypes.func.isRequired,
};
