import React from 'react';
import PropTypes from 'prop-types';
import { ButtonGroup } from '@material-ui/core';

import AdicionarOperacion from './AdicionarOperacion';
import ModificarOperacion from './ModificarOperacion';
import { perteneceRolIngenieria } from '../../../Comunes/datosComunes';

export default function CrudOperacion(props) {
  return (
    <div >
      <ButtonGroup
        style={{ display: 'flex', alignContent: 'center' }}
        variant="contained"
        aria-label="contained primary button group"
      >
        {
          perteneceRolIngenieria() &&
          <React.Fragment>
            <AdicionarOperacion {...props} />
            <ModificarOperacion {...props} />
          </React.Fragment>
        }
      </ButtonGroup>
    </div>
  );
}
CrudOperacion.propTypes = {
  subsecciones: PropTypes.objectOf(PropTypes.string).isRequired,
  SaveOp: PropTypes.isRequired,
};
