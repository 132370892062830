import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Container, Row, Col, Card, CardBody } from 'reactstrap';

import Loader from '../../shared/components/loader/Loader';
import CabeceraDetalle from './components/CabeceraDetalle';
import DetalleAlarma from './components/DetalleAlarma';
import { ApiUrl, AuthTokenKey } from '../../appConfig.json';

const DetallesAlarmas = (props) => {
  const [alarmaInfo, setAlarmaInfo] = useState({});
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const { match: { params: { id } } } = props;

    fetch(`${ApiUrl}/Alarmas/GetAlarma/${id}`, {
      method: 'GET',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json;charset=utf-8',
        Authorization: localStorage.getItem(AuthTokenKey),
      },
    }).then(res => res.json())
      .then((response) => {
        setAlarmaInfo(response);
        setLoading(false);
      });
  }, [props]);

  return (
    loading ?
      <Loader loading={loading} />
      :
      <Container className="padding-top-21">
        <Row className="justify-content-center">
          <Col lg={8} md={10} sm={12}>
            <Card>
              <CardBody className="padding-34">
                <CabeceraDetalle {...alarmaInfo} />
                <DetalleAlarma {...alarmaInfo} {...props} />
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
  );
};

DetallesAlarmas.propTypes = {
  match: PropTypes.objectOf().isRequired,
  history: PropTypes.objectOf().isRequired,
};

export default DetallesAlarmas;
