import React from 'react';
import { IconButton,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Divider,
  Paper,
  Chip } from '@material-ui/core';
import { DeleteIcon } from 'mdi-react';
import { getEfficiencyColor } from '../../../../shared/funtions/ColoresEficiencias';
import { perteneceRolIngenieria } from '../../../Comunes/datosComunes';

export default function TablaPasillos(props) {
  return (
    <>
      <Paper>
        <Divider />
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell>Codigo</TableCell>
              <TableCell>Fecha de creación</TableCell>
              <TableCell>Fecha de modificación</TableCell>
              <TableCell>Eficiencia</TableCell>
              <TableCell />
            </TableRow>
          </TableHead>
          <TableBody>
            {props.pasillos.map(p => (
              <TableRow key={p.id}>
                <TableCell>
                  {p.codigo}
                </TableCell>
                <TableCell>
                  {p.fechaCreacion.slice(0, 10)}
                </TableCell>
                <TableCell>
                  {p.fechaModificacion.slice(0, 10)}
                </TableCell>
                <TableCell>
                  <Chip
                    size="small"
                    label={`${p.eficienciaPasillo.toFixed(2)}%`}
                    style={{
                      width: '100%',
                      background: getEfficiencyColor(p.eficienciaPasillo.toFixed(2)),
                      color: '#FFFFFF',
                    }}
                  />
                </TableCell>
                {
                  perteneceRolIngenieria() &&
                  <TableCell>
                    <IconButton size="small" onClick={() => { props.delete(p); }} >
                      <DeleteIcon fontSize="small" />
                    </IconButton>
                  </TableCell>
                }
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Paper>
    </>
  );
}
