import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import Grid from '@material-ui/core/Grid';
import {
  Row,
  Col,
  Card,
  CardBody,
  Badge,
  UncontrolledButtonDropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from 'reactstrap';

import Eficiencia from '../../../shared/components/eficiencia/Eficiencia';
import { redondear } from './../../Comunes/datosComunes';

const IndicadorPlanta = ({
  planta, handleChangeTime, proyeccion, meta, notificada, time,
}) => (
  <Card>
    <CardBody>
      <div className="row card__title d-flex">
        <Col sm={6} className="d-flex justify-content-center align-items-center">
          <h5 className="bold-text text-center">Tejido</h5>
        </Col>
        <Col xs={12} sm={6} className="d-flex justify-content-end">
          <UncontrolledButtonDropdown>
            <DropdownToggle caret className="btn-rounded" outline>
              <span className="lnr lnr-calendar-full" /> {time}
            </DropdownToggle>
            <DropdownMenu>
              <DropdownItem value="Turno" onClick={handleChangeTime}>Turno</DropdownItem >
              <DropdownItem value="Dia" onClick={handleChangeTime}>Día</DropdownItem >
            </DropdownMenu>
          </UncontrolledButtonDropdown>
        </Col>
      </div>
      <Row>
        <Col lg={6}>
          <Eficiencia porcentaje={planta.porcentajeEficienciaLote * 100} innerRadius={88} round={1} />
        </Col>
        <Col lg={6} className="d-flex flex-column justify-content-between">
          <Row>
            <Col sm={12}>
              <Grid container style={{ marginTop: '5%' }}>
                <Grid style={{ marginBlockEnd: '2%' }} item xs={3}>
                  <b>PO:</b>
                  <h4>
                    <Badge style={{ width: '80%' }} color="dark" pill>{
                      redondear(planta.porcentajeParoOperativo * 100, 1)}%
                    </Badge>
                  </h4>
                </Grid>
                <Grid style={{ marginBlockEnd: '2%' }} item xs={3}>
                  <b>DM: </b>
                  <h4>
                    <Badge style={{ width: '80%' }} color="dark" pill>{
                      redondear(planta.porcentajeParoMecanico * 100, 1)}%
                    </Badge>
                  </h4>
                </Grid>
                <Grid style={{ marginBlockEnd: '2%' }} item xs={3}>
                  <b>CR+CCR: </b>
                  <h4>
                    <Badge style={{ width: '80%' }} color="dark" pill>{
                    redondear((planta.porcentajeParoCambioReferencia +
                    planta.porcentajeParoColaCambioReferencia) * 100, 1)}%
                    </Badge>
                  </h4>
                </Grid>
                <Grid style={{ marginBlockEnd: '2%' }} item xs={3}>
                  <b>META:</b>
                  <h4>
                    <Badge style={{ width: '80%' }} color="dark" pill>{Math.round(meta)}</Badge>
                  </h4>
                </Grid>
                <Grid style={{ marginBlockEnd: '2%' }} item xs={3}>
                  <b>Ef.OP: </b>
                  <h4>
                    <Badge style={{ width: '80%' }} color="dark" pill>{
                      redondear(planta.porcentajeEficienciaOperativa * 100, 1)}%
                    </Badge>
                  </h4>
                </Grid>
                <Grid style={{ marginBlockEnd: '2%' }} item xs={3}>
                  <b>OTROS: </b>
                  <h4>
                    <Badge style={{ width: '80%' }} color="dark" pill>{
                    redondear((planta.porcentajeParoElectronico +
                      planta.porcentajeParoLimpieza +
                      planta.porcentajeParoServicios +
                      planta.porcentajeParoFaltaRepuesto +
                      planta.porcentajeParoCambioPinta) * 100, 1)}%
                    </Badge>
                  </h4>
                </Grid>
                <Grid style={{ marginBlockEnd: '2%' }} item xs={3}>
                  <b>MPB+MPT: </b>
                  <h4>
                    <Badge style={{ width: '80%' }} color="dark" pill>{
                    redondear((planta.porcentajeParoMateriaPrimaBodega +
                    planta.porcentajeParoMateriaPrimaTej) * 100, 1)}%
                    </Badge>
                  </h4>
                </Grid>
                <Grid style={{ marginBlockEnd: '2%' }} item xs={3}>
                  <b>PLC: </b>
                  <h4>
                    <Badge style={{ width: '80%' }} color="dark" pill>{Math.round(planta.docenasPLC)} </Badge>
                  </h4>
                </Grid>
                <Grid style={{ marginBlockEnd: '2%' }} item xs={3}>
                  <b>PRYCN: </b>
                  <h4>
                    <Badge style={{ width: '80%' }} color="dark" pill>{Math.round(proyeccion)} </Badge>
                  </h4>
                </Grid>
                <Grid style={{ marginBlockEnd: '2%' }} item xs={3}>
                  <b>NOTI: </b>
                  <h4>
                    <Badge style={{ width: '80%' }} color="dark" pill>{Math.round(notificada)}</Badge>
                  </h4>
                </Grid>
                <Grid style={{ marginBlockEnd: '2%' }} item xs={3}>
                  <b>%2DAS REP: </b>
                  <h4>
                    <Badge style={{ width: '80%' }} color="dark" pill>{
                      redondear(planta.porcentajeSegundasReportadas * 100, 1)}%
                    </Badge>
                  </h4>
                </Grid>
              </Grid>
            </Col>
          </Row>
        </Col>
      </Row>
    </CardBody>
  </Card>
);

IndicadorPlanta.propTypes = {
  planta: PropTypes.string.isRequired,
  handleChangeTime: PropTypes.isRequired,
  proyeccion: PropTypes.string.isRequired,
  meta: PropTypes.string.isRequired,
  notificada: PropTypes.string.isRequired,
  time: PropTypes.string.isRequired,
};

export default IndicadorPlanta;
