import {
  PendientePorEstandar,
  EficienciaAlta,
  ProcesoIngresadoDiferenteSAP,
  EficienciaBaja,
} from './Events';

import {
  pendienteEstandarEventHandler,
  eficienciaAltaEventHandler,
  procesoIngreadoDiferenteSapEventHandler,
  eficienciaBajaEventHandler,
} from './EventHandlers';

export const ListenEventosIngenieria = (hubConnection) => {
  EficienciaAlta(hubConnection);
  PendientePorEstandar(hubConnection);
  ProcesoIngresadoDiferenteSAP(hubConnection);
  EficienciaBaja(hubConnection);
};

export const RemoveEventosIngenieria = (hubConnection) => {
  hubConnection.off('PendientePorEstandar', pendienteEstandarEventHandler);
  hubConnection.off('EficienciaAlta', eficienciaAltaEventHandler);
  hubConnection.off('ProcesoIngresadoDiferenteSAP', procesoIngreadoDiferenteSapEventHandler);
  hubConnection.off('EficienciaBaja', eficienciaBajaEventHandler);
};
