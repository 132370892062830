import React from 'react';
import { Grid } from '@material-ui/core';
import { createMuiTheme } from '@material-ui/core/styles';
import { ThemeProvider } from '@material-ui/styles';
import { Container } from 'reactstrap';

// import { ReferenciaCategoria } from './components/ReferenciaCategoria';
import { NormaEmpaque } from './components/NormaEmpaque';
import { Procesos } from './components/Procesos';
import { TiempoProcesos } from './components/TiempoProcesos';
import Context from './Context';
import { ActionButtons } from './components/ActionButtons';


import { ProcesosSeleccionados } from './components/ProcesosSeleccionados';
import { ReferenciaCategoria } from './components/ReferenciaCategoria';
import SubseccionesCheck from './components/SubseccionesCheck';

const theme = createMuiTheme({
  palette: {
    primary: { main: '#263238' },
    secondary: { main: '#1a237e' },
  },
});

const Simulador = () => (
  <Context.Provider>
    <ThemeProvider theme={theme}>
      <Container>
        <Grid container spacing={1}>
          <Grid item xs={12} sm={12} md={9}>
            <Grid container spacing={1}>
              <Procesos />
              <Grid item xs={12} sm={8} md={8} style={{ marginTop: '24px' }}>
                <ReferenciaCategoria />
                <ProcesosSeleccionados />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={12} md={3}>
            <Grid item xs={12}>
              <NormaEmpaque />
            </Grid>
            <Grid item xs={12}>
              <TiempoProcesos />
            </Grid>
            <Grid item xs={12}>
              <SubseccionesCheck />
            </Grid>
            <Grid item xs={12} container>
              <ActionButtons />
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </ThemeProvider>
  </Context.Provider>
);

export default Simulador;
