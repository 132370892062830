import React from 'react';
import PropTypes from 'prop-types';
import { Progress } from 'reactstrap';
import { getColorEficienciaLinear } from '../../funtions/ColoresEficiencias';

const getEficiencia = eficiencia => (eficiencia > 130 ? 130 : eficiencia);

const EficienciaLinear = ({ eficiencia, operario }) => (
  <div>
    <div className="mobile-app-widget">
      <div className="mobile-app-widget__title">
        <h5>{operario}</h5>
      </div>
      <div
        className={`progress-wrap progress-wrap--small 
        ${getColorEficienciaLinear(eficiencia)} progress-wrap--label-top`}
      >
        <Progress value={getEficiencia(eficiencia)}>
          <p className="progress__label">{getEficiencia(eficiencia)}%</p>
        </Progress>
      </div>
    </div>
  </div>
);

EficienciaLinear.propTypes = {
  eficiencia: PropTypes.number.isRequired,
  operario: PropTypes.string.isRequired,
};

export default EficienciaLinear;
