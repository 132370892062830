import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import {
  Col,
  UncontrolledButtonDropdown,
  Row,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  InputGroup,
  InputGroupAddon,
  Button,
} from 'reactstrap';

// import Occupancy from './components/Occupancy';
import EficienciaEventoTable from './components/EficienciaEventoTable';
import EficienciaGeneralUbicacion from './components/EficienciaGeneralUbicacion';

import { ApiUrl, AuthTokenKey, UsuarioRestriccion, VisualizarRestringidoKey,
  UsuarioRestriccionKey, UbicacionRestriccionKey, CryUsername } from './../../appConfig.json';
// import Loader from '../../shared/components/loader/Loader';

class DetallesUbicacion extends Component {
  constructor(props) {
    super(props);
    this.state = {
      detallesUbicacion: [],
      loading: true,
      time: 'TURNO',
      rangoDias: false,
      restricted: false,
      temporizadorId: 0,
    };
  }

  componentDidMount = async () => {
    const { ubicacion } = this.props.match.params;
    this.getEventosAsync(ubicacion);

    const currentUser = localStorage.getItem(CryUsername);

    if (currentUser === UsuarioRestriccion) {
      this.setState({
        restricted: true,
      });
      localStorage.setItem(VisualizarRestringidoKey, true);
      localStorage.setItem(UbicacionRestriccionKey, ubicacion);
    }

    const temporizadorId = setInterval(async () => {
      await this.getEventosAsync(ubicacion);
    }, 60000);
    this.setState({ temporizadorId });
  };

  componentWillUnmount = () => {
    clearInterval(this.state.temporizadorId);
  }

  getEventosAsync = async () => {
    const { ubicacion } = this.props.match.params;

    this.setState({
      time: 'TURNO',
      loading: true,
      rangoDias: false,
    });

    const response = await
    fetch(
      `${ApiUrl}/eficiencia/ubicacion/${ubicacion}`,
      {
        mode: 'cors',
        method: 'GET',
        headers: {
          'Content-Type': 'application/json;charset=utf-8',
          Authorization: localStorage.getItem(AuthTokenKey),
        },
      },
    );

    const detallesUbicacion = await response.json();

    this.setState({
      detallesUbicacion,
      loading: false,
    });
  }

  getEventosDiaAsync = async () => {
    const { ubicacion } = this.props.match.params;

    this.setState({
      time: 'DIA',
      loading: true,
      rangoDias: false,
    });

    const data = JSON.stringify({
      ubicacionId: ubicacion,
    });
    const response = await
    fetch(
      `${ApiUrl}/ubicaciones/detallesDiaUbicacion`,
      {
        mode: 'cors',
        method: 'POST',
        body: data,
        headers: {
          'Content-Type': 'application/json;charset=utf-8',
          Authorization: localStorage.getItem(AuthTokenKey),
        },
      },
    );

    const detallesUbicacion = await response.json();

    this.setState({
      detallesUbicacion,
      loading: false,
    });
  }

  getEventosRangoDiasAsync = async () => {
    const { ubicacion } = this.props.match.params;
    const desde = this.inputDesde.value;
    const hasta = this.inputHasta.value;

    this.setState({
      time: 'RANGO',
      loading: true,
    });

    const data = JSON.stringify({
      ubicacionId: ubicacion,
      desde,
      hasta,
    });

    const response = await
    fetch(
      `${ApiUrl}/ubicaciones/detallesRangoDiasUbicacion`,
      {
        mode: 'cors',
        method: 'POST',
        body: data,
        headers: {
          'Content-Type': 'application/json;charset=utf-8',
          Authorization: localStorage.getItem(AuthTokenKey),
        },
      },
    );

    const detallesUbicacion = await response.json();

    this.setState({
      detallesUbicacion,
      loading: false,
    });
  }

  HanldeEventosRangoDiasAsync = () => {
    this.setState({ rangoDias: true });
  };

  render() {
    const {
      detallesUbicacion, loading, time, rangoDias, restricted,
    } = this.state;
    const { ubicacion } = this.props.match.params;
    return (
      <Fragment>
        <Col sm={12} className="card detallesCard">
          <Row>
            {
              !restricted &&
              <Col md={6}>
                <UncontrolledButtonDropdown>
                  <DropdownToggle caret className="btn-rounded" outline>
                    <span className="lnr lnr-calendar-full" /> { time }
                  </DropdownToggle>
                  <DropdownMenu>
                    <DropdownItem onClick={this.getEventosDiaAsync}>Dia</DropdownItem >
                    <DropdownItem onClick={this.getEventosAsync}>Turno</DropdownItem >
                    <DropdownItem onClick={this.HanldeEventosRangoDiasAsync}>Rango</DropdownItem >
                  </DropdownMenu>
                </UncontrolledButtonDropdown>
              </Col>
            }
          </Row>
          <EficienciaGeneralUbicacion
            load={loading}
            data={detallesUbicacion}
            restricted={restricted}
          />
          {
            rangoDias &&
            <Fragment>
              <hr />
              <Row className="d-flex justify-content-center">
                <Col md={6}>
                  <InputGroup>
                    <InputGroupAddon addonType="prepend">Desde</InputGroupAddon>
                    <input className="form-control" type="date" ref={(el) => { this.inputDesde = el; }} />
                    <InputGroupAddon addonType="prepend">Hasta</InputGroupAddon>
                    <input className="form-control" type="date" ref={(el) => { this.inputHasta = el; }} />
                    <InputGroupAddon addonType="append">
                      <Button
                        onClick={this.getEventosRangoDiasAsync}
                        style={{ marginBottom: 0, padding: '0px 25px' }}
                        color="primary"
                      >
                        <span className="lnr lnr-magnifier" /> Buscar
                      </Button>
                    </InputGroupAddon>
                  </InputGroup>
                </Col>
              </Row>
            </Fragment>
            }
        </Col>
        { !loading ?
          <>
            {/* <Occupancy /> */}
            <hr />
            <EficienciaEventoTable
              eventos={detallesUbicacion.eventos}
              ubicacion={ubicacion}
              restricted={restricted}
            />
          </>
          : <h4 className="text-center">Cargando ...</h4>
        }
      </Fragment>
    );
  }
}

DetallesUbicacion.propTypes = {
  match: PropTypes.objectOf(PropTypes.shape()).isRequired,
};

export default DetallesUbicacion;
