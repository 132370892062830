import React from 'react';
import { IconButton,
  TextField,
  Paper,
  Grid,
  FormControl,
  InputLabel,
  Select,
  MenuItem } from '@material-ui/core';
import { CheckCircleIcon } from 'mdi-react';
import { perteneceRolIngenieria } from '../../../Comunes/datosComunes';

export default function Form(props) {
  return (
    <>
      <Paper>
        <Grid container style={{ margin: '2%' }} >
          <Grid item xs={12}>
            <h5 style={{ marginTop: '3%', marginBlockEnd: '1%' }} >Información grupo talla</h5>
          </Grid>
          <Grid item xs={3} style={{ marginBlockEnd: '3%' }}>
            <TextField
              id="codigo"
              style={{ width: '90%' }}
              label="Código"
              value={props.codigo}
              onChange={(e) => { props.onChange(e); }}
            />
          </Grid>
          <Grid item xs={3} style={{ marginBlockEnd: '3%' }} >
            <TextField
              id="descripcion"
              style={{ width: '90%' }}
              label="Descripción"
              value={props.descripcion}
              onChange={(e) => { props.onChange(e); }}
            />
          </Grid>
          <Grid item xs={3} style={{ marginBlockEnd: '3%' }}>
            <FormControl style={{ width: '90%' }} >
              <InputLabel id="unidadNegocio">Unidades</InputLabel>
              <Select
                name="unidadNegocio"
                onChange={(e) => { props.onChange(e); }}
                value={props.unidad}
              >
                {props.unidades.map(uni => (<MenuItem key={uni.id} value={uni.id}>{uni.descripcion}</MenuItem>))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={3} style={{ marginBlockEnd: '2%', marginTop: '2%' }}>
            {
              perteneceRolIngenieria() &&
              <IconButton size="small" onClick={() => props.save()} >
                <CheckCircleIcon fontSize="small" style={{ color: '#214589' }} color="primary" />
              </IconButton>
            }
          </Grid>
        </Grid>
      </Paper>
    </>
  );
}
