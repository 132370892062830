import React from 'react';
import PropTypes from 'prop-types';
import {
  Row,
  Col,
  Container,
} from 'reactstrap';
import IndicadorEficienciaSeccion from './IndicadorEficienciaSeccion';
import IndicadorPlanta from './IndicadorPlanta';

const Secciones = ({
  unidad, planta, dataTejido, handleChangeTime, time,
}) => (
  <Container className="dashboard">
    <Row>
      <Col md={12} lg={6} className="card-no-padding">
        <IndicadorPlanta
          planta={planta}
          time={time}
          handleChangeTime={handleChangeTime}
        />
      </Col>
      {
        planta.secciones.map(seccion => (
          <IndicadorEficienciaSeccion
            key={seccion.elementoId}
            codigo={seccion.elementoId}
            base={`/subsecciones/unidad/${unidad}/planta/${planta.elementoId}/seccion`}
            seccion={seccion.nombre}
            eficiencia={seccion.eficiencia}
            tiempoParo={seccion.tiempoParo}
            real={seccion.docenasNotificadas}
            esperado={seccion.esperado}
            dataTejido={dataTejido}
          />))
      }
    </Row>
  </Container>
);


Secciones.propTypes = {
  planta: PropTypes.string.isRequired,
  unidad: PropTypes.string.isRequired,
  handleChangeTime: PropTypes.isRequired,
  time: PropTypes.string.isRequired,
  dataTejido: PropTypes.objectOf(PropTypes.string).isRequired,
};

export default Secciones;
