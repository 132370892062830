import React from 'react';
import PropTypes from 'prop-types';
import { FormControlLabel, Grid, Radio, RadioGroup } from '@material-ui/core';
import 'react-picky/dist/picky.css';
import MaterialUISelectorMultiple from '../Comunes/MateriaUISelectorMultiple';

class FiltroCargaReferenciaCategoria extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      subsecciones: [],
      categorias: [],
      grupoTallas: [],
      operaciones: [],
      materiales: [],
      conError: 'null',
    };
    this.subseccionSeleccionada = this.subseccionSeleccionada.bind(this);
    this.materialSeleccionado = this.materialSeleccionado.bind(this);
    this.categoriaSeleccionada = this.categoriaSeleccionada.bind(this);
    this.grupoTallaSeleccionada = this.grupoTallaSeleccionada.bind(this);
    this.operacionSeleccionada = this.operacionSeleccionada.bind(this);
    this.conErrorSeleccionado = this.conErrorSeleccionado.bind(this);
  }

  async subseccionSeleccionada(valores) {
    await this.setState({ subsecciones: valores });
    await this.props.filtrar(this.state);
  }
  async materialSeleccionado(valores) {
    await this.setState({ materiales: valores });
    await this.props.filtrar(this.state);
  }
  async categoriaSeleccionada(valores) {
    await this.setState({ categorias: valores });
    await this.props.filtrar(this.state);
  }
  async grupoTallaSeleccionada(valores) {
    await this.setState({ grupoTallas: valores });
    await this.props.filtrar(this.state);
  }
  async operacionSeleccionada(valores) {
    await this.setState({ operaciones: valores });
    await this.props.filtrar(this.state);
  }
  async conErrorSeleccionado({ target }) {
    await this.setState({ conError: target.value });
    this.props.filtrar(this.state);
  }

  async limpiarFiltro() {
    await this.setState({
      subsecciones: [],
      categorias: [],
      grupoTallas: [],
      operaciones: [],
      materiales: [],
      conError: 'null',
    });
  }

  renderGridItem(child) {
    const { disabled } = this.props;
    const Form = () => (
      <Grid item xs={12} md={6} lg={3}>
        {child}
      </Grid>
    );
    return disabled ? null : Form();
  }

  renderErrorFiltro() {
    return (
      <RadioGroup
        value={this.state.conError}
        onChange={this.conErrorSeleccionado}
        style={{ flexDirection: 'row', flexWrap: 'unset', whiteSpace: 'nowrap' }}
      >
        <FormControlLabel value="null" control={<Radio />} label="Todos" />
        <FormControlLabel value="false" control={<Radio />} label="Sin error" />
        <FormControlLabel value="true" control={<Radio />} label="Con errores" />
      </RadioGroup>
    );
  }

  render() {
    return (
      <Grid container direction="row" spacing={2}>
        {
          this.renderGridItem(<MaterialUISelectorMultiple
            variant="outlined"
            lista={this.props.filtro.listaSubsecciones}
            label="Subsección"
            placeholder="Subsecciones"
            value={this.state.subsecciones}
            onChange={this.subseccionSeleccionada}
          />)
        }
        {
          this.renderGridItem(<MaterialUISelectorMultiple
            variant="outlined"
            lista={this.props.filtro.listaMateriales}
            label="Materiales"
            placeholder="Materiales"
            value={this.state.materiales}
            onChange={this.materialSeleccionado}
          />)
        }
        {
          this.renderGridItem(<MaterialUISelectorMultiple
            variant="outlined"
            lista={this.props.filtro.listaCategorias}
            label="Categoría"
            placeholder="Categorias"
            value={this.state.categorias}
            onChange={this.categoriaSeleccionada}
          />)
        }
        {
          this.renderGridItem(<MaterialUISelectorMultiple
            variant="outlined"
            lista={this.props.filtro.listaGrupoTallas}
            label="Grupo Tallas"
            placeholder="Grupos Tallas"
            value={this.state.grupoTallas}
            onChange={this.grupoTallaSeleccionada}
          />)
        }
        {
          this.renderGridItem(<MaterialUISelectorMultiple
            variant="outlined"
            lista={this.props.filtro.listaOperaciones}
            label="Operación"
            placeholder="Operaciones"
            value={this.state.operaciones}
            onChange={this.operacionSeleccionada}
          />)
        }
        {
          this.renderGridItem(this.renderErrorFiltro())
        }
      </Grid>
    );
  }
}

FiltroCargaReferenciaCategoria.propTypes = {
  disabled: PropTypes.bool,
  filtrar: PropTypes.func.isRequired,
  filtro: PropTypes.shape({
    conError: PropTypes.bool,
    listaSubsecciones: PropTypes.arrayOf(PropTypes.shape({})),
    listaCategorias: PropTypes.arrayOf(PropTypes.shape({})),
    listaGrupoTallas: PropTypes.arrayOf(PropTypes.shape({})),
    listaOperaciones: PropTypes.arrayOf(PropTypes.shape({})),
    listaMateriales: PropTypes.arrayOf(PropTypes.shape({})),
  }),
};

FiltroCargaReferenciaCategoria.defaultProps = {
  disabled: false,
  filtro: {
    conError: null,
    listaSubsecciones: [],
    listaCategorias: [],
    listaGrupoTallas: [],
    listaOperaciones: [],
    listaMateriales: [],
  },
};

export default FiltroCargaReferenciaCategoria;
