import React from 'react';
import { Tooltip,
  Button,
  Dialog,
  DialogContent,
  DialogActions,
  DialogTitle,
  FormControlLabel,
  Checkbox,
  Grid,
  Typography,
  TextField,
  FormControl,
  InputLabel,
  InputAdornment } from '@material-ui/core';
import { CreateIcon } from 'mdi-react';
import config from '../../../../appConfig.json';
import Context from '../../store';

export default function ModificarTurno(props) {
  const [open, setOpen] = React.useState(false);
  const [switchDefecto, setSwitchDefecto] = React.useState(false);
  const [esOrdinario, setEsOrdinario] = React.useState(false);
  const [esNocturno, setEsNocturno] = React.useState(false);
  const [proceso, setProceso] = React.useState({});

  const handleChecked = (e) => {
    const array = { ...proceso };
    array[e.target.name] = e.target.checked;
    setProceso(array);
    if (e.target.name === 'switchDefecto') setSwitchDefecto(e.target.checked);
    else if (e.target.name === 'esOrdinario') setEsOrdinario(e.target.checked);
    else setEsNocturno(e.target.checked);
  };

  const handleChangeHora = (e) => {
    const array = { ...proceso };
    array[e.target.id] = e.target.value;
    if (array.horaFinal !== undefined && array.horaInicial !== undefined) {
      const horaI = e.target.id === 'horaInicial' ? (e.target.value).split(':') : array.horaInicial.split(':');
      const horaF = e.target.id === 'horaFinal' ? (e.target.value).split(':') : array.horaFinal.split(':');
      const t1 = new Date();
      const t2 = new Date();
      t1.setHours(horaF[0], horaF[1]);
      t2.setHours(horaI[0], horaI[1]);
      t1.setHours(t1.getHours() - t2.getHours(), t1.getMinutes() - t2.getMinutes());
      array.cantidadMinutos = (t1.getHours() * 60) + t1.getMinutes();
    }
    setProceso(array);
  };

  const handleChange = (e) => {
    const array = { ...proceso };
    array[e.target.id] = e.target.value;
    setProceso(array);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = (procesoEspecial) => {
    const array = { ...procesoEspecial };
    array.usuario = localStorage.getItem(config.CryUsername);

    setProceso(array);
    setSwitchDefecto(array.switchDefecto);
    setEsOrdinario(array.esOrdinario);
    setEsNocturno(array.esNocturno);
    setOpen(true);
  };

  return (
    <>
      <Context.Consumer>
        {
          (value) => {
            const handleSave = async () => {
              const p = { ...value.proceso };
              p.descripcionTurno = proceso.descripcionTurno;
              p.cantidadMinutos = proceso.cantidadMinutos;
              p.version = proceso.version;
              p.switchDefecto = proceso.switchDefecto;
              p.esOrdinario = proceso.esOrdinario;
              p.esNocturno = proceso.esNocturno;
              p.horaInicial = proceso.horaInicial;
              p.horaFinal = proceso.horaFinal;
              value.activateProceso(p);
              const request = await fetch(`${config.ApiUrl}/turnos/editar/${proceso.id}`, {
                mode: 'cors',
                method: 'POST',
                body: JSON.stringify(proceso),
                headers: {
                  'Content-Type': 'application/json',
                  Authorization: localStorage.getItem(config.AuthTokenKey),
                  Accept: 'application/json',
                },
              });
              const response = await request.json();
              props.fetch();
              handleClose();
            };
            if (value.proceso === null) {
              return (
                <Tooltip title="Modifica un turno" >
                  <Button
                    variant="contained"
                    style={{ backgroundColor: 'rgb(18, 24, 88)', width: '50%' }}
                  >
                    <CreateIcon style={{ color: 'white' }} />
                    <h5 style={{ color: 'white' }}><strong>MODIFICAR</strong></h5>
                  </Button>
                </Tooltip>);
            }
            return (
              <>
                <Tooltip title="Modifica un turno" >
                  <Button
                    onClick={() => { handleOpen(value.proceso); }}
                    variant="contained"
                    style={{ backgroundColor: 'rgb(18, 24, 88)', width: '50%' }}
                  >
                    <CreateIcon style={{ color: 'white' }} />
                    <h5 style={{ color: 'white' }}><strong>MODIFICAR</strong></h5>
                  </Button>
                </Tooltip>
                <Dialog
                  open={open}
                  onClose={handleClose}
                  scroll="paper"
                  aria-labelledby="scroll-dialog-title"
                  maxWidth="sm"
                >
                  <form
                    onSubmit={(e) => {
                    e.preventDefault();
                    handleSave();
                    }}
                  >
                    <DialogTitle>
                      <Typography gutterBottom variant="h6" align="center">
                        MODIFICAR TURNO
                      </Typography>
                    </DialogTitle>
                    <DialogContent dividers>
                      <Grid container>
                        <Grid item xs={6}>
                          <TextField
                            readOnly
                            type="number"
                            label="Código"
                            id="id"
                            value={proceso.id}
                            onChange={handleChange}
                          />
                        </Grid>
                        <Grid item xs={6} style={{ marginTop: '3%' }}>
                          <TextField
                            readOnly
                            id="cantidadMinutos"
                            value={proceso !== null ? proceso.cantidadMinutos : 0}
                            InputProps={{
                              startAdornment: <InputAdornment position="start">Minutos</InputAdornment>,
                            }}
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <TextField
                            required
                            fullWidth
                            label="Descripción"
                            style={{ marginBlockEnd: '2%' }}
                            id="descripcionTurno"
                            defaultValue={proceso.descripcionTurno}
                            onChange={handleChange}
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <TextField
                            required
                            type="number"
                            label="Version"
                            id="version"
                            defaultValue={proceso.version}
                            onChange={handleChange}
                            style={{ marginBlockEnd: '2%' }}
                          />
                        </Grid>
                        <Grid item xs={4}>
                          <FormControlLabel
                            style={{ marginTop: '2%' }}
                            control={<Checkbox
                              checked={switchDefecto}
                              name="switchDefecto"
                              onChange={handleChecked}
                            />}
                            label="Defecto"
                          />
                        </Grid>
                        <Grid item xs={4}>
                          <FormControlLabel
                            style={{ marginTop: '2%' }}
                            control={<Checkbox
                              checked={esOrdinario}
                              name="esOrdinario"
                              onChange={handleChecked}
                            />}
                            label="Ordinario"
                          />
                        </Grid>
                        <Grid item xs={4}>
                          <FormControlLabel
                            style={{ marginTop: '2%' }}
                            control={<Checkbox
                              checked={esNocturno}
                              name="esNocturno"
                              onChange={handleChecked}
                            />}
                            label="Nocturno"
                          />
                        </Grid>
                        <Grid item xs={2} style={{ marginTop: '2%' }}>
                          <InputLabel>Hora inicial</InputLabel>
                        </Grid>
                        <Grid item xs={4}>
                          <FormControl required>
                            <TextField
                              defaultValue={proceso.horaInicial}
                              required
                              type="time"
                              id="horaInicial"
                              onChange={handleChangeHora}
                            />
                          </FormControl>
                        </Grid>
                        <Grid item xs={2} style={{ marginTop: '2%' }}>
                          <InputLabel>Hora final</InputLabel>
                        </Grid>
                        <Grid item xs={4}>
                          <FormControl required>
                            <TextField
                              defaultValue={proceso.horaFinal}
                              required
                              type="time"
                              id="horaFinal"
                              onChange={handleChangeHora}
                            />
                          </FormControl>
                        </Grid>
                      </Grid>
                    </DialogContent>
                    <DialogActions>
                      <Button onClick={handleClose} color="default">
                        Cerrar
                      </Button>
                      <Button color="primary" type="submit" >
                        Modificar
                      </Button>
                    </DialogActions>
                  </form>
                </Dialog>
              </>
            );
          }
        }
      </Context.Consumer>
    </>
  );
}
