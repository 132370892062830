import React from 'react';
import PropTypes from 'prop-types';
import { Card, CardBody } from 'reactstrap';
import { Link } from 'react-router-dom';

import EficienciaLinearEmpleados from './EficienciaLinearEmpleados';
import IndicadoresNumericosUbicacion from './IndicadoresNumericosUbicacion';

const IndicadorEficienciaUbicacion = ({
  base, codigo, empleados, ubicacion,
}) =>
  (
    <div className="card-no-padding">
      <Link to={`${base}/${codigo}`}>
        <Card>
          <CardBody
            className="d-flex flex-column justify-content-between"
            style={(ubicacion.empleados.length === 0) ? {
              boxShadow: 'none', opacity: '.5', backgroundColor: '#d9d9d9',
            } : {}}
          >
            <div className="card__title">
              <h5 className="bold-text text-center">{ubicacion.nombre}</h5>
            </div>
            <EficienciaLinearEmpleados empleados={empleados} />
            <IndicadoresNumericosUbicacion
              cantidadReal={ubicacion.docenasNotificadas}
              tiempoParo={ubicacion.tiempoParo}
            />
          </CardBody>
        </Card>
      </Link>
    </div>
  );

IndicadorEficienciaUbicacion.propTypes = {
  codigo: PropTypes.number.isRequired,
  base: PropTypes.string.isRequired,
  empleados: PropTypes.arrayOf(PropTypes.object).isRequired,
  ubicacion: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default IndicadorEficienciaUbicacion;
