import React from 'react';
import Checkbox from '@material-ui/core/Checkbox';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';

export default function MaterialUISelectorMultiple(props) {
  const isOptionEqual = (option, value) => option.id === value.id;

  // Aquí se agrega la prop `filterOptions` para filtrar las opciones antes de mostrarlas al usuario

  const filterOptions = (options, params) => options.filter(op => op.descripcion != null);
  return (
    <Autocomplete
      style={{ minWidth: '100%', maxWidth: '100%' }}
      multiple
      loading={props.cargando}
      loadingText="Cargando..."
      noOptionsText="Sin elementos"
      onChange={(event, values) => {
        props.onChange(values);
      }}
      options={props.lista}
      disableCloseOnSelect
      value={props.value}
      size="small"
      getOptionLabel={op => op.descripcion}
      renderOption={(op, { selected }) => (
        <React.Fragment>
          <Checkbox
            key={op.id}
            color="black"
            style={{ marginRight: 3 }}
            checked={selected}
            size="small"
          />
          {op.descripcion}
        </React.Fragment>
      )}
      renderInput={params => (
        <TextField
          {...params}
          variant="standard"
          label={props.label}
          placeholder={props.placeholder}
          // fullWidth
        />
      )}
      getOptionSelected={isOptionEqual}
      filterOptions={filterOptions}
    />
  );
}
