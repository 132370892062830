import React, { Component } from 'react';
import PropTypes from 'prop-types';
import MenuCalcetines from './MenuCalcetines';
import MenuVestuario from './MenuVestuario';
import MenuSeamless from './MenuSeamless';
import Config from '../../../appConfig.json';

class SidebarContent extends Component {
  static propTypes = {
    // changeToDark: PropTypes.func.isRequired,
    // changeToLight: PropTypes.func.isRequired,
    onClick: PropTypes.func.isRequired,
    Ubicacion: PropTypes.string.isRequired,
  };

  hideSidebar = () => {
    this.props.onClick();
  };

  render() {
    if (this.props.Ubicacion === Config.unidadNegocioCalcetines) {
      return (
        <MenuCalcetines
          onClick={() => {}}
        />
      );
    }
    if (this.props.Ubicacion === Config.unidadNegocioVestuario) {
      return (
        <MenuVestuario
          onClick={() => {}}
        />
      );
    }
    if (this.props.Ubicacion === Config.unidadNegocioSeamless) {
      return (
        <MenuSeamless
          onClick={() => {}}
        />
      );
    }
    return (
      <div className="sidebar__content">
        <ul className="sidebar__block">
          {/* <SidebarCategory title="Monitor Eficiencias" icon="chart-bars"> */}
          {/* <SidebarLink title="Unidades de Negocio" route="/unidades" onClick={this.hideSidebar} /> */}
          {/* <SidebarLink title="Plantas" route="/plantas" onClick={this.hideSidebar} /> */}
          {/* <SidebarLink title="Secciones" route="/secciones" onClick={this.hideSidebar} /> */}
          {/* </SidebarCategory> */}
        </ul>
      </div>
    );
  }
}

export default SidebarContent;
