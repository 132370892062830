/* eslint-disable import/prefer-default-export */
import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Item, ItemBig, ItemSizable } from './styles';

export const GridRow = ({
  children, colSize, rowSize, gap,
}) => (
  <Grid colSize={colSize} rowSize={rowSize} gap={gap}>
    { children }
  </Grid>
);

export const SizableItem = ({
  children, rowStart, rowEnd, colStart, colEnd,
}) => (
  <ItemSizable
    rowStart={rowStart}
    rowEnd={rowEnd}
    colStart={colStart}
    colEnd={colEnd}
  >
    { children }
  </ItemSizable>
);

export const GridItem = ({ children, level }) => (
  <Item level={level}>
    { children }
  </Item>
);

export const GridItemBig = ({ children }) => (
  <ItemBig>
    { children }
  </ItemBig>
);

SizableItem.propTypes = {
  children: PropTypes.element.isRequired,
  rowStart: PropTypes.number,
  rowEnd: PropTypes.number,
  colStart: PropTypes.number,
  colEnd: PropTypes.number,
};
SizableItem.defaultProps = {
  rowStart: 1,
  rowEnd: 1,
  colStart: 1,
  colEnd: 1,
};

GridRow.propTypes = {
  children: PropTypes.element.isRequired,
  colSize: PropTypes.string,
  rowSize: PropTypes.string,
  gap: PropTypes.string,
};
GridRow.defaultProps = {
  colSize: '20%',
  rowSize: '130px',
  gap: '0px',
};

GridItem.propTypes = {
  children: PropTypes.element.isRequired,
  level: PropTypes.string.isRequired,
};

GridItemBig.propTypes = {
  children: PropTypes.element.isRequired,
};
