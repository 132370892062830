import React from 'react';

import { Paper } from '@material-ui/core';

import Generales from '../Generales/Generales';
import Detalladas from '../Detalladas/Detalladas';
import Operacion from '../Operacion/OperacionCompleta';

import config from '../../../appConfig.json';
import Loader from '../../../shared/components/loader/Loader';

class Simulador extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: undefined,
      loading: true,
      error: null,
      input: '',
      originalData: undefined,
      subsecciones: null,
    };
  }

  componentDidMount() {
    this.fetchData();
  }

  fetchData = async () => {
    this.setState({ loading: true, error: null });
    try {
      const listaOp = await fetch(
        `${config.ApiUrl}/operaciones/generales/lista`,
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: localStorage.getItem(config.AuthTokenKey),
          },
        },
      );
      const operaciones = await listaOp.json();
      const listaSub = await fetch(
        `${config.ApiUrl}/Secciones/subsecciones`,
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: localStorage.getItem(config.AuthTokenKey),
          },
        },
      );
      const subseccions = await listaSub.json();
      this.setState({
        loading: false, data: operaciones, originalData: operaciones, subsecciones: subseccions,
      });
    } catch (err) {
      this.setState({ loading: false, error: err });
    }
  }

  handleInputChange = (e) => {
    this.setState({ input: e.target.value });
    const result = this.state.data
      .filter(op => (
        op.descripcion !== null && op))
      .filter(op => (
        op.descripcion.toLowerCase().indexOf(e.target.value.toLowerCase()) !== -1 && op
      ));
    if (result.length !== 0 && e.target.value !== '') { this.setState({ data: result }); }
    if (result.length === 0 && e.target.value === '') { this.setState({ data: this.state.originalData }); }
    if (result.length !== 0 && e.target.value === '') { this.setState({ data: this.state.originalData }); }
    if (result.length === 0 && e.target.value !== '') { this.setState({ data: this.state.originalData }); }
  }

  handlesaveOp = () => {
    this.fetchData();
  }

  render() {
    const { loading, error, data } = this.state;

    if (loading === true) {
      return (
        <div>
          <Loader loading={loading} />
        </div>
      );
    }

    if (error) {
      return 'Error';
    }

    return (
      <>
        <div
          className="container"
          style={{
          marginTop: '10px',
          marginBlockEnd: '10px',
        }}
        >
          <div className="row">
            <div className="col-lg-3" >
              <Paper>
                <Generales
                  onChange={this.handleInputChange}
                  Input={this.state.input}
                  data={data}
                  handleOperacionesReload={this.fetchData}
                />
              </Paper>
            </div>
            <div className="col-lg-6">
              <Detalladas />
            </div>
            <div className="col-lg-3">
              <Operacion
                subsecciones={this.state.subsecciones}
                SaveOp={this.handlesaveOp}
                handleOperacionesReload={this.fetchData}
              />
            </div>
          </div>
        </div>
      </>
    );
  }
}
export default Simulador;

