import config from './../../appConfig.json';
import
{
  ejecutarConsulta,
  descargarArchivo,
  ejecutarActualizacion,
  obtenerInfoOperario,
} from './../Comunes/datosComunes';

async function obtenerInfoEmpleado(cedula, signal) {
  return obtenerInfoOperario(cedula, signal);
}
async function obtenerPeriodoActual(signal) {
  return ejecutarConsulta(
    `${config.ApiUrl}/incentivos/periodo-actual`,
    'GET',
    null,
    signal,
  );
}
async function obtenerTopeAdvertenciaIncentivo(signal) {
  return ejecutarConsulta(
    `${config.ApiUrl}/incentivos/tope-advertencia-ajuste`,
    'GET',
    null,
    signal,
  );
}
async function calcularIncentivos(filtro, signal) {
  return ejecutarConsulta(
    `${config.ApiUrl}/incentivos/calcular`,
    'POST',
    filtro,
    signal,
  );
}
async function consultarIncentivos(filtro, signal) {
  return ejecutarConsulta(
    `${config.ApiUrl}/incentivos/incentivos`,
    'POST',
    filtro,
    signal,
  );
}
async function exportarIncentivosExcel(filtro, signal) {
  return descargarArchivo(
    `${config.ApiUrl}/incentivos/incentivos-excel`,
    'POST',
    filtro,
    'Incentivos.xlsx',
    signal,
  );
}
async function generarPlanoNomina(filtro, signal) {
  return descargarArchivo(
    `${config.ApiUrl}/incentivos/incentivos-nomina`,
    'POST',
    filtro,
    'Nomina.txt',
    signal,
  );
}
async function guardarCorreccionIncentivo(incentivo, signal) {
  return ejecutarActualizacion(
    `${config.ApiUrl}/incentivos/correccion`,
    'POST',
    incentivo,
    signal,
  );
}
async function guardarDatosAjuste(incentivo, signal) {
  return ejecutarActualizacion(
    `${config.ApiUrl}/incentivos/ajuste`,
    'POST',
    incentivo,
    signal,
  );
}
function esAjuste(tipo) {
  return tipo === 2 || tipo === 3;
}
export
{
  calcularIncentivos,
  consultarIncentivos,
  exportarIncentivosExcel,
  generarPlanoNomina,
  guardarCorreccionIncentivo,
  guardarDatosAjuste,
  obtenerInfoEmpleado,
  obtenerPeriodoActual,
  obtenerTopeAdvertenciaIncentivo,
  esAjuste,
};
