import React, { createContext, useState } from 'react';
import PropTypes from 'prop-types';

const Context = createContext();

const Provider = ({ children }) => {
  const [proceso, setProceso] = useState(null);
  let value = {};

  value = {
    proceso,
    activateProceso: (n) => {
      setProceso(n);
    },
  };
  return (
    <Context.Provider value={value}>
      { children }
    </Context.Provider>
  );
};

Provider.propTypes = {
  children: PropTypes.element.isRequired,
};

export default {
  Provider,
  Consumer: Context.Consumer,
};
