import React from 'react';
import { Input, Label, FormGroup, FormFeedback } from 'reactstrap';
import {
  FormControl,
  NativeSelect,
  InputLabel,
} from '@material-ui/core';
import PropTypes from 'prop-types';

class SelectorSimple extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      datos: [],
    };
  }
  componentDidMount() {
    this.montado = true;
    const ref = this;
    this.props.obtenerDatos(this.abortController.signal)
      .then((r) => {
        if (this.montado) {
          ref.setState({ loading: false, datos: r });
        }
      });
  }
  componentWillUnmount() {
    this.montado = false;
    this.abortController.abort();
  }
  abortController = new AbortController();
  montado = false;
  marcador() {
    let marcador = null;
    if (this.state.loading) {
      marcador = <option key="x" value={null}>{}</option>;
    } else {
      marcador = <option key="x" value="">{}</option>;
    }
    return marcador;
  }
  opciones() {
    if (this.state.datos && this.state.datos.length) {
      return (
        this.state.datos.map(x => (
          <option
            key={x[this.props.campoId]}
            value={x[this.props.campoId]}
          >
            {x[this.props.campoDesc]}
          </option>
        )));
    }
    return null;
  }
  etiqueta() {
    if (this.props.mostrarEtiqueta) {
      return <Label className="labelSimple">{this.props.etiqueta}</Label>;
    }
    return null;
  }
  render() {
    return (
      <FormControl
        variant="standard"
        fullWidth
        col={this.props.col}
        className={this.props.className}
      >
        {this.etiqueta()}
        <InputLabel>{this.props.primaryLabel}</InputLabel>
        <NativeSelect
          value={this.props.value}
          name={this.props.inputName}
          onChange={this.props.onChange}
          disabled={this.props.disabled}
          invalid={this.props.invalid}
        >
          {this.marcador()}
          {this.opciones()}
        </NativeSelect>
        <FormFeedback>{this.props.invalidMessage}</FormFeedback>
      </FormControl>
    );
  }
}
SelectorSimple.propTypes = {
  inputName: PropTypes.string.isRequired,
  campoId: PropTypes.string.isRequired,
  campoDesc: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  primaryLabel: PropTypes.string.isRequired,
  etiqueta: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  obtenerDatos: PropTypes.func.isRequired,
  mostrarEtiqueta: PropTypes.bool,
  col: PropTypes.number,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  invalid: PropTypes.bool,
  invalidMessage: PropTypes.string,
};
SelectorSimple.defaultProps = {
  value: null,
  mostrarEtiqueta: false,
  etiqueta: null,
  col: undefined,
  className: undefined,
  disabled: false,
  invalid: false,
  invalidMessage: null,
};
export default SelectorSimple;
