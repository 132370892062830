import React, { Component } from 'react';
import { Redirect, Route } from 'react-router-dom';
import config from './../../../appConfig.json';
import Loader from '../loader/Loader';

class PrivateRoute extends Component {
  state = {
    loading: true,
    canAccess: true,
  }

  componentDidMount = () => {
    this.getTokenValidation();
  }

  getTokenValidation = async () => {
    const {
      AuthTokenKey, ApiUrl, VisualizarRestringidoKey, UbicacionRestriccionKey,
    } = config;
    const token = localStorage.getItem(AuthTokenKey);

    if (token !== null && token !== undefined) {
      const apiData = await this.validateToken(ApiUrl, token);

      if (apiData.ok) {
        const validation = await apiData.json();
        this.setState({
          loading: false,
          canAccess: validation,
        });

        const restringido = localStorage.getItem(VisualizarRestringidoKey);

        if (restringido !== undefined && restringido) {
          const ubicacionRestriccion = localStorage.getItem(UbicacionRestriccionKey);
          window.location.href = `/#/detalles/ubicacion/${ubicacionRestriccion}`;
        }
      }
    } else {
      this.setState({
        loading: false,
        canAccess: false,
      });
    }
  };

  validateToken = async (ApiUrl, token) => {
    const validationResponse = await fetch(`${ApiUrl}/Token/Validate`, {
      mode: 'cors',
      method: 'POST',
      body: JSON.stringify(token),
      headers: {
        'Content-Type': 'application/json;charset=utf-8',
      },
    });

    return validationResponse;
  }

  canAccess = true;

  render() {
    const { component: RoutedComponent, ...rest } = this.props;
    const { loading, canAccess } = this.state;
    return (!loading ?
      <Route
        {...rest}
        render={props => (
          canAccess ?
            <RoutedComponent {...props} />
            : <Redirect
              to={{
                pathname: '/login',
                state: { from: props.locations },
              }}
            />)
        }
      /> : <Loader loading={loading} />
    );
  }
}

export default PrivateRoute;
