import React from 'react';
import { Grid, Button, Checkbox, FormControlLabel } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import PropTypes from 'prop-types';
import SelectorSimpleMaterial from '../Comunes/SelectorSimpleMaterial';
import { diasSemana, fechaBloqueada } from './datos';

const varsDiasSemana = [
  'lunes',
  'martes',
  'miercoles',
  'jueves',
  'viernes',
  'sabado',
  'domingo',
];
const obtenerDiasSemana = async () => {
  let i = -1;
  return (
    diasSemana.map((x) => {
      i += 1;
      return {
        id: i,
        descripcion: x,
      };
    })
  );
};

class Replicador extends React.Component {
  constructor(props) {
    super(props);
    this.diaOrigenCambiado = this.diaOrigenCambiado.bind(this);
    this.diaSeleccionado = this.diaSeleccionado.bind(this);
    this.confirmar = this.confirmar.bind(this);
    this.cancelar = this.cancelar.bind(this);
    this.restablecerEstado = this.restablecerEstado.bind(this);
    this.state = {
      diaOrigen: props.diaOrigenPredeterminado,
      lunes: false,
      martes: false,
      miercoles: false,
      jueves: false,
      viernes: false,
      sabado: false,
      domingo: false,
    };
  }
  async diaOrigenCambiado(e) {
    const x = varsDiasSemana[e.target.value].toLowerCase();
    await this.setState({ diaOrigen: e.target.value, [x]: false });
  }
  async diaSeleccionado(e) {
    await this.setState({ [e.target.name]: e.target.checked });
  }
  async confirmar() {
    if (this.props.confirmado) {
      const x = {
        origen: this.state.diaOrigen,
        destinos: [
          this.state.lunes,
          this.state.martes,
          this.state.miercoles,
          this.state.jueves,
          this.state.viernes,
          this.state.sabado,
          this.state.domingo,
        ],
      };
      this.props.confirmado(x);
      await this.restablecerEstado();
    }
  }
  async cancelar() {
    if (this.props.cancelado) {
      this.props.cancelado();
      await this.restablecerEstado();
    }
  }
  async restablecerEstado() {
    await this.setState({
      diaOrigen: this.props.diaOrigenPredeterminado,
      lunes: false,
      martes: false,
      miercoles: false,
      jueves: false,
      viernes: false,
      sabado: false,
      domingo: false,
    });
  }
  render() {
    return (
      <Grid xs={3}>
        <Dialog
          open={this.props.activo}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          maxWidth="md"
        >
          <DialogTitle id="alert-dialog-title">
            Replicar datos de pasillos y turnos
          </DialogTitle>
          <DialogContent>
            <Grid contanier spacing={2} direction="column">
              <Grid item xs={3}>
                <SelectorSimpleMaterial
                  inputName="diaOrigen"
                  value={this.state.diaOrigen}
                  onChange={this.diaOrigenCambiado}
                  obtenerDatos={obtenerDiasSemana}
                  campoId="id"
                  campoDesc="descripcion"
                  etiqueta="Día origen"
                  elementoVacio={false}
                />
              </Grid>
              <Grid container item xs={12}>
                <Grid item xs={2}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={this.state.lunes}
                        onChange={this.diaSeleccionado}
                        disabled={this.state.diaOrigen === 0 || fechaBloqueada(this.props.fechaBase, 0) < 0}
                        name="lunes"
                        color="primary"
                      />
                    }
                    label="Lunes"
                  />
                </Grid>
                <Grid item xs={2}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={this.state.martes}
                        onChange={this.diaSeleccionado}
                        disabled={this.state.diaOrigen === 1 || fechaBloqueada(this.props.fechaBase, 1) < 0}
                        name="martes"
                        color="primary"
                      />
                    }
                    label="Martes"
                  />
                </Grid>
                <Grid item xs={2}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={this.state.miercoles}
                        onChange={this.diaSeleccionado}
                        disabled={this.state.diaOrigen === 2 || fechaBloqueada(this.props.fechaBase, 2) < 0}
                        name="miercoles"
                        color="primary"
                      />
                    }
                    label="Miércoles"
                  />
                </Grid>
                <Grid item xs={2}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={this.state.jueves}
                        onChange={this.diaSeleccionado}
                        disabled={this.state.diaOrigen === 3 || fechaBloqueada(this.props.fechaBase, 3) < 0}
                        name="jueves"
                        color="primary"
                      />
                    }
                    label="Jueves"
                  />
                </Grid>
                <Grid item xs={2}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={this.state.viernes}
                        onChange={this.diaSeleccionado}
                        disabled={this.state.diaOrigen === 4 || fechaBloqueada(this.props.fechaBase, 4) < 0}
                        name="viernes"
                        color="primary"
                      />
                    }
                    label="Viernes"
                  />
                </Grid>
                <Grid item xs={2}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={this.state.sabado}
                        onChange={this.diaSeleccionado}
                        disabled={this.state.diaOrigen === 5 || fechaBloqueada(this.props.fechaBase, 5) < 0}
                        name="sabado"
                        color="primary"
                      />
                    }
                    label="Sábado"
                  />
                </Grid>
                <Grid item xs={2}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={this.state.domingo}
                        onChange={this.diaSeleccionado}
                        disabled={this.state.diaOrigen === 6 || fechaBloqueada(this.props.fechaBase, 6) < 0}
                        name="domingo"
                        color="primary"
                      />
                    }
                    label="Domingo"
                  />
                </Grid>
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.confirmar} color="primary">
              Replicar
            </Button>
            <Button onClick={this.cancelar} color="primary">
              Cancelar
            </Button>
          </DialogActions>
        </Dialog>
      </Grid>
    );
  }
}

Replicador.propTypes = {
  activo: PropTypes.bool.isRequired,
  diaOrigenPredeterminado: PropTypes.number,
  confirmado: PropTypes.func.isRequired,
  cancelado: PropTypes.func.isRequired,
  fechaBase: PropTypes.shape.isRequired,
};

Replicador.defaultProps = {
  diaOrigenPredeterminado: 0,
};

export default Replicador;
