import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import {
  Row,
  Col,
  Card,
  CardBody,
  ListGroup,
  ListGroupItem,
  Badge,
  UncontrolledButtonDropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from 'reactstrap';

import Eficiencia from '../../../shared/components/eficiencia/Eficiencia';

const IndicadorPlanta = ({
  planta, handleChangeTime, time,
}) => (
  <Card>
    <CardBody>
      <div className="row card__title d-flex">
        <Col sm={6} className="d-flex justify-content-center align-items-center">
          <h5 className="bold-text text-center">Planta {planta.nombre}</h5>
        </Col>
        <Col xs={6} sm={3} className="d-flex justify-content-start">
          <UncontrolledButtonDropdown>
            <DropdownToggle caret className="btn-rounded" outline>
              <span className="lnr lnr-calendar-full" /> {time}
            </DropdownToggle>
            <DropdownMenu>
              <DropdownItem value="Turno" onClick={handleChangeTime}>Turno</DropdownItem >
              <DropdownItem value="Dia" onClick={handleChangeTime}>Dia</DropdownItem >
            </DropdownMenu>
          </UncontrolledButtonDropdown>
        </Col>
        <Col xs={6} sm={3} className="d-flex justify-content-end">
          <Link to="/detalles/planta/1110/unidad/11" className="btn btn-outline-secondary btn-rounded">
            <span className="lnr lnr-chart-bars" />
          </Link>
        </Col>
      </div>
      <Row>
        <Col lg={6}>
          <Eficiencia porcentaje={planta.eficiencia} innerRadius={88} />
        </Col>
        <Col lg={6} className="d-flex flex-column justify-content-between">
          <Row>
            <Col sm={12}>
              <ListGroup flush>
                <ListGroupItem className="d-flex justify-content-between align-items-center">
                  <b>T. DE PARO:</b>
                  <h3>
                    <Badge color="dark" pill>{planta.tiempoParo} MINS</Badge>
                  </h3>
                </ListGroupItem>
                <ListGroupItem className="d-flex justify-content-between align-items-center">
                  <b>NOTIFICADO SAP TEJ:</b>
                  <h3>
                    <Badge color="dark" pill>{Math.round(planta.docenasNotificadas)} DZ</Badge>
                  </h3>
                </ListGroupItem>
                <ListGroupItem className="d-flex justify-content-between align-items-center">
                  <b>NOTIFICADO SAP EMP:</b>
                  <h3>
                    <Badge color="dark" pill>{Math.round(planta.docenasNotificadasEmp)} DZ</Badge>
                  </h3>
                </ListGroupItem>
                <ListGroupItem className="d-flex justify-content-between align-items-center">
                  <b>META:</b>
                  <h3>
                    <Badge color="dark" pill>{planta.meta} DZ</Badge>
                  </h3>
                </ListGroupItem>
              </ListGroup>
            </Col>
          </Row>
        </Col>
      </Row>
    </CardBody>
  </Card>
);

IndicadorPlanta.propTypes = {
  handleChangeTime: PropTypes.isRequired,
  time: PropTypes.string.isRequired,
  planta: PropTypes.string.isRequired,
};

export default IndicadorPlanta;
