import React from 'react';
import PropTypes from 'prop-types';
import { TextField, Grid, Button, Select, NativeSelect } from '@material-ui/core';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import { es } from 'date-fns/locale';
import { FileExcelIcon, SearchIcon } from 'mdi-react';
import MaterialUISelectorMultiple from '../../Comunes/MateriaUISelectorMultiple';
import SelectorSimple from '../../Comunes/SelectorSimple';
import { obtenerTurnosPrincipales, obtenerPasillos, truncarFecha } from '../../Comunes/datosComunes';
import SelectorSimpleMaterial from '../../Comunes/SelectorSimpleMaterial';

class FiltroReporteBasicoSegundas extends React.Component {
  constructor(props) {
    super(props);
    this.handleChangeFechaInicial = this.handleChangeFechaInicial.bind(this);
    this.handleChangeFechaFinal = this.handleChangeFechaFinal.bind(this);
    this.handleChangePasillo = this.handleChangePasillo.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleBlur = this.handleBlur.bind(this);
    this.construirFiltro = this.construirFiltro.bind(this);
    this.fijarFiltroConsulta = this.fijarFiltroConsulta.bind(this);
    this.fijarFiltroExportacion = this.fijarFiltroExportacion.bind(this);
    this.state = {
      errores: {
        fechaInicial: null,
        fechaFinal: null,
        lote: null,
      },
      cargando: {
        segundas: false,
        pasillos: false,
      },
      fechaInicial: this.inicio(),
      fechaFinal: this.inicio(),
      lote: null,
      turno: null,
      pasillos: [],
      listaPasillos: [],
      maquina: null,
    };
  }
  async componentDidMount() {
    await this.cargarPasillos();
  }
  abortController = new AbortController();
  inicio = () => {
    const d = new Date();
    d.setHours(0, 0, 0, 0);
    return d.toISOString();
  };
  async cargarPasillos() {
    await this.setState({ cargando: { pasillos: true } });
    const lista = await obtenerPasillos(this.abortController.signal);
    await this.setState({
      listaPasillos: lista.sort((a, b) => a - b).map(x => ({ id: x.id, descripcion: x.codigo })),
      pasillos: [],
      cargando: { pasillos: false },
    });
  }
  async handleChangeFechaInicial(v) {
    await this.setState({ fechaInicial: truncarFecha(v) });
  }
  async handleChangeFechaFinal(v) {
    await this.setState({ fechaFinal: truncarFecha(v) });
  }
  async handleChangePasillo(values) {
    if (Array.isArray(values)) {
      const pasillos = values.map(x => x.id);
      await this.setState({ pasillos });
    } else {
      console.error('Values is not an array:', values);
    }
  }
  async handleChange(e) {
    await this.setState({ [e.target.name]: e.target.value });
  }
  async handleBlur(e) {
    const v = e.target.value;
    if (v) {
      await this.setState({ [e.target.name]: v.padStart(10, '0') });
    }
  }
  async fijarFiltroConsulta() {
    if (this.props.filtroConsultaEstablecido) {
      const filtro = await this.construirFiltro();
      if (filtro != null && this.props.filtroConsultaEstablecido) {
        this.props.filtroConsultaEstablecido(filtro);
      }
    }
  }
  async fijarFiltroExportacion() {
    if (this.props.filtroExportacionEstablecido) {
      const filtro = await this.construirFiltro();
      if (filtro != null && this.props.filtroExportacionEstablecido) {
        this.props.filtroExportacionEstablecido(filtro);
      }
    }
  }
  async construirFiltro() {
    if (await this.validarEntrada()) {
      return {
        fechaInicioConsulta: this.state.fechaInicial,
        fechaFinConsulta: this.state.fechaFinal,
        codigoLoteConsulta: this.state.lote,
        codigoTurnoConsulta: this.state.turno !== -1 ? this.state.turno : null,
        pasillos: this.state.pasillos,
        codigoMaquinaConsulta: this.state.maquina,
      };
    }
    return null;
  }
  async validarEntrada() {
    let v = true;
    let mensajeFechaInicial = null;
    let mensajeFechaFinal = null;
    let mensajeLote = null;
    if (!this.state.fechaInicial && !this.state.fechaFinal && !this.state.lote) {
      mensajeFechaInicial = 'Debe especificar la fecha inicial de la consulta';
      mensajeFechaFinal = 'Debe especificar la fecha final de la consulta';
      mensajeLote = 'Debe especificar el lote para la consulta';
      v = false;
    }
    if (v) {
      const fechaInicio = Date.parse(this.state.fechaInicial);
      const fechaFin = Date.parse(this.state.fechaFinal);
      if (fechaFin < fechaInicio) {
        v = false;
        mensajeFechaInicial = 'La fecha inicial debe ser menor que la final';
        mensajeFechaFinal = 'La fecha final debe ser mayor que la inicial';
      } else if (((fechaFin - fechaInicio) / 1000) > (15 * 86400)) {
        v = false;
        mensajeFechaInicial = 'La diferencia entre la fecha inicial y la final no puede ser ' +
          'superior a quince días';
      }
    }
    await this.setState({
      errores: {
        fechaInicial: mensajeFechaInicial,
        fechaFinal: mensajeFechaFinal,
        lote: mensajeLote,
      },
    });
    return v;
  }
  render() {
    return (
      <Grid direction="column" container spacing={1} alignItems="center">
        <Grid container item direction="row" spacing={1} justify="center">
          <Grid container item direction="column" lg={3} sm={6} xs={12} spacing={1}>
            <MuiPickersUtilsProvider utils={DateFnsUtils} locale={es}>
              <Grid item>
                <KeyboardDatePicker
                  name="fechaInicial"
                  size="small"
                  inputVariant="standard"
                  autoOk
                  format="dd/MM/yyyy"
                  label="Fecha inicial"
                  value={this.state.fechaInicial}
                  onChange={this.handleChangeFechaInicial}
                  clearable
                  clearLabel="Limpiar"
                  cancelLabel="Cancelar"
                  okLabel="Aceptar"
                  error={this.state.errores.fechaInicial}
                  helperText={this.state.errores.fechaInicial}
                />
              </Grid>
              <Grid item>
                <KeyboardDatePicker
                  locale="es-ES"
                  size="small"
                  name="fechaFinal"
                  inputVariant="standard"
                  autoOk
                  format="dd/MM/yyyy"
                  label="Fecha final"
                  value={this.state.fechaFinal}
                  onChange={this.handleChangeFechaFinal}
                  clearable
                  clearLabel="Limpiar"
                  cancelLabel="Cancelar"
                  okLabel="Aceptar"
                  error={this.state.errores.fechaFinal}
                  helperText={this.state.errores.fechaFinal}
                />
              </Grid>
            </MuiPickersUtilsProvider>
          </Grid>
          <Grid container item direction="column" lg={2} sm={6} xs={12} spacing={1}>
            <Grid item>
              <TextField
                variant="standard"
                size="small"
                name="lote"
                label="Lote"
                value={this.state.lote}
                onChange={this.handleChange}
                onBlur={this.handleBlur}
                error={this.state.errores.lote}
                helperText={this.state.errores.lote}
              />
            </Grid>
          </Grid>
          <Grid container item direction="column" lg={2} sm={6} xs={12} spacing={1}>
            <Grid item>
              <SelectorSimpleMaterial
                inputName="turno"
                value={this.state.turno}
                onChange={this.handleChange}
                obtenerDatos={obtenerTurnosPrincipales}
                campoId="id"
                campoDesc="descripcionTurno"
                etiqueta="Turno"
                etiquetaValorInicial="(Todos)"
              />
            </Grid>
          </Grid>
          <Grid container item direction="column" lg={2} sm={6} xs={12} spacing={1}>
            <Grid item>
              <MaterialUISelectorMultiple
                variant="outlined"
                cargando={this.state.cargando.pasillos}
                lista={this.state.listaPasillos}
                label="Pasillo"
                placeholder="Pasillos"
                value={this.pasillos}
                onChange={this.handleChangePasillo}
              />
            </Grid>
          </Grid>
          <Grid container item direction="column" lg={2} sm={6} xs={12} spacing={1}>
            <Grid item>
              <TextField
                variant="standard"
                size="small"
                name="maquina"
                label="Máquina"
                value={this.state.maquina}
                onChange={this.handleChange}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid container item direction="column" md={10}>
          <Grid container spacing={1} justify="center" direction="row">
            <Grid item xs={12} md={3}>
              <Button
                className="btn btn-primary"
                onClick={this.fijarFiltroConsulta}
                disabled={this.props.disabled}
                primary
                variant="outlined"
              >
                <SearchIcon />&nbsp;
                Consultar
              </Button>
            </Grid>
            <Grid item xs={12} md={3}>
              <Button
                className="btn btn-secondary"
                onClick={this.fijarFiltroExportacion}
                disabled={this.props.disabled}
                variant="outlined"
              >
                <FileExcelIcon />&nbsp;
                Exportar
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    );
  }
}

FiltroReporteBasicoSegundas.propTypes = {
  filtroConsultaEstablecido: PropTypes.func.isRequired,
  filtroExportacionEstablecido: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
};
FiltroReporteBasicoSegundas.defaultProps = {
  disabled: false,
};

export default FiltroReporteBasicoSegundas;
