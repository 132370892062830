import React from 'react';
import Pasillos from '../EficienciaTejido/Components/Pasillos';
import Loader from '../../shared/components/loader/Loader';
import config from '../../appConfig.json';

const { AuthTokenKey } = config;

class EficienciasTejido extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      dataTejido: [],
      loading: true,
      error: null,
      time: 'Turno',
    };
  }
  componentDidMount() {
    this.fetchDataTejido(this.state.time);
  }

  handleChangeTime = async (horario) => {
    await this.setState({ time: horario.target.value });
    this.fetchDataTejido(this.state.time);
  }

  fetchDataTejido = async (time) => {
    this.setState({ loading: true, error: null });
    try {
      let respuestaTejido = null;
      respuestaTejido = await fetch(
        `${config.ApiUrl}/tejido/eficiencia/${time}`,
        {
          headers: {
            'Content-Type': 'application/json;charset=utf-8',
            Authorization: localStorage.getItem(AuthTokenKey),
          },
        },
      );
      const datosTejido = await respuestaTejido.json();
      this.setState({ loading: false, dataTejido: datosTejido });
    } catch (error) {
      this.setState({ error: 'falló' });
    }
  }

  render() {
    const {
      loading, error, dataTejido,
    } = this.state;
    if (loading === true) {
      return (
        <div>
          <Loader
            loading={loading}
          />
        </div>
      );
    }
    return (
      <Pasillos
        pasillos={dataTejido.listaPasillos}
        base={config.ApiUrl}
        dataTejido={dataTejido.encabezado}
        proyeccion={dataTejido.proyeccion}
        meta={dataTejido.meta}
        notificada={dataTejido.notificada}
        handleChangeTime={this.handleChangeTime}
        time={this.state.time}
      />
    );
  }
}
export default EficienciasTejido;
