import React, { PureComponent } from 'react';
import { Bar, CartesianGrid, ComposedChart, Line, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';
import OccupancyTooltipContent from './OccupancyTooltipContent';

const data = [
  {
    name: 'Mon 10/07',
    uv: 95,
    departure: 75,
    arrival: 10,
  },
  {
    name: 'Tue 11/07',
    uv: 85,
    departure: 23,
    arrival: 65,
  },
  {
    name: 'Wed 12/07',
    uv: 47,
    departure: 26,
    arrival: 45,
  },
  {
    name: 'Thu 13/07',
    uv: 80,
    departure: 25,
    arrival: 45,
  },
  {
    name: 'Fri 14/07',
    uv: 55,
    departure: 35,
    arrival: 15,
  },
  {
    name: 'Sat 15/07',
    uv: 99,
    departure: 30,
    arrival: 40,
  },
  {
    name: 'Sun 16/07',
    uv: 85,
    departure: 48,
    arrival: 26,
  },
  {
    name: 'Mon 10/07',
    uv: 95,
    departure: 75,
    arrival: 10,
  },
  {
    name: 'Tue 11/07',
    uv: 85,
    departure: 23,
    arrival: 65,
  },
  {
    name: 'Wed 12/07',
    uv: 47,
    departure: 26,
    arrival: 45,
  },
  {
    name: 'Thu 13/07',
    uv: 80,
    departure: 25,
    arrival: 45,
  },
  {
    name: 'Fri 14/07',
    uv: 55,
    departure: 35,
    arrival: 15,
  },
  {
    name: 'Sat 15/07',
    uv: 99,
    departure: 30,
    arrival: 40,
  },
  {
    name: 'Sun 16/07',
    uv: 85,
    departure: 48,
    arrival: 26,
  },
];

class Occupancy extends PureComponent {
  toPercent = (decimal, fixed = 0) => `${decimal.toFixed(fixed)}%`;

  render() {
    return (
      <ResponsiveContainer height={321} style={{ width: '100%' }}>
        <ComposedChart data={data} margin={{ top: 20, left: -15 }}>
          <XAxis dataKey="name" tickLine={false} padding={{ left: 20 }} />
          <YAxis tickLine={false} tickFormatter={this.toPercent} />
          <Tooltip content={<OccupancyTooltipContent colorForKey={{ uv: '#555555' }} />} />
          <CartesianGrid vertical={false} />
          <Bar dataKey="uv" name="Stay overs" fill="#dbdaff" barSize={20} />
          <Line type="linear" name="Departures" dataKey="departure" stroke="#5eab10" />
          <Line type="linear" name="Arrivals" dataKey="arrival" stroke="#0091f3" />
        </ComposedChart>
      </ResponsiveContainer>
    );
  }
}

export default Occupancy;
