import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Popover from '@material-ui/core/Popover';
import Typography from '@material-ui/core/Typography';
import { IconButton, Grid, Divider } from '@material-ui/core';
import { InformationIcon } from 'mdi-react';

const useStyles = makeStyles(theme => ({
  typography: {
    padding: theme.spacing(2),
  },
}));

export default function DetalleSubseccion(props) {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  return (
    <>
      <IconButton aria-describedby={id} size="small" style={{ width: '20px' }} onClick={handleClick} >
        <InformationIcon fontSize="small" />
      </IconButton>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <Grid container style={{ padding: '3%', width: '105px', height: '110px' }} >
          <Grid item xs={12}>
            <Divider style={{ marginTop: '2%' }} />
            <strong>CRITERIO 1 = </strong>{props.detalle.criterioBusquedaUno}
          </Grid>
          <Grid item xs={12}>
            <strong>CRITERIO 2 = </strong>{props.detalle.criterioBusquedaDos}
          </Grid>
          <Grid item xs={12}>
            <strong>CRITERIO 3 = </strong>{props.detalle.criterioBusquedaTres}
          </Grid>
          <Grid item xs={12}>
            <strong>CRITERIO 4 = </strong>{props.detalle.criterioBusquedaCuatro}
          </Grid>
          <Grid item xs={12}>
            <strong>ALARMA   = </strong>{props.detalle.alarmaFaltaEstandar}
            <Divider style={{ marginBlockEnd: '2%' }} />
          </Grid>
        </Grid>
      </Popover>
    </>
  );
}
