import React, { useState, useEffect } from 'react';
import { Grid, Paper, Divider, ButtonGroup } from '@material-ui/core';
import { createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles';
import config from '../../../appConfig.json';
import Loader from '../../../shared/components/loader/Loader';
import Alerta from '../../Comunes/Alerta';
import Filtro from '../Filtro';
import ListaSecciones from './Components/ListaSecciones';
import Subsecciones from './Components/Subsecciones';
import Form from './Components/Form';
import Context from '../../Operaciones/store';
import CrearSeccion from './Components/CrearSeccion';
import ModificarSeccion from './Components/ModificarSeccion';
import { perteneceRolIngenieria } from '../../Comunes/datosComunes';

const theme = createMuiTheme({
  palette: {
    primary: { main: '#263238' },
    secondary: { main: '#1a237e' },
  },
});

const Secciones = () => {
  const [secciones, setSecciones] = useState([]);
  const [seccionesOriginales, setSeccionesOriginales] = useState([]);
  const [unidadesNegocio, setUnidadesNegocio] = useState([]);
  const [plantas, setPlantas] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);

  const resetearError = () => {
    setError(false);
  };
  function fetchData() {
    try {
      window.fetch(
        `${config.ApiUrl}/secciones/mas-unidad-planta`,
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: localStorage.getItem(config.AuthTokenKey),
          },
        },
      )
        .then(res => res.json())
        .then((response) => {
          setSecciones(response);
          setSeccionesOriginales(response);
          setLoading(false);
        });

      window.fetch(
        `${config.ApiUrl}/unidadesNegocio/`,
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: localStorage.getItem(config.AuthTokenKey),
          },
        },
      )
        .then(res => res.json())
        .then((response) => {
          setUnidadesNegocio(response);
        });

      window.fetch(
        `${config.ApiUrl}/Plantas/`,
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: localStorage.getItem(config.AuthTokenKey),
          },
        },
      )
        .then(res => res.json())
        .then((response) => {
          setPlantas(response);
        });
    } catch {
      setError(false);
      console.error('Error al obtener datos:', error);
    }
  }
  const reload = () => {
    window.location.reload();
  };

  const handleChange = (e) => {
    const result = secciones.filter(s =>
      (s.descripcion.toLowerCase().indexOf(e.target.value.toLowerCase()) !== -1 && s));
    if (result.length !== 0 && e.target.value !== '') { setSecciones(result); }
    if (result.length === 0 && e.target.value === '') { setSecciones(seccionesOriginales); }
    if (result.length !== 0 && e.target.value === '') { setSecciones(seccionesOriginales); }
    if (result.length === 0 && e.target.value !== '') { setSecciones(seccionesOriginales); }
  };


  useEffect(fetchData, []);
  if (loading) return (<div> <Loader loading={loading} /></div>);
  return (
    <>
      <MuiThemeProvider theme={theme}>
        <Context.Provider>
          <Alerta
            texto="Ocurrió un error de ejecución. Intente nuevamente."
            toggle={resetearError}
            isVisible={error}
          />
          <Grid container >
            <Grid item xs={12} md={3} lg={3} >
              <Paper style={{ marginTop: '3%', marginLeft: '3%' }}>
                <Filtro onChange={handleChange} label="Sección" helperText="Buscar secciones" margen="5%" />
                <Divider />
                <ListaSecciones secciones={secciones} fetch={reload} />
              </Paper>
            </Grid>
            <Grid item xs={12} md={9} lg={6} >
              <Subsecciones />
            </Grid>
            <Grid item xs={12} lg={3}>
              <Grid container>
                <Grid item xs={12}>
                  <Form />
                </Grid>
                <Grid item xs={12}>
                  {
                    perteneceRolIngenieria() &&
                    <ButtonGroup variant="contained" style={{ margin: '3%', display: 'flex', alignContent: 'center' }} >
                      <CrearSeccion fetch={fetchData} unidades={unidadesNegocio} plantas={plantas} />
                      <ModificarSeccion fetch={fetchData} unidades={unidadesNegocio} plantas={plantas} />
                    </ButtonGroup>
                  }
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Context.Provider>
      </MuiThemeProvider>
    </>
  );
};

export default Secciones;
