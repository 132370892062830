import React, { useState, useEffect } from 'react';
import { createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles';
import { Paper, Divider } from '@material-ui/core';
import config from '../../../appConfig.json';
import Alerta from '../../Comunes/Alerta';
import Loader from '../../../shared/components/loader/Loader';
import Filtro from '../Filtro';
import TablaTallas from './Components/TablaTallas';
import CrearTalla from './Components/CrearTalla';
import ModificarTalla from './Components/ModificarTalla';
import EliminarTalla from './Components/EliminarTalla';

const theme = createMuiTheme({
  palette: {
    primary: { main: '#263238' },
    secondary: { main: '#1a237e' },
  },
});

const Tallas = () => {
  const [talla, setTalla] = useState({});
  const [tallas, setTallas] = useState([]);
  const [tallasOrigen, setTallasOrigen] = useState([]);
  const [unidadesNegocio, setUnidadesNegocio] = useState([]);
  const [error, setError] = useState(false);
  const [gruposTalla, setGruposTalla] = useState([]);
  const [openI, setOpenI] = React.useState(false);
  const [alertI, setAlertI] = React.useState(false);
  const [openD, setOpenD] = React.useState(false);
  const [alertD, setAlertD] = React.useState(false);
  const [openU, setOpenU] = React.useState(false);
  const [loading, setLoading] = React.useState(true);
  const [select, setSelect] = React.useState('Grupo');
  const [selectU, setSelectU] = React.useState('Unidad');

  function fetchData() {
    try {
      window.fetch(
        `${config.ApiUrl}/tallas/mas-grupos-unidades`,
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: localStorage.getItem(config.AuthTokenKey),
            Accept: 'application/json',
          },
        },
      )
        .then(res => res.json())
        .then((response) => {
          setTallas(response);
          setTallasOrigen(response);
          setLoading(false);
        });

      window.fetch(
        `${config.ApiUrl}/unidadesNegocio/`,
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: localStorage.getItem(config.AuthTokenKey),
            Accept: 'application/json',
          },
        },
      )
        .then(res => res.json())
        .then((response) => {
          setUnidadesNegocio(response);
        });

      window.fetch(
        `${config.ApiUrl}/tallas/grupos-talla`,
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: localStorage.getItem(config.AuthTokenKey),
            Accept: 'application/json',
          },
        },
      )
        .then(res => res.json())
        .then((response) => {
          setGruposTalla(response);
        });
    } catch {
      handleAlertOpen(1);
      console.error('Error al obtener datos:', error);
    }
  }

  useEffect(fetchData, []);

  const handleChange = (e) => {
    const result = tallas.filter(t =>
      (t.descripcion.toLowerCase().indexOf(e.target.value.toLowerCase()) !== -1 && t));
    if (result.length !== 0 && e.target.value !== '') { setTallas(result); }
    if (result.length === 0 && e.target.value === '') { setTallas(tallasOrigen); }
    if (result.length !== 0 && e.target.value === '') { setTallas(tallasOrigen); }
    if (result.length === 0 && e.target.value !== '') { setTallas(tallasOrigen); }
  };

  const handleOpen = (sw) => {
    if (sw === 1) setOpenD(true);
    if (sw === 2) setOpenI(true);
    if (sw === 3) setOpenU(true);
  };

  const handleClose = (sw) => {
    if (sw === 1) { setOpenD(false); setAlertD(false); }
    if (sw === 2) { setOpenI(false); setAlertI(false); }
    if (sw === 3) { setOpenU(false); }
    setSelect('');
    setSelectU('');
  };

  const handleAlertOpen = (sw) => {
    if (sw === 1) setAlertD(true);
    if (sw === 2) setAlertI(true);
  };

  const handleDialogDelete = (Talla) => {
    setTalla(Talla);
    handleOpen(1);
  };

  const handleDialogUpdate = (Talla) => {
    const array = { ...Talla };
    gruposTalla.forEach((g) => {
      if (g.descripcion === array.grupoTalla) { array.grupoTalla = g.id; }
    });
    unidadesNegocio.forEach((u) => {
      if (u.descripcion === array.unidadNegocio) { array.unidadNegocio = u.id; }
    });
    setSelect(array.grupoTalla);
    setSelectU(array.unidadNegocio);
    setTalla(array);
    handleOpen(3);
  };

  const handleDialogInsert = () => {
    handleOpen(2);
  };

  const handleChangeInputs = (e) => {
    const array = { ...talla };
    array[e.target.id] = e.target.value;
    setTalla(array);
  };

  const handleSelect = (e, sw) => {
    const array = { ...talla };
    array[e.target.name] = e.target.value;
    setTalla(array);
    if (sw === 1) setSelect(e.target.value);
    if (sw === 2) setSelectU(e.target.value);
  };

  const handleInsert = async () => {
    const array = {
      ...talla,
      fechaCreacion: new Date(),
      fechaModificacion: new Date(),
      usuario: localStorage.getItem(config.CryUsername),
    };
    const request = await fetch(`${config.ApiUrl}/tallas/nueva`, {
      mode: 'cors',
      method: 'PUT',
      body: JSON.stringify(array),
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: localStorage.getItem(config.AuthTokenKey),
      },
    });
    const response = await request.json();
    if (response.sw === 1) {
      handleAlertOpen(2);
    } else {
      // window.location.reload();
      fetchData();
      handleClose(2);
    }
  };

  const handleUpdate = async () => {
    const request = await fetch(`${config.ApiUrl}/tallas/editar/${talla.id}`, {
      mode: 'cors',
      method: 'POST',
      body: JSON.stringify(talla),
      headers: {
        'Content-Type': 'application/json',
        Authorization: localStorage.getItem(config.AuthTokenKey),
        Accept: 'application/json',
      },
    });
    const response = await request.json();
    // window.location.reload();
    fetchData();
    handleClose(3);
  };

  const handleDelete = async () => {
    const request = await fetch(
      `${config.ApiUrl}/tallas/eliminar/${talla.id}`,
      {
        mode: 'cors',
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
          Authorization: localStorage.getItem(config.AuthTokenKey),
        },
      },
    );
    if (request.status === 400) {
      handleAlertOpen(1);
    } else {
      // window.location.reload();
      fetchData();
      handleClose(1);
    }
  };

  if (loading) return (<div> <Loader loading={loading} /></div>);
  return (
    <>
      {error && (
        <Alerta
          isVisible={this.state.error}
          toggle={() => setError(false)}
          texto={`Error: ${error}`}
        />
      )}
      <MuiThemeProvider theme={theme}>
        <Paper style={{ marginRight: '1%', marginLeft: '1%', marginTop: '2%' }}>
          <div style={{ width: '30%' }}>
            <Filtro onChange={handleChange} label="Talla" helperText="Buscar Tallas" margen="5%" />
          </div>
          <Divider />
          <TablaTallas
            tallas={tallas}
            onDialogDelete={handleDialogDelete}
            onDialogInsert={handleDialogInsert}
            onDialogUpdate={handleDialogUpdate}
            unidades={unidadesNegocio}
            fetch={fetchData}
          />
          <EliminarTalla
            open={openD}
            alert={alertD}
            handleClose={handleClose}
            handleDelete={handleDelete}
          />
          <CrearTalla
            open={openI}
            alert={alertI}
            handleClose={handleClose}
            handleInsert={handleInsert}
            unidades={unidadesNegocio}
            grupos={gruposTalla}
            select={select}
            selectU={selectU}
            handleChangeInputs={handleChangeInputs}
            handleSelect={handleSelect}
          />
          <ModificarTalla
            open={openU}
            handleClose={handleClose}
            handleUpdate={handleUpdate}
            unidades={unidadesNegocio}
            grupos={gruposTalla}
            select={select}
            selectU={selectU}
            handleChangeInputs={handleChangeInputs}
            handleSelect={handleSelect}
            talla={talla}
          />
        </Paper>
      </MuiThemeProvider>
    </>
  );
};
export default Tallas;

