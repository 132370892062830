import React from 'react';
import { FormGroup, Input, Label, FormFeedback, Button } from 'reactstrap';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  IconButton,
  TablePagination,
} from '@material-ui/core';
import { EditIcon, DeleteIcon, AddCircleIcon } from 'mdi-react';
import ModalForm from '../Comunes/ModalForm';
import ModalConfirmacion from './ModalConfirmacion';
import FiltroEstandaresOperacionTalla from './FiltroEstandaresOperacionTalla';
import EstandaresOperacionTallaForm from './EstandaresOperacionTallaForm';
import {
  obtenerEstandaresOperacionTallaPaginado,
  obtenerEstandaresOperacionTallaExcel,
  borrarEstandarOperacionTalla,
  actualizarEstandarOperacionTalla,
} from './datosEstandares';
import { mostrarValidacion, esDecimalValido, convertirDecimal, perteneceRolIngenieria } from '../Comunes/datosComunes';
import { CryUsername } from '../../appConfig.json';
import Alerta from '../Comunes/Alerta';
import StyledTableCell from '../Comunes/StyledTableCell';

class EstandaresOperacionTalla extends React.Component {
  static inicializarEstadoErrores() {
    return {
      hayError: false,
      estandar: [],
    };
  }
  constructor(props) {
    super(props);
    this.obtenerDatos = this.obtenerDatos.bind(this);
    this.ejecutarConsulta = this.ejecutarConsulta.bind(this);
    this.exportarExcel = this.exportarExcel.bind(this);
    this.iniciarEdicion = this.iniciarEdicion.bind(this);
    this.iniciarCreacion = this.iniciarCreacion.bind(this);
    this.valorEstandarCambiado = this.valorEstandarCambiado.bind(this);
    this.actualizarEstandar = this.actualizarEstandar.bind(this);
    this.cancelarEdicionEstandar = this.cancelarEdicionEstandar.bind(this);
    this.finalizarCreacionEstandar = this.finalizarCreacionEstandar.bind(this);
    this.iniciarBorrado = this.iniciarBorrado.bind(this);
    this.borradoConfirmado = this.borradoConfirmado.bind(this);
    this.resetearEstadoErrores = this.resetearEstadoErrores.bind(this);
    this.state =
    {
      filtro: {
        secciones: [],
        subsecciones: [],
        operaciones: [],
        tallas: [],
      },
      borrandoEstandar: false,
      cargando: false,
      exportando: false,
      estandarBorradoId: null,
      valorEstandar: null,
      editandoEstandar: false,
      creandoEstandar: false,
      estandarEditadoId: null,
      guardando: false,
      errores: EstandaresOperacionTalla.inicializarEstadoErrores(),
      // estandares: [],
      consulta: {
        cantidadRegistros: 0,
        lista: [],
      },
      page: 0,
      rowsPerPage: 10,
    };
  }
  componentDidMount() {
    this.obtenerDatos(this.state.filtro);
    this.actualizarEstandar();
    this.ejecutarConsulta(this.state.filtro);
  }
  handleChangePage = (event, newPage) => {
    this.setState({ page: newPage });
  };
  handleChangeRowsPerPage = (event) => {
    this.setState({
      rowsPerPage: parseInt(event.target.value, 10),
      page: 0, // Reset to the first page when changing rows per page
    });
  };
  async obtenerDatos(filtro) {
    let consulta = null;
    const resultado = await obtenerEstandaresOperacionTallaPaginado(filtro);
    if (!resultado.error) {
      consulta = {
        cantidadRegistros: resultado.datos.cantidadRegistros,
        lista: resultado.datos.estandares,
      };
    } else {
      consulta = { cantidadRegistros: 0, lista: [] };
    }
    this.setState({
      consulta,
      cargando: false,
      errores: {
        hayError: resultado.error,
      },
    });
  }
  async iniciarEdicion(info) {
    await this.setState({
      editandoEstandar: true,
      guardando: false,
      estandarEditadoId: info.estandarId,
      valorEstandar: info.estandar,
    });
  }
  async iniciarCreacion() {
    await this.setState({ creandoEstandar: true, guardando: false });
  }
  async valorEstandarCambiado(e) {
    await this.setState({ valorEstandar: e.target.value });
  }
  async actualizarEstandar() {
    let hayError = false;
    const mensajes = [];
    let ve = null;
    if (this.state.valorEstandar != null && this.state.valorEstandar !== '') {
      ve = convertirDecimal(this.state.valorEstandar);
      if (isNaN(ve) || !esDecimalValido(this.state.valorEstandar)) {
        hayError = true;
        mensajes.push('El formato del valor de estándar no es válido');
      }
    } else {
      hayError = true;
      mensajes.push('No especificó el valor del estándar');
    }
    if (!hayError) {
      await this.setState({ guardando: true, errores: EstandaresOperacionTalla.inicializarEstadoErrores() });
      const estandar = {
        estandarId: this.state.estandarEditadoId,
        estandar: ve,
        usuario: localStorage.getItem(CryUsername),
      };
      const resultado = await actualizarEstandarOperacionTalla(estandar);
      if (!resultado.error) {
        await this.refrescarConsulta();
      } else {
        const errores = { ...resultado.datos };
        errores.hayError = true;
        await this.setState({ guardando: false, errores });
      }
    } else {
      await this.setState({ guardando: false, errores: { estandar: mensajes } });
    }
  }
  async refrescarConsulta() {
    const {
      secciones,
      subsecciones,
      operaciones,
      tallas,
    } = this.state.filtro;
    await this.setState({
      creandoEstandar: false,
      editandoEstandar: false,
      borrandoEstandar: false,
      guardando: false,
      estandarEditadoId: null,
      estandarBorradoId: null,
      errores: EstandaresOperacionTalla.inicializarEstadoErrores(),
      filtro: {
        secciones,
        subsecciones,
        operaciones,
        tallas,
      },
    });
  }
  async cancelarEdicionEstandar() {
    await this.setState({
      editandoEstandar: false,
      creandoEstandar: false,
      borrandoEstandar: false,
      guardando: false,
      estandarEditadoId: null,
      estandarBorradoId: null,
      errores: EstandaresOperacionTalla.inicializarEstadoErrores(),
    });
  }
  async finalizarCreacionEstandar() {
    this.refrescarConsulta();
  }
  iniciarBorrado(estandarId) {
    this.setState({ borrandoEstandar: true, estandarBorradoId: estandarId });
  }
  async ejecutarConsulta(filtro) {
    await this.setState({ filtro, cargando: true });
  }
  async exportarExcel(filtro) {
    await this.setState({ exportando: true });
    try {
      await obtenerEstandaresOperacionTallaExcel(filtro);
      await this.setState({ exportando: false });
    } catch (e) {
      await this.setState({ exportando: false, errores: { hayError: true } });
    }
  }
  async borradoConfirmado() {
    await borrarEstandarOperacionTalla(this.state.estandarBorradoId);
    await this.refrescarConsulta();
  }
  async resetearEstadoErrores() {
    await this.setState({ errores: EstandaresOperacionTalla.inicializarEstadoErrores() });
  }
  renderizarFormularioEdicion() {
    return (
      <div>
        <Alerta
          texto={
            !this.state.errores.hayError ? 'Hay errores en el formulario. Por favor verifique y corríjalos.' :
              'Ocurrió un error, por favor intente nuevamente'
          }
          toggle={this.resetearEstadoErrores}
          isVisible={
            this.state.errores.hayError ||
            (this.state.editandoEstandar &&
              this.state.errores.estandar &&
              this.state.errores.estandar.length > 0)
          }
        />
        <FormGroup>
          <Label>Estandar</Label>
          <Input
            type="text"
            name="valorEstandar"
            onChange={this.valorEstandarCambiado}
            value={this.state.valorEstandar}
            disabled={this.state.guardando}
            invalid={this.state.errores && this.state.errores.estandar && this.state.errores.estandar.length > 0}
          />
          <FormFeedback>{mostrarValidacion(this.state.errores.estandar)}</FormFeedback>
        </FormGroup>
        <div className="row">
          <div className="col text-center">
            <Button
              onClick={this.actualizarEstandar}
              disabled={this.state.guardando}
            >Guardar
            </Button>
            <Button
              onClick={this.cancelarEdicionEstandar}
              disabled={this.state.guardando}
            >Cancelar
            </Button>
          </div>
        </div>
      </div>
    );
  }
  renderizarFormularioCreacion() {
    if (this.state.creandoEstandar) {
      return (
        <EstandaresOperacionTallaForm
          onSave={this.finalizarCreacionEstandar}
          onCancel={this.cancelarEdicionEstandar}
        />
      );
    }
    return null;
  }
  render() {
    const { page, rowsPerPage } = this.state;
    const emptyRows = rowsPerPage - Math.min(rowsPerPage, this.state.consulta.lista.length - page * rowsPerPage);
    return (
      <React.Fragment>
        <Alerta
          texto="Ocurrió un error de ejecución. Intente nuevamente."
          toggle={this.resetearEstadoErrores}
          isVisible={this.state.errores.hayError}
        />
        <FiltroEstandaresOperacionTalla
          ejecucionConsulta={this.obtenerDatos}
          ejecucionExportacion={this.exportarExcel}
          etiquetaConsultar="Consultar"
          etiquetaExportar="Exportar"
          onChange={() => console.log('Ok')}
          disabled={this.state.cargando || this.state.exportando}
        />
        <TableContainer component={Paper}>
          <Table>
            {/* Table Head */}
            <TableHead>
              <TableRow>
                <StyledTableCell>Secc.</StyledTableCell>
                <StyledTableCell>Subs.</StyledTableCell>
                <StyledTableCell>Op. G.</StyledTableCell>
                <StyledTableCell>Desc. Op.</StyledTableCell>
                <StyledTableCell>Talla</StyledTableCell>
                <StyledTableCell>Grupo talla</StyledTableCell>
                <StyledTableCell>Unidad Med.</StyledTableCell>
                <StyledTableCell>Estándar</StyledTableCell>
                <StyledTableCell>Creación</StyledTableCell>
                <StyledTableCell>Modificación</StyledTableCell>
                <StyledTableCell>Usuario</StyledTableCell>
                {perteneceRolIngenieria() && (
                  <StyledTableCell>
                    Nueva
                    <IconButton
                      size="small"
                      onClick={this.iniciarCreacion}
                    >
                      <AddCircleIcon color="primary" />
                    </IconButton>
                  </StyledTableCell>
                )}
              </TableRow>
            </TableHead>

            {/* Table Body */}
            <TableBody>
              {(rowsPerPage > 0
                ? this.state.consulta.lista.slice(
                  page * rowsPerPage,
                  page * rowsPerPage + rowsPerPage,
                )
                : this.state.consulta.lista
              ).map(row => (
                <TableRow key={row.id}>
                  <TableCell>{row.codigoSeccion}</TableCell>
                  <TableCell>{row.codigoSubseccion}</TableCell>
                  <TableCell>{row.codigoOperacion}</TableCell>
                  <TableCell>{row.descripcionOperacionGeneral}</TableCell>
                  <TableCell>{row.descripcionTalla}</TableCell>
                  <TableCell>{row.descripcionGrupoTalla}</TableCell>
                  <TableCell>{row.unidadMedida}</TableCell>
                  <TableCell>{row.estandar}</TableCell>
                  <TableCell>
                    {new Intl.DateTimeFormat('es-CO').format(new Date(row.fechaCreacion))}
                  </TableCell>
                  <TableCell>
                    {new Intl.DateTimeFormat('es-CO').format(new Date(row.fechaModificacion))}
                  </TableCell>
                  <TableCell>{row.usuario}</TableCell>
                  <TableCell>
                    <IconButton
                      size="small"
                      disabled={!perteneceRolIngenieria()}
                      onClick={() => this.iniciarEdicion({
                        estandarId: row.estandarId,
                        estandar: row.estandar,
                      })}
                    >
                      <EditIcon />
                    </IconButton>
                    <IconButton
                      size="small"
                      disabled={!perteneceRolIngenieria()}
                      onClick={() => this.iniciarBorrado(row.estandarId)}
                    >
                      <DeleteIcon style={{ color: '#e10000' }} />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
              {emptyRows > 0 && (
                <TableRow style={{ height: 33 * emptyRows }}>
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
          </Table>
          <TablePagination
            rowsPerPageOptions={[10, 20, 30, 40, 50]}
            component="div"
            count={this.state.consulta.cantidadRegistros}
            rowsPerPage={this.state.rowsPerPage}
            page={this.state.page}
            onChangePage={this.handleChangePage}
            onChangeRowsPerPage={this.handleChangeRowsPerPage}
            labelRowsPerPage=""
          />
          <ModalConfirmacion
            open={this.state.borrandoEstandar}
            title={<h4 className="text-center font-weight-bold custom-title">Confirmación</h4>}
            text={`¿Está seguro de borrar el estándar con identificador ${this.state.estandarBorradoId}?`}
            onConfirm={this.borradoConfirmado}
            onCancel={this.cancelarEdicionEstandar}
            confirmLabel="Sí"
            cancelLabel="No"
          />
          <ModalForm
            open={this.state.editandoEstandar}
            title={
              <h4 className="text-center font-weight-bold custom-title">
                Actualización de estandar Operación Talla
              </h4>
            }
            size=""
            toggle={this.cancelarEdicionEstandar}
            content={this.renderizarFormularioEdicion()}
          />
          <ModalForm
            open={this.state.creandoEstandar}
            title={<h4 className="text-center font-weight-bold custom-title">Creación de estándar operación talla</h4>}
            // boton={<Button outline className="btn btn-xs py-1 my-1" onClick=
            // {this.cancelarEdicionEstandar}>x</Button>}
            content={this.renderizarFormularioCreacion()}
          />
        </TableContainer>

      </React.Fragment>
    );
  }
}

export default EstandaresOperacionTalla;
