/* eslint-disable max-len */
import Push from 'push.js';
import logo from '../../img/logo/screenIcon.png';

const DETALLES_URL = '/#/alarmas';

/**
 * Dispara la alarma cuando el tipo de
 * evento es provisional.
 * @param {object} receivedEvent - Evento
 */
export const pendienteEstandarEventHandler = (receivedEvent) => {
  Push.create('ESTANDAR PROVISIONAL', {
    body: `Mesa: ${receivedEvent.mesa} \n${receivedEvent.cedulaEmpleado} - ${receivedEvent.nombreEmpleado}`,
    icon: logo,
    onClick: () => {
      window.location.href = `${DETALLES_URL}`;
    },
    link: `${DETALLES_URL}`,
    requireInteraction: true,
  });
};

/**
 * Dispara la alarma cuando la eficiencia de la
 * ubicación, subsección o sección supera el
 * umbral definido.
 * @param {object} receivedEvent - Evento
 */
export const eficienciaAltaEventHandler = (receivedEvent) => {
  Push.create('EFICIENCIA ALTA', {
    body: `Sección: ${receivedEvent.seccion}\nMaquina/Mesa: ${receivedEvent.mesa}\nCédula: ${receivedEvent.cedulaEmpleado}\nNombre: ${receivedEvent.nombreEmpleado}\nEficiencia: ${receivedEvent.eficiencia}`,
    icon: logo,
    onClick: () => {
      window.location.href = `${DETALLES_URL}`;
    },
    link: `${DETALLES_URL}`,
    requireInteraction: true,
  });
};

/**
 * Dispara la alarma cuando el proceso ingresado
 * en el evento es diferente a su homologo en SAP.
 * @param {object} receivedEvent - Evento
 */
export const procesoIngreadoDiferenteSapEventHandler = (receivedEvent) => {
  Push.create('PROCESO INGRESADO DIFERENTE A PROCESO DE SAP', {
    body: `Sección: ${receivedEvent.seccion}\nMaquina: ${receivedEvent.mesa}\nCédula: ${receivedEvent.cedulaEmpleado}\nNombre: ${receivedEvent.nombreEmpleado}\nLote: ${receivedEvent.codigoLote}\nProceso SAP: ######\nProceso Ingresado: ${receivedEvent.codigoOperacionGeneral}`,
    icon: logo,
    onClick: () => {
      window.location.href = `${DETALLES_URL}`;
    },
    link: `${DETALLES_URL}`,
    requireInteraction: true,
  });
};


/**
 * Dispara la alarma cuando la eficiencia de la
 * ubicación, subsección o sección está por debajo
 * del umbral definido.
 * @param {object} receivedEvent - Evento
 */
export const eficienciaBajaEventHandler = (receivedEvent) => {
  Push.create('EFICIENCIA BAJA', {
    body: `Sección: ${receivedEvent.seccion}\nMaquina/Mesa: ${receivedEvent.mesa}\nCédula: ${receivedEvent.cedulaEmpleado}\nNombre: ${receivedEvent.nombreEmpleado}\nEficiencia: ${receivedEvent.eficiencia}`,
    icon: logo,
    onClick: () => {
      window.location.href = `${DETALLES_URL}`;
    },
    link: `${DETALLES_URL}`,
    requireInteraction: true,
  });
};
