import React, { PureComponent, Fragment } from 'react';
import { Collapse } from 'reactstrap';
import { Link } from 'react-router-dom';
import NotificationsIcon from 'mdi-react/NotificationsIcon';
import { ApiUrl, AuthTokenKey } from './../../../appConfig.json';

export default class TopbarNotification extends PureComponent {
  state = {
    collapse: false,
    notifications: [],
    loading: true,
  };

  componentDidMount = () => {
    try {
      this.getNotifications();
    } catch (error) {
      console.error(error);
    }
  };
  getNotifications = () => {
    fetch(`${ApiUrl}/alarmas/`, {
      method: 'GET',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json;charset=utf-8',
        Authorization: localStorage.getItem(AuthTokenKey),
      },
    }).then((res) => {
      if (res.status !== 200) {
        throw new Error(`Error fetching notifications: ${res.status}`);
      }
      return res.json();
    }).then((response) => {
      this.setState({
        notifications: response,
        loading: false,
      });
    });
  }

  toggle = () => {
    this.setState({ collapse: !this.state.collapse });
    this.getNotifications();
  };

  render() {
    const { notifications, loading } = this.state;
    const NoncheckedNotifications = Array.isArray(notifications)
      ? notifications.map(notification => (
        !notification.checked && (
          <Link to={`/Alarmas/${notification.id}`} className="topbar__collapse-item" key={notification.id}>
            <p className="topbar__collapse-message">
              <span className="topbar__collapse-name">
                {notification.nombre} ({notification.seccion.descripcion})
              </span>
              <br />
              {`${notification.operario.nombre} ${notification.operario.apellidos}`}
            </p>
            <p className="topbar__collapse-date">{notification.fechaOrigen}</p>
          </Link>
        )
      ))
      : null;

    return (
      <Fragment>
        <button className="topbar__btn topbar__btn--mail topbar__btn--new" onClick={this.toggle}>
          <NotificationsIcon />
          <div className="topbar__btn-new-label">
            <div />
          </div>
        </button>
        {this.state.collapse && <button className="topbar__back" onClick={this.toggle} />}
        <Collapse
          isOpen={this.state.collapse}
          className="topbar__collapse-content"
        >
          <div className="topbar__collapse-title-wrap">
            <p className="topbar__collapse-title">Alarmas</p>
          </div>
          {
            loading ? 'Cargando ...' : NoncheckedNotifications
          }
          {
            NoncheckedNotifications <= 0 &&
            <p className="text-center margin-bottom-21">
              No hay alarmas pendientes.
            </p>
          }
          <Link className="topbar__collapse-link" to="/Alarmas" onClick={this.toggle}>
            <span className="lnr lnr-list" /> Ver todas las Alarmas
          </Link>
        </Collapse>
      </Fragment>
    );
  }
}
