import React, { useState } from 'react';
import { createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles';
import {
  Container,
  Grid,
  Typography,
} from '@material-ui/core';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import getWeek from 'date-fns/getWeek';
import startOfWeek from 'date-fns/startOfWeek';
import DateFnsUtils from '@date-io/date-fns';
import { es } from 'date-fns/locale';
import PropTypes from 'prop-types';
import Alerta from '../Comunes/Alerta';
import MetasSecciones from './MetasSecciones';

const theme = createMuiTheme({
  palette: {
    primary: { main: '#263238' },
    secondary: { main: '#1a237e' },
  },
});
const fechaRef = startOfWeek(new Date(), { weekStartsOn: 1 /* lunes */ });
// fechaRef.setDate(fechaRef.getDate() - 1);

export default function MetasTextil(props) {
  const [error, setError] = useState(false);
  const [fecha, setFecha] = useState(new Date());
  const [semana, setSemana] = useState(getWeek(fecha, { weekStartsOn: 1, firstWeekContainsDate: 4 }));
  const [ano, setAno] = useState(fecha.getFullYear());
  const resetearError = () => {
    setError(false);
  };
  const fechaCambiada = (v) => {
    const s = getWeek(v, { weekStartsOn: 1, firstWeekContainsDate: 4 });
    const a = v.getFullYear();
    if (a !== ano || s !== semana) {
      setFecha(v);
      setSemana(s);
      setAno(v.getFullYear());
    } else {
      setFecha(v);
    }
    setError(false);
  };
  const alOcurrirError = (mensaje) => {
    setError(true);
  };
  return (
    <React.Fragment>
      <MuiThemeProvider theme={theme}>
        <Container style={{ marginTop: '16px' }}>
          <Grid container xs={12} spacing={3} direction="column" alignItems="center">
            <Grid item>
              <Alerta
                texto="Ocurrió un error de ejecución. Intente nuevamente."
                toggle={resetearError}
                isVisible={error}
              />
            </Grid>
            <Grid item>
              <Typography variant="h5">Metas producción textil</Typography>
            </Grid>
            <Grid>
              <MuiPickersUtilsProvider utils={DateFnsUtils} locale={es}>
                <Grid>
                  <KeyboardDatePicker
                    name="semana"
                    size="small"
                    inputVariant="outlined"
                    minDate={fechaRef}
                    // maxDate={new Date(fechaRef.getFullYear(), 11, 31)}
                    format={`'Semana ${semana} - 'MMMM dd`}
                    label="Semana"
                    value={fecha}
                    onChange={fechaCambiada}
                    cancelLabel="Cancelar"
                    okLabel="Ok"
                  />
                </Grid>
              </MuiPickersUtilsProvider>
            </Grid>
            <Grid item>
              <MetasSecciones
                planta={1170}
                fecha={fecha}
                enError={alOcurrirError}
              />
            </Grid>
          </Grid>
        </Container>
      </MuiThemeProvider>
    </React.Fragment>
  );
}
