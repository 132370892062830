import React from 'react';
import PropTypes from 'prop-types';

import EficienciaLinear from '../../../shared/components/eficiencia/EficienciaLinear';

const EficienciaLinearEmpleados = ({ empleados }) =>
  empleados.map(empleado => (
    <EficienciaLinear
      eficiencia={Math.round(empleado.eficiencia)}
      operario={empleado.nombre}
    />));

EficienciaLinearEmpleados.propTypes = {
  empleados: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default EficienciaLinearEmpleados;
