import config from './../../../appConfig.json';
import
{
  ejecutarConsulta,
  descargarArchivo,
  ejecutarActualizacion,
  obtenerInfoOperario,
} from './../../Comunes/datosComunes';

async function consultarSegundasPaginado(filtro, signal) {
  return ejecutarConsulta(
    `${config.ApiUrl}/segundas/informe-basico`,
    'POST',
    filtro,
    signal,
  );
}
async function exportarInformeBasicoSegundasExcel(filtro, signal) {
  return descargarArchivo(
    `${config.ApiUrl}/segundas/informe-basico-excel`,
    'POST',
    filtro,
    'Segundas.xlsx',
    signal,
  );
}

export {
  consultarSegundasPaginado,
  exportarInformeBasicoSegundasExcel,
};

