import React from 'react';
import PropTypes from 'prop-types';
import { Modal, Button, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

class ModalForm extends React.Component {
  constructor(props) {
    super(props);
    this.confirm = this.confirm.bind(this);
    this.cancel = this.cancel.bind(this);
  }
  confirm(e) {
    if (this.props.onConfirm) {
      this.props.onConfirm(e);
    }
  }
  cancel(e) {
    if (this.props.onCancel) {
      this.props.onCancel(e);
    }
  }
  renderFooter() {
    if (this.props.showButtons) {
      return (
        <ModalFooter>
          <Button className="btn btn-primary" onClick={this.confirm}>Aceptar</Button>{' '}
          <Button color="secondary" onClick={this.cancel}>Cancelar</Button>
        </ModalFooter>
      );
    }
    return null;
  }
  render() {
    return (
      <Modal isOpen={this.props.open} size={this.props.size}>
        <div className="row"><div className="col-11" /><div className="col-1">{this.props.boton}</div></div>
        <ModalHeader>{this.props.title}</ModalHeader>
        <ModalBody>
          {this.props.content}
        </ModalBody>
        {this.renderFooter()}
      </Modal>
    );
  }
}

ModalForm.propTypes = {
  onCancel: PropTypes.func,
  onConfirm: PropTypes.func,
  open: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
  content: PropTypes.element,
  boton: PropTypes.element,
  showButtons: PropTypes.bool,
  size: PropTypes.string,
};
ModalForm.defaultProps = {
  content: null,
  boton: null,
  onCancel: null,
  onConfirm: null,
  showButtons: false,
  size: 'xl',
};

export default ModalForm;
