import React from 'react';
import Operacion from './Components/Operacion';
import CrudOperacion from './Components/CrudOperacion';

function OperacionCompleta(props) {
  return (
    <>
      <Operacion />
      {/* <CrudOperacion
        subsecciones={props.subsecciones}
        SaveOp={props.SaveOp}

      /> */}
      <CrudOperacion {...props} />
    </>
  );
}
export default OperacionCompleta;
