import config from './../../appConfig.json';

import
{
  ejecutarConsulta,
  descargarArchivo,
  ejecutarActualizacion,
  obtenerInfoOperario,
} from './../Comunes/datosComunes';

function ordenarIndicadores(indicadores) {
  const orden =
  {
    S100: 1,
    S104: 2,
    S101: 3,
    S102: 4,
    S105: 5,
    S110: 6,
    S111: 7,
    S103: 8,
    S106: 9,
    S107: 10,
    S108: 11,
    S109: 12,
  };
  indicadores.sort((a, b) => {
    const oa = orden[`S${a.codigoSeccion}`];
    const ob = orden[`S${b.codigoSeccion}`];
    return oa - ob;
  });
}
async function obtenerMetas(plantaId, signal) {
  return ejecutarConsulta(
    `${config.ApiUrl}/indicadores/textil/metas-acumuladas/planta/${plantaId}`,
    'GET',
    null,
    signal,
  );
}
async function obtenerInformeBasico(plantaId, signal) {
  return ejecutarConsulta(
    `${config.ApiUrl}/indicadores/textil/planta/${plantaId}`,
    'GET',
    null,
    signal,
  );
}
async function obtenerIndicadoresOtrosProcesos(plantaId, signal) {
  return ejecutarConsulta(
    `${config.ApiUrl}/indicadores/textil/otros-procesos/planta/${plantaId}`,
    'GET',
    null,
    signal,
  );
}
function textoFechaHoraActualizacion(indicadores) {
  if (indicadores && indicadores.length) {
    const f = indicadores
      .map(x => new Date(Date.parse(x.fechaHoraActualizacion)))
      .reduce((mx, x) => (
        x.getTime() >= mx.getTime() ? x : mx
      ));
    return `${f.getDate().toString().padStart(2, '0')}/` +
    `${(f.getMonth() + 1).toString().padStart(2, '0')}/` +
    `${f.getFullYear().toString()} ${f.getHours().toString().padStart(2, '0')}:` +
    `${f.getMinutes().toString().padStart(2, '0')}`;
  }
  return '';
}

export
{
  textoFechaHoraActualizacion,
  obtenerMetas,
  obtenerInformeBasico,
  obtenerIndicadoresOtrosProcesos,
  ordenarIndicadores,
};
