import React from 'react';
import { Table, Button } from 'reactstrap';
import PropTypes from 'prop-types';

function table(props) {
  const { data } = props;
  return (
    <div>
      <Table striped>
        <thead>
          <tr>
            <th scope="row">Lote</th>
            <th scope="row">Uds primeras</th>
            <th scope="row">Uds segundas rep</th>
            <th scope="row">%sdas reportadas</th>
            <th scope="row">uds sdas NR</th>
            <th scope="row">%sdas no reportadas</th>
            <th scope="row">Ef. Máquina</th>
            <th scope="row">Ef. Operativa</th>
            <th scope="row">PO</th>
            <th scope="row">MPB</th>
            <th scope="row">DM</th>
            <th scope="row">CR</th>
            <th scope="row">CCR</th>
            <th scope="row">MPT</th>
            <th scope="row">OTROS</th>
          </tr>
        </thead>
        <tbody>
          {data.map(lote => (
            <tr>
              <td>{lote.lote}</td>
              <td>{lote.primeras}</td>
              <td>{lote.segundas}</td>
              <td>{lote.porcentajeSegundasReportadas}</td>
              <td>{lote.segundasNoRegistradas}</td>
              <td>{0}%</td>
              <td>{lote.porcentajeEficienciaLote}%</td>
              <td>{lote.porcentajeEficienciaOperativa}%</td>
              <td>{lote.porcentajeParoOperativo}%</td>
              <td>{lote.porcentajeParoMateriaPrimaBodega}%</td>
              <td>{lote.porcentajeParoMecanico}%</td>
              <td>{lote.porcentajeParoCambioReferencia }%</td>
              <td>{lote.porcentajeParoColaCambioReferencia}%</td>
              <td>{lote.porcentajeParoMateriaPrimaTej}%</td>
              <td>{Math.floor((lote.porcentajeParoElectronico +
                  lote.porcentajeParoFaltaRepuesto + lote.porcentajeParoServicios +
                  lote.porcentajeParoCambioPinta + lote.porcentajeParoLimpieza + lote.porcentajeParoOff
                  ))}%
              </td>
            </tr>
            ))}
        </tbody>
      </Table>
    </div>
  );
}

table.propTypes = {
  data: PropTypes.objectOf(PropTypes.object).isRequired,
};
export default table;
