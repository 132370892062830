import React, { Fragment, useState } from 'react';
import PropTypes from 'prop-types';
import { Row, Col, Button } from 'reactstrap';

import { ApiUrl, AuthTokenKey } from './../../../appConfig.json';

import Eficiencia from '../../../shared/components/eficiencia/Eficiencia';

const DetalleAlarma = ({
  id,
  operario,
  checked,
  observacion,
  eficiencia,
  ubicacion,
  history,
}) => {
  const [loading, setLoading] = useState(false);
  const [observation, setObservation] = useState(observacion);

  const setAlarmaCheck = async () => {
    setLoading(!loading);

    const request = await fetch(`${ApiUrl}/alarmas/SetAlarmaChecked`, {
      method: 'POST',
      mode: 'cors',
      body: JSON.stringify({
        id,
        observacion: observation,
      }),
      headers: {
        'Content-Type': 'application/json;charset=utf-8',
        Authorization: localStorage.getItem(AuthTokenKey),
      },
    });

    if (request.ok) {
      history.push('/Alarmas');
    }
  };

  const handleObservationChange = event => setObservation(event.target.value);

  return (
    <Fragment>
      <Row className="text-center">
        <Col md="6" className="padding-top-21">
          <Eficiencia porcentaje={eficiencia} />
        </Col>
        <Col md="6" className="padding-top-21">
          <Row>
            <Col md="12">
              <b>OPERARIO: </b>
              {`${operario.nombre} ${operario.apellidos}`}
            </Col>
          </Row>
          <hr />
          <Row>
            <Col md="6">
              <b>SECCION: </b>
              { ubicacion.subseccion.descripcion }
            </Col>
            <Col md="6">
              <b>UBICACION: </b>
              MESA {ubicacion.descripcion}
            </Col>
          </Row>
          <hr />
          <Row>
            <Col>
              <b>OBSERVACION: </b>
              <textarea
                className="form-control"
                rows="5"
                value={observation}
                onChange={handleObservationChange}
              >
                { observacion }
              </textarea>
            </Col>
          </Row>
        </Col>
      </Row>
      <Row className="text-center padding-top-21">
        <Col>
          {
            !checked &&
            <Button disabled={loading} onClick={setAlarmaCheck} outline color="primary" className="btn-rounded">
              <span className="lnr lnr-checkmark-circle" />  { !loading ? 'Marcar como atendida' : 'Cargando ...' }
            </Button>
          }
        </Col>
      </Row>
    </Fragment>
  );
};

DetalleAlarma.propTypes = {
  id: PropTypes.objectOf().isRequired,
  operario: PropTypes.objectOf().isRequired,
  checked: PropTypes.bool.isRequired,
  observacion: PropTypes.string.isRequired,
  eficiencia: PropTypes.number.isRequired,
  ubicacion: PropTypes.objectOf().isRequired,
  history: PropTypes.objectOf().isRequired,
};

export default DetalleAlarma;
