import React from 'react';
import {
  Button,
  Dialog,
  DialogTitle,
  IconButton,
  Tooltip,
  DialogActions,
  DialogContent,
} from '@material-ui/core';
import { RemoveCircleIcon } from 'mdi-react';

import Context from './store';
import config from '../../appConfig.json';
import Alert from '../../shared/components/alert/Alert';


function EliminarProceso(props) {
  const {
    method,
    tipoP,
    msgAlerta,
  } = props;
  const [open, setOpen] = React.useState(false);
  const [alert, setAlert] = React.useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setAlert(false);
  };
  return (
    <>
      <Context.Consumer>
        {
          (value) => {
              const handleDelete = async () => {
               const request = await fetch(
                 `${config.ApiUrl}/${method}/eliminar/${value.proceso.id}`,
                 {
                  mode: 'cors',
                  method: 'DELETE',
                  headers: {
                    'Content-Type': 'application/json;charset=utf-8',
                    Authorization: localStorage.getItem(config.AuthTokenKey),
                  },
                },
                );
               if (request.status === 400) {
                  setAlert(true);
                } else {
                  window.location.reload();
                  handleClose();
                }
              };
              return (
                <>
                  <Tooltip title={`Elimina ${tipoP}`} >
                    <IconButton
                      size="small"
                      aria-label={`Elimina ${tipoP}`}
                      onClick={handleOpen}
                    >
                      <RemoveCircleIcon style={{ color: '#717575' }} />
                    </IconButton>
                  </Tooltip>
                  <Dialog
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="form-dialog-title"
                  >
                    <DialogTitle align="center" id="alert-dialog-title">
                      {`¿Está seguro de eliminar ${tipoP}?`}
                    </DialogTitle>
                    <DialogContent>
                      <Alert
                        color="danger"
                        className="alert--bordered"
                        icon
                        visible={alert}
                      >
                        {msgAlerta}
                      </Alert>
                    </DialogContent>
                    <DialogActions align="center" >
                      <Button
                        onClick={handleDelete}
                        color="primary"
                      >
                          SI
                      </Button>
                      <Button onClick={handleClose} color="primary">
                         NO
                      </Button>
                    </DialogActions>
                  </Dialog>
              </>
              );
}
          }
      </Context.Consumer>
    </>
  );
}

export default EliminarProceso;
