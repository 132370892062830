import config from './../../appConfig.json';
import { ejecutarConsulta, ejecutarActualizacion, descargarArchivo } from './../Comunes/datosComunes';

async function obtenerEstandaresOperacionTallaPaginado(filtro, signal) {
  return ejecutarConsulta(
    `${config.ApiUrl}/estandares/operacion-talla`,
    'POST',
    filtro,
    signal,
  );
}
async function obtenerEstandaresOperacionTallaExcel(filtro, signal) {
  await descargarArchivo(
    `${config.ApiUrl}/estandares/operacion-talla-excel`,
    'POST',
    filtro,
    'Estandares.xlsx',
    signal,
  );
}
async function crearEstandarOperacionTalla(estandar, signal) {
  return ejecutarActualizacion(
    `${config.ApiUrl}/estandares/operacion-talla/nuevo`,
    'POST',
    estandar,
    signal,
  );
}
async function actualizarEstandarOperacionTalla(estandar, signal) {
  return ejecutarActualizacion(
    `${config.ApiUrl}/estandares/operacion-talla/actualizar`,
    'PUT',
    estandar,
    signal,
  );
}
async function borrarEstandarOperacionTalla(estandarId, signal) {
  return ejecutarActualizacion(
    `${config.ApiUrl}/estandares/operacion-talla/${estandarId}/suprimir`,
    'DELETE',
    {},
    signal,
  );
}
async function obtenerEstandaresOperacionPaginado(filtro, signal) {
  return ejecutarConsulta(
    `${config.ApiUrl}/estandares/operacion`,
    'POST',
    filtro,
    signal,
  );
}
async function obtenerEstandaresOperacionExcel(filtro, signal) {
  await descargarArchivo(
    `${config.ApiUrl}/estandares/operacion-excel`,
    'POST',
    filtro,
    'Estandares.xlsx',
    signal,
  );
}
async function crearEstandarOperacion(estandar, signal) {
  return ejecutarActualizacion(
    `${config.ApiUrl}/estandares/operacion/nuevo`,
    'POST',
    estandar,
    signal,
  );
}
async function actualizarEstandarOperacion(estandar, signal) {
  return ejecutarActualizacion(
    `${config.ApiUrl}/estandares/operacion/actualizar`,
    'PUT',
    estandar,
    signal,
  );
}
async function borrarEstandarOperacion(estandarId, signal) {
  return ejecutarActualizacion(
    `${config.ApiUrl}/estandares/operacion/${estandarId}/suprimir`,
    'DELETE',
    {},
    signal,
  );
}
async function obtenerEstandaresUbicacionPaginado(filtro, signal) {
  return ejecutarConsulta(
    `${config.ApiUrl}/estandares/ubicacion`,
    'POST',
    filtro,
    signal,
  );
}
async function obtenerEstandaresUbicacionExcel(filtro, signal) {
  await descargarArchivo(
    `${config.ApiUrl}/estandares/ubicacion-excel`,
    'POST',
    filtro,
    'Estandares.xlsx',
    signal,
  );
}
async function actualizarEstandarUbicacion(estandar, signal) {
  return ejecutarActualizacion(
    `${config.ApiUrl}/estandares/ubicacion/actualizar`,
    'PUT',
    estandar,
    signal,
  );
}
async function borrarEstandarUbicacion(estandarId, signal) {
  return ejecutarActualizacion(
    `${config.ApiUrl}/estandares/ubicacion/${estandarId}/suprimir`,
    'DELETE',
    {},
    signal,
  );
}
async function crearEstandarUbicacion(estandar, signal) {
  return ejecutarActualizacion(
    `${config.ApiUrl}/estandares/ubicacion/nuevo`,
    'POST',
    estandar,
    signal,
  );
}
export
{
  obtenerEstandaresOperacionTallaPaginado,
  obtenerEstandaresOperacionTallaExcel,
  crearEstandarOperacionTalla,
  actualizarEstandarOperacionTalla,
  borrarEstandarOperacionTalla,
  obtenerEstandaresOperacionPaginado,
  obtenerEstandaresOperacionExcel,
  crearEstandarOperacion,
  actualizarEstandarOperacion,
  borrarEstandarOperacion,
  obtenerEstandaresUbicacionPaginado,
  obtenerEstandaresUbicacionExcel,
  actualizarEstandarUbicacion,
  borrarEstandarUbicacion,
  crearEstandarUbicacion,
};
