import React from 'react';
import { makeStyles, Grid, Divider, Typography, Paper, Checkbox } from '@material-ui/core';
import Context from '../../../Operaciones/store';

const useStyles = makeStyles(() => ({
  root: {
    margin: '3%',
    padding: '4%',
    width: '95%',
  },
}));

function Form() {
  const classes = useStyles();

  return (
    <>
      <Context.Consumer>
        {
          value => (
            <Paper elevation={3} className={classes.root}>
              <Grid container>
                <Grid item xs={10} style={{ marginTop: '6%' }} >
                  <Typography variant="h6" style={{ fontSize: '120%' }}>
                    {value.operacion === null ? 'SECCIÓN' : value.operacion.descripcion}
                  </Typography>
                </Grid>
                <Grid item xs={2} style={{ marginTop: '6%' }}>
                  <Typography variant="h6" style={{ fontSize: '120%' }} >
                    {value.operacion === null ? '000' : value.operacion.codigo}
                  </Typography>
                </Grid>
                <Paper elevation={0} className={classes.root}>
                  <Grid container>
                    <Grid item xs={2}>
                      <Typography variant="button">
                        PLANTA:
                      </Typography>
                    </Grid>
                    <Grid item xs={10} style={{ textAlign: 'end' }} >
                      <Typography variant="button">
                        {value.operacion === null ? 'PLANTA' : value.operacion.planta}
                      </Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <Divider variant="middle" style={{ marginBlockEnd: '10%' }} />
                    </Grid>
                    <Grid item xs={6}>
                      <Typography variant="button">
                        U NEGOCIO:
                      </Typography>
                    </Grid>
                    <Grid item xs={6} style={{ textAlign: 'end' }} >
                      <Typography variant="button">
                        {value.operacion === null ? 'U NEGOCIO' : value.operacion.unidadNegocio}
                      </Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <Divider variant="middle" style={{ marginBlockEnd: '10%' }} />
                    </Grid>
                    <Grid item xs={6}>
                      <Typography variant="button">
                        INCENTIVOS:
                      </Typography>
                    </Grid>
                    <Grid item xs={6} style={{ textAlign: 'end' }} >
                      <Checkbox
                        style={{ width: '10px', height: '10px' }}
                        color="primary"
                        checked={value.operacion === null ? false : value.operacion.pagaIncentivosDiscriminados}
                        name="checkedA"
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Divider variant="middle" style={{ marginBlockEnd: '10%' }} />
                    </Grid>
                    <Grid item xs={6}>
                      <Typography variant="button">
                        F CREACIÓN:
                      </Typography>
                    </Grid>
                    <Grid item xs={6} style={{ textAlign: 'end' }} >
                      <Typography variant="button" >
                        {value.operacion === null ? 'F CREACIÓN' : value.operacion.fechaCreacion.slice(0, 10)}
                      </Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <Divider variant="middle" style={{ marginBlockEnd: '10%' }} />
                    </Grid>
                    <Grid item xs={6}>
                      <Typography variant="button">
                        F MODIFICACIÓN:
                      </Typography>
                    </Grid>
                    <Grid item xs={6} style={{ textAlign: 'end' }} >
                      <Typography variant="button" >
                        {value.operacion === null ? 'F MODIFICACIÓN' : value.operacion.fechaModificacion.slice(0, 10)}
                      </Typography>
                    </Grid>
                  </Grid>
                </Paper>
              </Grid>
            </Paper>
              )
        }
      </Context.Consumer>
    </>
  );
}

export default Form;
