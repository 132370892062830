/* eslint-disable import/prefer-default-export */
import React, { Component } from 'react';
import {
  Grid,
  TableHead,
  TableRow,
  TableCell,
  TextField,
  TableBody,
} from '@material-ui/core';

import { Paper, Table } from './../styles';
import { FilteredProcess } from './FilteredProcess';
import { ApiUrl, AuthTokenKey } from './../../../appConfig.json';
import Context from '../Context';

export class Procesos extends Component {
  constructor(props) {
    super(props);
    this.state = {
      filterText: '',
      operaciones: [{}],
      operacionesFiltro: [{}],
      loading: true,
    };
    this.handleFilterText.bind(this);
  }

  componentDidMount = async () => {
    const request = await fetch(
      `${ApiUrl}/Operaciones/Simulador`,
      {
        headers: {
          'Content-Type': 'application/json;charset=utf-8',
          Authorization: localStorage.getItem(AuthTokenKey),
        },
      },
    );
    const response = await request.json();

    this.setState({
      operaciones: response,
      operacionesFiltro: response,
      loading: false,
    });
  }

  handleFilterText = (e) => {
    const operacionesFiltro = this.state.operaciones.filter(operacion =>
      (operacion.nombre.toLowerCase().indexOf(e.target.value.toLowerCase()) !== -1) && operacion);

    this.setState({
      operacionesFiltro,
      filterText: e.target.value,
    });
  }

  checkUndefinedEstandar = grupoTalla => (grupoTalla !== undefined ? grupoTalla.estandar : 0);

  render() {
    const {
      operacionesFiltro, filterText, loading,
    } = this.state;
    return (
      <Grid item xs={12} sm={4} md={4}>
        <Paper>
          <Table>
            {
              loading ?
                <TableRow>
                  <TableCell style={{ textAlign: 'center' }}>
                    <svg className="load__icon">
                      <path fill="#4ce1b6" d="M12,4V2A10,10 0 0,0 2,12H4A8,8 0 0,1 12,4Z" />
                    </svg>
                  </TableCell>
                </TableRow>
              :
              <>
                <TableHead>
                  <TableRow>
                    <TableCell>
                      <TextField
                        label="Operación"
                        fullWidth
                        value={filterText}
                        onChange={this.handleFilterText}
                        helperText="Buscar Operaciones"
                      />
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <Context.Consumer>
                    {
                      ({
                        procesos, activateProcesos, activateEstBebes, activateEstNinios, activateEstAdultos,
                        estandarBebes, estandarNinios, estandarAdultos, procesosAgregados, activateProcesosAgregados,
                      }) => {
                        const handleProcessSelect = (op) => {
                          const ope = [...procesos];
                          ope.push(op);

                          const operacionesAgregadas = [...procesosAgregados];
                          operacionesAgregadas.push(op);


                          let bebes = estandarBebes;
                          let ninios = estandarNinios;
                          let adultos = estandarAdultos;

                          bebes += this.checkUndefinedEstandar(op.estandares[0]);
                          ninios += this.checkUndefinedEstandar(op.estandares[1]);
                          adultos += this.checkUndefinedEstandar(op.estandares[2]);

                          activateProcesos(ope);
                          activateProcesosAgregados(operacionesAgregadas);

                          activateEstBebes(bebes);
                          activateEstNinios(ninios);
                          activateEstAdultos(adultos);
                        };
                        return (
                          <FilteredProcess operaciones={operacionesFiltro} handleProcessSelect={handleProcessSelect} />
                        );
                      }
                    }
                  </Context.Consumer>
                </TableBody>
              </>
            }
          </Table>
        </Paper>
      </Grid>
    );
  }
}
