import { event } from 'jquery';
import config from './../../appConfig.json';
import { ejecutarConsulta, ejecutarActualizacion, descargarArchivo } from './../Comunes/datosComunes';

async function obtenerEficienciaEventosPaginado(filtro, signal) {
  return ejecutarConsulta(
    `${config.ApiUrl}/eventos/eficiencia`,
    'POST',
    filtro,
    signal,
  );
}
async function obtenerEficienciaEvento(eventoId, signal) {
  return ejecutarConsulta(
    `${config.ApiUrl}/eventos/eficiencia/${eventoId}`,
    'GET',
    null,
    signal,
  );
}
async function obtenerEficienciaEventosExcel(filtro, signal) {
  await descargarArchivo(`${config.ApiUrl}/eventos/eficiencia-excel`, 'POST', filtro, 'Eventos.xlsx', signal);
}
async function registrarCorreccion(evento, signal) {
  return ejecutarActualizacion(
    `${config.ApiUrl}/eventos/correccion`,
    'PUT',
    evento,
    signal,
  );
}
async function registrarCorreccionComoNuevoEvento(evento, signal) {
  return ejecutarActualizacion(
    `${config.ApiUrl}/eventos/correccion-nuevo`,
    'POST',
    evento,
    signal,
  );
}
async function eliminarEficienciaEvento(eventoId, signal) {
  return ejecutarActualizacion(
    `${config.ApiUrl}/eventos/eficiencia/${eventoId}/suprimir`,
    'DELETE',
    null,
    signal,
  );
}
async function obtenerInfoLoteEficiencia(lote, signal) {
  return ejecutarConsulta(
    `${config.ApiUrl}/eventos/lote/${lote}`,
    'GET',
    null,
    signal,
  );
}
export {
  obtenerEficienciaEventosPaginado,
  obtenerEficienciaEvento,
  obtenerEficienciaEventosExcel,
  registrarCorreccion,
  registrarCorreccionComoNuevoEvento,
  eliminarEficienciaEvento,
  obtenerInfoLoteEficiencia,
};

