import React, { useState, useEffect } from 'react';
import { createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles';
import { Grid, Paper, Divider } from '@material-ui/core';

import Form from './Components/Form';

import Loader from '../../../shared/components/loader/Loader';
import config from '../../../appConfig.json';

import Context from '../store';
import Filtro from '../Filtro';
import Lista from '../Lista';

const theme = createMuiTheme({
  palette: {
    primary: { main: '#263238' },
    secondary: { main: '#1a237e' },
  },
});

const Turnos = () => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [turnos, setTurnos] = useState([]);
  const [turnosOrigen, setTurnosOrigen] = useState([]);
  const [unidadesNegocio, setUnidadesNegocio] = useState([]);

  function fetchData() {
    try {
      window.fetch(
        `${config.ApiUrl}/turnos`,
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: localStorage.getItem(config.AuthTokenKey),
            Accept: 'application/json',
          },
        },
      )
        .then(res => res.json())
        .then((response) => {
          setTurnos(response);
          setTurnosOrigen(response);
          setLoading(false);
        });

      window.fetch(
        `${config.ApiUrl}/unidadesNegocio/`,
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: localStorage.getItem(config.AuthTokenKey),
          },
        },
      )
        .then(res => res.json())
        .then((response) => {
          setUnidadesNegocio(response);
        });
    } catch (e) {
      setError(false);
      console.error('Error al obtener datos:', e);
    }
  }

  useEffect(fetchData, []);

  const handleChange = (e) => {
    const result = turnos.filter(p =>
      (p.descripcionTurno.toLowerCase().indexOf(e.target.value.toLowerCase()) !== -1 && p));
    if (result.length !== 0 && e.target.value !== '') { setTurnos(result); }
    if (result.length === 0 && e.target.value === '') { setTurnos(turnosOrigen); }
    if (result.length !== 0 && e.target.value === '') { setTurnos(turnosOrigen); }
    if (result.length === 0 && e.target.value !== '') { setTurnos(turnosOrigen); }
  };


  if (loading) return (<div> <Loader loading={loading} /></div>);
  return (
    <MuiThemeProvider theme={theme}>
      <Context.Provider>
        <Grid container>
          <Grid item xs={12} sm={4} md={4}>
            <Paper style={{ margin: '2%' }}>
              <Filtro onChange={handleChange} label="Turno" helperText="Buscar turnos" margen="5%" />
              <Divider />
              <Lista
                procesos={turnos}
                tipo="turno"
                method="turnos"
                tipoP="este turno"
                msgAlerta="ESTE TURNO TIENE DETALLES ASOCIADOS."
              />
            </Paper>
          </Grid>
          <Grid item xs={4} sm={8} md={8}>
            <Form unidades={unidadesNegocio} fetch={fetchData} />
          </Grid>
        </Grid>
      </Context.Provider>
    </MuiThemeProvider>
  );
};

export default Turnos;
