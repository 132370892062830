import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles, TextField, InputAdornment } from '@material-ui/core';
import { MagnifyIcon } from 'mdi-react';

export default function Filtro(props) {
  const useStyles = makeStyles(() => ({
    root: {
      '& > *': {
        margin: `${props.margen}`,
        width: '90%',
      },
    },
  }));

  const classes = useStyles();
  return (
    <div className={classes.root}>
      <TextField
        id="standard-basic"
        label={props.label}
        type="text"
        helperText={props.helperText}
        onChange={props.onChange}
        InputProps={{
        startAdornment: <InputAdornment position="start"><MagnifyIcon style={{ color: 'gray' }} /></InputAdornment>,
        }}
      />
    </div>
  );
}
Filtro.propTypes = {
  onChange: PropTypes.func.isRequired,
  label: PropTypes.string.isRequired,
  helperText: PropTypes.string.isRequired,
  margen: PropTypes.string.isRequired,
};
