import React from 'react';
import PropTypes from 'prop-types';
import { Col, Badge } from 'reactstrap';

const IndicadorBadge = props => (
  <Col md={6} sm={6} xs={6} outline className="d-flex flex-column align-items-center">
    <h3>
      <Badge color="">
        { props.cantidad } <small>({ props.unidadMedida })</small>
      </Badge>
    </h3>
    <small>
      <b>{ props.indicador }</b>
    </small>
  </Col>
);

IndicadorBadge.defaultProps = {
  unidadMedida: '',
};

IndicadorBadge.propTypes = {
  cantidad: PropTypes.number.isRequired,
  indicador: PropTypes.string.isRequired,
  unidadMedida: PropTypes.string,
};

export default IndicadorBadge;
