import React from 'react';
import { Row } from 'reactstrap';
import PropTypes from 'prop-types';
import IndicadorBadge from '../IndicadorBadge/IndicadorBadge';

const IndicadoresNumericosEstandar = ({ tiempoParo, real }) => (
  <Row>
    <IndicadorBadge
      indicador="T.PARO"
      unidadMedida="MIN"
      cantidad={Math.round(tiempoParo)}
    />
    <IndicadorBadge
      indicador="REAL"
      unidadMedida="DZ"
      cantidad={Math.round(real)}
    />
    {/* <IndicadorBadge
      indicador="ESPER"
      unidadMedida="DZ"
      cantidad={Math.round(esperado)}
    /> */}
  </Row>
);

IndicadoresNumericosEstandar.defaultProps = {
  tiempoParo: 0,
  real: 0,
};

IndicadoresNumericosEstandar.propTypes = {
  tiempoParo: PropTypes.number,
  real: PropTypes.number,
};

export default IndicadoresNumericosEstandar;
