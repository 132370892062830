import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { SubscribeToHub } from '../../redux/actions/NotificationsActions';
import { ThemeProps } from '../../shared/prop-types/ReducerProps';

class MainWrapper extends PureComponent {
  static propTypes = {
    children: PropTypes.element.isRequired,
    dispatch: PropTypes.func.isRequired,
    theme: ThemeProps.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {};
  }

  componentWillMount = () => {
    this.props.dispatch(SubscribeToHub());
  }

  render() {
    const { theme } = this.props;

    return (
      <div className={theme.className}>
        <div className="wrapper">
          {this.props.children}
        </div>
      </div>
    );
  }
}

export default connect(state => ({
  theme: state.theme,
  connection: state.notification,
}))(MainWrapper);
