import React from 'react';
import { Input, FormGroup, Label, FormFeedback } from 'reactstrap';
import PropTypes from 'prop-types';
import {
  FormControl,
  Select,
  InputLabel,
  NativeSelect,
} from '@material-ui/core';

class SelectorCascada extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      primarios: [],
      filtrados: [],
      loadingPrimario: true,
      loadingFiltrado: false,
    };
    this.handleChangePrimario = this.handleChangePrimario.bind(this);
    this.handleFilteredChange = this.handleFilteredChange.bind(this);
  }
  componentDidMount() {
    this.montado = true;
    this.obtenerPrimarios();
    this.obtenerDatosFiltrados(this.props.primaryValue);
  }
  componentWillUnmount() {
    this.montado = false;
    this.abortController.abort();
  }
  montado = false;
  abortController = new AbortController();
  async obtenerPrimarios() {
    await this.setState({ loadingPrimario: true });
    const data = await this.props.getParents(this.abortController.signal);
    if (this.montado) {
      await this.setState({ primarios: data, loadingPrimario: false });
    }
  }
  async handleChangePrimario(e) {
    this.obtenerDatosFiltrados(e.target.value);
    if (this.props.onChange) {
      this.props.onChange(e);
      if (this.props.onFilteredChange) {
        this.props.onFilteredChange({ target: { name: this.props.filteredInputName, value: '' } });
      }
    }
  }
  async handleFilteredChange(e) {
    if (this.props.onFilteredChange) {
      this.props.onFilteredChange(e);
    }
  }
  async obtenerDatosFiltrados(primarioId) {
    await this.setState({ filtrados: [], loadingFiltrado: true });
    const filtrados = await this.props.getChilds(parseInt(primarioId, 10), this.abortController.signal);
    if (this.montado) {
      await this.setState({ filtrados, loadingFiltrado: false });
    }
  }
  crearElementosPrimarios() {
    if (this.state.loadingPrimario) {
      return <option key="xx" value="">{}</option>;
    }
    return (
      <React.Fragment>
        <option key="xx" value="">{}</option>;
        {this.state.primarios.map(e => <option key={e.id.toString()} value={e.id}>{e.descripcion}</option>)}
      </React.Fragment>
    );
  }
  crearElementosFiltrados() {
    if (this.state.loadingFiltrado) {
      return <option key="x" value="">{}</option>;
    }
    return (
      <React.Fragment>
        <option key="x" value="">{}</option>
        {this.state.filtrados.map(e => <option key={e.id.toString()} value={e.id}>{e.descripcion}</option>)}
      </React.Fragment>);
  }
  etiqueta() {
    if (this.props.mostrarEtiqueta) {
      return <Label className="labelSimple">{this.props.etiqueta}</Label>;
    }
    return null;
  }
  render() {
    return (
      <React.Fragment>
        <FormControl

          className={this.props.primaryCol ? `col-${this.props.primaryCol}`
          : undefined}
        >
          {this.etiqueta()}
          <InputLabel>{this.props.primaryLabel}</InputLabel>
          <NativeSelect
            name={this.props.primaryInputName}
            value={this.props.primaryValue}
            onChange={this.handleChangePrimario}
            disabled={this.props.disabled}
            invalid={this.props.primaryInvalid}
          >
            {this.crearElementosPrimarios()}
          </NativeSelect>
          <FormFeedback>{this.props.primaryInvalidMessage}</FormFeedback>
        </FormControl>
        <FormControl
          sx={{ m: 1, minWidth: 120 }}
          className={this.props.filteredCol ? `col-${this.props.filteredCol}`
            : undefined}
        >
          <InputLabel>{this.props.filteredLabel}</InputLabel>
          <NativeSelect
            name={this.props.filteredInputName}
            value={this.props.filteredValue}
            onChange={this.props.onFilteredChange}
            disabled={this.props.disabled || !this.props.primaryValue}
            invalid={this.props.filteredInvalid}
          >
            {this.crearElementosFiltrados()}
          </NativeSelect>
          <FormFeedback>{this.props.filteredInvalidMessage}</FormFeedback>
        </FormControl>
      </React.Fragment>);
  }
}
SelectorCascada.propTypes = {
  getParents: PropTypes.func.isRequired,
  getChilds: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  onFilteredChange: PropTypes.func,
  primaryInputName: PropTypes.string.isRequired,
  filteredInputName: PropTypes.string.isRequired,
  primaryLabel: PropTypes.string.isRequired,
  etiqueta: PropTypes.string,
  filteredLabel: PropTypes.string.isRequired,
  primaryValue: PropTypes.string,
  filteredValue: PropTypes.string,
  primaryCol: PropTypes.number,
  filteredCol: PropTypes.number,
  disabled: PropTypes.bool,
  mostrarEtiqueta: PropTypes.bool,
  primaryInvalid: PropTypes.bool,
  primaryInvalidMessage: PropTypes.string,
  filteredInvalid: PropTypes.bool,
  filteredInvalidMessage: PropTypes.string,
};
SelectorCascada.defaultProps = {
  onFilteredChange: null,
  mostrarEtiqueta: false,
  primaryValue: null,
  etiqueta: null,
  filteredValue: null,
  primaryCol: undefined,
  filteredCol: undefined,
  disabled: false,
  primaryInvalid: false,
  primaryInvalidMessage: null,
  filteredInvalid: false,
  filteredInvalidMessage: null,
};

export default SelectorCascada;
