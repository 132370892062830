import React from 'react';
import { FormGroup, Label, Form, Button, FormFeedback } from 'reactstrap';
import { NativeSelect, TextField, InputLabel } from '@material-ui/core';
import PropTypes from 'prop-types';
import SelectorCascada from '../Comunes/SelectorCascada';
import SelectorSimple from '../Comunes/SelectorSimple';
import { crearEstandarOperacion } from './datosEstandares';
import {
  obtenerOperacionesPorSubseccion,
  obtenerOperacionesDetalladasPorOperacion,
  obtenerSecciones,
  obtenerSubseccionesPorSeccion,
  obtenerGruposTallas,
  mostrarValidacion,
  esDecimalValido,
  convertirDecimal,
} from '../Comunes/datosComunes';
import { CryUsername } from '../../appConfig.json';
import Alerta from '../Comunes/Alerta';

class EstandaresOperacionForm extends React.Component {
  static estadoErroresInicial() {
    return {
      hayErrores: false,
      subseccionId: [],
      operacionDetalladaId: [],
      grupoTallaId: [],
      estandar: [],
    };
  }
  constructor(props) {
    super(props);
    this.state = {
      estandar: 0,
      operacionGeneral: '',
      operacionDetalladaId: '',
      seccion: '',
      subseccionId: '',
      grupoTallaId: '',
      operaciones: [],
      operacionesDetalladas: [],
      cargandoOperaciones: false,
      cargandoOperacionesDetalladas: false,
      guardando: false,
      errores: EstandaresOperacionForm.estadoErroresInicial(),
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleChangeSubseccion = this.handleChangeSubseccion.bind(this);
    this.handleChangeOperacionGeneral = this.handleChangeOperacionGeneral.bind(this);
    this.guardarEstandar = this.guardarEstandar.bind(this);
    this.validarFormulario = this.validarFormulario.bind(this);
    this.cancelar = this.cancelar.bind(this);
    this.mensajeError = this.mensajeError.bind(this);
    this.resetearEstadoErrores = this.resetearEstadoErrores.bind(this);
    this.cargarOperaciones = this.cargarOperaciones.bind(this);
    this.cargarOperacionesDetalladas = this.cargarOperacionesDetalladas.bind(this);
  }
  componentWillUnmount() {
    this.abortController.abort();
  }
  abortController = new AbortController();
  handleChange = async (e) => {
    await this.setState({ [e.target.name]: e.target.value });
  }
  async handleChangeSubseccion(e) {
    await this.setState({ subseccionId: e.target.value });
    await this.cargarOperaciones();
    this.handleChangeOperacionGeneral({ target: { name: 'operacionGeneral', value: '' } });
  }
  async cargarOperaciones() {
    if (this.state.subseccionId) {
      await this.setState({ cargandoOperaciones: true, operaciones: [] });
      const operaciones = await obtenerOperacionesPorSubseccion(this.state.subseccionId);
      await this.setState({ cargandoOperaciones: false, operaciones });
    }
  }
  async handleChangeOperacionGeneral(e) {
    await this.setState({ operacionGeneral: e.target.value });
    await this.cargarOperacionesDetalladas();
    this.handleChange({ target: { name: 'operacionDetalladaId', value: '' } });
  }
  async cargarOperacionesDetalladas() {
    if (this.state.operacionGeneral) {
      await this.setState({
        cargandoOperacionesDetalladas: true,
        operacionesDetalladas: [],
      });
      const operacionesDetalladas =
        await obtenerOperacionesDetalladasPorOperacion(this.state.operacionGeneral);
      await this.setState({ cargandoOperacionesDetalladas: false, operacionesDetalladas });
    }
  }
  async validarFormulario() {
    const errores = EstandaresOperacionForm.estadoErroresInicial();
    if (!this.state.subseccionId) {
      errores.hayErrores = true;
      errores.subseccionId = ['Debe especificar la subsección'];
    }
    if (!this.state.operacionDetalladaId) {
      errores.hayErrores = true;
      errores.operacionDetalladaId = ['Debe especificar la operación detallada'];
    }
    if (!this.state.grupoTallaId) {
      errores.hayErrores = true;
      errores.grupoTallaId = ['Debe especificar del grupo de talla'];
    }
    const valorEstandar = convertirDecimal(this.state.estandar);
    if (this.state.estandar == null || this.state.estandar === '') {
      errores.hayErrores = true;
      errores.estandar = ['Debe especificar el valor del estandar'];
    } else if (isNaN(valorEstandar) || !esDecimalValido(this.state.estandar)) {
      errores.hayErrores = true;
      errores.estandar.push('El formato del estandar especificado no es válido');
    } else if (valorEstandar <= 0) {
      errores.hayErrores = true;
      errores.estandar = ['El valor del estandar especificado no es válido. Debe ser mayor que 0'];
    }
    await this.setState({ errores });
    return !errores.hayErrores;
  }
  async guardarEstandar() {
    if (await this.validarFormulario()) {
      await this.setState({ guardando: true });
      const datos = {
        operacionDetalladaId: this.state.operacionDetalladaId,
        estandar: convertirDecimal(this.state.estandar),
        grupoTallaId: this.state.grupoTallaId,
        subseccionId: this.state.subseccionId,
        usuario: localStorage.getItem(CryUsername),
      };
      const resultado = await crearEstandarOperacion(datos);
      if (!resultado.error) {
        await this.setState({ guardando: false, errores: EstandaresOperacionForm.estadoErroresInicial() });
        this.props.onSave();
      } else {
        const errores = { ...resultado.datos };
        errores.hayErrores = true;
        await this.setState({ guardando: false, errores });
      }
    }
  }
  async cancelar() {
    this.props.onCancel();
  }
  async resetearEstadoErrores() {
    const errores = EstandaresOperacionForm.estadoErroresInicial();
    await this.setState({ errores });
  }
  mensajeError() {
    const { errores } = this.state;
    if (errores.hayErrores) {
      if ((errores.estandar && errores.estandar.length > 0) ||
        (errores.subseccionId && errores.subseccionId.length) ||
        (errores.grupoTallaId && errores.grupoTallaId.length) ||
        (errores.operacionDetalladaId && errores.operacionDetalladaId.length)) {
        return 'Hay errores en el formulario. Por favor revise y corríjalos';
      }
      return 'Se presentaron errores. Por favor intente nuevamente';
    }
    return '';
  }
  renderListaOperaciones() {
    if (this.state.cargandoOperaciones) {
      return (
        <option key="-1" value="">Cargando...</option>
      );
    }
    const ops = this.state.operaciones.map(x => x);
    ops.unshift({ id: '', descripcion: 'Operación General' });
    return (
      ops.map(x => (
        <option
          key={x.id}
          value={x.id}
        >
          {x.descripcion}
        </option>
      )));
  }
  renderListaOperacionesDetalladas() {
    if (this.state.cargandoOperacionesDetalladas) {
      return (
        <option key="-1" value="">Cargando...</option>
      );
    }
    const ops = this.state.operacionesDetalladas.map(x => x);
    ops.unshift({ id: '', descripcionOperacionDetallada: 'Operacion Detalla' });
    return (
      ops.map(x => (
        <option
          key={x.id}
          value={x.id}
        >
          {x.descripcionOperacionDetallada}
        </option>
      )));
  }
  render() {
    return (
      <Form>
        <Alerta
          texto={this.mensajeError()}
          toggle={this.resetearEstadoErrores}
          isVisible={this.state.errores.hayErrores}
        />
        <div className="container text-center">
          <div className="row d-flex justify-content-center">
            <div className="col-8">
              <SelectorCascada
                primaryLabel="Sección"
                filteredLabel="Subsección"
                primaryInputName="seccion"
                filteredInputName="subseccionId"
                primaryValue={this.state.seccion}
                filteredValue={this.state.subseccionId}
                onChange={this.handleChange}
                onFilteredChange={this.handleChangeSubseccion}
                getParents={obtenerSecciones}
                getChilds={obtenerSubseccionesPorSeccion}
                primaryCol={4}
                filteredCol={4}
                filteredInvalid={
                  this.state.errores &&
                  this.state.errores.subseccionId &&
                  this.state.errores.subseccionId.length > 0
                }
                filteredInvalidMessage={mostrarValidacion(this.state.errores.subseccionId)}
                disabled={this.state.guardando}
              />
            </div>
            <div className="col-md-6 custom-margin-column">
              <FormGroup>
                <NativeSelect
                  className="selectoOpGeneral"
                  type="select"
                  name="operacionGeneral"
                  value={this.state.operacionGeneral}
                  onChange={this.handleChangeOperacionGeneral}
                  disabled={this.state.guardando || !this.state.subseccionId}
                >
                  {this.renderListaOperaciones()}
                </NativeSelect>
                <FormFeedback>{mostrarValidacion(this.state.errores.operacionDetalladaId)}</FormFeedback>
              </FormGroup>
            </div>
            <div className="col-md-3">
              <FormGroup>
                <NativeSelect
                  className="selectOpDetallada"
                  name="operacionDetalladaId"
                  value={this.state.operacionDetalladaId}
                  onChange={this.handleChange}
                  disabled={this.state.guardando || !this.state.operacionGeneral}
                  invalid={
                    this.state.errores &&
                    this.state.errores.operacionDetalladaId &&
                    this.state.errores.operacionDetalladaId.length > 0
                  }
                >
                  {this.renderListaOperacionesDetalladas()}
                </NativeSelect>
                <FormFeedback>{mostrarValidacion(this.state.errores.operacionDetalladaId)}</FormFeedback>
              </FormGroup>
            </div>
          </div>
          <div className="d-flex justify-content-center">
            <div className="col-3">
              <SelectorSimple
                className="selectGTalla"
                inputName="grupoTallaId"
                value={this.state.grupoTallaId}
                primaryLabel="Grupo Talla"
                onChange={this.handleChange}
                obtenerDatos={obtenerGruposTallas}
                mostrarEtiqueta
                campoId="id"
                campoDesc="descripcion"
                disabled={this.state.saving}
                invalid={
                  this.state.errores &&
                  this.state.errores.grupoTallaId &&
                  this.state.errores.grupoTallaId.length > 0
                }
                invalidMessage={mostrarValidacion(this.state.errores.grupoTallaId)}
              />
            </div>
            <div className="col-3">
              <FormGroup>
                <InputLabel>Estandar:</InputLabel>
                <TextField
                  id="estandar"
                  name="estandar"
                  type="text"
                  placeholder="estandar"
                  onChange={this.handleChange}
                  value={this.state.estandar}
                  disabled={this.state.guardando}
                  invalid={
                    this.state.errores &&
                    this.state.errores.estandar &&
                    this.state.errores.estandar.length > 0
                  }
                />
                <FormFeedback>{mostrarValidacion(this.state.errores.estandar)}</FormFeedback>
                <Label>MIN DZ</Label>
              </FormGroup>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col text-center">
            <Button
              className="btn btn-primary"
              onClick={this.guardarEstandar}
              disabled={this.state.guardando}
            >
              Guardar
            </Button>
            <Button onClick={this.cancelar} disabled={this.state.guardando}>Cancelar</Button>
          </div>
        </div>
      </Form>
    );
  }
}

EstandaresOperacionForm.propTypes = {
  history: PropTypes.shape({ push: PropTypes.func.isRequired }).isRequired,
  match: PropTypes.shape({ params: PropTypes.shape({ id: PropTypes.string.isRequired }) }).isRequired,
  onSave: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
};

export default EstandaresOperacionForm;
