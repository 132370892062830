import config from './../../appConfig.json';
import { ejecutarConsulta, ejecutarActualizacion, descargarArchivo } from './../Comunes/datosComunes';

async function obtenerProgramacionTejidoPasillos(ano, semana, signal) {
  return ejecutarConsulta(
    `${config.ApiUrl}/programacion-turnos/pasillos/${ano}/${semana}`,
    'GET',
    null,
    signal,
  );
}

async function obtenerProgramacionTejidoPasillo(pasillo, ano, semana, signal) {
  return ejecutarConsulta(
    `${config.ApiUrl}/programacion-turnos/pasillo/${pasillo}/${ano}/${semana}`,
    'GET',
    null,
    signal,
  );
}

async function actualizarProgramacionPasillos(datos, signal) {
  return ejecutarActualizacion(
    `${config.ApiUrl}/programacion-turnos/pasillos`,
    'POST',
    datos,
    signal,
  );
}

async function actualizarProgramacionPasillo(pasilloId, datos, signal) {
  return ejecutarActualizacion(
    `${config.ApiUrl}/programacion-turnos/pasillo/${pasilloId}`,
    'POST',
    datos,
    signal,
  );
}
async function actualizarProgramacionSecciones(plantaId, datos, signal) {
  return ejecutarActualizacion(
    `${config.ApiUrl}/programacion-turnos/secciones-textil/${plantaId}`,
    'POST',
    datos,
    signal,
  );
}
async function obtenerProgramacionTejidoSecciones(plantaId, ano, semana, signal) {
  return ejecutarConsulta(
    `${config.ApiUrl}/programacion-turnos/secciones-textil/${plantaId}/${ano}/${semana}`,
    'GET',
    null,
    signal,
  );
}


const diasSemana = [
  'Lunes',
  'Martes',
  'Miércoles',
  'Jueves',
  'Viernes',
  'Sábado',
  'Domingo',
];
const marcadores = {
  1: 'A',
  2: 'B',
};
function replicar(programacion, turnos, fechaBase, origen, destinos) {
  const dias =
    destinos
      .map((v, i) => ({ id: i, replicado: v }))
      .filter(x => x.replicado);
  const seleccionados = programacion.filter(x => x.seleccionado);
  for (let i = 0; i < seleccionados.length; i += 1) {
    const p = seleccionados[i];
    for (let j = 0; j < turnos.length; j += 1) {
      const turno = turnos[j];
      const h = p[`horasD${origen}T${turno.id}`];
      const l = p[`limiteD${origen}T${turno.id}`];
      for (let k = 0; k < dias.length; k += 1) {
        const d = dias[k];
        if (!turnoBloqueado(fechaBase, d.id, turno)) {
          p[`horasD${d.id}T${turno.id}`] = h;
          p[`limiteD${d.id}T${turno.id}`] = l;
        }
      }
    }
  }
  return programacion;
}
function fechaBloqueada(fechaBase, d) {
  if (fechaBase == null) {
    return true;
  }
  const xd = new Date(fechaBase.getTime());
  const hoy = new Date();
  hoy.setHours(0, 0, 0, 0);
  xd.setDate(xd.getDate() + d);
  if (xd.getTime() < hoy.getTime()) {
    return -1;
  } else if (xd.getTime() === hoy.getTime()) {
    return 0;
  }
  return 1;
}
function turnoBloqueado(fechaBase, d, t) {
  const x = fechaBloqueada(fechaBase, d);
  if (x < 0) {
    return true;
  } else if (x > 0) {
    return false;
  }
  const f = new Date();
  const hs = f.getHours().toString().padStart(2, '0');
  const ms = f.getMinutes().toString().padStart(2, '0');
  const ss = f.getSeconds().toString().padStart(2, '0');
  const h = `${hs}:${ms}:${ss}`;
  return t == null || h >= t.horaInicio;
}
async function descargarExcelPasillosTurnos(ano, semana, signal) {
  return descargarArchivo(
    `${config.ApiUrl}/programacion-turnos/pasillos/${ano}/${semana}/excel`,
    'GET',
    null,
    'Pasillos_turnos.xlsx',
    signal,
  );
}
async function descargarExcelPasilloTurnos(pasilloId, ano, semana, signal) {
  return descargarArchivo(
    `${config.ApiUrl}/programacion-turnos/pasillo/${pasilloId}/${ano}/${semana}/excel`,
    'GET',
    null,
    'Pasillos_turnos.xlsx',
    signal,
  );
}
async function descargarExcelSeccionesTurnos(plantaId, ano, semana, signal) {
  return descargarArchivo(
    `${config.ApiUrl}/programacion-turnos/secciones-textil/${plantaId}/${ano}/${semana}/excel`,
    'GET',
    null,
    'Secciones_turnos.xlsx',
    signal,
  );
}
export {
  obtenerProgramacionTejidoPasillos,
  obtenerProgramacionTejidoPasillo,
  actualizarProgramacionPasillos,
  actualizarProgramacionPasillo,
  descargarExcelPasillosTurnos,
  descargarExcelPasilloTurnos,
  obtenerProgramacionTejidoSecciones,
  descargarExcelSeccionesTurnos,
  actualizarProgramacionSecciones,
  replicar,
  fechaBloqueada,
  turnoBloqueado,
  diasSemana,
  marcadores,
};
