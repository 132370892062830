import React from 'react';
import { Label, Button } from 'reactstrap';
import PropTypes from 'prop-types';
import { eliminarEficienciaEvento } from './datosCorreccion';

class BorradoEvento extends React.Component {
  constructor(props) {
    super(props);
    this.borrarEvento = this.borrarEvento.bind(this);
    this.cerrar = this.cerrar.bind(this);
  }
  async borrarEvento() {
    eliminarEficienciaEvento(this.props.eventoId);
    if (this.props.onDelete) {
      this.props.onDelete();
    }
  }
  cerrar() {
    if (this.props.onClose) {
      this.props.onClose();
    }
  }
  render() {
    return (
      <React.Fragment>
        <div className="row">
          <Label className="col-12 px-1 mx-1 pb-3 mb-3">¿Está seguro de que desea borrar el evento?</Label>
        </div>
        <div className="row">
          <div className="col-12 text-center">
            <Button onClick={this.borrarEvento}>Borrar</Button>
            <Button onClick={this.cerrar}>Cerrar</Button>
          </div>
        </div>
      </React.Fragment>
    );
  }
}
BorradoEvento.propTypes = {
  eventoId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  onDelete: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
};
export default BorradoEvento;
