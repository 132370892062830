import React from 'react';
import { Grid,
  Button,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Paper,
  CircularProgress } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import PropTypes from 'prop-types';
import { obtenerIndicadoresOtrosProcesos, textoFechaHoraActualizacion, ordenarIndicadores } from './datos';
import { separarGruposMiles } from '../Comunes/datosComunes';

class OtrosProcesos extends React.Component {
  constructor(props) {
    super(props);
    this.cargarIndicadores = this.cargarIndicadores.bind(this);
    this.state = {
      cargando: false,
      indicadores: [],
      error: false,
    };
  }
  componentDidMount() {
    this.cargarIndicadores();
  }
  componentWillUnmount() {
    this.abortController.abort();
  }
  abortController = new AbortController();
  async cargarIndicadores() {
    await this.setState({ cargando: true, error: false });
    const respuesta = await obtenerIndicadoresOtrosProcesos(this.props.planta, this.abortController.signal);
    if (!respuesta.error) {
      ordenarIndicadores(respuesta.datos);
      await this.setState({
        indicadores: respuesta.datos,
        cargando: false,
        error: false,
      });
    } else {
      await this.setState({
        cargando: false,
        error: true,
      });
    }
  }
  tabla = (indicadores) => {
    if (this.state.cargando) {
      return (
        <Grid container justify="center"><CircularProgress color="primary" /></Grid>);
    } else if (this.state.error) {
      return (<span>Ocurrió un error</span>);
    }
    return (
      <Paper elevation={3}>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell align="left">Proceso</TableCell>
              <TableCell align="right">Prod. Día</TableCell>
              <TableCell align="right">Prod. Acumulada</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {indicadores.map(i => (
              <TableRow>
                <TableCell align="left">{this.props.formatearSeccion(i)}</TableCell>
                <TableCell align="right">{separarGruposMiles(Math.round(i.valorDiario))}</TableCell>
                <TableCell align="right">{separarGruposMiles(Math.round(i.valorSemanal))}</TableCell>
              </TableRow>))
            }
          </TableBody>
        </Table>
      </Paper>);
  };
  render() {
    return (
      <Grid md={3}>
        <Dialog
          disableBackdropClick
          fullWidth
          onEscapeKeyDown={this.props.finalizar}
          open={this.props.activo}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          maxWidth="sm"
        >
          <DialogTitle id="alert-dialog-title">
            Última actualización {textoFechaHoraActualizacion(this.state.indicadores)}
          </DialogTitle>
          <DialogContent>
            <Grid container item xs={12} direction="column">
              {this.tabla(this.state.indicadores)}
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.props.finalizar} color="primary">
              {this.state.cargando ? 'Cancelar' : 'Aceptar'}
            </Button>
          </DialogActions>
        </Dialog>
      </Grid>);
  }
}

OtrosProcesos.propTypes = {
  planta: PropTypes.number.isRequired,
  activo: PropTypes.bool.isRequired,
  finalizar: PropTypes.func.isRequired,
  formatearSeccion: PropTypes.func.isRequired,
};

export default OtrosProcesos;
