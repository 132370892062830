export const CHANGE_UBICACION_TO_CALCETINES = 'CHANGE_UBICACION_TO_CALCETINES';
export const CHANGE_UBICACION_TO_VESTUARIO = 'CHANGE_UBICACION_TO_VESTUARIO';
export const CHANGE_UBICACION_TO_SEAMLESS = 'CHANGE_UBICACION_TO_SEAMLESS';
export const RESTABLECER_UBICACION = 'RESTABLECER_UBICACION';

export function changeUbicaciontoCalcetines() {
  return {
    type: CHANGE_UBICACION_TO_CALCETINES,
  };
}

export function changeUbicaciontoVestuario() {
  return {
    type: CHANGE_UBICACION_TO_VESTUARIO,
  };
}

export function changeUbicaciontoSeamless() {
  return {
    type: CHANGE_UBICACION_TO_SEAMLESS,
  };
}

export function restablecerUbicacion() {
  return {
    type: RESTABLECER_UBICACION,
  };
}
