import React from 'react';
import ListaIncentivos from './ListaIncentivos';
import ContextoIncentivos from '../ContextoIncentivos';
import MaterialDialog from '../../Comunes/MaterialDialog';

const ResultadoCalculo = () => (
  <ContextoIncentivos.Consumer>
    {
      ({
        cargando,
        confirmacionCalculoRequerida,
        fijarConfirmacionCalculoRequerida,
        ejecutarCalculoIncentivo,
        error,
      }) => {
        const Modal = () => (
          <MaterialDialog
            titulo="Reemplazar las liquidaciones"
            texto="Ya existen liquidaciones para las fechas especificadas. Desea reemplazar las liquidaciones"
            textoAfirmativo="Sí"
            textoNegativo="No"
            afirmativo={confirmarCalculo}
            negativo={ignorar}
          />
        );
        const ignorar = async () => {
          await fijarConfirmacionCalculoRequerida(false);
        };
        const confirmarCalculo = async () => {
          await fijarConfirmacionCalculoRequerida(false);
          await ejecutarCalculoIncentivo(true);
        };
        if (error.hayError) {
          return (<div>{error.mensaje}</div>);
        } else if (confirmacionCalculoRequerida) {
          return Modal();
        }
        return (<ListaIncentivos />);
      }
    }
  </ContextoIncentivos.Consumer>
);

export default ResultadoCalculo;
