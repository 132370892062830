import React from 'react';
import PropTypes from 'prop-types';
import { Row, Col, Container } from 'reactstrap';
import IndicadorEficienciaPlantas from './IndicadorEficienciaPlantas';
import { plantasIndicadores } from '../../../appConfig.json';

const Plantas = (props) => {
  const getUrl = (id) => {
    if (plantasIndicadores.some(x => x === id)) {
      return `/indicadores/textil/planta/${id}`;
    }
    return `/detalles/planta/${id}/unidad/${props.unidadId}`;
  };
  return (
    <Container className="dashboard">
      <Row>
        <Col md={12} className="text-center">
          <h3 className="page-title">Plantas { props.unidad.toLowerCase() }</h3>
        </Col>
      </Row>
      <Row className="justify-content-center">
        {
          props.plantas.map(planta => (<IndicadorEficienciaPlantas
            key={planta.id}
            // base={`/detalles/planta/${planta.id}/unidad/${props.unidadId}`}
            base={getUrl(planta.id)}
            planta={planta.descripcion}
            eficiencia={0}
            unidadNegocioId={props.unidadId}
          />))
        }
      </Row>
    </Container>);
};

Plantas.propTypes = {
  plantas: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  unidad: PropTypes.string.isRequired,
  unidadId: PropTypes.number.isRequired,
};

export default Plantas;
