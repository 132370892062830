import React from 'react';
import {
  Grid,
  Container,
  Typography,
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Paper,
  TablePagination,
  withStyles,
} from '@material-ui/core';
import 'react-table/react-table.css';
import FiltroReporteBasicoSegundas from './FiltroReporteBasicoSegundas';
import { consultarSegundasPaginado, exportarInformeBasicoSegundasExcel } from './datosInformBasicoSegundas';
import Alerta from '../../Comunes/Alerta';

const StyledTableCell = withStyles(theme => ({
  head: {
    backgroundColor: '#F5F5F5',
    color: '#263238',
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const fd = new Intl.DateTimeFormat();

class ReporteBasicoSegundas extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      segundas: [],
      cargando: false,
      exportando: false,
      paginas: 0,
      campoOrden: 'fecha',
      ordenAscendente: true,
      filtro: null,
      error: false,
      page: 0,
      rowsPerPage: 10,
    };
    this.filtroConsultaEstablecido = this.filtroConsultaEstablecido.bind(this);
    this.filtroExportacionEstablecido = this.filtroExportacionEstablecido.bind(this);
    this.ejecutarConsulta = this.ejecutarConsulta.bind(this);
    this.obtenerDatos = this.obtenerDatos.bind(this);
    this.resetearError = this.resetearError.bind(this);
  }

  abortController = new AbortController();

  async filtroConsultaEstablecido(filtro) {
    await this.setState({ filtro, segundas: [] });
    await this.ejecutarConsulta({
      ...this.state.filtro,
      pagina: 0,
      campoOrdenacion: this.state.campoOrden,
      ordenAscendente: this.state.ordenAscendente,
      cantidadRegistros: this.state.registrosPagina,
    });
  }

  async filtroExportacionEstablecido(filtro) {
    await this.setState({ filtro, exportando: true, error: false });
    try {
      await exportarInformeBasicoSegundasExcel(filtro, this.abortController.signal);
    } catch (e) {
      await this.setState({ error: true });
    } finally {
      await this.setState({ exportando: false });
    }
  }

  async ejecutarConsulta(f) {
    await this.setState({ cargando: true, error: false });
    const resultado = await consultarSegundasPaginado(f, this.abortController.signal);
    if (!resultado.error) {
      const paginas = Math.ceil(resultado.datos.cantidadRegistros);
      await this.setState({ segundas: resultado.datos.segundas, paginas, cargando: false });
    } else {
      await this.setState({
        segundas: [],
        cargando: false,
        paginas: 0,
        error: true,
      });
    }
  }

  async obtenerDatos(state) {
    if (this.state.filtro != null) {
      let orden = 'fecha';
      if (state && state.sorted && state.sorted.length > 0) {
        [orden] = state.sorted;
      }
      await this.ejecutarConsulta({
        ...this.state.filtro,
        pagina: state.page,
        cantidadRegistros: state.pageSize,
        campoOrdenacion: orden.id,
        ordenAscendente: !orden.desc,
      });
    }
  }

  async resetearError() {
    await this.setState({ error: false });
  }

  render() {
    const { page, rowsPerPage } = this.state;
    const emptyRows = rowsPerPage - Math.min(rowsPerPage, this.state.segundas.length - page * rowsPerPage);
    return (
      <Container style={{ marginTop: '16px' }}>
        <Grid container item direction="column" spacing={1} alignItems="center">
          <Grid item xs={12}>
            <Alerta
              texto="Ocurrió un error de ejecución. Intente nuevamente."
              toggle={this.resetearError}
              isVisible={this.state.error}
            />
          </Grid>
          <Grid item xs={12}>
            <Typography variant="h6">Informe básico de segundas</Typography>
          </Grid>
          <Grid item>
            <FiltroReporteBasicoSegundas
              filtroConsultaEstablecido={this.filtroConsultaEstablecido}
              filtroExportacionEstablecido={this.filtroExportacionEstablecido}
              disabled={this.state.cargando || this.state.exportando}
            />
          </Grid>
          <Grid item xs={12}>
            <TableContainer component={Paper}>
              <Table>
                <TableHead>
                  <TableRow>
                    <StyledTableCell align="left">Fecha</StyledTableCell>
                    <StyledTableCell align="right">Turno</StyledTableCell>
                    <StyledTableCell align="left">Desc. Sección</StyledTableCell>
                    <StyledTableCell align="left">Lote</StyledTableCell>
                    <StyledTableCell align="left">Material TP</StyledTableCell>
                    <StyledTableCell align="right">Talla</StyledTableCell>
                    <StyledTableCell align="right">Und Sda</StyledTableCell>
                    <StyledTableCell align="right">Cod daño</StyledTableCell>
                    <StyledTableCell align="right">CC Operario</StyledTableCell>
                    <StyledTableCell align="right" style={{ width: 300 }}>Operario</StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {(rowsPerPage > 0
                    ? this.state.segundas.slice(
                      page * rowsPerPage,
                      page * rowsPerPage + rowsPerPage,
                    )
                    : this.state.segundas
                  ).map(row => (
                    <TableRow key={row.fecha}>
                      <TableCell align="left">
                        {fd.format(Date.parse(row.fecha, { year: 'numeric', month: '2-digit', day: '2-digit' }))}
                      </TableCell>
                      <TableCell align="right">{row.codigoTurno}</TableCell>
                      <TableCell align="left">{row.descripcionSeccion}</TableCell>
                      <TableCell align="left">{row.codigoLote}</TableCell>
                      <TableCell align="left">{row.codigoMaterial}</TableCell>
                      <TableCell align="right">{row.codigoTalla}</TableCell>
                      <TableCell align="right">{row.cantidad}</TableCell>
                      <TableCell align="right">{row.codigoDano}</TableCell>
                      <TableCell align="right">{row.cedulaOperario}</TableCell>
                      <TableCell align="right" style={{ width: 300 }}>{row.nombreOperario}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
              <TablePagination
                align="right"
                rowsPerPageOptions={[5, 10, 15, 20, 30, 50, 100]}
                component="div"
                count={this.state.paginas}
                rowsPerPage={rowsPerPage}
                page={page}
                onChangePage={(event, newPage) => this.setState({ page: newPage })}
                onChangeRowsPerPage={event => this.setState({ rowsPerPage: parseInt(event.target.value, 10), page: 0 })}
              />
            </TableContainer>
          </Grid>
        </Grid>
      </Container>
    );
  }
}

export default ReporteBasicoSegundas;
