import React, { createContext, useState } from 'react';
import PropTypes from 'prop-types';

const Context = createContext();

const Provider = ({ children }) => {
  const [referencia, setReferencia] = useState('');
  const [categoria, setcategoria] = useState('');
  const [normaEmpaque, setNormaEmpaque] = useState('');
  const [procesos, setProcesos] = useState([]);
  const [estandarBebes, setEstandarBebes] = useState(0);
  const [estandarNinios, setEstandarNinios] = useState(0);
  const [estandarAdultos, setEstandarAdultos] = useState(0);
  const [bebesCheck, setBebesCheck] = useState(true);
  const [niniosCheck, setNiniosCheck] = useState(true);
  const [adultosCheck, setAdultosCheck] = useState(true);
  const [empaqueManualCheck, setEmpaqueManualCheck] = useState(true);
  const [empaqueAutotexCheck, setEmpaqueAutotexCheck] = useState(true);
  const [procesosEliminados, setProcesosEliminados] = useState([]);
  const [procesosAgregados, setProcesosAgregados] = useState([]);

  const [error, setError] = useState({ status: true, mensaje: '' });

  const value = {
    referencia,
    activateReferencia: (reference) => {
      setReferencia(reference);
    },
    categoria,
    activateCategoria: (category) => {
      setcategoria(category);
    },
    normaEmpaque,
    activateNormaEmpaque: (packRule) => {
      setNormaEmpaque(packRule);
    },
    procesos,
    activateProcesos: (processes) => {
      setProcesos(processes);
    },
    estandarBebes,
    activateEstBebes: (estandard) => {
      setEstandarBebes(Number(estandard.toFixed(2)));
    },
    estandarNinios,
    activateEstNinios: (estandard) => {
      setEstandarNinios(Number(estandard.toFixed(2)));
    },
    estandarAdultos,
    activateEstAdultos: (estandard) => {
      setEstandarAdultos(Number(estandard.toFixed(2)));
    },
    bebesCheck,
    activateBebes: (check) => {
      setBebesCheck(check);
    },
    niniosCheck,
    activateNinios: (check) => {
      setNiniosCheck(check);
    },
    adultosCheck,
    activateAdultos: (check) => {
      setAdultosCheck(check);
    },
    empaqueManualCheck,
    activateEmpaqueManual: (check) => {
      setEmpaqueManualCheck(check);
    },
    empaqueAutotexCheck,
    activateEmpaqueAutotex: (check) => {
      setEmpaqueAutotexCheck(check);
    },
    procesosEliminados,
    activateProcesosEliminados: (eliminados) => {
      setProcesosEliminados(eliminados);
    },
    procesosAgregados,
    activateProcesosAgregados: (agregados) => {
      setProcesosAgregados(agregados);
    },
    error,
    activateError: (err) => {
      setError(err);
    },
  };

  return (
    <Context.Provider value={value}>
      { children }
    </Context.Provider>
  );
};

Provider.propTypes = {
  children: PropTypes.element.isRequired,
};

export default {
  Provider,
  Consumer: Context.Consumer,
};
