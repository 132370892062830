import React from 'react';
import { Checkbox, Grid } from '@material-ui/core';
import { CircleOutlineIcon, CheckCircleIcon } from 'mdi-react';
import Context from '../Context';

const SubseccionesCheck = () => (
  <Context.Consumer>
    {
      ({
        empaqueManualCheck, empaqueAutotexCheck,
        activateEmpaqueManual, activateEmpaqueAutotex,
      }) => (
        <Grid
          container
          spacing={1}
          style={{ paddingTop: '24px' }}
        >
          <Grid item container xs={6} justify="center" alignItems="center">
            EMP. MANUAL
            <Checkbox
              icon={<CircleOutlineIcon />}
              checkedIcon={<CheckCircleIcon />}
              color="primary"
              checked={empaqueManualCheck}
              onChange={e => activateEmpaqueManual(e.target.checked)}
            />
          </Grid>
          <Grid container xs={6} justify="center" alignItems="center">
            EMP. AUTOTEX
            <Checkbox
              icon={<CircleOutlineIcon />}
              checkedIcon={<CheckCircleIcon />}
              color="primary"
              checked={empaqueAutotexCheck}
              onChange={e => activateEmpaqueAutotex(e.target.checked)}
            />
          </Grid>
        </Grid>
      )
    }
  </Context.Consumer>
);

export default SubseccionesCheck;
