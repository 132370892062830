import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles, List, ListItem, ListItemText } from '@material-ui/core';

import Context from './store';
import EliminarProceso from './EliminarProceso';
import { perteneceRolIngenieria } from '../Comunes/datosComunes';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.paper,
    position: 'relative',
    overflowY: 'auto',
    height: `${window.screen.height - 280}px`,
    width: '100%',
  },
  color: {
    color: '#717575',
  },
  textoColor: {
    '.MuiTypography-body1': {
      backgroundColor: 'aquamarine',
    },
  },
}));

export default function Lista(props) {
  const classes = useStyles();
  const {
    procesos,
    tipo,
    method,
    tipoP,
    msgAlerta,
  } = props;

  const item = (p) => {
    if (tipo === 'turno') {
      return (
        <ListItemText id={p.id}>
          <span
            style={{ fontSize: '90%' }}
            className="MuiTypography-root MuiListItemText-primary MuiTypography-body1"
          >
            {p.descripcionTurno}
          </span>
        </ListItemText>
      );
    }
    return (
      <ListItemText id={p.id}>
        <span
          style={{ fontSize: '90%' }}
          className="MuiTypography-root MuiListItemText-primary MuiTypography-body1"
        >
          {p.descripcion}
        </span>
      </ListItemText>
    );
  };

  return (
    <>
      <Context.Consumer>
        {
          value => (
            <List className={classes.root} >
              {procesos.map(p => (
                <ListItem
                  button
                  divider
                  key={p.id}
                  onClick={() => { value.activateProceso(p); }}
                >
                  {item(p)}
                  {
                    perteneceRolIngenieria() &&
                    <EliminarProceso
                      method={method}
                      tipoP={tipoP}
                      msgAlerta={msgAlerta}
                    />
                  }
                </ListItem>
            ))}
            </List>
          )
        }
      </Context.Consumer>
    </>
  );
}
Lista.propTypes = {
  procesos: PropTypes.arrayOf(PropTypes.object).isRequired,
  tipo: PropTypes.string.isRequired,
};
