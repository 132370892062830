import React from 'react';
import { FormGroup, Label, Input, Form, Button, FormFeedback } from 'reactstrap';
import PropTypes from 'prop-types';
import { NativeSelect, TextField, InputLabel } from '@material-ui/core';
import SelectorCascada from '../Comunes/SelectorCascada';
import SelectorSimple from '../Comunes/SelectorSimple';
import { crearEstandarUbicacion } from './datosEstandares';
import {
  obtenerOperaciones,
  obtenerUbicacionesPorSubseccion,
  obtenerSecciones,
  obtenerSubseccionesPorSeccion,
  obtenerProgramas,
  mostrarValidacion,
  esDecimalValido,
  convertirDecimal,
  esEnteroValido,
} from '../Comunes/datosComunes';
import { CryUsername } from '../../appConfig.json';
import Alerta from '../Comunes/Alerta';

class EstandaresUbicacionForm extends React.Component {
  static estadoErroresInicial() {
    return {
      hayErrores: false,
      subseccionId: [],
      operacionGeneralId: [],
      ubicacionId: [],
      programaId: [],
      pares: [],
      estandar: [],
    };
  }
  constructor(props) {
    super(props);
    this.state = {
      estandar: '0',
      operacionGeneralId: '',
      seccion: '',
      subseccionId: '',
      ubicacionId: '',
      cargandoUbicaciones: false,
      ubicaciones: [],
      programaId: '',
      pares: '',
      guardando: false,
      errores: EstandaresUbicacionForm.estadoErroresInicial(),
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleChangeSubseccion = this.handleChangeSubseccion.bind(this);
    this.guardarEstandar = this.guardarEstandar.bind(this);
    this.validarFormulario = this.validarFormulario.bind(this);
    this.cancelar = this.cancelar.bind(this);
    this.mensajeError = this.mensajeError.bind(this);
    this.resetearEstadoErrores = this.resetearEstadoErrores.bind(this);
    this.cargarUbicaciones = this.cargarUbicaciones.bind(this);
  }
  componentWillUnmount() {
    this.abortController.abort();
  }
  abortController = new AbortController();
  handleChange = async (e) => {
    await this.setState({ [e.target.name]: e.target.value });
  }
  async handleChangeSubseccion(e) {
    await this.setState({ subseccionId: e.target.value });
    await this.cargarUbicaciones();
  }
  async cargarUbicaciones() {
    if (this.state.subseccionId) {
      await this.setState({
        cargandoUbicaciones: true,
        ubicaciones: [],
        ubicacionId: '',
      });
      const ubicaciones =
        await obtenerUbicacionesPorSubseccion(this.state.subseccionId);
      await this.setState({ cargandoUbicaciones: false, ubicaciones });
    }
  }
  async validarFormulario() {
    const errores = EstandaresUbicacionForm.estadoErroresInicial();
    if (!this.state.subseccionId) {
      errores.hayErrores = true;
      errores.subseccionId = ['Debe especificar la subsección'];
    }
    if (!this.state.operacionGeneralId) {
      errores.hayErrores = true;
      errores.operacionGeneralId = ['Debe especificar la operación general'];
    }
    if (!this.state.programaId && (!this.state.ubicacionId || !this.state.pares)) {
      errores.hayErrores = true;
      errores.programaId = ['Debe especificar el programa o la ubicación y el número de pares'];
      if (!this.state.ubicacionId) {
        errores.ubicacionId = ['Debe especificar la ubicación y el número de pares o el programa'];
      }
      if (!this.state.pares) {
        errores.pares = ['Debe especificar el número de pares y la ubicación o el programa'];
      }
    }
    if (this.state.pares) {
      if (!esEnteroValido(this.state.pares)) {
        errores.hayErrores = true;
        errores.pares.push('El formato de la cantidad de pares no es válido');
      }
    }
    const valorEstandar = convertirDecimal(this.state.estandar);
    if (this.state.estandar == null || this.state.estandar === '') {
      errores.hayErrores = true;
      errores.estandar = ['Debe especificar el valor del estandar'];
    } else if (isNaN(valorEstandar) || !esDecimalValido(this.state.estandar)) {
      errores.hayErrores = true;
      errores.estandar = ['El formato del estandar especificado no es válido'];
    } else if (valorEstandar <= 0) {
      errores.hayErrores = true;
      errores.estandar = ['El valor del estandar especificado no es válido. Debe ser mayor que 0'];
    }
    await this.setState({ errores });
    return !errores.hayErrores;
  }
  async guardarEstandar() {
    if (await this.validarFormulario()) {
      await this.setState({ guardando: true });
      const datos = {
        subseccionId: this.state.subseccionId,
        operacionGeneralId: this.state.operacionGeneralId,
        ubicacionId: this.state.ubicacionId,
        programaId: this.state.programaId,
        pares: this.state.pares,
        estandar: convertirDecimal(this.state.estandar),
        usuario: localStorage.getItem(CryUsername),
      };
      const resultado = await crearEstandarUbicacion(datos);
      if (!resultado.error) {
        await this.setState({ guardando: false, errores: EstandaresUbicacionForm.estadoErroresInicial() });
        this.props.onSave();
      } else {
        const errores = { ...resultado.datos };
        errores.hayErrores = true;
        await this.setState({ guardando: false, errores });
      }
    }
  }
  async cancelar() {
    this.props.onCancel();
  }
  async resetearEstadoErrores() {
    const errores = EstandaresUbicacionForm.estadoErroresInicial();
    await this.setState({ errores });
  }
  mensajeError() {
    const { errores } = this.state;
    if (errores.hayErrores) {
      if ((errores.estandar && errores.estandar.length > 0) ||
        (errores.subseccionId && errores.subseccionId.length) ||
        (errores.ubicacionId && errores.ubicacionId.length) ||
        (errores.programaId && errores.programaId.length) ||
        (errores.pares && errores.pares.length) ||
        (errores.operacionGeneralId && errores.operacionGeneralId.length)) {
        return 'Hay errores en el formulario. Por favor revise y corríjalos';
      }
      return 'Se presentaron errores. Por favor intente nuevamente';
    }
    return '';
  }
  renderListaUbicaciones() {
    if (this.state.cargandoUbicaciones) {
      return (
        <option key="-1" value="">Cargando...</option>
      );
    }
    const ops = this.state.ubicaciones.map(x => x);
    ops.unshift({ id: '', descripcion: '' });
    return (
      ops.map(x => (
        <option
          key={x.id}
          value={x.id}
        >
          {x.descripcion}
        </option>
      )));
  }
  render() {
    return (
      <Form>
        <Alerta
          texto={this.mensajeError()}
          toggle={this.resetearEstadoErrores}
          isVisible={this.state.errores.hayErrores}
        />
        <div className="container text-center">
          <div className="col align-self-start">
            <SelectorCascada
              className="selectSeccionUbicacion"
              primaryLabel="Secci&oacute;n"
              filteredLabel="Subsecci&oacute;n"
              primaryInputName="seccion"
              filteredInputName="subseccionId"
              primaryValue={this.state.seccion}
              filteredValue={this.state.subseccionId}
              onChange={this.handleChange}
              onFilteredChange={this.handleChangeSubseccion}
              getParents={obtenerSecciones}
              getChilds={obtenerSubseccionesPorSeccion}
              primaryCol={4}
              filteredCol={4}
              filteredInvalid={
                this.state.errores &&
                this.state.errores.subseccionId &&
                this.state.errores.subseccionId.length > 0
              }
              filteredInvalidMessage={mostrarValidacion(this.state.errores.subseccionId)}
              disabled={this.state.guardando}
            />
          </div>
        </div>
        <div className="row justify-content-center">
          <div className="col-4">
            <SelectorSimple
              inputName="operacionGeneralId"
              value={this.state.operacionGeneralId}
              primaryLabel="Operaci&oacute;n"
              onChange={this.handleChange}
              obtenerDatos={obtenerOperaciones}
              mostrarEtiqueta
              campoId="id"
              campoDesc="descripcion"
              disabled={this.state.saving}
              invalid={
                this.state.errores &&
                this.state.errores.operacionGeneralId &&
                this.state.errores.operacionGeneralId.length > 0
              }
              invalidMessage={mostrarValidacion(this.state.errores.operacionGeneralId)}
            />
          </div>
          <div className="col-4">
            <SelectorSimple
              className="selectPrograma"
              inputName="programaId"
              value={this.state.programaId}
              primaryLabel="Programa"
              onChange={this.handleChange}
              obtenerDatos={obtenerProgramas}
              mostrarEtiqueta
              campoId="id"
              campoDesc="descripcion"
              disabled={this.state.saving}
              invalid={
                this.state.errores &&
                this.state.errores.programaId &&
                this.state.errores.programaId.length > 0
              }
              invalidMessage={mostrarValidacion(this.state.errores.programaId)}
            />
          </div>
        </div>
        <div className="container text-center">
          <div className="row justify-content-center">
            <div className="col-4">
              <InputLabel variant="outlined" htmlFor="uncontrolled-native">
                Ubicaci&oacute;n&nbsp;
              </InputLabel>
              <NativeSelect
                className="selectUbicacion"
                name="ubicacionId"
                value={this.state.ubicacionId}
                onChange={this.handleChange}
                disabled={this.state.guardando || !this.state.subseccionId}
                invalid={
                  this.state.errores &&
                  this.state.errores.ubicacionId &&
                  this.state.errores.ubicacionId.length > 0
                }
              >
                {this.renderListaUbicaciones()}
              </NativeSelect>
              <FormFeedback>{mostrarValidacion(this.state.errores.ubicacionId)}</FormFeedback>
            </div>
            <div className="col-4">
              <InputLabel
                variant="filled"
                htmlFor="uncontrolled-native"
              >
                Pares:&nbsp;
              </InputLabel>
              <TextField
                className="selectPares"
                id="pares"
                name="pares"
                type="text"
                onChange={this.handleChange}
                value={this.state.pares}
                disabled={this.state.guardando}
                invalid={
                  this.state.errores &&
                  this.state.errores.pares &&
                  this.state.errores.pares.length > 0
                }
              />
              <FormFeedback>{mostrarValidacion(this.state.errores.pares)}</FormFeedback>
            </div>
            <div className="col-8 align-self-start">
              <FormGroup>
                <InputLabel variant="standard" htmlFor="uncontrolled-native">Estandar:&nbsp;</InputLabel>
                <TextField
                  id="estandar"
                  name="estandar"
                  type="text"
                  className="selectUbicacion"
                  placeholder="estandar"
                  onChange={this.handleChange}
                  value={this.state.estandar}
                  disabled={this.state.guardando}
                  invalid={
                    this.state.errores &&
                    this.state.errores.estandar &&
                    this.state.errores.estandar.length > 0
                  }
                />
                <FormFeedback>{mostrarValidacion(this.state.errores.estandar)}</FormFeedback>
                <Label>MIN DZ</Label>
              </FormGroup>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col text-center">
            <Button
              className="btn btn-primary"
              onClick={this.guardarEstandar}
              disabled={this.state.guardando}
            >
              Guardar
            </Button>
            <Button onClick={this.cancelar} disabled={this.state.guardando}>Cancelar</Button>
          </div>
        </div>
      </Form>
    );
  }
}

EstandaresUbicacionForm.propTypes = {
  history: PropTypes.shape({ push: PropTypes.func.isRequired }).isRequired,
  match: PropTypes.shape({ params: PropTypes.shape({ id: PropTypes.string.isRequired }) }).isRequired,
  onSave: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
};

export default EstandaresUbicacionForm;
