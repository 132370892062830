import React from 'react';
import {
  Button,
  Dialog,
  DialogTitle,
  IconButton,
  Tooltip,
  DialogActions,
  DialogContent,
} from '@material-ui/core';
import { RemoveCircleIcon } from 'mdi-react';

import Context from '../../store';

import Alert from '../../../../shared/components/alert/Alert';

import config from '../../../../appConfig.json';


function EliminarGeneral(props) {
  const [open, setOpen] = React.useState(false);
  const [alert, setAlert] = React.useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setAlert(false);
  };
  return (
    <>
      <Context.Consumer>
        {
          (value) => {
              const handleDelete = async () => {
                const request = await fetch(
                  `${config.ApiUrl}/operaciones/general/${props.operacion.id}/eliminar`,
                  {
                    mode: 'cors',
                    method: 'DELETE',
                    headers: {
                      'Content-Type': 'application/json',
                      Authorization: localStorage.getItem(config.AuthTokenKey),
                    },
                  },
                );
                if (request.status === 400) {
                  setAlert(true);
                } else {
                  window.location.reload(false);
                  handleClose();
                }
              };
              return (
                <>
                  <Tooltip title="Elimina esta operación general" >
                    <IconButton
                      size="small"
                      aria-label="Elimina esta operación general"
                      onClick={handleOpen}
                    >
                      <RemoveCircleIcon style={{ color: '#717575' }} />
                    </IconButton>
                  </Tooltip>
                  <Dialog
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="form-dialog-title"
                  >
                    <DialogTitle align="center" id="alert-dialog-title">
                      ¿Está seguro de eliminar esta operación general?
                    </DialogTitle>
                    <DialogContent>
                      <Alert
                        color="danger"
                        className="alert--bordered"
                        icon
                        visible={alert}
                      >
                      ESTA OPERACIÓN GENERAL TIENE DETALLES ASOCIADOS.
                      </Alert>
                    </DialogContent>
                    <DialogActions align="center" >
                      <Button
                        onClick={handleDelete}
                        color="primary"
                      >
                          SI
                      </Button>
                      <Button onClick={handleClose} color="primary">
                         NO
                      </Button>
                    </DialogActions>
                  </Dialog>
              </>
              );
}
          }
      </Context.Consumer>
    </>
  );
}

export default EliminarGeneral;
