import React, { createContext, useState } from 'react';
import PropTypes from 'prop-types';

const Context = createContext();

const Provider = ({ children }) => {
  const [operacion, setOperacion] = useState(null);
  const [detalladas, setDetalladas] = useState([]);
  const [detalladasOrigin, setDetalladasOrigin] = useState([]);
  const [lote, setLote] = useState(null);
  let value = {};

  value = {
    operacion,
    activateOp: (n) => {
      setOperacion(n);
    },
    detalladas,
    activateDet: (n) => {
      setDetalladas(n);
    },
    detalladasOrigin,
    activateDetOrigin: (n) => {
      setDetalladasOrigin(n);
    },
    lote,
    activateLote: (n) => {
      setLote(n);
    },
  };
  return (
    <Context.Provider value={value}>
      { children }
    </Context.Provider>
  );
};

Provider.propTypes = {
  children: PropTypes.element.isRequired,
};

export default {
  Provider,
  Consumer: Context.Consumer,
};
