import React from 'react';
import { TextField, Grid, Button } from '@material-ui/core';
import { FileExcelIcon, SearchIcon } from 'mdi-react';
import ContextIncentivos from '../ContextoIncentivos';
import { perteneceRolIngenieria } from '../../Comunes/datosComunes';

const FiltroIncentivos = () => (
  <ContextIncentivos.Consumer>
    {
      ({
        cargando,
        fechaInicial,
        fijarFechaInicial,
        fechaFinal,
        fijarFechaFinal,
        cedulaOperario,
        fijarCedulaOperario,
        ejecutarCalculoIncentivo,
        ejecutarConsultaIncentivo,
        exportarExcel,
        generarPlanoNomina,
        fijarEstadoRegistroAjuste,
      }) => {
        const fechaInicialCambio = (e) => {
          fijarFechaInicial(e.target.value);
        };
        const fechaFinalCambio = (e) => {
          fijarFechaFinal(e.target.value);
        };
        const cedulaOperarioCambio = (e) => {
          fijarCedulaOperario(e.target.value);
        };
        const iniciarCalculo = () => {
          ejecutarCalculoIncentivo(false);
        };
        const iniciarConsulta = () => {
          ejecutarConsultaIncentivo();
        };
        const iniciarRegistroAjuste = () => {
          fijarEstadoRegistroAjuste(true);
        };
        const iniciarExportacionExcel = () => {
          exportarExcel();
        };
        const iniciarGeneracionPlano = () => {
          generarPlanoNomina();
        };
        return (
          <Grid container spacing={2} direction="column">
            <Grid container item spacing={2} direction="row" justify="center">
              <Grid item md={2}>
                <TextField
                  variant="standard"
                  type="date"
                  label="Fecha inicial"
                  value={fechaInicial}
                  onChange={fechaInicialCambio}
                />
              </Grid>
              <Grid item md={2}>
                <TextField
                  variant="standard"
                  type="date"
                  label="Fecha final"
                  value={fechaFinal}
                  onChange={fechaFinalCambio}
                />
              </Grid>
              <Grid item md={2}>
                <TextField
                  variant="standard"
                  label="Operario"
                  value={cedulaOperario}
                  onChange={cedulaOperarioCambio}
                />
              </Grid>
            </Grid>
            <Grid container item direction="row" justify="center" md={12}>
              <Grid item md={2}>
                <Button
                  className="btn btn-secondary"
                  variant="outlined"
                  onClick={iniciarCalculo}
                  disabled={cargando && !perteneceRolIngenieria()}
                >
                  Calcular
                </Button>
              </Grid>
              <Grid item md={2}>
                <Button
                  className="btn btn-primary"
                  variant="outlined"
                  onClick={iniciarConsulta}
                  disabled={cargando}
                >
                  <SearchIcon />&nbsp;
                  Consultar
                </Button>
              </Grid>
              <Grid item md={2}>
                <Button
                  className="btn btn-secondary"
                  variant="outlined"
                  onClick={iniciarRegistroAjuste}
                  disabled={!perteneceRolIngenieria() || cargando}
                >
                  Ajuste
                </Button>
              </Grid>
              <Grid item md={2}>
                <Button
                  className="btn btn-secondary"
                  variant="outlined"
                  onClick={iniciarExportacionExcel}
                  disabled={cargando}
                >
                  <FileExcelIcon />&nbsp;
                  Exportar
                </Button>
              </Grid>
              <Grid item md={2}>
                <Button
                  variant="outlined"
                  onClick={iniciarGeneracionPlano}
                  disabled={!perteneceRolIngenieria() || cargando}
                  className="btn btn-secondary"
                >
                  Plano
                </Button>
              </Grid>
            </Grid>
          </Grid>
        );
      }
    }
  </ContextIncentivos.Consumer>
);


export default FiltroIncentivos;
