import React from 'react';
import PropTypes from 'prop-types';

import { makeStyles, List, ListItem, ListItemText } from '@material-ui/core';
import EliminarGeneral from './EliminarGeneral';
import Context from '../../store';
import config from '../../../../appConfig.json';
import { perteneceRolIngenieria } from '../../../Comunes/datosComunes';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.paper,
    position: 'relative',
    overflowY: 'auto',
    height: `${window.screen.height - 270}px`,
    width: '100%',
  },
  color: {
    color: '#717575',
  },
  textoColor: {
    '.MuiTypography-body1': {
      backgroundColor: 'aquamarine',
    },
  },
}));
export default function ListaGenerales(props) {
  const classes = useStyles();
  return (
    <>
      <Context.Consumer>
        {
          value => (
            <List className={classes.root} >
              {props.data.map(op => (
                <ListItem
                  button
                  divider
                  onClick={async () => {
                    try {
                      const response = await fetch(
                        `${config.ApiUrl}/operaciones/general/${op.id}/consultar`,
                        {
                          headers: {
                            'Content-Type': 'application/json',
                            Authorization: localStorage.getItem(config.AuthTokenKey),
                          },
                        },
                      );
                      const Op = await response.json();
                      const response2 = await fetch(
                        `${config.ApiUrl}/Operaciones/${op.id}/OperacionesDet`,
                        {
                          headers: {
                            'Content-Type': 'application/json',
                            Authorization: localStorage.getItem(config.AuthTokenKey),
                          },
                        },
                      );
                      const response3 = await fetch(
                        `${config.ApiUrl}/operaciones/generales/lista`,
                        {
                          headers: {
                            'Content-Type': 'application/json',
                            Authorization: localStorage.getItem(config.AuthTokenKey),
                          },
                        },
                      );
                      const OpDet = await response2.json();
                      value.activateOp(Op);
                      value.activateDet(OpDet);
                      value.activateDetOrigin(OpDet);
                    } catch (err) { console.log(err); }
                  }}
                  key={op.id}
                >
                  <ListItemText id={op.id}>
                    <span
                      style={{ fontSize: '90%' }}
                      className="MuiTypography-root MuiListItemText-primary MuiTypography-body1"
                    >
                      {op.operacionGeneral}
                      <span> : </span>
                      {op.descripcion}
                    </span>
                  </ListItemText>
                  {
                    perteneceRolIngenieria() &&
                    <EliminarGeneral
                      operacion={op}
                      method={props.handleOperacionesReload}
                    />
                  }
                </ListItem>
              ))}
            </List>
          )
        }
      </Context.Consumer>
    </>
  );
}
ListaGenerales.propTypes = {
  data: PropTypes.arrayOf(PropTypes.string).isRequired,
  detallada: PropTypes.arrayOf(PropTypes.string).isRequired,
};
