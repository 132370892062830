import React from 'react';
import PropTypes from 'prop-types';
import { Card, CardBody, Col } from 'reactstrap';
import { Link } from 'react-router-dom';
import Eficiencia from '../../../shared/components/eficiencia/Eficiencia';

const IndicadorEficienciaUnidad = props => (
  <Col md={6} lg={4}>
    <Link to={`${props.base}/${props.codigo}`}>
      <Card>
        <CardBody>
          <div className="card__title">
            <h5 className="bold-text text-center">{props.titulo}</h5>
          </div>
          <Eficiencia porcentaje={props.eficiencia} />
        </CardBody>
      </Card>
    </Link>
  </Col>
);

IndicadorEficienciaUnidad.propTypes = {
  titulo: PropTypes.string.isRequired,
  codigo: PropTypes.number.isRequired,
  base: PropTypes.string.isRequired,
  eficiencia: PropTypes.number.isRequired,
};

export default IndicadorEficienciaUnidad;
