import React from 'react';
import PropTypes from 'prop-types';
import { Button,
  Dialog,
  DialogContent,
  DialogActions,
  DialogTitle,
  Typography,
  Grid,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem } from '@material-ui/core';
import Alert from '../../../../shared/components/alert/Alert';

export default function CrearTalla(props) {
  return (
    <>
      <Dialog
        open={props.open}
        onClose={() => { props.handleClose(2); }}
        scroll="paper"
        aria-labelledby="scroll-dialog-title"
        maxWidth="sm"
      >
        <form
          onSubmit={(e) => {
          e.preventDefault();
          props.handleInsert();
            }}
        >
          <DialogTitle>
            <Typography variant="h6" align="center">
              ADICIONAR TALLA
            </Typography>
          </DialogTitle>
          <DialogContent dividers>
            <Alert
              color="danger"
              className="alert--bordered"
              icon
              visible={props.alert}
            >
              ESTA TALLA YA ESTA REGISTRADA.
            </Alert>
            <Grid container>
              <Grid item xs={6}>
                <TextField
                  required
                  label="Código"
                  id="codigo"
                  onChange={props.handleChangeInputs}
                  type="number"
                />
              </Grid>
              <Grid item xs={9} >
                <TextField
                  required
                  label="Descripción"
                  id="descripcion"
                  fullWidth
                  onChange={props.handleChangeInputs}
                />
              </Grid>
              <Grid item xs={6}>
                <FormControl style={{ minWidth: '242px', margin: '2%' }} required>
                  <InputLabel id="grupoTalla">Grupo talla</InputLabel>
                  <Select
                    labelId="grupoTalla"
                    name="grupoTalla"
                    onChange={(e) => { props.handleSelect(e, 1); }}
                    value={props.select}
                  >
                    {props.grupos.map(g => (<MenuItem key={g.id} value={g.id}>{g.descripcion}</MenuItem>))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={6}>
                <FormControl style={{ minWidth: '242px', margin: '2%' }} required>
                  <InputLabel id="unidadNegocio">Unidad de Negocio</InputLabel>
                  <Select
                    labelId="unidadNegocio"
                    name="unidadNegocio"
                    onChange={(e) => { props.handleSelect(e, 2); }}
                    value={props.selectU}
                  >
                    {props.unidades.map(uni => (<MenuItem key={uni.id} value={uni.id}>{uni.descripcion}</MenuItem>))}
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => { props.handleClose(2); }} color="default">
              Cerrar
            </Button>
            <Button color="primary" type="submit" >
              Añadir
            </Button>
          </DialogActions>
        </form>

      </Dialog>
    </>
  );
}
CrearTalla.propTypes = {
  handleClose: PropTypes.func.isRequired,
  handleInsert: PropTypes.func.isRequired,
  handleChangeInputs: PropTypes.func.isRequired,
  handleSelect: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  alert: PropTypes.bool.isRequired,
  unidades: PropTypes.arrayOf(PropTypes.object),
  grupos: PropTypes.arrayOf(PropTypes.object),
  select: PropTypes.string.isRequired,
  selectU: PropTypes.string.isRequired,
};
