import React from 'react';
import PropTypes from 'prop-types';
import { TextField, Grid, Button } from '@material-ui/core';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import { es } from 'date-fns/locale';
import { FileExcelIcon, SearchIcon } from 'mdi-react';
import { obtenerSecciones, truncarFecha } from '../../Comunes/datosComunes';
import MaterialUISelectorMultiple from './../../Comunes/MateriaUISelectorMultiple';

class FiltroReporteBasicoSegundas extends React.Component {
  constructor(props) {
    super(props);
    this.handleChangeFechaInicial = this.handleChangeFechaInicial.bind(this);
    this.handleChangeFechaFinal = this.handleChangeFechaFinal.bind(this);
    this.handleChangeSeccion = this.handleChangeSeccion.bind(this);
    this.handleChangeOperario = this.handleChangeOperario.bind(this);
    this.construirFiltro = this.construirFiltro.bind(this);
    this.fijarFiltroConsulta = this.fijarFiltroConsulta.bind(this);
    this.fijarFiltroExportacion = this.fijarFiltroExportacion.bind(this);
    this.state = {
      errores: {
        fechaInicial: null,
        fechaFinal: null,
      },
      cargando: {
        segundas: false,
        secciones: false,
      },
      fechaInicial: this.inicio(),
      fechaFinal: this.inicio(),
      operario: null,
      secciones: [],
      listaSecciones: [],
    };
  }
  async componentDidMount() {
    await this.cargarSecciones();
  }
  abortController = new AbortController();
  inicio = () => {
    const d = new Date();
    d.setHours(0, 0, 0, 0);
    return d.toISOString();
  };
  async cargarSecciones() {
    await this.setState({ cargando: { secciones: true } });
    const secciones = await obtenerSecciones(this.abortController.signal);
    await this.setState({ listaSecciones: secciones, secciones: [], cargando: { secciones: false } });
  }
  async handleChangeFechaInicial(v) {
    await this.setState({ fechaInicial: truncarFecha(v) });
  }
  async handleChangeFechaFinal(v) {
    await this.setState({ fechaFinal: truncarFecha(v) });
  }
  async handleChangeSeccion(valores, values) {
    if (Array.isArray(values)) {
      const secciones = values.map(valores);
      await this.setState({ secciones });
    } else {
      console.error('Values is not an array:', values);
    }
  }
  async handleChangeOperario(e) {
    await this.setState({ operario: e.target.value });
  }
  async fijarFiltroConsulta() {
    if (this.props.filtroConsultaEstablecido) {
      const filtro = await this.construirFiltro();
      if (filtro != null && this.props.filtroConsultaEstablecido) {
        this.props.filtroConsultaEstablecido(filtro);
      }
    }
  }
  async fijarFiltroExportacion() {
    if (this.props.filtroExportacionEstablecido) {
      const filtro = await this.construirFiltro();
      if (filtro != null && this.props.filtroExportacionEstablecido) {
        this.props.filtroExportacionEstablecido(filtro);
      }
    }
  }
  async construirFiltro() {
    if (await this.validarEntrada()) {
      return {
        fechaInicioConsulta: this.state.fechaInicial,
        fechaFinConsulta: this.state.fechaFinal,
        cedulaOperarioConsulta: this.state.operario,
        seccionesConsulta: this.state.secciones,
      };
    }
    return null;
  }
  async validarEntrada() {
    let v = true;
    let mensajeFechaInicial = null;
    let mensajeFechaFinal = null;
    if (!this.state.fechaInicial) {
      mensajeFechaInicial = 'Debe especificar la fecha inicial de la consulta';
      v = false;
    }
    if (!this.state.fechaFinal) {
      mensajeFechaFinal = 'Debe especificar la fecha final de la consulta';
      v = false;
    }
    if (v) {
      const fechaInicio = Date.parse(this.state.fechaInicial);
      const fechaFin = Date.parse(this.state.fechaFinal);
      if (fechaFin < fechaInicio) {
        v = false;
        mensajeFechaInicial = 'La fecha inicial debe ser menor que la final';
        mensajeFechaFinal = 'La fecha final debe ser mayor que la inicial';
      } else if (((fechaFin - fechaInicio) / 1000) > (15 * 86400)) {
        v = false;
        mensajeFechaInicial = 'La diferencia entre la fecha inicial y la final no puede ser ' +
          'superior a quince días';
      }
    }
    await this.setState({ errores: { fechaInicial: mensajeFechaInicial, fechaFinal: mensajeFechaFinal } });
    return v;
  }
  render() {
    return (
      <Grid direction="column" container spacing={1} alignItems="center">
        <Grid container item direction="row" spacing={1} justify="center">
          <Grid container item direction="column" lg={4} sm={6} xs={12} spacing={1}>
            <MuiPickersUtilsProvider utils={DateFnsUtils} locale={es}>
              <Grid item>
                <KeyboardDatePicker
                  name="fechaInicial"
                  size="small"
                  inputVariant="standard"
                  autoOk
                  format="dd/MM/yyyy"
                  label="Fecha inicial"
                  value={this.state.fechaInicial}
                  onChange={this.handleChangeFechaInicial}
                  clearable
                  clearLabel="Limpiar"
                  cancelLabel="Cancelar"
                  okLabel="Aceptar"
                  error={this.state.errores.fechaInicial}
                  helperText={this.state.errores.fechaInicial}
                />
              </Grid>
              <Grid item>
                <KeyboardDatePicker
                  locale="es-ES"
                  size="small"
                  name="fechaFinal"
                  inputVariant="standard"
                  autoOk
                  format="dd/MM/yyyy"
                  label="Fecha final"
                  value={this.state.fechaFinal}
                  onChange={this.handleChangeFechaFinal}
                  clearable
                  clearLabel="Limpiar"
                  cancelLabel="Cancelar"
                  okLabel="Aceptar"
                  error={this.state.errores.fechaFinal}
                  helperText={this.state.errores.fechaFinal}
                />
              </Grid>
            </MuiPickersUtilsProvider>
          </Grid>
          <Grid container item direction="column" lg={4} sm={6} xs={12} spacing={1}>
            <Grid item>
              <TextField
                variant="standard"
                size="small"
                name="operario"
                label="Operario"
                value={this.state.operario}
                onChange={this.handleChangeOperario}
              />
            </Grid>
          </Grid>
          <Grid container item direction="column" lg={4} sm={6} xs={12} spacing={1}>
            <Grid item>
              <MaterialUISelectorMultiple
                variant="outlined"
                cargando={this.state.cargando.secciones}
                lista={this.state.listaSecciones}
                label="Sección"
                placeholder="Secciones"
                value={this.secciones}
                onChange={this.handleChangeSeccion}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid container item direction="column" md={10}>
          <Grid container spacing={1} justify="center" direction="row">
            <Grid item xs={12} md={4}>
              <Button
                className="btn btn-primary"
                onClick={this.fijarFiltroConsulta}
                disabled={this.props.disabled}
                primary
                variant="outlined"
              >
                <SearchIcon />
                Consultar
              </Button>
            </Grid>
            <Grid item xs={12} md={4}>
              <Button
                className="btn btn-secondary"
                onClick={this.fijarFiltroExportacion}
                disabled={this.props.disabled}
                variant="outlined"
              >
                <FileExcelIcon />&nbsp;
                Exportar
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    );
  }
}

FiltroReporteBasicoSegundas.propTypes = {
  filtroConsultaEstablecido: PropTypes.func.isRequired,
  filtroExportacionEstablecido: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
};
FiltroReporteBasicoSegundas.defaultProps = {
  disabled: false,
};

export default FiltroReporteBasicoSegundas;
