/* eslint-disable import/prefer-default-export */
import { ApiUrl, AuthTokenKey } from './../../../appConfig.json';

export const crearEstandar = async (
  referencia, categoria, normaEmpaque, procesos, estandarBebes, estandarNinios,
  estandarAdultos, bebesCheck, niniosCheck, adultosCheck, empaqueManualCheck, empaqueAutotexCheck,
) => {
  const data = {
    CodigoMaterial: referencia,
    CodigoCategoria: categoria,
    NormaEmpaque: normaEmpaque,
    Operaciones: procesos,
    Bebes: {
      GrupoTallaId: 1,
      Crear: bebesCheck,
      Estandar: estandarBebes,
    },
    Ninios: {
      GrupoTallaId: 2,
      Crear: niniosCheck,
      Estandar: estandarNinios,
    },
    Adultos: {
      GrupoTallaId: 3,
      Crear: adultosCheck,
      Estandar: estandarAdultos,
    },
    EmpaqueManual: empaqueManualCheck,
    EmpaqueAutotex: empaqueAutotexCheck,
  };

  const request = await fetch(`${ApiUrl}/Operaciones/Estandar/Crear`, {
    mode: 'cors',
    method: 'POST',
    body: JSON.stringify(data),
    headers: {
      'Content-Type': 'application/json;charset=utf-8',
      Authorization: localStorage.getItem(AuthTokenKey),
    },
  });

  const response = await request.json();

  return response;
};

export const modificarEstandar = async (
  referencia, categoria, normaEmpaque, estandarBebes, estandarNinios,
  estandarAdultos, bebesCheck, niniosCheck, adultosCheck, empaqueManualCheck,
  empaqueAutotexCheck, procesosEliminados, procesosAgregados, procesos,
) => {
  const data = {
    CodigoMaterial: referencia,
    CodigoCategoria: categoria,
    NormaEmpaque: normaEmpaque,
    Operaciones: procesos,
    OperacionesAgregadas: procesosAgregados,
    OperacionesEliminadas: procesosEliminados,
    Bebes: {
      GrupoTallaId: 1,
      Estandar: estandarBebes,
    },
    Ninios: {
      GrupoTallaId: 2,
      Estandar: estandarNinios,
    },
    Adultos: {
      GrupoTallaId: 3,
      Estandar: estandarAdultos,
    },
    bebesCheck,
    niniosCheck,
    adultosCheck,
    EmpaqueManual: empaqueManualCheck,
    EmpaqueAutotex: empaqueAutotexCheck,
  };

  const request = await fetch(`${ApiUrl}/Operaciones/Estandar/Editar`, {
    mode: 'cors',
    method: 'POST',
    body: JSON.stringify(data),
    headers: {
      'Content-Type': 'application/json;charset=utf-8',
      Authorization: localStorage.getItem(AuthTokenKey),
    },
  });

  const response = await request.json();

  return response;
};

export const eliminarEstandar = async (referencia, categoria) => {
  const data = {
    CodigoMaterial: referencia,
    CodigoCategoria: categoria,
  };

  const request = await fetch(`${ApiUrl}/Operaciones/Estandar/Eliminar`, {
    mode: 'cors',
    method: 'POST',
    body: JSON.stringify(data),
    headers: {
      'Content-Type': 'application/json;charset=utf-8',
      Authorization: localStorage.getItem(AuthTokenKey),
    },
  });

  const response = await request.json();

  return response;
};
