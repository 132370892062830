import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Row, Col } from 'reactstrap';

const CabeceraDetalle = ({
  id,
  nombre,
  fechaOrigen,
  fechaCheck,
}) => (
  <Fragment>
    <Row>
      <Col className="text-center">
        <h2>{nombre.toUpperCase()}</h2>
      </Col>
    </Row>
    <hr />
    <Row className="text-center">
      <Col md={2} sm={4} xs={12}>
        <b>#{id}</b>
      </Col>
      <Col md={5} sm={4} xs={12}>
        <b>ORIGINADA: </b>
        {fechaOrigen}
      </Col>
      <Col md={5} sm={4} xs={12}>
        <b>ATENDIDA: </b>
        {fechaCheck !== null ? fechaCheck : 'NO ATENDIDA'}
      </Col>
    </Row>
    <hr />
  </Fragment>
);

CabeceraDetalle.propTypes = {
  id: PropTypes.number.isRequired,
  nombre: PropTypes.string.isRequired,
  fechaOrigen: PropTypes.string.isRequired,
  fechaCheck: PropTypes.string.isRequired,
};

export default CabeceraDetalle;
