/* eslint-disable import/prefer-default-export */
import React from 'react';
import { TableRow, TableCell } from '@material-ui/core';

import { Chip } from './../styles';
import Context from '../Context';

// eslint-disable-next-line react/prop-types
export const Proceso = ({ proc }) => {
  const checkUndefinedEstandar = grupoTalla => (grupoTalla !== undefined ? grupoTalla.estandar : 0);

  return (
    <Context.Consumer>
      {
        ({
          procesos, activateProcesos, activateEstBebes, activateEstNinios, activateEstAdultos,
          estandarBebes, estandarNinios, estandarAdultos, procesosEliminados, activateProcesosEliminados,
          procesosAgregados, activateProcesosAgregados,
        }) => {
          const handleDeleteProc = (proceso) => {
            const operaciones = [...procesos];

            // const cutIndex = operaciones.findIndex(element => element.id === id);

            // const opToDelete = operaciones[cutIndex];
            const opToDelete = proceso;
            const findResult = procesosAgregados.find(p => p.id === opToDelete.id);

            const operacionesEliminadas = [...procesosEliminados];
            const operacionesAgregadas = [...procesosAgregados];

            if (!findResult) operacionesEliminadas.push(opToDelete);
            else operacionesAgregadas.splice(operacionesAgregadas.indexOf(opToDelete), 1);

            // operaciones.splice(cutIndex, 1);
            operaciones.splice(operaciones.indexOf(proceso), 1);

            let bebes = estandarBebes;
            let ninios = estandarNinios;
            let adultos = estandarAdultos;

            bebes -= checkUndefinedEstandar(proc.estandares[0]);
            ninios -= checkUndefinedEstandar(proc.estandares[1]);
            adultos -= checkUndefinedEstandar(proc.estandares[2]);

            activateProcesos(operaciones);
            activateProcesosEliminados(operacionesEliminadas);
            activateProcesosAgregados(operacionesAgregadas);

            activateEstBebes(bebes);
            activateEstNinios(ninios);
            activateEstAdultos(adultos);
          };

          return (
            <TableRow key={proc.id}>
              <TableCell>
                <Chip
                  label={proc.nombre}
                  variant="outlined"
                  color="primary"
                  onDelete={() => handleDeleteProc(proc)}
                />
              </TableCell>
              {
                proc.estandares.map(est => (
                  <TableCell>
                    {
                      est.estandar === 0 ? '---' : est.estandar
                    }
                  </TableCell>
                ))
              }
            </TableRow>
          );
        }
      }
    </Context.Consumer>
  );
};
