import React from 'react';
import { Tooltip,
  Button,
  Dialog,
  DialogContent,
  DialogActions,
  DialogTitle,
  FormControlLabel,
  Checkbox,
  Grid,
  Typography,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem } from '@material-ui/core';
import { CreateIcon } from 'mdi-react';
import config from '../../../../appConfig.json';
import Context from '../../../Operaciones/store';

export default function ModificarSeccion(props) {
  const [open, setOpen] = React.useState(false);
  const [select, setSelect] = React.useState('Unidad');
  const [selectP, setSelectP] = React.useState('Planta');
  const [checked, setChecked] = React.useState(false);
  const [seccion, setSeccion] = React.useState({});

  const handleChange = (e) => {
    const array = { ...seccion };
    array[e.target.id] = e.target.value;
    setSeccion(array);
  };

  const handleChecked = (e) => {
    const array = { ...seccion };
    array.pagaIncentivosDiscriminados = e.target.checked;
    setSeccion(array);
    setChecked(e.target.checked);
  };

  const handleSelect = (e) => {
    const array = { ...seccion };
    array[e.target.name] = e.target.value;
    setSeccion(array);
    if (e.target.name === 'unidadNegocio') setSelect(e.target.value);
    if (e.target.name === 'planta') setSelectP(e.target.value);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = (sec) => {
    const array = { ...sec };
    array.usuario = localStorage.getItem(config.CryUsername);
    props.unidades.forEach((u) => {
      if (u.descripcion === array.unidadNegocio) { array.unidadNegocio = u.id; }
    });
    props.plantas.forEach((p) => {
      if (p.descripcion === array.planta) { array.planta = p.id; }
    });
    setSeccion(array);
    setChecked(array.pagaIncentivosDiscriminados);
    setSelect(array.unidadNegocio);
    setSelectP(array.planta);
    setOpen(true);
  };

  return (
    <>
      <Context.Consumer>
        {
          (value) => {
            if (value.operacion === null) {
              return (
                <Tooltip title="Modifica una sección" >
                  <Button
                    variant="contained"
                    style={{ backgroundColor: 'rgb(18, 24, 88)', width: '50%' }}
                  >
                    <CreateIcon style={{ color: 'white' }} />
                    <h5 style={{ color: 'white' }}><strong>MODIFICAR</strong></h5>
                  </Button>
                </Tooltip>
              );
            }

            const handleSave = async () => {
              const p = { ...value.operacion };
              p.descripcion = seccion.descripcion;
              p.pagaIncentivosDiscriminados = seccion.pagaIncentivosDiscriminados;
              props.unidades.forEach((u) => {
                if (u.id === seccion.unidadNegocio) {
                   p.unidadNegocio = u.descripcion;
                }
              });
              props.plantas.forEach((u) => {
                if (u.id === seccion.planta) {
                   p.planta = u.descripcion;
                }
              });
              value.activateOp(p);
              const request = await fetch(`${config.ApiUrl}/secciones/editar/${seccion.id}`, {
                mode: 'cors',
                method: 'POST',
                body: JSON.stringify(seccion),
                headers: {
                  'Content-Type': 'application/json',
                  Authorization: localStorage.getItem(config.AuthTokenKey),
                  Accept: 'application/json',
                },
              });
              const response = await request.json();
              props.fetch();
              setOpen(false);
            };

            return (
              <>
                <Tooltip title="Modifica una sección" >
                  <Button
                    variant="contained"
                    style={{ backgroundColor: 'rgb(18, 24, 88)', width: '50%' }}
                    onClick={() => { handleOpen(value.operacion); }}
                  >
                    <CreateIcon style={{ color: 'white' }} />
                    <h5 style={{ color: 'white' }}><strong>MODIFICAR</strong></h5>
                  </Button>
                </Tooltip>
                <Dialog
                  open={open}
                  onClose={handleClose}
                  scroll="paper"
                  aria-labelledby="form-dialog-title"
                  maxWidth="sm"
                >
                  <form onSubmit={(e) => {
                    e.preventDefault();
                    handleSave();
                    }}
                  >
                    <DialogTitle>
                      <Typography gutterBottom variant="h6" align="center">
                        ADICIONAR SECCIÓN
                      </Typography>
                    </DialogTitle>
                    <DialogContent dividers>
                      <Grid container>
                        <Grid item xs={3}>
                          <TextField
                            readOnly
                            label="Código"
                            id="codigo"
                            value={seccion.codigo}
                            type="number"
                            style={{ width: '80%' }}
                          />
                        </Grid>
                        <Grid item xs={5}>
                          <FormControl style={{ minWidth: '180px' }} required>
                            <InputLabel id="planta">Planta</InputLabel>
                            <Select
                              name="planta"
                              onChange={handleSelect}
                              value={selectP}
                            >
                              {props.plantas.map(uni =>
                                (<MenuItem key={uni.id} value={uni.id}>{uni.descripcion}</MenuItem>))}
                            </Select>
                          </FormControl>
                        </Grid>
                        <Grid item xs={4}>
                          <FormControl style={{ minWidth: '150px' }} required>
                            <InputLabel id="unidadNegocio">Unidad de Negocio</InputLabel>
                            <Select
                              name="unidadNegocio"
                              onChange={handleSelect}
                              value={select}
                            >
                              {props.unidades.map(uni =>
                                (<MenuItem key={uni.id} value={uni.id}>{uni.descripcion}</MenuItem>))}
                            </Select>
                          </FormControl>
                        </Grid>
                        <Grid item xs={9} >
                          <TextField
                            required
                            label="Descripción"
                            id="descripcion"
                            fullWidth
                            defaultValue={seccion.descripcion}
                            onChange={handleChange}
                          />
                        </Grid>
                        <Grid item xs={3}>
                          <FormControlLabel
                            control={<Checkbox
                              color="primary"
                              checked={checked}
                              onChange={handleChecked}
                              name="checkedA"
                            />}
                            label="Incentivos"
                          />
                        </Grid>
                      </Grid>
                    </DialogContent>
                    <DialogActions>
                      <Button onClick={handleClose} color="default">
                        Cerrar
                      </Button>
                      <Button color="primary" type="submit" >
                        Modificar
                      </Button>
                    </DialogActions>
                  </form>
                </Dialog>
              </>
            );
          }
        }
      </Context.Consumer>
    </>
  );
}
