import config from './../../appConfig.json';
import { ejecutarConsulta, ejecutarActualizacion } from './../Comunes/datosComunes';

async function obtenerMetas(plantaId, fecha, signal) {
  return ejecutarConsulta(
    `${config.ApiUrl}/indicadores/textil/metas-dia-semana/planta/${plantaId}/${fecha}`,
    'GET',
    null,
    signal,
  );
}
async function actualizarMetas(plantaId, datos, signal) {
  return ejecutarActualizacion(
    `${config.ApiUrl}/indicadores/textil/metas/planta/${plantaId}`,
    'POST',
    datos,
    signal,
  );
}
export { obtenerMetas, actualizarMetas };

