import React from 'react';

import Divider from '@material-ui/core/Divider';

import BarraDeBusqueda from './Components/BarraDeBusqueda';
import ListaGenerales from './Components/ListaGenerales';

function Generales(props) {
  return (
    <>
      <BarraDeBusqueda
        onChange={props.onChange}
        Input={props.Input}
      />
      <Divider />
      <ListaGenerales {...props} />
    </>
  );
}

export default Generales;
