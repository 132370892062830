import React from 'react';
import ContextIncentivos from '../ContextoIncentivos';
import Alerta from '../../Comunes/Alerta';

const FiltroIncentivos = () => (
  <ContextIncentivos.Consumer>
    {
      ({
        error,
        fijarInfoError,
      }) => {
        const resetearError = () => {
          fijarInfoError(false, null);
        };
        return (
          <Alerta
            texto={error.mensaje}
            toggle={resetearError}
            isVisible={error.hayError}
          />
        );
      }
    }
  </ContextIncentivos.Consumer>
);

export default FiltroIncentivos;
