import React from 'react';
import { TextField, Grid, Button } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Snackbar from '@material-ui/core/Snackbar';
import { CloseBoxIcon } from 'mdi-react';
import ContextIncentivos from '../ContextoIncentivos';
import SelectorSubsecciones from './SelectorSubsecciones';
import {
  obtenerInfoEmpleado,
  obtenerPeriodoActual,
  obtenerTopeAdvertenciaIncentivo,
  guardarDatosAjuste,
} from '../datosIncentivos';
import { convertirDecimal, esDecimalValido } from '../../Comunes/datosComunes';
import MaterialDialog from '../../Comunes/MaterialDialog';

class Ajuste extends React.Component {
  static estadoErrorinicial() {
    return {
      hayErrores: false,
      cedula: [],
      subseccionId: [],
      valorIncentivo: [],
    };
  }
  constructor(props) {
    super(props);
    this.state = {
      cargando: false,
      cedula: '',
      nombres: '',
      apellidos: '',
      codigo: '',
      buscandoEmpleado: false,
      periodo: '',
      subseccionId: '',
      valorIncentivo: 0,
      cargandoPeriodo: false,
      topeAjuste: -1,
      alerta: false,
      confirmacionRequerida: false,
      mensajeAlerta: '',
      errores: Ajuste.estadoErrorinicial(),
    };
    this.mostrarAlerta = this.mostrarAlerta.bind(this);
    this.quitarAlerta = this.quitarAlerta.bind(this);
    this.restablecer = this.restablecer.bind(this);
    this.cargarPeriodoActual = this.cargarPeriodoActual.bind(this);
    this.validar = this.validar.bind(this);
    this.mostrarError = this.mostrarError.bind(this);
    this.textoValorIncentivo = this.textoValorIncentivo.bind(this);
    this.buscarEmpleado = this.buscarEmpleado.bind(this);
    this.valorIncentivoCambio = this.valorIncentivoCambio.bind(this);
    this.subseccionCambio = this.subseccionCambio.bind(this);
    this.cedulaCambio = this.cedulaCambio.bind(this);
    this.registrarAjuste = this.registrarAjuste.bind(this);
  }
  async componentDidMount() {
    await this.cargarPeriodoActual();
    await this.cargarTopAdvertenciaIncentivos();
  }
  abortController = new AbortController();
  nd = new Intl.NumberFormat();
  async mostrarAlerta(tiempo, mensaje) {
    await this.setState({ alerta: true, mensajeAlerta: mensaje });
    if (tiempo > 0) {
      setTimeout(() => {
        this.quitarAlerta();
      }, tiempo);
    }
  }
  async quitarAlerta() {
    await this.setState({ alerta: false, mensajeAlerta: '' });
  }
  async restablecer() {
    this.quitarAlerta();
    await this.setState({
      cedula: '',
      nombres: '',
      apellidos: '',
      codigo: '',
      subseccionId: '',
      valorIncentivo: 0,
    });
  }
  async cargarPeriodoActual() {
    try {
      await this.setState({ cargandoPeriodo: true });
      const p = await obtenerPeriodoActual(this.abortController.signal);
      if (!p.error) {
        await this.setState({ periodo: p.datos.periodo });
      } else {
        this.mostrarAlerta(3000, 'Falló la consulta del periodo actual');
      }
    } catch {
      await this.setState({ periodo: '' });
    } finally {
      await this.setState({ cargandoPeriodo: false });
    }
  }
  async cargarTopAdvertenciaIncentivos() {
    if (this.state.topeAjuste < 0) {
      try {
        const r = await obtenerTopeAdvertenciaIncentivo(this.abortController.signal);
        if (!r.error) {
          await this.setState({ topeAjuste: r.datos });
        } else {
          this.mostrarAlerta(3000, 'Falló la consulta del periodo actual');
        }
      } catch {
        await this.setState({ topeAjuste: -1 });
      }
    }
  }
  validar() {
    const xerrores = Ajuste.estadoErrorinicial();
    if (!this.state.cedula) {
      xerrores.hayErrores = true;
      xerrores.cedula.push('Es obligatorio');
    }
    if (!this.state.subseccionId) {
      xerrores.hayErrores = true;
      xerrores.subseccionId.push('Es obligatorio');
    }
    const valor = convertirDecimal(this.state.valorIncentivo);
    if (this.state.valorIncentivo == null || this.state.valorIncentivo === '') {
      xerrores.hayErrores = true;
      xerrores.valorIncentivo.push('Es obligatorio');
    } else if (isNaN(valor) || !esDecimalValido(this.state.valorIncentivo)) {
      xerrores.hayErrores = true;
      xerrores.valorIncentivo.push('Formato no válido');
    } else if (valor === 0) {
      xerrores.hayErrores = true;
      xerrores.valorIncentivo.push('Debe ser diferente de cero');
    }
    this.setState({ errores: xerrores });
    return !xerrores.hayErrores;
  }
  async registrarAjuste(info) {
    let resultado = null;
    try {
      await this.setState({ cargando: true });
      resultado = await guardarDatosAjuste(info, this.abortController.signal);
    } catch (e) {
      this.mostrarAlerta(3000, 'Se presentó un error al guardar los datos.');
    } finally {
      this.setState({ cargando: false });
    }
    return resultado;
  }
  async guardarAjuste() {
    const resultado = await this.registrarAjuste({
      cedula: this.state.cedula,
      seccionId: this.state.subseccionId,
      fecha: new Date(),
      periodo: this.state.periodo,
      valorIncentivo: this.state.valorIncentivo,
    });
    if (resultado.error) {
      this.setState({
        errores: {
          hayErrores: true,
          cedula: resultado.datos.cedula || [],
          subseccionId: resultado.datos.subseccionId || [],
          valorIncentivo: resultado.datos.valorIncentivo || [],
        },
      });
      return false;
    }
    return true;
  }
  mostrarError(campo) {
    let mensaje = null;
    if (this.state.errores && this.state.errores.hayErrores) {
      const mensajes = this.state.errores[campo];
      mensaje = mensajes.join(' - ');
    }
    return mensaje;
  }
  textoValorIncentivo(s) {
    const v = parseFloat(s);
    return this.nd.format(v);
  }
  async cedulaCambio(e) {
    await this.setState({ cedula: e.target.value, errores: { ...this.state.errores, cedula: [] } });
  }
  async buscarEmpleado(e) {
    let xnombres = '';
    let xapellidos = '';
    let xcodigo = '';
    if (this.state.cedula) {
      await this.setState({ buscandoEmpleado: true });
      this.mostrarAlerta(-1, 'Buscando');
      const info = await obtenerInfoEmpleado(this.state.cedula);
      if (!info.error) {
        this.quitarAlerta();
        xnombres = info.datos.nombre;
        xapellidos = info.datos.apellidos;
        xcodigo = info.datos.codigoEmpleado;
      } else {
        this.mostrarAlerta(3000, 'El operario no existe');
      }
    }
    await this.setState({
      nombres: xnombres,
      apellidos: xapellidos,
      codigo: xcodigo,
      buscandoEmpleado: false,
    });
  }
  async valorIncentivoCambio(e) {
    await this.setState({ valorIncentivo: e.target.value, errores: { ...this.state.errores, valorIncentivo: [] } });
  }
  async subseccionCambio(e) {
    await this.setState({ subseccionId: e.target.value, errores: { ...this.state.errores, subseccionId: [] } });
  }
  render() {
    return (
      <ContextIncentivos.Consumer>
        {
          ({
            ejecutarConsultaIncentivo,
            registrandoAjuste,
            fijarEstadoRegistroAjuste,
          }) => {
            const cancelarAjuste = () => {
              this.restablecer();
              fijarEstadoRegistroAjuste(false);
              this.setState({ errores: Ajuste.estadoErrorinicial() });
            };
            const iniciarGuardadoAjuste = async (confirmado) => {
              if (this.validar()) {
                const valor = parseFloat(this.state.valorIncentivo);
                if (Math.abs(valor) > this.state.topeAjuste && !confirmado) {
                  await this.setState({ confirmacionRequerida: true });
                } else {
                  await this.setState({ confirmacionRequerida: false });
                  if (await this.guardarAjuste()) {
                    await this.restablecer();
                    await fijarEstadoRegistroAjuste(false);
                    await ejecutarConsultaIncentivo(true);
                  }
                }
              }
            };
            const ejecutarGuardado = async () => {
              await iniciarGuardadoAjuste(false);
            };
            const confirmarAjuste = async () => {
              await iniciarGuardadoAjuste(true);
            };
            const descartarAjuste = async () => {
              await this.setState({ confirmacionRequerida: false });
            };
            const ajusteForm = () => (
              <React.Fragment>
                <MaterialDialog
                  mostrar={this.state.confirmacionRequerida}
                  titulo="Confirmación de ajuste"
                  texto={`El valor ingresado, ${this.textoValorIncentivo(this.state.valorIncentivo)}, ` +
                  `constituye un ajuste superior a ${this.textoValorIncentivo(this.state.topeAjuste)} ` +
                  '¿Desea continuar?'}
                  textoAfirmativo="Sí"
                  textoNegativo="No"
                  afirmativo={confirmarAjuste}
                  negativo={descartarAjuste}
                />
                <Dialog
                  open={registrandoAjuste}
                  aria-labelledby="alert-dialog-title"
                  aria-describedby="alert-dialog-description"
                  maxWidth="md"
                  fullWidth
                >
                  <DialogTitle id="alert-dialog-title">
                    Registro de ajuste
                  </DialogTitle>
                  <DialogContent>
                    <Snackbar
                      open={this.state.alerta}
                      message={this.state.mensajeAlerta}
                      action={
                        <Button
                          variant="outlined"
                          color="light"
                          onClick={() => this.quitarAlerta()}
                        >
                          <CloseBoxIcon />
                        </Button>
                      }
                    />
                    <Grid container item spacing={4} xs={12}>
                      <Grid item xs={12} md={4}>
                        <TextField
                          label="Cédula"
                          autoFocus
                          fullWidth
                          value={this.state.cedula}
                          onChange={this.cedulaCambio}
                          onBlur={this.buscarEmpleado}
                          error={this.state.errores.hayErrores && this.state.errores.cedula.length > 0}
                          helperText={this.mostrarError('cedula')}
                        />
                      </Grid>
                      <Grid item xs={12} md={4}>
                        <TextField
                          label="Nombre"
                          fullWidth
                          value={
                            this.state.buscandoEmpleado ? 'Espere...' : `${this.state.nombres} ${this.state.apellidos}`
                          }
                          inputProps={
                            { readOnly: true }
                          }
                        />
                      </Grid>
                      <Grid item xs={12} md={4}>
                        <TextField
                          label="Código"
                          fullWidth
                          value={this.state.buscandoEmpleado ? 'Espere...' : this.state.codigo || ''}
                          inputProps={
                            { readOnly: true }
                          }
                        />
                      </Grid>
                    </Grid>
                    <Grid container item spacing={4} xs={12}>
                      <Grid item xs={12} md={4}>
                        <TextField
                          label="Periodo"
                          fullWidth
                          value={this.state.cargandoPeriodo ? 'Espere...' : this.state.periodo}
                          inputProps={
                            { readOnly: true }
                          }
                        />
                      </Grid>
                      <Grid item xs={12} md={4}>
                        <SelectorSubsecciones
                          value={this.state.subseccionId}
                          fullWidth
                          onChange={this.subseccionCambio}
                          error={this.state.errores.hayErrores && this.state.errores.subseccionId.length > 0}
                          mensaje={this.mostrarError('subseccionId')}
                        />
                      </Grid>
                      <Grid item xs={12} md={4}>
                        <TextField
                          label="Ajuste"
                          fullWidth
                          value={this.state.valorIncentivo}
                          onChange={this.valorIncentivoCambio}
                          error={this.state.errores.hayErrores && this.state.errores.valorIncentivo.length > 0}
                          helperText={this.mostrarError('valorIncentivo')}
                        />
                      </Grid>
                    </Grid>
                  </DialogContent>
                  <DialogActions>
                    <Button onClick={ejecutarGuardado} color="primary" disabled={this.state.cargando}>
                      Guardar
                    </Button>
                    <Button onClick={cancelarAjuste} color="primary" disabled={this.state.cargando}>
                      Cancelar
                    </Button>
                  </DialogActions>
                </Dialog>
              </React.Fragment>
            );
            return ajusteForm();
          }
        }
      </ContextIncentivos.Consumer>
    );
  }
}

export default Ajuste;
