import React from 'react';
import {
  makeStyles,
  Button,
  TextField,
  Dialog,
  DialogContent,
  DialogTitle,
  Fab,
  Tooltip,
  DialogActions,
} from '@material-ui/core';
import { AddIcon } from 'mdi-react';

import Context from '../../store';

import config from '../../../../appConfig.json';

import Alert from '../../../../shared/components/alert/Alert';
import { perteneceRolIngenieria } from '../../../Comunes/datosComunes';

const useStyles = makeStyles(theme => ({
  textField: {
    width: '200px',
  },
  textField2: {
    width: '189px',
    '& label.Mui-focused': {
      color: 'red',
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: 'red',
    },
    '& .MuiOutlinedInput-root': {
      '&:hover fieldset': {
        borderColor: 'blue',
      },
      '&.Mui-focused fieldset': {
        borderColor: 'red',
      },
    },
  },
  textField3: {
    '& label.Mui-focused': {
      color: 'red',
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: 'red',
    },
    '& .MuiOutlinedInput-root': {
      '&.Mui-focused fieldset': {
        borderColor: 'red',
      },
    },
  },
}));

function AdicionarDetallada() {
  const classes = useStyles();
  const [alert, setAlert] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const [Detallada, setDetallada] = React.useState({
    CodigoOperacionDetallada: '',
    DescripcionOperacionDetallada: '',
    OperacionGeneral: 0,
    Subseccion: 0,
    Usuario: '',
  });

  const handleOpen = (op) => {
    const array = Detallada;
    array.OperacionGeneral = op.id;
    array.Subseccion = op.subseccion.id;
    array.Usuario = op.usuario;
    setDetallada(array);
    setOpen(true);
  };

  const handleChange = (e) => {
    const array = Detallada;
    array[e.target.id] = e.target.value;
    setDetallada(array);
  };

  const handleClose = () => {
    setAlert(false);
    setOpen(false);
  };
  return (
    <>
      <Context.Consumer>
        {
          (value) => {
            const handleValueChange = async (e) => {
              e.preventDefault();
                const request = await fetch(`${config.ApiUrl}/operaciones/nueva/detallada`, {
                mode: 'cors',
                method: 'PUT',
                body: JSON.stringify(Detallada),
                headers: {
                  'Content-Type': 'application/json',
                  Authorization: localStorage.getItem(config.AuthTokenKey),
                  Accept: 'application/json',
                },
              });

              const response = await request.json();
              if (!response) {
                setAlert(true);
              } else {
                setAlert(false);
                const response2 = await
                fetch(
                  `${config.ApiUrl}/Operaciones/${Detallada.OperacionGeneral}/OperacionesDet`,
                  {
                    headers: {
                      'Content-Type': 'application/json',
                      Authorization: localStorage.getItem(config.AuthTokenKey),
                      Accept: 'application/json',
                    },
                  },
                );
                const det = await response2.json();
                value.activateDet(det);
                value.activateDetOrigin(det);
                handleClose();
              }
            };

            return (
                <>
                  {
                    perteneceRolIngenieria() &&
                    <Tooltip title="Adiciona una operación detallada" aria-label="add">
                      <Fab
                        aria-label="add"
                        size="small"
                        color="action"
                        onClick={() => { handleOpen(value.operacion); }}
                      >
                        <AddIcon />
                      </Fab>
                    </Tooltip>
                  }
                  <Dialog
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="form-dialog-title"
                  >
                    <form onSubmit={handleValueChange} >
                      <DialogTitle id="form-dialog-title" align="center">ADICIONAR OPERACIÓN DETALLADA</DialogTitle>
                      <DialogContent>
                        <Alert
                          color="danger"
                          className="alert--bordered"
                          icon
                          visible={alert}
                        >
                          ESTA OPERACIÓN DETALLADA YA ESTÁ REGISTRADA.
                        </Alert>
                        <div className="container">
                          <div className="row">
                            <div className="col-12">
                              <TextField
                                className={classes.textField3}
                                readonly
                                label="OperacionGeneral"
                                id="OperacionGeneral"
                                value={value.operacion.descripcion.toUpperCase()}
                                fullWidth
                                margin="dense"
                              />
                            </div>
                            <div className="col-6">
                              <TextField
                                required
                                label="Código"
                                id="CodigoOperacionDetallada"
                                className={classes.textField}
                                margin="dense"
                                variant="outlined"
                                type="number"
                                onChange={handleChange}
                              />
                            </div>
                            <div className="col-6">
                              <TextField
                                label="Subsección"
                                id="Subseccion"
                                className={classes.textField2}
                                margin="dense"
                                value={value.operacion.subseccion.descripcion.toUpperCase()}
                                variant="outlined"
                              />
                            </div>
                            <div className="col-12">
                              <TextField
                                required
                                id="DescripcionOperacionDetallada"
                                label="Descripción"
                                style={{ margin: 8 }}
                                helperText="Escribe la descripción o nombre la operación detallada."
                                fullWidth
                                onChange={handleChange}
                                margin="normal"
                                InputLabelProps={{
                                          shrink: true,
                                      }}
                              />
                            </div>
                          </div>
                        </div>
                      </DialogContent>
                      <DialogActions>
                        <Button type="submit" color="primary">
                            Guardar
                        </Button>
                        <Button onClick={handleClose} color="primary">
                          Cancelar
                        </Button>
                      </DialogActions>
                    </form>
                  </Dialog>
                </>
              );
            }
          }
      </Context.Consumer>
    </>
  );
}

export default AdicionarDetallada;
