import React from 'react';
import 'react-picky/dist/picky.css';
import PropTypes from 'prop-types';
import { obtenerSecciones } from './datosComunes';
import MaterialUISelectorMultiple from './MateriaUISelectorMultiple';


class ListaSeccionesMultiple extends React.Component {
  static infoSeccion(s) {
    return { id: s.id, descripcion: s.descripcion };
  }
  constructor(props) {
    super(props);
    this.state = {
      listaSecciones: [],
      loading: true,
    };
  }
  componentDidMount() {
    const ref = this;
    obtenerSecciones(this.abortController.signal)
      .then(r =>
        ref.setState({ listaSecciones: r.map(s => ListaSeccionesMultiple.infoSeccion(s)), loading: false }));
  }
  abortController = new AbortController();
  render() {
    return (
      <MaterialUISelectorMultiple
        variant="outlined"
        cargando={this.state.loading}
        lista={this.state.listaSecciones}
        label="Sección"
        placeholder="Secciones"
        value={this.props.secciones}
        onChange={this.props.onChange}
      />
    );
  }
}
ListaSeccionesMultiple.propTypes =
{
  onChange: PropTypes.func.isRequired,
  secciones: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string.isRequired, descripcion: PropTypes.string.isRequired,
  })).isRequired,
};
export default ListaSeccionesMultiple;
